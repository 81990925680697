import React, {useEffect} from 'react';
import s from './EditTourOperator.module.css';
import {useTranslation} from "react-i18next";
import {useOutletContext} from "react-router-dom";
import {useForm, FormProvider} from "react-hook-form";
import Button from "../Inputs/Button_old";
import {TextInput} from "@mantine/core";
import {AiOutlineMail, AiOutlinePhone} from "react-icons/ai";

const EditTourOperator = ({onSubmit, onCancel}) => {
  const {t} = useTranslation();
  const {tourOperator} = useOutletContext();
  const form = useForm();
  const {register, setValue, getValues} = form;


  const onSubmitClick = async () => {
    let data = getValues();
    console.log(data);
    if(onSubmit)return await onSubmit(data);
  }


  useEffect(() => {
    if(!tourOperator)return;
    ['name', 'surname', 'phone', 'email'].forEach(key => {
      if(tourOperator[key])setValue(key, tourOperator[key]);
    });
  } ,[tourOperator]);


  return (
    <FormProvider {...form}>
      <div className={"container m-auto ring-2 p-5 rounded-xl"}>

      <div className="w-full flex">
        <TextInput
          {...register('name')}
          label={t('tour operators data.name')}
          className="mb-5 mr-5 w-full"
        />
        <TextInput
          {...register('surname')}
          label={t('tour operators data.surname')}
          className={"mb-5 w-full"}
        />
      </div>

      <div className="w-full flex">
        <TextInput
          {...register('email')}
          label={t('tour operators data.email')}
          className={"mb-5 mr-5 w-full"}
          icon={<AiOutlineMail/>}
        />
        <TextInput
          {...register('phone')}
          label={t('tour operators data.phone')}
          className={"mb-5 w-full"}
          icon={<AiOutlinePhone/>}
        />
      </div>
      <hr className={"my-10"}/>
        <div className="flex flex-nowrap md:w-1/2 m-auto">
          <Button onClick={onSubmitClick} loadButton={true} className="mr-5">{t('tour operators data.edit button')}</Button>
          <Button onClick={onCancel}>{t('tour operators data.go back')}</Button>
        </div>

    </div>
    </FormProvider>
  );
};


export default EditTourOperator;
