import React, {useState} from 'react';
import s from './HomePage.module.css';
import {BrowserRouter, Outlet, useRoutes} from "react-router-dom";
import Menu from "../SideBar";
import Header from "../Header";
import ErrorCallbackComponent from "../ErrorCallbackComponent";
import {ErrorBoundary} from 'react-error-boundary'
import routes from "../../routes/content";

const RouteWrapper = () => useRoutes(routes);

const HomePage = props => {
  return (
    <div className={s.pageContainer}>
      <div className={s.leftContainer}>
        <Menu/>
      </div>
      <div className={s.rightContainer}>
        <Header/>
        <ErrorBoundary FallbackComponent={ErrorCallbackComponent}>
          <RouteWrapper/>
        </ErrorBoundary>
      </div>

    </div>
  );
};

export default HomePage;
