import {GoLocation} from "react-icons/go";

export const cutText = (text, maxSize, add = "...") => {
	if (typeof text !== 'string') return text;
	if (text.length > maxSize) return text.substring(0, maxSize - add.length) + add;
	return text;
}

export const formatSearchBarResults = (results, replace = {}) => {
	if (!Array.isArray(results)) return [];
	return results.map(result => ({
		value: result.label,
		description: result.smallLabel,
		icon: <GoLocation/>,
		raw: result,
		...replace
	})).filter(result => result.value && result.value.length > 0)
}

export const removeHTMLTags = text => typeof (text) === 'string' ? text.replace(/(<([^>]+)>)/ig, '') : text;
export const debounce = (callback, delay) => {
	let timeout = null;
	return async function (...args) {
		if (timeout) clearTimeout(timeout);
		timeout = setTimeout(async () => {
			await callback(...args);
			timeout = null;
		}, delay);
	}
}


export const distanceBetweenPoints = (a, b) => {
	if(!a.latitude || !a.longitude || !b.latitude || !b.longitude)return 0;

	const R = 6371e3;
	const p1 = a.latitude * Math.PI / 180; // in radians
	const p2 = b.latitude * Math.PI / 180;
	const l1 = (b.latitude - a.latitude) * Math.PI / 180;
	const l2 = (b.longitude - a.longitude) * Math.PI / 180;

	const p = Math.sin(l1 / 2) * Math.sin(l1 / 2) +
		Math.cos(p1) * Math.cos(p2) *
		Math.sin(l2 / 2) * Math.sin(l2 / 2);
	const c = 2 * Math.atan2(Math.sqrt(p), Math.sqrt(1 - p));

	return R * c; // in metres
}