import React, {useEffect} from 'react';
import s from './ToAvailability.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {formList, useForm} from "@mantine/form";
import {DateRangePicker} from "@mantine/dates";
import {AiOutlineDelete} from "react-icons/ai";
import sn from "../TONew/TONew.module.css";
import bases from "../../../routes/bases";
import {Itinerary, OutletItineraryContext} from "../../../types/itineraries";
import Switch from "../Itineraries/Inputs/Switch/Switch";
import Footer from "../Components/Footer/Footer";
import Button from "../Itineraries/Inputs/Button/Button";
import {GrAddCircle} from "react-icons/gr";
import HeaderTitle from "../Components/HeaderTitle/HeaderTitle";

const ToAvailability = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const {itinerary, setStep, saveItinerary, isItineraryBlocked} = useOutletContext<OutletItineraryContext>();
	const {id} = useParams();

	let form = useForm<{
		all_year: boolean,
		dates: {date: [Date | null, Date | null]}[]
	}>({
		initialValues: {
			all_year: false,
			dates: formList([
				{date: [null, null]}
			])
		}
	});

	useEffect(() => {
		setStep('availability');
	}, []);


	useEffect(() => {
		if (!itinerary || !itinerary.availability) return;
		form.setFieldValue('all_year', itinerary.availability.all_year);
		if (Array.isArray(itinerary.availability.dates)) {
			form.setFieldValue('dates', formList(itinerary.availability.dates.map(({date}) => ({
				date: [new Date(date[0]), new Date(date[1])]
			}))));
		}
	}, [itinerary]);

	const onSubmit = async () => {
		let data = {
			availability: form.values as unknown as Itinerary['availability']
		};

		if (await saveItinerary(data)) navigate(`/${bases.tour_operator_itineraries}/${id}/hotels`);
	}

	return (
		<div className="container m-auto">
			<HeaderTitle>{t('to.header.availability')}</HeaderTitle>
			<div className="flex justify-start">
				<Switch
				className="my-10"
				{...form.getInputProps('all_year', {type: 'checkbox'})}
				label={t('to.availability.all year available')}
				size="md"
				disabled={isItineraryBlocked}
			/>
			</div>


			{!form.values.all_year && <div>
				<div className="w-full flex flex-wrap">
					{form.values.dates.map((_, index) => (
						<div className="flex items-end mb-5">
							<DateRangePicker
								label={t('to.availability.date')}
								placeholder={t('to.availability.date')}
								minDate={new Date()}
								style={{width: '300px'}}
								// @ts-ignore
								{...form.getListInputProps('dates', index, 'date')}
								className="mr-10"
								disabled={isItineraryBlocked}
							/>
							<div style={{width: '400px'}} className="ml-10">
								<Button disabled={isItineraryBlocked} leftIcon={<AiOutlineDelete size={18}/>} color="red" variant="outline"
												onClick={() => form.removeListItem('dates', index)}>{t('to.availability.remove date')}</Button>
							</div>
						</div>
					))}
				</div>
				<Button disabled={isItineraryBlocked} color="yellow" variant="filled" shadow_border={true}
								rightIcon={<GrAddCircle className={s.add_circle_stroke} size={20} />}
					// @ts-ignore
								onClick={() => form.addListItem('dates', {date: [null, null]})}>{t('to.availability.add new date')}</Button>
			</div>}

			<Footer onSave={onSubmit}/>
		</div>
	);
};


export default ToAvailability;