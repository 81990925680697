import React, {useState} from 'react';
import s from './ToggleComponent.module.css';
import Title from "../LayoutComponents/Title";
import {AiOutlineUp} from "react-icons/ai";

// falta terminar. Hay que usar visiblity: hidden
const ToggleComponent = ({children, titleSize = 3,title, defaultValue = false, loadOnToggle = true}) => {
  const [visible, setVisible] = useState(defaultValue);


  return <div className={s.title}>
    <div className="flex flex-nowrap items-center p-3 cursor-pointer" onClick={() => setVisible(prev => !prev)}>
      <AiOutlineUp
        size={18}
        className={`${visible ? 'rotate-180' : ''} mr-5 transform transition-transform duration-700`}/>
      <Title size={titleSize}>{title}</Title>
    </div>
    {visible && children}
  </div>

};


export default ToggleComponent;
