import React, {useEffect, useState, useMemo, useCallback} from 'react';
import s from './Gallery.module.css';
import {useTranslation} from "react-i18next";
import {useGallery} from "../../controllers/useGallery/useGallery";
import {toastError} from "../../helpers/ErrorManager";
import {Divider, Loader, Menu} from "@mantine/core";
import Button from "../Inputs/Button";
import Uppy from "@uppy/core";
import {Dashboard} from "@uppy/react";
import {TiDeleteOutline, TiTick} from "react-icons/ti";
import {AiOutlineEdit} from "react-icons/ai";

import Spanish from '@uppy/locales/lib/es_ES';
import Tus from '@uppy/tus';
import Compressor from '@uppy/compressor';
import {BiTrash} from "react-icons/bi";
import {BsFillCheckSquareFill} from "react-icons/bs";

import config from '../../config';
import notFoundSVG from "../../resources/svg/not_found_image.svg";

const Albums = ({selected, onSelect}) => {
	const {t} = useTranslation();

	const {getAllAlbums, deleteAlbum, newAlbum, editAlbum} = useGallery();
	
	const [albums, setAlbums] = useState([]);
	const [loading, setLoading] = useState(false);

	
	
	const loadAlbums = async ({signal}) => {
		setLoading(true);
		const {error, albums} = await getAllAlbums({signal: signal});
		if (error) return toastError(error, t);
		setAlbums(albums);
		if(!selected && albums.length > 0) {
			onSelect(albums[0]);
		}
		setLoading(false);
	}
	
	useEffect(() => {
		const abortController = new AbortController();
		loadAlbums({signal: abortController.signal});
		return () => abortController.abort();
	}, []);

	const onNewAlbum = async () => {
		let name = prompt(t('gallery data.new album ask name'));
		if(!name)return;
		const {error, id} = await newAlbum({name});
		if (error) return toastError(error, t);
		setAlbums([...albums, {name, _id: id}]);
	}

	const onAlbumDelete = async (id) => {
		if(window.confirm(t('gallery data.delete album confirm'))){
			const {success} = await deleteAlbum({id});
			if(!success)return toastError(t('gallery data.delete album error'), t);
			setAlbums(albums.filter(a => a._id !== id));
		}
	}

	const onAlbumEdit = async ({_id, name}) => {
		let newName = prompt(t('gallery data.new album ask name'), name);
		if(!newName)return;
		setLoading(true);
		const {error, success} = await editAlbum({name: newName, id: _id});
		setLoading(false);
		if(error)return toastError(error, t);
		if(!success)return toastError(t('gallery data.edit album error'), t);
		setAlbums(prev => prev.map(a => a._id === _id ? {...a, name: newName} : a));
	}


	if(loading) return <Loader/>;
	
	return <div>
		<div className="flex flex-wrap">
			{albums && albums.map(album => <div onClick={() => onSelect(album)} key={album._id}
																					className={`${s.album} ${selected._id === album._id ? s.selectedAlbum : ''}`}>
				<div>{album.name}</div>
				<div className={s.albumDelete} onClick={() => onAlbumDelete(album._id)}><TiDeleteOutline size={16}/></div>
				<div className={s.albumEdit} onClick={() => onAlbumEdit(album)}><AiOutlineEdit size={16}/></div>
			</div>)}
		</div>
		<div className="mx-5 mt-5">
			<Button onClick={onNewAlbum}>{t('gallery data.new album')}</Button>
		</div>
	</div>
}

const ListImages = ({images, selected, onChange, onImageSelect}) => {
	const {t} = useTranslation();

	const {renameImage, deleteImage} = useGallery();

	const onImageDelete = async (image) => {
		if(!window.confirm(t('gallery data.delete image confirm')))return;
		const {error, success} = await deleteImage({id: image._id});
		if(error)return toastError(error, t);
		if(!success)return toastError(t('gallery data.delete image error'), t);
		onChange();
	}

	const onImageRename = async (image) => {
		let name = window.prompt(t('gallery data.new image ask name'), image.name);
		if(!name)return;
		const {error, success} = await renameImage({id: image._id, name});

		if(error)return toastError(error, t);
		if(!success)return toastError(t('gallery data.rename image error'), t);

		onChange();
	}


	return <div className={s.imagesContainer}>
		{Array.isArray(images) && images.map(image => {
			let isSelected = selected.findIndex(file_id => file_id === image.file_id) !== -1;
			return <div className={s.image}>
				<img src={`${config.imagesURL}/${image.file_id}`} onClick={() => onImageSelect(image)}/>
				{isSelected && <div onClick={() => onImageSelect(image)} className={s.imageSelected}><BsFillCheckSquareFill size={50}/></div>}
				<div className={s.imageNameContainer}>
					<div className={s.imageName}>{image.name}</div>

					<Menu control={<div><AiOutlineEdit size={18} className={s.pencil}/></div>}>
						<Menu.Item icon={<TiTick size={20}/>} onClick={() => onImageSelect(image)}>{t(`gallery data.${isSelected ? 'de' : ''}select image`)}</Menu.Item>
						<Menu.Item icon={<AiOutlineEdit size={20}/>} onClick={() => onImageRename(image)}>{t('gallery data.rename image')}</Menu.Item>
						<Divider/>
						<Menu.Item color="red" icon={<BiTrash size={20}/>} onClick={() => onImageDelete(image)}>{t('gallery data.delete image')}</Menu.Item>
					</Menu>
				</div>
			</div>
		})}
	</div>

}


const Gallery = ({maxSelections = false, onDone, defaultValue}) => {
	const {t} = useTranslation();
	const {getAllImages} = useGallery();

	const [album, setAlbum] = useState(null);
	const [images, setImages] = useState([]);
	const [selectedImages, setSelectedImages] = useState([]);
	const [loading, setLoading] = useState(true);

	const loadImages = useCallback(async () => {
		setLoading(true);
		const {error, images} = await getAllImages({album_id: album?._id});
		setLoading(false);
		if (error) return toastError(error, t);
		setImages(images);
	}, [album, getAllImages, t]);

	useEffect(() => {
		if(album)loadImages();
	}, [album]);

	const uppy = useMemo(() => {
		let u = new Uppy({
			autoProceed: true,
			allowMultipleUploads: true,
			debug: true,
			locale: Spanish,
			restrictions: {
				maxFileSize: 100000000,
				maxNumberOfFiles: 10,
				minNumberOfFiles: 1,
				allowedFileTypes: ['image/*']
			},
			meta: {}
		});
		u.use(Tus,{
			endpoint: 'https://upload.trippytrekkers.com/tp_tus_upload',
			//endpoint: 'http://localhost:1080/tp_tus_upload',
			retryDelays: [0, 1000, 3000, 5000]
		});

		u.use(Compressor, {
			quality: 0.8,
			maxWidth: 1920,
			maxHeight: 1080
		});

		u.on('complete', (file) => {
			console.log('Added file', file);
			loadImages();
		});
		u.on('error', error => {
			console.log(error);
		});

/*		u.use(GoogleDrive, {
			target: Dashboard,
			companionUrl: 'http://localhost:1080/tp_tus_upload'
		});*/
		return u;
	} ,[loadImages]);
	useEffect(() => {
		return () => uppy.close({reason: 'unmount'});
	}, [uppy]);

	useEffect(() => {
		if(album) {
			uppy.setMeta({
				album_id: album._id
			});
		}
	}, [album, uppy]);

	useEffect(() => {
		if(Array.isArray(defaultValue) && defaultValue.length > 0 && Array.isArray(images)){
			setSelectedImages(defaultValue);
		}
	}, [defaultValue, images]);

	const onImageSelect = image => {
		setSelectedImages(prev => {
			let r;
			if(!prev.includes(image.file_id)){
				r = [...prev, image.file_id];
			}else{
				r = prev.filter(file_id => file_id !== image.file_id);
			}
			if(maxSelections && r.length > maxSelections){
				r = r.slice(1);
			}
			return r;
		});
	}


	return (
		<div className="w-full flex flex-nowrap">
			<div className={s.albumsContainer}>
				<Albums selected={album} onSelect={album => setAlbum(album)}/>
			</div>
			<div className={s.rightContainer}>

				{selectedImages.length > 0 && <div className={s.selectedImagesText}>
					<div className="flex flex-nowrap">
						<div
							className="mr-5">{selectedImages.length} {t('gallery data.selected images', {count: selectedImages.length})}</div>
						<div className={s.selectedImagesDeselect}
								 onClick={() => setSelectedImages([])}>{t('gallery data.deselect')}</div>
					</div>
					{onDone && <Button color="green" onClick={() => onDone(selectedImages)}>{t('gallery data.continue')}</Button>}
				</div>}

{/*				{(images.length === 0 || showDashbard) && <Dashboard uppy={uppy}/>}
				{images.length > 0 && !showDashbard && <Button>{t('gallery data.upload new photo')}</Button>}*/}
				{loading ? <Loader/> : <ListImages onChange={() => loadImages()} selected={selectedImages} onImageSelect={image => onImageSelect(image)} images={images}/>}
				<Dashboard uppy={uppy}/>
			</div>
		</div>
	);
};


export default Gallery;