import React, {useEffect, useState} from 'react';
import s from './SmallActivity.module.css';
import {useTranslation} from "react-i18next";
import notFoundSVG from "../../../../resources/svg/not_found_image.svg";
import {cutText, removeHTMLTags} from "../../../../helpers/util";
import useActivity from "../../../../controllers/useActivity/useActivity";
import ShowError from "../../../ShowError";
import Loading from "../../../Loading";
import {openActivity} from "../../../../helpers/externalPage";
import {MoneyRender} from "../../../Table/Renders";

import config from '../../../../config';
import {langRender} from "../../../../helpers/i18n";
import Image from "../../../Image";

const SmallActivity = ({
												 loading,
												 activityToLoad,
												 api,
												 activityCode,
												 name,
												 description,
												 picture,
												 available,
												 other,
												optionalPrice, // for summary
												 canOpenActivity = true,
												 compact: _compact = false,
												 ...rest
											 }) => {
	const {t} = useTranslation();
	const height = 150;
	const [activity, setActivity] = useState();
	const [error, setError] = useState();
	const [compact, setCompact] = useState(_compact);

	const {searchActivityByCode} = useActivity();

	picture = picture || (Array.isArray(rest.images) && rest.images.length && `${config.imagesURL}/${rest.images[0]}`);


	const loadActivity = async () => {
		const {error, activity} = await searchActivityByCode({
			code: activityToLoad.code,
			api: activityToLoad.api
		});
		if (error || !activity) return setError(error || 'activity not found');
		if (activity) setActivity(activity);
	}

	useEffect(() => {
		if (activityToLoad) {
			loadActivity();
		} else {
			setActivity(rest);
		}
	}, [activityToLoad]);


	if (error) return <ShowError error={error}/>
	if (activityToLoad && !activity) return <Loading/>
	if (activityToLoad && activity) return <SmallActivity
		{...activity}
	/>
	const compactClass = compact ? s.compact : s.notCompact;

	return <div onMouseEnter={() => _compact && setCompact(false)} onMouseLeave={() => _compact && setCompact(!compact)}
							className={s.activity} onClick={() => {
		if (canOpenActivity) openActivity({api, code: activityCode})
	}}>
		{loading ? <Loading/> : <>
      <Image className={compactClass} src={picture}  alt={t('small activity.picture alt')} style={{width: 200, height}}/>
			{/*<img className={compactClass} src={picture} onError={e => e.target.src = notFoundSVG}*/}
			{/*			 alt={t('small activity.picture alt')} width={200} height={height}/>*/}
			<div style={{height: compact ? 'auto' : height}} className="ml-5">
				<div className="font-bold text-2xl mb-1">{langRender(name)}</div>
				{optionalPrice && <div>{t('to.activity.optional')} {<MoneyRender value={optionalPrice}/>}</div>}
				<div className={compactClass}>
					 <div className={s.description}>{cutText(removeHTMLTags(langRender(description)), 200)}</div>
					{/*      {Array.isArray(available) && available.filter(a => a.start.day && a.start.type && a.end.day && a.end.type).map(available => {
        return <div className="font-bold">
          {`${t('to.activity.day')} ${available.start.day} ${available.start.type === 'day_hour' ? available.start.hour : ''} - `}
          {`${available.start.day !== available.end.day ? `${t('to.activity.day')} ${available.end.day}` : ''} `}
          {available.end.type === 'day_hour' ? available.end.hour : ''}
        </div>
      })}*/}


					{rest.optional && <>
						{t('to.activity.optional') + " "}
						{!!rest.price && <MoneyRender value={rest.price}/>}
					</>}
					{activity?.dateFrom && <div className="font-bold">
						{activity?.dateFrom}
						{(activity?.dateTo && activity?.dateFrom !== activity?.dateTo) && ' - ' + activity?.dateTo}
					</div>}
					{other}
				</div>
			</div>
		</>}
	</div>
}


export default SmallActivity;
