import {Button, DatePicker, Form, FormProps} from "antd";
import Input from "antd/es/input";
import {EventFormValues, CreateEvent, Event} from "./types";
import dayjs from "dayjs";
import {dateToServer} from "../../../util/util";
import {useMemo} from "react";
import {canTDWrite} from "../../Permissions/VisibleWithPermissions";



interface EventFormProps extends FormProps {
  onFinish: (values: CreateEvent) => void
  loading?: boolean
}

export function TDEventForm({onFinish, initialValues, loading, ...props}: EventFormProps) {

  const defaultInitialValues: Partial<EventFormValues> = {
    color: '#e09052',
  }

  const onFinishHandler = (values: EventFormValues) => {
    console.log(values)
    onFinish({
      name: values.name,
      description: values.description,
      color: values.color,
      from: dateToServer(values.date[0].toDate()),
      to: dateToServer(values.date[1].toDate())
    })
  }

  const parseInitialValues = (event?: Event) => {
    if(!event) return {}
    const values: EventFormValues = {
      name: event.name,
      description: event.description,
      color: event.color,
      date: [dayjs(event.from), dayjs(event.to)]
    }
    return values
  }

  const finalInitialValues = {
    ...defaultInitialValues,
    ...parseInitialValues(initialValues as Event)
  }

  const canWrite = useMemo(() => canTDWrite(), [])


  return <Form labelCol={{span: 3}} className="px-5" {...props} initialValues={finalInitialValues} onFinish={onFinishHandler}>
    <Form.Item name="name" label="Nombre" rules={[{required: true}]}>
      <Input/>
    </Form.Item>
    <Form.Item name="date" label="Fecha" rules={[{required: true}]}>
      <DatePicker.RangePicker minDate={dayjs()} />
    </Form.Item>
    <Form.Item name="description" label="Descripción">
      <Input.TextArea />
    </Form.Item>
    <Form.Item name="color" label="Color">
      <Input type="color" />
    </Form.Item>



    {canWrite && <Form.Item wrapperCol={{span: 3, offset: 10}}>
      <Button loading={loading} disabled={loading} type="primary" htmlType="submit">Guardar</Button>
    </Form.Item>}
  </Form>

}