import React, {useEffect, useState} from 'react';
import s from './ProgressiveShow.module.css';

interface ProgressiveShowProps {
  visible?: boolean;
  showIn?: number;
  children: any;
}

const ProgressiveShow = ({visible: _visible, children, showIn }: ProgressiveShowProps) => {
  const [visible, setVisible] = useState(_visible);


  useEffect(() => {
    if(!showIn)return;
    setTimeout(() => {
      setVisible(true);
    }, showIn);
  }, [showIn]);

  useEffect(() => {
    if(_visible !== undefined)setVisible(_visible);
  }, [_visible]);

  const className = `${s.container} ${visible ? s.visible : ''}`;

  return (
    <div className={className}>
      {children}
    </div>
  );
};


export default ProgressiveShow;
