import dayjs from "dayjs";
import {TableDefaultClientParams, TableParams, TableSortOrder} from "./types";
import {Dispatch, SetStateAction} from "react";
import {TablePaginationConfig} from "antd";
import {SorterResult} from "antd/es/table/interface";
import {dateToServer} from "../util";

export const defaultTableParams: TableParams = {
  pagination: {
    current: 1,
    pageSize: 100,
  }
}

export function tableParamsToSearchParams(tableParams: TableParams) {
  const params: TableDefaultClientParams = {
    page: tableParams.pagination?.current?.toString() || '1',
    pageSize: tableParams.pagination?.pageSize?.toString() || '10',
    sortField: tableParams.sortField,
    ...Object.fromEntries(Object.entries(tableParams.filters || {}).map(([key, value]) => {
      let parsedResult = value?.toString() || ''

      // parse date range filter
      if(Array.isArray(value)){
        // @ts-ignore
        if(dayjs.isDayjs(value[0])){
          // @ts-ignore
          parsedResult = dayjs(dateToServer(value[0])).toISOString()
        }
        if(dayjs.isDayjs(value[1])){
          // @ts-ignore
          parsedResult += ',' + dayjs(dateToServer(value[1])).toISOString()
        }

        // // @ts-ignore
        // if((value[1] as Dayjs)?.$isDayjsObject || moment.isMoment(value[1])){
        //   parsedResult += ',' + (value[1] as unknown as Dayjs).toISOString()
        // }

      }
      return [key, parsedResult]
    }).filter( (v) => v[1] !== ''))
  }

  if (tableParams.sortOrder) {
    params.sortOrder = tableParams.sortOrder;
  }

  if(!params.sortField){
    delete params.sortField
  }

  return new URLSearchParams(params as unknown as Record<string, string>)
}

export function setTotalToTableParams(setTableParams: Dispatch<SetStateAction<TableParams>>, total?: number) {
  setTableParams((prev: TableParams) => ({
    ...prev,
    pagination: {
      ...prev.pagination,
      total
    }
  }))
}

export function handleTableChange<T>(setTableParams: Dispatch<SetStateAction<TableParams>>) {
  return (
    pagination: TablePaginationConfig,
    filters: TableParams['filters'],
    sorter: SorterResult<T> | SorterResult<T>[],
  ) => {
    const sort =  sorter as SorterResult<T>
    setTableParams(prev => ({
      pagination,
      filters: {...(prev.filters ?? {}), ...filters},
      sortField: sort.order ? sort.field as string : '',
      sortOrder: sort.order as TableSortOrder,
    }));
  };
}