import React, {createRef, useEffect, useRef, useState} from "react";

import s from './ContextMenu.module.css';
import {useTranslation} from "react-i18next";
import ReactDOM from 'react-dom';

const useContextMenu = props => {
  const {t} = useTranslation();
  const [state, setState] = useState({
    event: false,
    data: false
  });
  const resetState = () => setState({event: false, data: false});

  const ref = createRef();


  const ContextTrigger = props => {
    const trigger = useRef();


    const onContextMenu = e => {
      e.preventDefault();
      setState({event: e, data: props.data});
    }
    const onClickOutside = e => {
      if (
        trigger.current &&
        !trigger.current.contains(e.target) &&
        state.event !== false &&
        !ref.current.contains(e.target)
      ) {
        resetState();
      }
    }

    useEffect(() => {
      const t = trigger.current;
      t.addEventListener('contextmenu', onContextMenu);
      document.body.addEventListener('click', onClickOutside);
      return () => {
        t.removeEventListener('contextmenu', onContextMenu);
        document.body.removeEventListener('click', onClickOutside);
      }
    }, []);

    return (
      <div className={s.trigger} ref={trigger}>
        {props.children}
      </div>
    );
  }



  const MenuItem = props => {
    return (
      <div className={s.menuItem} onClick={e => {
        props.onClick && props.onClick(e, state.data);
        resetState();
      }}>
        {props.children}
      </div>
    );
  }

  const ContextMenu = props => {
    const [style, setStyle] = useState({
      left: 0,
      top: 0
    });
    const {innerWidth, innerHeight} = window;


    useEffect(() => {

      if(ref.current) {
        const rect = ref.current.getBoundingClientRect();
        let left = state.event.pageX;//rect.left + window.scrollX;
        let top = state.event.pageY;//rect.top + window.scrollY;

        if (top + rect.height > innerHeight) top -= rect.height;
        if (left + rect.width > innerWidth) left = rect.width < innerWidth ? (innerWidth - rect.width) / 2 : 0;
        // falta terminar

        setStyle({left, top});
      }

    }, [innerWidth, innerHeight, ref.current]);


    if (!state.event || !state.event.target) return null;

    return (ReactDOM.createPortal(
      (<div
        ref={ref}
        className={s.container}
        style={{top: style.top, left: style.left}}>
        {props.children}
      </div>),
      document.body
    ));
  }


  return {ContextMenu, MenuItem, ContextTrigger}
}

export default useContextMenu;
