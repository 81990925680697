import useAuth from "../../providers/UserProvider/useAuth";
import {ControllerTableResult} from "../../types/controllers";
import {UserOnTable} from "../../components/Clients/types";
export const useClientController = () => {
  const {secQuery} = useAuth();

  const deleteClient = async (id_client: string) => {
    const {error, data, aborted} = await secQuery(`/admin/userPerson/${id_client}`, {}, 'DELETE');
    if(aborted)return false;
    return {error, success: data?.success};
  }

  const getClients = async ({filters, page, resultsPerPage}: any = {}) => {
    const {error, data} = await secQuery('/api/admin/user', {filters}, 'POST');
    return {error, clients: data?.users};
  }

  const getClientsOnTable = async (body: any) => {
    const {error, data} = await secQuery('/api/admin/user', body, 'POST');
    if(error){
      throw new Error(error);
    }
    return data as ControllerTableResult<UserOnTable>;
  }

  const getClient = async ({client_id}: any) => {
    const {error, data} = await secQuery(`/api/admin/user/${client_id}`, null, 'POST');
    return {error, data: data, client: data?.user};
  }

  const getPurchases = async ({client_id, filters}: any) => {
    const {error, data} = await secQuery(`/api/admin/user/${client_id}/purchase`, {filters}, 'POST');
    return {error, purchases: data?.purchases}
  }

  const getCalendarData = async ({client_id, date_from, date_to}: any) => {
    const {error, data} = await secQuery(`/api/admin/user/${client_id}/calendar`, {date_from, date_to}, 'POST');
    return {error, events: data?.events};
  }



  return {deleteClient, getClients, getClient, getPurchases, getCalendarData, getClientsOnTable};
};

export default useClientController;

