import s from './PaymentsTable.module.css';
import {useTranslation} from "react-i18next";
import {paymentDataTableTestData} from "../mock";
import Table from "../../NewTable/NewTable";
import {useColumns} from "./columns";
import PurchasedHotel from "../../Hotel/PurchasedHotel/PurchasedHotel";
import {PaymentDataTableType} from "./types";
import PurchasedInsurance from "../../Insurance/PurchasedInsurance";

const NotFound = () => {
  const {t} = useTranslation();
  return <p>{t('payment not associated with order')}</p>
}

const ExpandedRowRender = ({record}: { record: PaymentDataTableType }) => {
  const {t} = useTranslation();

  if (record.asset === 'hotel') {
    let hotel = record.ordered!.hotels!.find(orderedHotel => orderedHotel.reference === record.tag);
    if(!hotel)return <NotFound/>
    return <PurchasedHotel hotel={hotel!}/>
  }

  if(record.asset === 'insurance'){
    return <PurchasedInsurance insurance={record.ordered!.insurance!}/>
  }

  return <NotFound/>
}


const isRowExpandable = (record: PaymentDataTableType) => {
  return !!((record.ordered && record.tag) &&
    (
      (record.asset === 'hotel' && Array.isArray(record.ordered.hotels)) ||
      (record.asset === 'insurance' && record.ordered.insurance)
    )
  );
}

interface PaymentsTableProps {

}

const PaymentsTable = (props: PaymentsTableProps) => {
  const {t} = useTranslation();
  const columns = useColumns();

  return <div>
    <div className="showTitle">{t('payments data.payments table title')}</div>
    <Table
      rowKey={"_id"}
      data={paymentDataTableTestData}
      columns={columns}
      expandable={{
        expandedRowRender: record => <ExpandedRowRender key={record._id} record={record}/>,
        rowExpandable: isRowExpandable
      }}
    />
  </div>
}

export default PaymentsTable;