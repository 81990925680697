import s from './EditDistribution.module.css';
import {ItPackageDistribution} from "../../../../../../../types/itineraries";
import {useTranslation} from "react-i18next";
import TextInput from "../../../../../Itineraries/Inputs/TextInput/TextInput";
import React, {useCallback, useState} from "react";

import {ReactComponent as ArrowSVG} from '../../../../../../../resources/svg/it/distribution_arrow.svg';
import Button from "../../../../../Itineraries/Inputs/Button/Button";
import {AiFillSave} from "react-icons/ai";
import {VscChromeClose} from "react-icons/vsc";

interface EditDistributionProps {
  distribution: ItPackageDistribution;
  onCancel: () => void;
  onSave: (distribution: ItPackageDistribution) => void;
}

const EditDistribution = ({onSave, onCancel, ...props}: EditDistributionProps) => {
  const {t} = useTranslation();
  const [distribution, setDistribution] = useState<ItPackageDistribution>(props.distribution);

  let rooms = distribution.persons_per_room.length;
  let persons = distribution.persons_per_room.reduce((t, n) => t + n, 0);

  const onRoomsChange = (e: any) => {
    const rooms = parseInt(e.target.value);
    if(rooms < 1 || rooms > 10) return;
    setDistribution(prev => {
      const newDistribution = {...prev};
      newDistribution.persons_per_room = newDistribution.persons_per_room.slice(0, rooms);
      while (newDistribution.persons_per_room.length < rooms) {
        newDistribution.persons_per_room.push(1);
      }
      return newDistribution;
    });
  }

  const onPersonsChange = (e: any, i: number) => {
    const persons = parseInt(e.target.value);
    if(persons < 1 || persons > 10) return;
    setDistribution(prev => {
      const newDistribution = {...prev};
      // @ts-ignore - fixing on blur. This allows the user to delete the input
      newDistribution.persons_per_room[i] = isNaN(persons) ? '' : persons;
      return newDistribution;
    });
  }

  const onPersonsBlur = (e: any, i: number) => {
    const persons = parseInt(e.target.value);
    if(isNaN(persons) || !persons) {
      setDistribution(prev => {
        const newDistribution = {...prev};
        newDistribution.persons_per_room[i] = 1;
        return newDistribution;
      });
    }
  }

  const onSaveCallback = useCallback(() => {
    onSave(distribution);
  }, [distribution]);

  return <div className={s.container}>
    <div>
      <div className="flex flex-nowrap items-center" style={{width: '424px'}}>
        <div className="mr-20">
          <div className="mb-5 text-center">{t('to.itinerary.package.number of rooms')}</div>
          <TextInput
            classNames={{input: s.input}}
            type="number"
            value={rooms}
            min={1}
            max={10}
            onChange={onRoomsChange}
          />
        </div>
        <div>
          <div className="mb-5 text-center">{t('to.itinerary.package.number of persons')}</div>
          <TextInput
            type="number"
            classNames={{input: s.input}}
            value={persons}
            disabled={true}
          />
        </div>
      </div>

      {distribution.persons_per_room.map((persons, i) =>
        <div className={s.pContainer}>
          <div className={s.label} style={{width: '187px'}}>{t('to.itinerary.package.room')} Nº {i + 1}</div>
          <div className={s.arrow}><ArrowSVG/></div>
          <div className={s.label} style={{width: '106px'}}>{t('to.itinerary.package.persons', {count: 2})}</div>
          <div style={{height: '36px'}}>
            <TextInput
            classNames={{input: s.smallInput}}
            value={persons}
            min={1}
            max={10}
            onChange={e => onPersonsChange(e, i)}
            onBlur={e => onPersonsBlur(e, i)}
          />
          </div>
        </div>
      )}</div>

    <div className={s.verBar}/>
    <div className="flex flex-col items-center justify-between">
      <div className={s.cancelButton} onClick={() => onCancel()}>
        <VscChromeClose size={20}/>
      </div>
      <div>
        <div className="text-center mb-3">{t('save')}</div>
        <Button
          className={s.saveButton}
          onClick={async () => await onSaveCallback()}
          color="green"
          variant="filled"
          shadow_border={true}
        >
          <div style={{position: "relative", left: "-10px"}}><AiFillSave size={25}/></div>
        </Button>
      </div>
    </div>
  </div>
}

export default EditDistribution;