import React from 'react';
import ThemeProvider from "./ThemeProvider/ThemeProvider";
import LocaleProvider from "./LocaleProvider/LocaleProvider";
import AuthProvider from "./UserProvider/AuthProvider";
import MantineThemeProvider from "./MantineThemeProvider/MantineThemeProvider";
import {MantineSpotlightProvider} from "./MantineSpotlightProvider/MantineSpotlightProvider";
import {SWRProvider} from "./SWRProvider";
import {CurrencyProvider} from "./CurrencyProviders/CurrencyProvider";
import {AntdProvider} from "./AntdProvider";

export const AllProviders = (props) => {
  return (
    <MantineThemeProvider>
      <ThemeProvider>
        <LocaleProvider>
          <AuthProvider>
            <CurrencyProvider>
              <SWRProvider>
                <MantineSpotlightProvider>
                  <AntdProvider>
                    {props.children}
                  </AntdProvider>
                </MantineSpotlightProvider>
              </SWRProvider>
            </CurrencyProvider>
          </AuthProvider>
        </LocaleProvider>
      </ThemeProvider>
    </MantineThemeProvider>
  );
}

export default AllProviders;
