import s from './ListPackage.module.css';
import {ItPackage} from "../../../../types/itineraries";
import {useTranslation} from "react-i18next";
import {langRender} from "../../../../helpers/i18n";
import {MoneyRender} from "../../../Table/Renders";
import Button from "../../Itineraries/Inputs/Button/Button";
import React from "react";
import {MdEdit} from "react-icons/md";
import {IoClose} from "react-icons/io5";

interface PackageProps {
  _package: ItPackage;

  onDelete?: (_package: ItPackage) => Promise<void>;
  onEdit?: (_package: ItPackage) => Promise<void>;
}

const ListPackage = ({_package, onDelete, onEdit}: PackageProps) => {
  const {t} = useTranslation();


  return <div className={s.container}>
    <div className={s.nameContainer}>
      <div className={s.name}>{langRender(_package.name || t('to.itinerary.package.missing name'))}</div>
    </div>
    <div className={s.priceContainer}>
      <div className="w-2/6 font-semibold">{t('to.itinerary.package.price value')}</div>
      <div className={s.price}><MoneyRender value={_package.distributions?.reduce((t, d) => d.total_price + t, 0)}/></div>
    </div>
    <div className={s.warning}>{t('to.itinerary.package.price warning')}</div>
    <div className={s.buttons}>
      {onDelete && <Button no_border={true} color="white" className={s.deleteButton} leftIcon={<IoClose size={25}/>}
													 variant="filled"
													 onClick={async () => await onDelete(_package)}/>}

      {onEdit &&
				<Button color="green" variant="filled" shadow_border={true} className={s.editButton} rightIcon={<MdEdit size={20}/>}
								onClick={(async () => await onEdit(_package))}>
          {t('to.edit')}
				</Button>
      }
    </div>
  </div>
}

export default ListPackage;