import React, {useEffect, useState} from 'react';
import s from './TOItineraries.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import bases from "../../../routes/bases";
import useTourOperatorItinerary from "../../../controllers/useTourOperatorItinerary";
import SmallItinerary from "../../SmallItinerary";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import {manageError} from "../../../helpers/ErrorManager";
import {Title} from "@mantine/core";
import Button from "../Itineraries/Inputs/Button/Button";
import {ErrorType} from "../../../types/errors";
import {Itinerary} from "../../../types/itineraries";
import HeaderTitle from "../Components/HeaderTitle/HeaderTitle";
import {FaSave} from "react-icons/fa";
import {GiClick} from "react-icons/gi";
import {Switch} from "antd";

const TOItineraries = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  let {id: admin_id} = useParams();
  //const {setChosen} = useMenu();

  const {newItinerary, getItineraries, deleteItinerary, duplicateItinerary, editItinerary} = useTourOperatorItinerary();


  const [itineraries, setItineraries] = useState<Itinerary[]>();

  const loadItineraries = async () => {
    try {
      const itineraries = await getItineraries({admin_id});
      setItineraries(itineraries.data);
    }catch(error){
      manageError(error as ErrorType, t);
    }
  }
  useEffect(() => {
    loadItineraries();
    //if(setChosen)setChosen('itineraries');
  }, []);


  const onNewItinerary = async () => {
    try {
      const id = await newItinerary();
      navigate(`/${bases.tour_operator_itineraries}/${id}`);
    }catch(error){
      manageError(error as ErrorType, t);
    }
  }

  const onItineraryDelete = async (id: string) => {
    if(!window.confirm(t('to.itinerary.sure delete')))return;
    try {
      await deleteItinerary({id});
      setItineraries(prev => prev!.filter(a => a._id !== id));
    }catch(error){
      manageError(error as ErrorType, t);
    }
  }

  const onItineraryDuplicate = async (id: string) => {
    try {
      await duplicateItinerary({id});
      await loadItineraries();
    }catch(error){
      manageError(error as ErrorType, t);
    }
  }

  const onItineraryToggle = async (itinerary: Itinerary) => {
    try{
      await editItinerary({id: itinerary._id, itinerary: {enabled: !itinerary.enabled}})
      await loadItineraries();
    }catch(error){
      manageError(error as ErrorType, t);
    }
  }


  //if (!itineraries) return <Loading/>


  return (
    <div className="container px-10 m-auto">
      <HeaderTitle>{t('to.header.itineraries')}</HeaderTitle>
      <Button color="yellow" variant="filled" shadow_border={true} onClick={onNewItinerary} rightIcon={<GiClick/>}>{t('create new')}</Button>

      <div className="flex items-center my-10 mx-5 text-3xl">
        <div className="mr-5"><FaSave/></div>
        {t('to.saved itineraries')}
      </div>

      {(!itineraries || !itineraries.length) && <Title order={3}>{t('to.no itineraries found')}</Title>}
      {Array.isArray(itineraries) && itineraries.length > 0 &&
      <div className="flex flex-wrap">
        {itineraries.map(itinerary => <div className={s.itineraryContainer}>
          <SmallItinerary key={itinerary._id} itinerary={itinerary} customComponent={
            <div className={s.toggleItineraryContainer}>
              <Switch checkedChildren="Activado" unCheckedChildren="Desactivado" value={itinerary.enabled} onChange={() => onItineraryToggle(itinerary)} />
            </div>
          }/>
          <div className={s.buttons}>
            <Button color="red" variant="outline" rightIcon={<AiOutlineDelete size={18} />} onClick={async () => await onItineraryDelete(itinerary._id)}>
              {t('to.delete')}
            </Button>
            <Button color="green" rightIcon={<AiOutlineEdit size={18}/>} onClick={() => navigate(`/${bases.tour_operator_itineraries}/${itinerary._id}`)}>
                {t('to.edit')}
            </Button>
            <Button color="green" onClick={async () => await onItineraryDuplicate(itinerary._id)}>{t('to.duplicate')}</Button>
          </div>
        </div>)}
      </div>}
    </div>
  );
};


export default TOItineraries;
