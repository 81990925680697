import React, {useEffect, useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import bases from "../../../routes/bases";
import useTourOperatorItinerary from "../../../controllers/useTourOperatorItinerary";
import {toastError} from "../../../helpers/ErrorManager";
import Activity from "../../Activity/Activity";
import {ItActivity, OutletItineraryContext} from "../../../types/itineraries";
import HeaderTitle from "../Components/HeaderTitle/HeaderTitle";
import Bullet from "../Itineraries/Bullet/Bullet";
import ToItAddBox from "../ToItAddBox";
import Footer from "../Components/Footer/Footer";
import {createPlacesForActivities} from "../util";


interface ShowActivityProps {
  activity: ItActivity;
  onDeleteActivity: (id: string) => Promise<void>;
  isItineraryBlocked: boolean;
}

const ShowActivity = ({activity, onDeleteActivity, isItineraryBlocked}: ShowActivityProps) => {
  const navigate = useNavigate();
  const {id} = useParams();

  let props: any = {
    onEdit: () => navigate(`/${bases.tour_operator_itineraries}/${id}/activity/${activity._id}`),
    onDelete: async () => await onDeleteActivity(activity._id)
  };
  if (isItineraryBlocked) props = {};

  return <Activity className="mr-5" to_load={{id: activity.activity_id}} variant="list" {...props}/>
}

const TOActivities = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {id} = useParams();

  const {newActivity, deleteActivity} = useTourOperatorItinerary();

  const {itinerary, setStep, setItinerary, isItineraryBlocked} = useOutletContext<OutletItineraryContext>();

  useEffect(() => {
    setStep('activities');
  }, []);

  const onDeleteActivity = async (activity_id: string) => {
    const {error, itinerary} = await deleteActivity({activity_id, itinerary_id: id!});
    if (error || !itinerary) return toastError(error, t);
    setItinerary(itinerary);
  }
  const onAdd = async (place: any) => {
    await onNewActivity(place, [{id: 0, place, day: place.day}]);
  }
  const placesActivitiesSummary = useMemo(() => {
    if (!Array.isArray(itinerary?.activities) || !Array.isArray(itinerary.places)) return [];
    return itinerary.activities.filter(activity => {
      if (!activity.activity_id) return false
      const place = itinerary.places!.find(p => p.id === activity.place_id);
      return !(!place || place.activity_blocked);
    }).map(activity => ({
      day: activity.day,
      place_id: activity.place_id,
      element: <ShowActivity isItineraryBlocked={isItineraryBlocked} activity={activity}
                             onDeleteActivity={onDeleteActivity}/>
    }));
  }, [itinerary]);

  const places = useMemo(() => createPlacesForActivities(itinerary.places!), [itinerary]);

  if (!id) return <></>;

  const onNewActivity = async (place: any, available: ItActivity['available']) => {
    let data: any = {itinerary_id: id};
    if (available) data.available = available;
    data.place_id = place.id;
    data.day = place.day;
    const {error, id: activity_id, itinerary} = await newActivity(data);
    if (error || !activity_id) return toastError(error, t);

    setItinerary(itinerary);
    navigate(`/${bases.tour_operator_itineraries}/${id}/activity/${activity_id}`);
  }


  return (
    <div className="container m-auto">
      <HeaderTitle>{t('to.header.activities')}</HeaderTitle>

      {!places?.length &&
        <div className="font-bold text-2xl">Primera necesitas agregar días en la pestaña de Itinerario</div>}


      {places?.map((place: any, index) => {
        let bullet = place.bullet || place.day;
        let title = place.title || place.address?.label;
        let elements = placesActivitiesSummary
          .filter((ph: any) => ph.place_id === place.id && (!ph.day || ph.day === place.day))
          .map(el => el.element);

        return <div key={index}>
          <div className="flex flex-nowrap items-center">
            <Bullet>{bullet}</Bullet>
            <div className="ml-5">{title}</div>
          </div>
          <div className="my-5 flex flex-wrap items-center">
            {elements}
            <ToItAddBox disabled={isItineraryBlocked} onClick={() => onAdd(place)}/>
          </div>
        </div>
      })}

      <Footer onSave={() => navigate(`/${bases.tour_operator_itineraries}/${id}/summary`)}/>
    </div>
  );
};


export default TOActivities;
