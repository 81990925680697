import s from './Switch.module.css';
import {Switch as MantineSwitch, SwitchProps as SwitchMantineProps} from "@mantine/core";

interface SwitchProps extends SwitchMantineProps {
  children?: string;
}
const Switch = ({children, ...props}: SwitchProps) => {
  return <div className={s.container}>
    <MantineSwitch {...props} classNames={{input: s.input}}/>
    {children && <div className="text-2xl font-semibold ml-5">{children}</div>}
  </div>
}

export default Switch;