import {useNavigate} from "react-router-dom";
import {useCallback, useState} from "react";
import {CreateEvent} from "./types";
import {postData} from "../../../util/req";
import {Card} from "antd";
import {TDEventForm} from "./TDEventForm";

export default function TDEventsCreate(){
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async (values: CreateEvent) => {
    setLoading(true);
    const response = await postData(`/td/event`, values)
    setLoading(false);
    if(response.id){
      navigate(`/td/events`)
    }
  }, []);

  return (
    <Card style={{maxWidth: 1000}} className="mx-auto my-5" title="Nuevo evento">
      <TDEventForm onFinish={handleSubmit} loading={loading}/>
    </Card>
  );
}