import React, {useEffect} from 'react';
import s from './ToFeatures.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import useTourOperatorItinerary from "../../../controllers/useTourOperatorItinerary";
import bases from "../../../routes/bases";
import Button from "../../Inputs/Button";
import Title from "../../LayoutComponents/Title";
import {Loader} from "@mantine/core";
import MoneyInputWithController from "../../Inputs/MoneyInputWithController";
import {FormList, formList, useForm} from "@mantine/form";
import {manageError} from "../../../helpers/ErrorManager";
import {AiOutlineDelete} from "react-icons/ai";
import GalleryPickUnOneImage from "../../Gallery/GalleryPickUnOneImage";
import {toast} from "react-toastify";
import MultiLangInputV2 from "../../Inputs/MultiLangInputV2";
import {ItFeature, OutletItineraryContext} from "../../../types/itineraries";
import {ErrorType} from "../../../types/errors";
import Footer from "../Components/Footer/Footer";
import TextInput from "../Itineraries/Inputs/TextInput/TextInput";
import Textarea from "../Itineraries/Inputs/Textarea/Textarea";
import sn from "../TONew/TONew.module.css";


type FormFeature = Omit<ItFeature, 'created_at' | '_id'> & { key: string };

const ToFeatures = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {id} = useParams();

  const {newFeature, editFeature, deleteFeature} = useTourOperatorItinerary();

  const {itinerary, setStep, setItinerary, isItineraryBlocked} = useOutletContext<OutletItineraryContext>();

  const form = useForm<{
    features: FormList<FormFeature>;
  }>({
    initialValues: {
      features: formList([])
    }
  });

  useEffect(() => {
    setStep('features');
  }, []);

  const onNewFeature = async () => {
    const {error, id: feature_id, itinerary} = await newFeature({itinerary_id: id!, feature: {} as ItFeature});
    if (error || !feature_id) return manageError(error as ErrorType, t);

    setItinerary(itinerary);
    form.addListItem('features', {key: feature_id});
  }


  const onDeleteFeature = async (feature_id: string, index: number) => {
    const {error, itinerary} = await deleteFeature({itinerary_id: id!, feature_id});
    if (error || !itinerary) return manageError(error as ErrorType, t);
    setItinerary(itinerary);
    form.removeListItem('features', index);
  }

  const onSaveFeature = async (feature_id: string) => {
    let formFeature = form.values.features.find(f => f.key === feature_id);
    if (!formFeature) return manageError({message: 'feature not found'}, t);
    const {key, ...featureData} = formFeature;

    const {error, feature: newFeature} = await editFeature({
      itinerary_id: id!,
      feature_id: key,
      feature: featureData
    });
    if (error) return manageError(error as ErrorType, t);

    setItinerary(prev => ({
      ...prev,
      features: prev.features!.map(f => f._id === newFeature._id ? newFeature : f)
    }));
    toast.success(t('saved'));
  }

  useEffect(() => {
    if (!Array.isArray(itinerary?.features)) return;
    form.setValues({
      features: formList(itinerary.features.map(f => ({
        name: f.name ?? {es: ''},
        description: f.description ?? {es: ''},
        price: f.price,
        image: f.image,
        key: f._id
      })))
    });
  }, [itinerary]);

  if (!itinerary) return <Loader/>


  return (
    <div className="container m-auto">

      {(!Array.isArray(itinerary?.features) || itinerary.features.length === 0) &&
				<Title size={3} className="my-10">{t('to.itinerary.no features found')}</Title>}


      {form.values.features.map((feature, index) => {
        return <div key={feature.key} className={s.container}>
          <div className="w-full flex flex-wrap items-center justify-center">
            <div className="mr-10">
              <MultiLangInputV2
                formType="mantine"
                formList={true}
                Input={TextInput}
                name="features"
                formIndex={index}
                formListName="name"
                form={form}
                label={t('to.itinerary.feature name')}
                style={{width: 400}}
                disabled={isItineraryBlocked}
              />

              <MoneyInputWithController
                name={''}
                label={t('to.itinerary.feature price')}
                {...form.getListInputProps('features', index, 'price')}
                disabled={isItineraryBlocked}
              />
            </div>
            <div className="flex flex-nowrap mt-5">
              <MultiLangInputV2
                formType="mantine"
                formList={true}
                Input={Textarea}
                name="features"
                formIndex={index}
                formListName={'description'}
                form={form}
                label={t('to.itinerary.description')}
                minRows={6}
                style={{width: 400}}
                disabled={isItineraryBlocked}
              />
              <GalleryPickUnOneImage
                label={null}
                defaultValue={feature.image}
                className="ml-10"
                onDone={(image: string) => {
                  form.setListItem('features', index, {...feature, image})
                }}
                disabled={isItineraryBlocked}
              />
            </div>
          </div>


          <div className="flex flex-nowrap">
            <Button
              style={{minWidth: '200px'}}
              color="green"
              onClick={() => onSaveFeature(feature.key)}
              className="mr-5"
              disabled={isItineraryBlocked}
            >{t('to.save')}
            </Button>
            <Button
              leftIcon={<AiOutlineDelete size={18}/>}
              color="red"
              variant="outline"
              onClick={() => onDeleteFeature(feature.key, index)}
              disabled={isItineraryBlocked}
            >{t('to.itinerary.remove feature')}</Button>
          </div>

        </div>
      })}

      <Button disabled={isItineraryBlocked} onClick={onNewFeature}>{t('create new feature')}</Button>

      {/*<Footer onSave={() => navigate(`/${bases.tour_operator_itineraries}/${id}/summary`)}/>*/}
    </div>
  );
};


export default ToFeatures;