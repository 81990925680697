import React, {useEffect, useState} from 'react';
import s from './MySavedHotel.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useHotel} from "../../../controllers/useHotel";
import {ReactComponent as StarSVG} from "../../../resources/svg/star.svg";
import Loading from "../../Loading";
import Button from "../../Inputs/Button";
import bases from "../../../routes/bases";
import {Textarea, TextInput} from "@mantine/core";
import {AiOutlineMail, AiOutlinePhone} from "react-icons/ai";
import SelectAddress from "../../Inputs/SelectAddress";
import {GoLocation} from "react-icons/go";
import {formatSearchBarResults} from "../../../helpers/util";
import MySavedHotelRegimen from "../MySavedHotelRegimen";
import {useForm} from "@mantine/form";
import Label from "../../Inputs/Label";
import {toastError} from "../../../helpers/ErrorManager";
import MySavedHotelFacilities from "../MySavedHotelFacilities";
import GalleryPickUpImages from "../../Gallery/GalleryPickUpImages";
import PhoneInput from "../../PhoneInput";
import MultiLangInput from "../../MultiLangInput";
import {LocalHotel} from "../../../types/hotel";
import Rating from "../../Inputs/Rating/Rating";
import MultiLangInputV2 from "../../Inputs/MultiLangInputV2";


interface FormValues extends Partial<Omit<LocalHotel, 'address'>> {
	address?: {
		value: string;
		raw: any;
		icon: React.ReactNode;
	}
}


interface MySavedHotelProps {
	id?: string;
	onSave?: (hotel_id: string) => void;
	onCancel?: () => void;
}

const MySavedHotel = ({id: _id, onSave, onCancel}: MySavedHotelProps) => {
	const {t} = useTranslation();
	const {id: param_id} = useParams();
	const navigate = useNavigate();
	const {searchHotelsBar} = useHotel();
	const [lang, setLang] = useState('es');




	const form = useForm<FormValues>({
		initialValues: {
			stars: 0
		}
	});

	const {setFieldValue, values} = form;
	const [hotel, setHotel] = useState();

	const {getSavedHotel, editSavedHotel} = useHotel();

	const [loading, setLoading] = useState(true);

	let hotel_id = _id || param_id;


	const loadHotel = async () => {
		setLoading(true);
		const {error, hotel} = await getSavedHotel(hotel_id!);
		setLoading(false);
		if (error) return toastError(error, t);

		setHotel(hotel);

		const keys: (keyof LocalHotel)[] = ['web','name', 'description', 'phone', 'email', 'stars', 'regime', 'facilities', 'images'];

		keys.forEach(key => {
			setFieldValue(key, hotel[key]);
		});
		if (hotel.address) {
			setFieldValue('address', {
				value: hotel.address.label,
				raw: hotel.address,
				icon: <GoLocation/>
			});
		}
	}

	const searchAddress = async (str: string) => {
		if (str.length <= 1) return [];
		const {error, results} = await searchHotelsBar({q: str});
		if (error) return [];
		let r = formatSearchBarResults(results);
		return r;
	}

	useEffect(() => {
		loadHotel();
	}, []);

	const _onSave = async () => {
		let hotel = values;

		hotel.address = hotel?.address?.raw;
		//if(Array.isArray(hotel.regime))hotel.regime = hotel.regime.filter(a => a);

		const {error, success} = await editSavedHotel(hotel_id!, hotel);
		if (error || !success) return toastError(error, t);
		if (onSave) return onSave(hotel_id!);
		navigate(`/${bases.saved_hotel}`);
	}


	return (
		<>
			{loading && <Loading/>}
			<div className={loading ? 'invisible' : 'm-5'}>
				<div className="w-full flex">
					<TextInput
						label={t('to.hotel.name')}
						{...form.getInputProps(`name`)}
						className={`${s.input} mr-10`}
					/>

					<div className="w-full ml-5">
						<Label name="" htmlFor={`stars`} className="">{t('to.hotel.stars')}</Label>
						<Rating stars={values.stars} onSelect={(stars) => setFieldValue('stars', stars)}/>
					</div>


				</div>

				<MultiLangInputV2
					formType={'mantine'}
					lang={lang}
					setLang={setLang}
					Input={Textarea}
					form={form}
					name="description"
					label={t('to.hotel.description')}
					minRows={6}
				/>
				<MySavedHotelRegimen form={form} hotel={hotel}/>
				<MySavedHotelFacilities form={form}/>
				<div className="w-full flex mt-5">
					<PhoneInput
						label={t('to.hotel.phone')}
						{...form.getInputProps(`phone`)}
						/*@ts-ignore*/
						icon={<AiOutlinePhone size={16}/>}
						className={`${s.input} mr-10`}
					/>
					<TextInput
						label={t('to.hotel.email')}
						{...form.getInputProps(`email`)}
						className={s.input}
						icon={<AiOutlineMail size={16}/>}
					/>
				</div>
				<div className="w-full flex">
					<TextInput
						label={t('to.hotel.web')}
						{...form.getInputProps(`web`)}
						className={`${s.input} mr-10`}
					/>
					/*@ts-ignore*/
					<SelectAddress
						id={`to_hotel_address`}
						name={`address`}
						icon={<GoLocation size={16}/>}
						searchFunction={searchAddress}
						label={t('to.hotel.address')}
						className="w-full"
						form={form}
					/>

				</div>



				<GalleryPickUpImages
					onDone={t => setFieldValue('images', t)}
					label={t('to.hotel.pictures')}
					defaultValue={values.images}
				/>

				{/*
        <UploadOrderedImages name={`pictures`} containerClassName="my-5"/>
*/}
				<div className="mt-10 flex flex-nowrap">
					<Button onClick={_onSave} className={s.button}>{t('save hotel')}</Button>
					{onCancel && <Button onClick={() => onCancel()} className={s.button}>{t('cancel')}</Button>}
				</div>
			</div>
		</>
	);
};


export default MySavedHotel;
