import React from 'react';
import './Modal.css';
import ReactModal from 'react-modal';
import {BsX} from 'react-icons/bs';

const customStyle = {
  content: {
    zIndex: '10'
  }
}

const Modal = ({isOpen, children, styles, onClose}) => {
  return (
    <ReactModal
      appElement={document.body}
      isOpen={isOpen}
      className="p-10 border-2 focus:outline-none relative"
      onRequestClose={onClose}
      style={customStyle}
    >
      {onClose &&
      <div className="absolute right-5 cursor-pointer" onClick={onClose}><BsX size={20}/></div>}

      {children}
    </ReactModal>
  );
};


export default Modal;
