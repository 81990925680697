import {useNavigate} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import {TableParams, TableResult, TableSortOrder} from "../../../../util/table/types";
import {
  defaultTableParams,
  setTotalToTableParams,
  tableParamsToSearchParams
} from "../../../../util/table/table";
import useSWR from "swr";
import {Divider, Table, TablePaginationConfig} from "antd";
import {sellersPaymentColumns} from "./columns";
import bases from "../../../../routes/bases";
import {Seller} from "../../TDSellers/types";
import {SellersPayment, SellersPaymentTableSum} from "./types";
import {MoneyRender} from "../../../Table/Renders";
import {canPayToSeller} from "./util";
import {GroupByDates} from "./components/GroupByDates";
import {PayToSellers} from "./components/PayToSellers";
import {canTDWrite} from "../../../Permissions/VisibleWithPermissions";
import {SorterResult} from "antd/es/table/interface";


interface Props {
  selectedRowKeys: React.Key[]
  onSelectionChange: (selectedRowKeys: React.Key[]) => void
  refresh?: number
  sellers: Seller[]
}

export function TDSellersPaymentTable({refresh, sellers, selectedRowKeys, onSelectionChange}: Props) {
  const [loadingButton, setLoadingButton] = useState(false);

  const [tableParams, setTableParams] = useState<TableParams>(defaultTableParams);
  const {
    isLoading,
    data,
    mutate
  } = useSWR<TableResult<SellersPayment>>(`/api/admin/td/sellers_payment?${tableParamsToSearchParams(tableParams)}`);
  const {data: sum} = useSWR<SellersPaymentTableSum>(`/api/admin/td/sellers_payment_sum?${tableParamsToSearchParams(tableParams)}`);
  const [reservationsToPay, setReservationsToPay] = useState<SellersPayment[]>([]);
  const navigate = useNavigate()

  const [groupDate, setGroupDate] = useState<string | undefined>(undefined)


  useEffect(() => {
    setTotalToTableParams(setTableParams, data?.total)
  }, [data]);


  useEffect(() => {
    if (!refresh) return;
    mutate();
  }, [refresh]);

  const onSellerPay = async () => {
    const reservationsToPay = data?.data.filter(reservation => selectedRowKeys.includes(reservation._id))
    setReservationsToPay(reservationsToPay ?? [])
  }

  const canPayButtonDisabled = () => {
    const selected = data?.data.filter(reservation => selectedRowKeys.includes(reservation._id))
    return loadingButton || selectedRowKeys.length === 0 || selected?.some(reservation => !canPayToSeller(reservation))
  }

  const canWrite = useMemo(() => canTDWrite(), [])


  const customHandleTableChange = (
      pagination: TablePaginationConfig,
      filters: TableParams['filters'],
      sorter: SorterResult<SellersPayment> | SorterResult<SellersPayment>[],
    ) => {
      const sort =  sorter as SorterResult<SellersPayment>
      setTableParams(prev => {
        if(filters && !filters.payment_date && groupDate){
          delete filters.payment_date
        }
        return ({
          pagination,
          filters: {...(prev.filters ?? {}), ...filters},
          sortField: sort.order ? sort.field as string : '',
          sortOrder: sort.order as TableSortOrder,
        })
      });
    };

  return <>

    <PayToSellers onClose={(payed) => {
      if(payed){
        onSelectionChange([])
        mutate()
      }
      setReservationsToPay([])
    }} reservations={reservationsToPay}/>
    <div className="p-10 pt-0 mb-20">
      <Divider>Pagos a los vendedores</Divider>

      {/*<Button disabled={canPayButtonDisabled()} className="mb-5" onClick={onSellerPay}*/}
      {/*        loading={loadingButton}>Pagar las reservas seleccionadas</Button>*/}
    <GroupByDates value={groupDate} setValue={setGroupDate} tableParams={tableParams} setTableParams={setTableParams}/>
    <Table
      rowSelection={{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectionChange
      }}
      columns={sellersPaymentColumns({canWrite, sellers ,onEdit: (id) => {
        navigate(`/${bases.td.reservations}/${id}`)
        }})}
      dataSource={data?.data}
      rowKey={seller => seller._id}
      loading={isLoading}
      pagination={tableParams.pagination}
      onChange={customHandleTableChange}
      summary={() => {
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={1} colSpan={4}>Total</Table.Summary.Cell>
              <Table.Summary.Cell index={2} colSpan={1}><MoneyRender value={sum?.total_price}/></Table.Summary.Cell>
              <Table.Summary.Cell index={2} colSpan={1}><MoneyRender value={sum?.commission}/></Table.Summary.Cell>
              <Table.Summary.Cell index={2} colSpan={3}><MoneyRender value={sum?.td_commission}/></Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }}
    />
  </div>
    </>
}