import React, {useState, useEffect} from 'react';
import s from './TDPayments.module.css';
import {useTranslation} from "react-i18next";
import Table from "../../Table";
import useTDReservations from "../../../controllers/useTDReservations";
import {MoneyRender} from "../../Table/Renders";
import Loading from "../../Loading";
import {Title} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import bases from "../../../routes/bases";

const TDPayments = props => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [sellers, setSellers] = useState([]);

	const {getPayments, getSellers,getPaymentTotalData} = useTDReservations();
	const loadSellers = async () => {
		setLoading(true);
		const {error, sellers} = await getSellers();
		setLoading(false);
		if (error) return alert(error);
		setSellers(sellers);
	}

	useEffect(() => {
		loadSellers();
	}, []);

	if(loading)return <Loading/>

	return (
		<div className="container m-auto pl-10">
			<div className="mb-10">
				<Title order={2} className="float-left">{t('td.payments.title')}</Title>
			</div>
			<Table
				tableName="td_payments"
				id="_id"
				defaultHiddenColumns={["_id"]}
				columns={[
					{accessor: '_id', Header: t('id'), type: 'string'},
					{accessor: 'reservation_date', Header: t('td.payments.reservation date'), type: 'date', filter: 'range_date'},
					{accessor: 'payment_date', Header: t('td.payments.payment date'), type: 'date', filter: 'range_date'},
					{accessor: 'seller', Header: t('td.payments.seller'), type: 'string', filter: 'list', data: sellers?.map(a => ({label: a, value: a}))},
					{accessor: 'amount', Header: t('td.payments.amount'), type: 'number'},
					{accessor: 'percentage', Header: t('td.payments.percentage'), type: 'number'}
				]}
				onEdit={row => navigate(`/${bases.td.reservations}/${row._id}`)}
				fetchData={async ({page, filters}) => {
					const {error, payments, totalRecords} = await getPayments({page, filters});
					return {error, data: payments, totalRecords};
				}}
				fetchTotalData={async ({filters}) => {
					const {error, data} = await getPaymentTotalData({filters});
					return {data};
				}}
				renders={{
					amount: (amount) => <MoneyRender value={amount}/>,
					percentage: (_, row) => (row.total_payed / row.total_price * 100) + '%'
				}}
			/>
		</div>
	);
};


export default TDPayments;