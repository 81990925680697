import React, {useEffect, useState} from 'react';
import s from './SmallItinerary.module.css';
import {useTranslation} from "react-i18next";
import useTourOperatorItinerary from "../../controllers/useTourOperatorItinerary";
import ShowError from "../ShowError";
import Loading from "../Loading";
import {cutText, removeHTMLTags} from "../../helpers/util";
import {langRender} from "../../helpers/i18n";
import {Itinerary} from "../../types/itineraries";
import {ErrorType} from "../../types/errors";


interface SmallItineraryProps {
  itineraryToLoad?: {_id: string}
  itinerary?: Itinerary;
  customComponent?: React.ReactNode
}

const SmallItinerary = ({itineraryToLoad, customComponent, itinerary: _itinerary}: SmallItineraryProps) => {
  const {t} = useTranslation();
  const [itinerary, setItinerary] = useState<Itinerary>();
  const [error, setError] = useState<string>();

  const {getItinerary} = useTourOperatorItinerary();


  const loadItinerary = async () => {
    try {
      const itinerary = await getItinerary({id: itineraryToLoad!._id});
      setItinerary(itinerary);
    }catch(err){
      const error = err as ErrorType;
      setError(error.message || 'itinerary not found');
    }
  }

  useEffect(() => {
    if (itineraryToLoad) {
      loadItinerary();
    }else{
      setItinerary(_itinerary);
    }
  }, [itineraryToLoad]);


  if (error) return <ShowError error={error}/>
  if (itineraryToLoad && !itinerary) return <Loading/>
  if (itineraryToLoad && itinerary) return <SmallItinerary itinerary={itinerary}/>

  if(!itinerary)return <Loading/>

  return <div className={s.container}>
    <div className="font-bold text-2xl mb-1">{langRender(itinerary.title) || t('small itinerary.title missing')}</div>
    <div className={s.description}>{cutText(removeHTMLTags(langRender(itinerary.internal_description)), 280) || t('small itinerary.description missing')}</div>
    {!!customComponent && customComponent}
  </div>
};


export default SmallItinerary;
