import React, {useEffect, useMemo, useState} from 'react';
import s from './TOHotels.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import bases from "../../../routes/bases";
import sn from "../TONew/TONew.module.css";
import useTourOperatorItinerary from "../../../controllers/useTourOperatorItinerary";
import ToShowSmallHotel from "./ToShowSmallHotel";
import {toastError} from "../../../helpers/ErrorManager";
import {ItHotel, ItPlace, OutletItineraryContext} from "../../../types/itineraries";
import Footer from "../Components/Footer/Footer";
import Bullet from "../Itineraries/Bullet/Bullet";
import ToItAddBox from "../ToItAddBox";
import HeaderTitle from "../Components/HeaderTitle/HeaderTitle";
import Hotel from "../../Hotel/Hotel";
import {createPlacesForHotels} from "../util";


interface ShowHotelProps {
	hotel: ItHotel;
	onDeleteHotel: (id: string) => Promise<void>;
	disabled?: boolean;
	[key: string]: any;
}

const ShowHotel = ({hotel, onDeleteHotel, disabled = false, ...props}: ShowHotelProps) => {
	const navigate = useNavigate();
	const {id} = useParams();

	return <Hotel
		className="mr-5 mb-5"
		variant={'list'}
		to_load={{
			id: hotel.hotel_id,
			api: hotel.api,
			code: hotel.hotel_code,
			type: hotel.api ? 'api' : 'local'
		}}
		onEdit={() => navigate(`/${bases.tour_operator_itineraries}/${id}/hotel/${hotel._id}`)}
		onDelete={async () => await onDeleteHotel(hotel._id)}
	/>
}


const TOHotels = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const {id} = useParams();

	const {newHotel, deleteHotel} = useTourOperatorItinerary();

	const {itinerary, setStep, setItinerary, isItineraryBlocked} = useOutletContext<OutletItineraryContext>();


	useEffect(() => {
		setStep('hotels');
	}, []);


	if(!id)throw new Error('itinerary id missing');

	const onNewHotel = async (place: any) => {
		let data: any = {itinerary_id: id};
		data.place_id = place.id;
		data.day = place.day;

		const {error, id: hotel_id, itinerary} = await newHotel(data);
		if (error || !hotel_id) return toastError(error, t);

		setItinerary(itinerary);

		navigate(`/${bases.tour_operator_itineraries}/${id}/hotel/${hotel_id}`);
	}

	const onDeleteHotel = async (hotel_id: string) => {
		const {error, itinerary} = await deleteHotel({itinerary_id: id, hotel_id});
		if (error || !itinerary) return toastError(error, t);
		setItinerary(itinerary);
	}

	const placesHotelsSummary = useMemo(() => {
		if (!Array.isArray(itinerary?.hotels)) return [];
		return itinerary.hotels.filter(hotel => hotel.place).map(hotel => ({
			label: hotel.place.address.label, // se usa para placesSummary
			place_id: hotel.place_id,
			day: hotel.day,
			element: <ShowHotel disabled={isItineraryBlocked} hotel={hotel} onDeleteHotel={onDeleteHotel}/>
		}));
	}, [itinerary]);

	const places = useMemo(() => createPlacesForHotels(itinerary.places!), [itinerary]);


	return (
		<div className="container m-auto">
			<HeaderTitle>{t('to.header.hotels')}</HeaderTitle>

      {!places?.length && <div className="font-bold text-2xl">Primera necesitas agregar días en la pestaña de Itinerario</div>}

			{places?.map((place: any, index) => {
				let bullet = place.bullet || place.day;
				let title = place.title || place.address?.label;
				let elements = placesHotelsSummary
					.filter((ph: any) => ph.place_id === place.id && (!ph.day || ph.day === place.day))
					.map(el => el.element);

				return <div key={index}>
					<div className="flex flex-nowrap items-center">
						<Bullet>{bullet}</Bullet>
						<div className="ml-5">{title}</div>
					</div>
					<div className="my-5 flex flex-wrap items-center">
						{elements}
						<ToItAddBox disabled={isItineraryBlocked} onClick={() => onNewHotel(place)}/>
					</div>
				</div>
			})}
			<Footer onSave={() => navigate(`/${bases.tour_operator_itineraries}/${id}/activities`)}/>
		</div>
	);
};


export default TOHotels;
