import React, {useEffect, useMemo, useState} from 'react';
import s from './TONewActivity.module.css';
import MySavedActivitiesSelector from "../../../Activity/MySavedActivities/MySavedActivitiesSelector";
import {useTranslation} from "react-i18next";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import sn from "../TONew.module.css";
import Button from "../../../Inputs/Button_old";
import useTourOperatorItinerary from "../../../../controllers/useTourOperatorItinerary";
import {toastError} from "../../../../helpers/ErrorManager";
import bases from "../../../../routes/bases";
import {Card} from "antd";

const TONewActivity = props => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {id, activity_id} = useParams();

  const {editActivity} = useTourOperatorItinerary();
  const {itinerary, setStep, setItinerary} = useOutletContext();

  useEffect(() => {
    setStep('activities');
  }, []);



  const activity = useMemo(() => {
    if (!itinerary) return false;
    return itinerary.activities.find(activity => activity._id === activity_id)
  }, [itinerary]);

  const [selected, setSelected] = useState(activity.activity_id || activity.code);



  const onActivitySave = async () => {
    let data = {};
    if(selected){
      data.activity_id = selected._id || selected.activity_id;
    }

    const {error, activity} = await editActivity({
      itinerary_id: id,
      activity_id,
      activity: data
    });
    if(error)return toastError(error, t);

    setItinerary(prev => ({
      ...prev,
      activities: prev.activities.map(_activity => _activity._id !== activity._id ? _activity : activity)
    }));

    navigate(`/${bases.tour_operator_itineraries}/${id}/activities`);
  }





  return <div className="container m-auto mt-10">
    <Card title="Mis actividades guardadas">
      <MySavedActivitiesSelector admin_id={itinerary.created_by} defaultSelected={selected}
                                 onSelect={activity => setSelected(activity)}/>
    </Card>
    <div className={sn.footer}>
      <Button
        onClick={onActivitySave} loadButton={true}>{t('to.save')}</Button>
    </div>
  </div>
};


export default TONewActivity;
