import React, { useState} from 'react';
import s from './ClientCalendar.module.css';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import useSelectionProvider from "../../../providers/SelectionProvider/useSelectionProvider";
import useClientController from "../../../controllers/useClientController";
import Calendar from "../Calendar";
import useWindowSize from "../../../hooks/useWindowSize/useWindowSize";

const ClientCalendar = props => {
  const {t} = useTranslation();
  const {height} = useWindowSize();

  let {id: client_id} = useParams();
  const {client: selected_client} = useSelectionProvider();
  const {getCalendarData} = useClientController();

  const [events, setEvents] = useState({});
  const calendarRef = React.useRef();

  const loadCalendarEvents = async (fetchInfo, resolve, reject) => {
    const date = {
      start: fetchInfo.start.valueOf(),
      end: fetchInfo.end.valueOf()
    }

    const key = date.start + date.end;
    console.log(key);
    if (events[key]) return resolve(events[key]);

   const {error, events: data} = await getCalendarData({
      client_id,
      date_from: date.start.valueOf(),
      date_to: date.end.valueOf()
    });




    if (!error && data) setEvents(prev => {

      let d = data.map(event => ({
        extendedProps: {
          event_id: Math.floor(Math.random() * 1000)
        },
        title: event.name,
        start: new Date(event.start_date),
        end: new Date(event.end_date),
      }));
      resolve(d);

      return {
        ...prev, [key]: d
      };
    });
  }


  const eventSources = [
    {events: loadCalendarEvents}
  ];


  return (
    <div className="m-5" style={{height: `calc(${height}px - 30px - var(--header-height))`}}>
      <Calendar
        ref={calendarRef}
        eventSources={eventSources}
      />
    </div>
  );
};


export default ClientCalendar;
