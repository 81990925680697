import React from 'react';
import s from './SelectAddress.module.css';
import InputWithSelect from "../InputWithSelect";


interface SelectAddressProps{
  searchFunction: (str: string) => Promise<any[]>;
  onChange?: (str: string) => void;
  id: string;
  name: string;
  [key: string]: any;
}

const SelectAddressOld = ({id, name, searchFunction, onChange, ...props}: SelectAddressProps) => {

  const searchAddress = async (str: string) => {
    onChange && onChange(str);
    if (str.length <= 1) return [];
    return await searchFunction(str);
  }

  return (
    <InputWithSelect
      name={name}
      id={id}
      onChange={searchAddress}
      {...props}
    />
  );
};


export default SelectAddressOld;
