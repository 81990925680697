import useAuth from "../../providers/UserProvider/useAuth";
export const useAPIDataProvider = () => {
  const {secQuery} = useAuth();

  const getContactUsData = async ({pages}) => {
    const {error, data} = await secQuery('/api/admin/contact_us', {pages}, 'POST');
    return {error, data};
  }
  const getContactUs = async ({contact_us_id}) => {
    const {error, data} = await secQuery(`/api/admin/contact_us/${contact_us_id}`, null, 'POST');
    return {error, data};
  }

  const getStartYourTripData = async ({pages}) => {
    const {error, data} = await secQuery('/api/admin/syt', {pages}, 'POST');
    return {error, data};
  }

  const getStartYourTrip = async ({id}) => {
    const {error, data} = await secQuery(`/api/admin/syt/${id}`, null, 'POST');
    return {error, data};
  }

  return {getContactUsData, getContactUs, getStartYourTrip, getStartYourTripData};
}

export default useAPIDataProvider;
