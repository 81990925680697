import {ReservationTable} from "./types";
import dayjs from "dayjs";


interface IsCancellableReservationProps {
  cancellation?: ReservationTable['cancellation']
  payments: Pick<ReservationTable['payments'][number], 'date'>[]
}
export function isCancellable(reservation: IsCancellableReservationProps) {
  if(!Array.isArray(reservation.payments) || !reservation.payments.length) return false
  const date = dayjs(reservation.payments[0].date)
  return !reservation.cancellation && !dayjs().isAfter(date) && dayjs().isBefore(date.subtract(24,'hour'));
}

export const paidFilterOptions = [
  {label: 'Pagadas', value: 'paid'},
  {label: 'Por pagar', value: 'to pay'},
]