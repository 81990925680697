import React from 'react';
import s from './Loading.module.css';
import PlaneLoader from "../PlaneLoader";

const Loading = props => {
  return <PlaneLoader/>
};


export default Loading;
