import {OrderedBookedHotel} from "../../../controllers/usePurchaseController/types";
import Hotel from "../Hotel";

export const fromHotelToVerticalTableData = (hotel: OrderedBookedHotel) => ({
  hotel: <Hotel
    to_load={{
      api: hotel.api,
      code: hotel.hotel.code,
      type: 'api'
    }}
    variant="vertical"
  />,
  apiReference: hotel.reference,
  name: hotel.hotel.name,
  checkIn: hotel.hotel.checkIn,
  checkOut: hotel.hotel.checkOut,
  status: hotel.status,
  totalNet: hotel.totalNet,
  rooms: hotel.hotel.rooms.length
});


export const roomColumns = (translator: any) => {
  const t = (a: string) => translator(`purchased data.${a}`);
  return [
    {dataIndex: 'name', title: t('room name')},
    {dataIndex: 'code', title: t('room code')},
    {dataIndex: 'board', title: t('board')},
   // {dataIndex: 'paxes', title: t('paxes')}
  ]
}

export const roomData = (rooms: OrderedBookedHotel['hotel']['rooms'], translator: any) => {
  const t = (a: string) => translator(`purchased data.${a}`);
  return rooms.map(room => ({
    name: room.name,
    code: room.code,
    board: room.rates[0].boardName,
    paxes: room.paxes.map(pax =>
      <p>{pax.name} {pax.surname || ''} ({t(pax.type)})</p>
    )
  }));
}