import config from '../config';
import logger from "./Logger";

export const encodeURIParams = (params) => {
  return Object.keys(params)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
    .join('&');
}

export const query = async (query, data, method = 'POST', {headers = {}, signal, replaceHeaders, rawBody = false} = {}) => {
  method = method.toUpperCase();
  let options = {
    method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      ...headers
    },
    mode: 'cors',
    withCredentials: true,
    signal
  };

  if(replaceHeaders)options.headers = replaceHeaders;

  if(method !== 'GET' && !rawBody)options.body = JSON.stringify(data);
  if(rawBody)options.body = data;

  let url = `${config.serverURL}${query}`;
  if(method === 'GET' && data){
    url += '?' + encodeURIParams(data);
  }

  try {
    let r = await fetch(url, options);
    let data = await r.json();
    if(!r.ok){
      if(data.error === 'Unauthorized' && data.statusCode === 401){
        return {error: 'Unauthorized', logout: true, statusCode: 401};
      }
/*
      if(data.error === 'Forbidden' && data.statusCode === 403){
        return {error: 'Forbidden', logout: true, statusCode: 403};
      }*/

      return {error: data.message || data.error};
    }
    return {error:data?.error, data};
  }catch(err){
    console.log('QUERY ERROR');
    console.log(err);

    logger.error(err);
    return {error: 'failed to fetch'}
  }
}
