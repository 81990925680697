
import {SWRConfig, SWRConfiguration} from "swr/_internal";
import React from "react";
import useAuth from "./UserProvider/useAuth";
import {handleRequestError} from "../util/errorManager/errorManager";




export function SWRProvider({children}: {children: React.ReactNode}){
  const {secQuery} = useAuth();
  const getData = async (url: string) => {
    const {data} = await handleRequestError(secQuery(url, undefined, 'GET'));
    return data;
  }

  const swrConfig: SWRConfiguration = {
    fetcher: getData
  }

  return (
    <SWRConfig value={swrConfig}>
      {children}
    </SWRConfig>
  )
}