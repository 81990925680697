import s from './PurchasedHotel.module.css';
import {OrderedBookedHotel} from "../../../controllers/usePurchaseController/types";
import VerticalTable from "../../NewTable/VerticalTable";
import {DateTimeRender, MoneyRender} from "../../Table/Renders";
import Table from "../../NewTable/NewTable";
import {fromHotelToVerticalTableData, roomColumns, roomData} from "./util";
import {useTranslation} from "react-i18next";

interface PurchasedHotelProps {
  hotel: OrderedBookedHotel;
}

const PurchasedHotel = ({hotel}: PurchasedHotelProps) => {
  const {t} = useTranslation();

  return <VerticalTable
    langGroup="purchased data"
    data={fromHotelToVerticalTableData(hotel)}
    valueRenders={{
      checkIn: DateTimeRender,
      checkOut: DateTimeRender,
      totalNet: (a: any) => <MoneyRender value={a}/>
    }}
    expandable={{
      expandedRowRender: record => <Table
        columns={roomColumns(t)}
        data={roomData(hotel.hotel.rooms, t)}
        pagination={false}
      />,
      rowExpandable: (record) => record.key === 'rooms'
    }}
    pagination={false}
  />
}

export default PurchasedHotel;