import React, {useMemo} from 'react';
import s from './ToItineraryCategories.module.css';
import {useTranslation} from "react-i18next";
import {useFormContext, Controller} from "react-hook-form";



import img_acuaticas from '../../../images/it_categories/acuaticas.png';
import img_aventura_campo from '../../../images/it_categories/aventura_campo.png';
import img_buceo from '../../../images/it_categories/buceo.png';
import img_historia from '../../../images/it_categories/historia.png';
import img_ocio from '../../../images/it_categories/ocio.png';
import img_pareja from '../../../images/it_categories/pareja.png';
import img_ski from '../../../images/it_categories/ski.png';
import img_voluntariado from '../../../images/it_categories/voluntariado.png';

import {ReactComponent as SelectedMarkSVG} from "../../../resources/svg/selected_mark.svg";
import Label from "../../Inputs/Label";
import {useOutletContext} from "react-router-dom";
import {
	itineraryCategories,
	ItineraryCategories,
	itineraryCategoriesIcons,
	OutletItineraryContext
} from "../../../types/itineraries";




interface ToItineraryCategoriesProps {
	className?: string;
	name?: string;
}

const ToItineraryCategories = ({className, name = "itinerary.categories"}: ToItineraryCategoriesProps) => {
	const {t} = useTranslation();
	const outletContext = useOutletContext<OutletItineraryContext>();

	const isItineraryBlocked = outletContext ? outletContext.isItineraryBlocked : false;

	const categories = itineraryCategories.map((name, i) => ({
		label: t(`to.itinerary.categories.${name}`),
		img: itineraryCategoriesIcons[i]
	}))

	return (
		<div className={className}>
			<Label name="itinerary.categories">{t('to.itinerary.categories.label')}</Label>
			<Controller
				defaultValue={[]}
				name={name}
				render={({field: {onChange, value}}) => (
					<div className={s.categories_container}>
						{categories.map(({label, img}) =>
							<div onClick={() => {
								if(isItineraryBlocked)return;
								let n = [...value];
								let index = n.indexOf(label);
								if (index === -1) {
									n = [...n, label];
								} else {
									n.splice(index, 1);
								}
								onChange(n);
							}} className={`${s.category} ${isItineraryBlocked ? s.categoryBlocked : ''} ${value.indexOf(label) !== -1 ? s.selected_category : ''}`}>
								<img draggable={false} src={img} alt={label}/>
								<div>{label}</div>
								<div className={s.tick}><SelectedMarkSVG/></div>
							</div>)}
					</div>
				)}

			/>
		</div>
	);
};


export default ToItineraryCategories;