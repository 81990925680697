import React from 'react';
import s from './GlobalSearchBarResult.module.css';
import SearchBarResults from "../SearchBarResults";
import {useNavigate} from 'react-router-dom';


const GlobalSearchBarResult = props => {
  const navigate = useNavigate();
  const onSelect = result => navigate(result.path);

  return (
    <SearchBarResults {...props} onSelect={onSelect} selected={props.selected} />
  );
};


export default GlobalSearchBarResult;
