import s from './PurchasedInsurance.module.css';
import {ContractedInsurance} from "../../controllers/usePurchaseController/types";
import {useTranslation} from "react-i18next";
import VerticalTable from "../NewTable/VerticalTable";
import {BooleanRender, DateRender, MoneyRender} from "../Table/Renders";
import {fromInsuranceToVerticalTableData} from "./util";

interface PurchasedInsuranceProps {
  insurance: ContractedInsurance;
}

const PurchasedInsurance = ({insurance}: PurchasedInsuranceProps) => {
  const {t} = useTranslation();
  return <VerticalTable
    langGroup="purchased data.insurance data"
    data={fromInsuranceToVerticalTableData(insurance)}
    valueRenders={{
      entryDate: DateRender,
      unsuscribeDate: DateRender,
      effectDate: DateRender,
      totalAmount: (a: any) =>  <MoneyRender value={a}/>,
      netPremiumAmount: (a: any) =>  <MoneyRender value={a}/>,
      netRetailPrice: (a: any) =>  <MoneyRender value={a}/>,
      isEditable: (v: boolean) => BooleanRender(v, null, {t}),
      isRemovable: (v: boolean) => BooleanRender(v, null, {t}),
    }}
 /*   expandable={{
      expandedRowRender: record => <Table
        columns={roomColumns(t)}
        data={roomData(hotel.hotel.rooms, t)}
        pagination={false}
      />,
      rowExpandable: (record) => record.key === 'rooms'
    }}*/
    pagination={false}
  />
}

export default PurchasedInsurance;