import React from 'react';
import s from './Label.module.css';

interface LabelProps extends  React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {
    name: string;
    className?: string;
}

const Label = ({name, className, ...props}: LabelProps) => {
    return (
        <label htmlFor={name} className={`${s.form} ${className || ''}`} {...props} />
    );
};


export default Label;