import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import {Card} from "antd";
import s from "../TDReservation.module.css";
import {BiTrash} from "react-icons/bi";
import {NumberInput, Select, Text} from "@mantine/core";
import {DatePicker} from "@mantine/dates";
import {TDSelectTrainer} from "../../components/SelectTrainer";

export const Product = ({form, index, list, products}) => {
  const {t} = useTranslation();

  const calculateDiscountByPercentage = (percentage) => {
    const item = form.values.products[index];
    const product = products.find(p => p._id === item.product_id);

    const total_price = product ? ((item.adults + item.children) * product.price) : 0;

    form.setListItem('products', index, {
      ...item,
      discount_percentage: percentage,
      discount: percentage * total_price / 100
    })
  }

  const calculateDiscountByValue = (value) => {
    const item = form.values.products[index];
    const product = products.find(p => p._id === item.product_id);

    const total_price = product ? ((item.adults + item.children) * product.price) : 0;

    let percentage = 0;
    if (value > 0) {
      percentage = Math.floor(value / total_price * 100);
    }

    form.setListItem('products', index, {
      ...item,
      discount_percentage: percentage,
      discount: value
    })
  }

  useEffect(() => {
    calculateDiscountByValue(form.values.products[index].discount);
  }, [form.values.products[index].product_id, form.values.products[index].adults, form.values.products[index].children])

  return <Card className="mb-5" title={`Producto ${index + 1}`} extra={form.values.products.length > 1 &&
    <div className={s.removeButton}><BiTrash size={20} onClick={() => form.removeListItem('products', index)}/>
    </div>}>
    <div className="flex items-end sm:flex-nowrap mt-5">
      <Select
        data={list}
        className={s.input}
        searchable
        label={t('td.reservations.product')}
        placeholder={t('td.reservations.product')}
        onChange={(id) => {
          const prev = form.values.products[index];
          const product = products.find(p => p._id === id);

          form.setListItem('products', index, {
            ...prev,
            product_id: id,
            product_salary: product.salary
          })
        }}
        value={form.values.products[index].product_id}
      />
      <NumberInput
        className={s.input}
        label={t('td.reservations.children')}
        {...form.getListInputProps('products', index, 'children')}
      />
      <NumberInput
        className={s.input}
        label={t('td.reservations.adults')}
        {...form.getListInputProps('products', index, 'adults')}
      />
      <DatePicker
        label="Fecha de actividad (inicio)"
        className={s.input}
        {...form.getListInputProps('products', index, 'date')}
      />


      <DatePicker
        label="Fecha de actividad (fin)"
        className={s.input}
        {...form.getListInputProps('products', index, 'date_end')}
      />
    </div>
    <Text className="mt-5" size="md">{t('td.reservations.discount')}</Text>
    <hr className="my-5"/>
    <div className="w-full flex items-end">
      <NumberInput
        label={t('td.reservations.percentage')}
        className={s.input}
        parser={(value) => value.replace('%', '')}
        formatter={(value) => `% ${value}`}
        min={0}
        max={100}
        value={form.values.products[index].discount_percentage}
        onChange={(value) => calculateDiscountByPercentage(value)}
      />

      <NumberInput
        label={t('td.reservations.value')}
        parser={(value) => value.replace(/\MXN\s?|(,*)/g, '')}
        formatter={(value) =>
          !Number.isNaN(parseFloat(value))
            ? `MXN ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : 'MXN '
        }
        className={s.input}
        value={form.values.products[index].discount}
        onChange={(value) => calculateDiscountByValue(value)}
      />
    </div>
    <hr className="my-5"/>
    <div className="w-full flex">
      <TDSelectTrainer
        className={s.input}
        {...form.getListInputProps('products', index, 'trainer')}
      />

      <NumberInput
        label="Salario por persona"
        parser={(value) => value.replace(/\MXN\s?|(,*)/g, '')}
        formatter={(value) =>
          !Number.isNaN(parseFloat(value))
            ? `MXN ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : 'MXN '
        }
        className={s.input}
        {...form.getListInputProps('products', index, 'product_salary')}
      /></div>
  </Card>
}
