import React, {useEffect, useState} from 'react';
import s from './TourOperatorRequestedBudget.module.css';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useTourOperator} from "../../controllers/useTourOperator/useTourOperator";
import Loading from "../Loading";
import ToggleComponent from "../ToggleComponent";
import ShowClient from "../Show/ShowClient";
import VerticalTable from "../Table/VerticalTable";
import {EmailRender} from "../Table/Renders";
import {TextInput} from "@mantine/core";
import MoneyInputWithController from "../Inputs/MoneyInputWithController";
import TourOperatorAnswerBudget from "../TourOperatorAnswerBudget";

const TourOperatorRequestedBudget = props => {
	const {t} = useTranslation();
	let {id} = useParams();
	const {getRequestedBudget} = useTourOperator();

	const [budget, setBudget] = React.useState(null);
	const [loading, setLoading] = useState(false);


	const loadBudget = async () => {
		setLoading(true);
		const {error, budget} = await getRequestedBudget({id});
		setBudget(budget);
		setLoading(false);
	}

	useEffect(() => {
		if(id) loadBudget();
	}, []);

	if (loading) return <Loading/>;

	return (
		<div>
			<ToggleComponent title={t('tour operator data budget.client data')}>
				<ShowClient tableProps={{hideHeader: true}} showTitle={false} client_id={3}/>
			</ToggleComponent>
			<ToggleComponent title={t('tour operator data budget.travel data')}>
				<VerticalTable
					langGroup={'tour operator data budget'}
					data={budget}
					hiddenValues={['_id', 'experience_id']}
					valueRenders={{
						email: EmailRender,
						insurance: v => 'Básico con cancelación',
						origin: v => v?.label,
						distribution: v => <div>
							{v?.map(d => <div>
								{d.adults} {t('adult', {count: d.adults})}
								{!!d.children && <font> {d.children} {t('children', {count: d.children})}</font>}
								{!!d.children_age?.length && ` ${t('tour operator data budget.children ages')}: ${d.children_age.join(', ')}`}
							</div>)}
						</div>
					}}
				/>
			</ToggleComponent>
			<ToggleComponent title={t('tour operator data budget.budget')}>
				<TourOperatorAnswerBudget/>


			</ToggleComponent>
		</div>
	);
};


export default TourOperatorRequestedBudget;