import React, {useEffect, useLayoutEffect, useState} from 'react';
import s from './TONew.module.css';

import {useForm, FormProvider, SubmitHandler} from "react-hook-form";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import bases from "../../../routes/bases";
import useTourOperatorItinerary from "../../../controllers/useTourOperatorItinerary";
import {manageError, toastError} from "../../../helpers/ErrorManager";
import Loading from "../../Loading";
import {ErrorType} from "../../../types/errors";
import {Itinerary, ItinerarySteps} from "../../../types/itineraries";

const TONew = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const {id} = useParams();

	const {getItinerary, editItinerary} = useTourOperatorItinerary();
	const [itinerary, setItinerary] = useState<Itinerary>();
	const [step, setStep] = useState('itineraries');
	const [lang, setLang] = useState('es');


	useEffect(() => {
		loadItinerary();
	}, []);

	const form = useForm<Itinerary>({
		defaultValues: {}
	});

	const location = useLocation();
	useLayoutEffect(() => document.documentElement.scrollTo(0, 0), [location.pathname]);


	const steps = React.useMemo<{text: ItinerarySteps}[]>(() => [
		{text: 'itineraries'},
		{text: 'availability'},
		{text: 'hotels'},
		{text: 'activities'},
		{text: 'transport'},
		{text: 'features'},
		{text: 'packages'},
		{text: 'summary'},
		// {text: 'validation'}
	], []);


	if(!id)return <></>;

	const loadItinerary = async () => {
		try {
			const itinerary = await getItinerary({id});
			if (Array.isArray(itinerary?.places)) itinerary.places = itinerary.places.filter(a => a);
			setItinerary(itinerary);
		}catch(error){
			return manageError(error as ErrorType, t)
		}

		return true;
	}

	const saveItinerary = async (itineraryData: Partial<Itinerary>) => {
		try{
			await editItinerary({id, itinerary: itineraryData});
			await loadItinerary();
			return true;
		}catch(error){
			manageError(error as ErrorType, t);
			return false;
		}
	}


	const onSubmit = (itinerary: Itinerary) => {
		console.log(itinerary);
	}


	if(!itinerary)return <Loading/>
	const isItineraryBlocked = [0, 1].includes(itinerary.validationStatus);

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)}>
				<div className={s.header}>
					{steps.map(({text}, i) => <div key={i}
																				 onClick={() => navigate(`/${bases.tour_operator_itineraries}/${id}/${text}`)}
																				 className={step === text ? s.active_step : ''}>{t(`to.header.${text}`)}</div>)}
				</div>
				<div className={s.container}>
					<Outlet context={{itinerary, setItinerary, loadItinerary, saveItinerary, steps, step, setStep, isItineraryBlocked,lang, setLang}}/>
				</div>
			</form>
		</FormProvider>
	);
};


export default TONew;
