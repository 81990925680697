import useAuth from "../../providers/UserProvider/useAuth";
import {OrderedType} from "./types";
export const usePurchaseController = () => {
  const {secQuery} = useAuth();

  const getPurchases = async ({filters, pages}: {filters?: any, pages?: any} = {}) => {
    const {error, data} = await secQuery(`/api/admin/purchase`, {filters, pages}, 'POST');
    return {error, purchases: data?.purchases, totalRecords: data?.totalRecords};
  }
  const getPurchase = async ({purchase_id}: {purchase_id: string}): Promise<{
    error?: string,
    purchase?: OrderedType
  }> => {
    const {error, data} = await secQuery(`/api/admin/purchase/${purchase_id}`, null, 'POST');
    return {error, purchase: data?.purchase};
  }
  return {getPurchases, getPurchase};

}

export default usePurchaseController;
