import React, {useEffect, useState} from 'react';
import s from './ShowClient.module.css';
import {useParams} from "react-router-dom";
import useSelectionProvider from "../../../providers/SelectionProvider/useSelectionProvider";
import {useTranslation} from "react-i18next";
import useClientController from "../../../controllers/useClientController";
import {AddIconRenderer, BooleanRender, DateRender, DateTimeRender} from "../../Table/Renders";
import {AiOutlineCalendar, AiOutlineInstagram, AiOutlineMail, AiOutlinePhone} from "react-icons/ai";
import VerticalTable from "../../Table/VerticalTable";
import {manageError} from "../../../helpers/ErrorManager";
const {GenderRender, PhoneRender, EmailRender} = require('../../Table/Renders');

const ShowClient = ({client_id: client_id_param, showTitle = true, tableProps = {}}) => {
  const {t} = useTranslation();
  let {id: client_id} = useParams();
  const {client: selected_client} = useSelectionProvider();
  const {getClient} = useClientController();

  return (
    <div>
      {showTitle && <div className="showTitle">{t('client data.client details')}</div>}
      <VerticalTable
        langGroup="client data"
        fetchData={async () => {
          const {error, client} = await getClient({client_id: client_id_param || client_id});
          if(error){
            manageError(error, t);
          }
          return {error, data: client};
        }}
        valueRenders={{
          email: EmailRender,
          phone: PhoneRender,
          gender: GenderRender,
          birthday: DateRender,
          email_verified: BooleanRender,
          phone_verified: BooleanRender,
          suspended: BooleanRender,
          updated_at: DateTimeRender,
          created_at: DateTimeRender
        }}
        keyRenders={{
          instagram: AddIconRenderer(<AiOutlineInstagram size={16}/>),
          phone: AddIconRenderer(<AiOutlinePhone size={16}/>),
          email: AddIconRenderer(<AiOutlineMail size={16}/>),
          birthday: AddIconRenderer(<AiOutlineCalendar size={16}/>)
        }}
      />
    </div>
  );
};


export default ShowClient;
