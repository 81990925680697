import React from 'react';
import {LocaleContext} from './LocaleContext';

import {useLocalStorage} from '../../hooks/useLocalStorage';
import {query} from "../../hooks/useQuery";

const capitalize = (a) => a ? a.charAt(0).toUpperCase() + a.substring(1, a.length) : '';


const defaultLanguage = async () => {
  let result = await query('getLang', {code: navigator.language.substring(0, 2)}, 'get');
  console.log(result);
  let data = {};
  if (result.ok) {
    result.data.forEach(l => {
      data[l.key] = {id: l.id, translated: l.translated};
    });
  }
  return data;
}

const LocaleProvider = props => {
  const [locale, setLocale] = useLocalStorage("lang", {
    "wrong user or password": {translated: 'wrong user or password'},
    "remember me": {translated: 'remember me'},
    "unknown error": {translated: "unknown error"},
    "please": {translated: 'please'},
    "try again": {translated: "try again"},
    "home": {translated: 'home'},
    'settings': {translated: "settings"},
    'calendar': {translated: 'calendar'},
    'analytics': {translated: 'analytics'},
    'social': {translated: 'social'},
    'payments': {translated: 'payments'},
    'search': {translated: 'search'},
    'profile': {translated: 'profile'},
    'roles': {translated: 'roles'},
    'not found': {translated: 'not found'},
    'permissions': {translated: 'permissions'},
    'persons': {translated: 'persons'},
    'admins': {translated: 'admins'},
    'description': {translated: 'description'},
    'read': {translated: 'read'},
    'write': {translated: 'write'},
    'update': {translated: 'update'},
    'delete': {translated: 'delete'},
    'my profile': {translated: 'my profile'},
    'logout': {translated: 'logout'},
    'username': {translated: 'username'},
    'email': {translated: 'email'},
    'first name': {translated: 'first name'},
    'second name': {translated: 'second name'},
    'surname': {translated: 'surname'},
    'an error occurred': {translated: 'an error occurred'},
    "can't connect to server": {translated: "can't connect to server"},
    'gender': {translated: 'gender'},
    'birthday': {translated: 'birthday'},
    'country': {translated: 'country'},
    'state': {translated: 'state'},
    'city': {translated: 'city'},
    'address': {translated: 'address'},
    'postcode': {translated: 'postcode'},
    'location': {translated: 'location'},
    'person data': {translated: 'person data'},
    'administrators': {translated: 'administrators'},
    'suspended': {translated: 'suspended'},
    'created at': {translated: 'created at'},
    'updated at': {translated: 'updated at'},
    'yes': {translated: 'yes'},
    'no': {translated: 'no'},
    'name': {translated: 'name'},
    'phone number': {translated: 'phone number'},
    'actions': {translated: 'actions'},
    'edit': {translated: 'edit'},
    'showing': {translated: 'showing'},
    'to': {translated: 'to'},
    'of': {translated: 'of'},
    'entries': {translated: 'entries'},
    'previous': {translated: 'previous'},
    'next': {translated: 'next'},
    'clients': {translated: 'clients'},
    'page size': {translated: 'page size'},
    'empty': {translated: 'empty'},
    'all': {translated: 'all'},
    'delete confirmation': {translated: 'delete confirmation'},
    'sure delete': {translated: 'are you sure you want to delete'},
    'cancel': {translated: 'cancel'}

  });

  /*  React.useEffect(() => {
      (async () => {
        if (!locale || locale.length === 0) {
          setLocale(await defaultLanguage());
        }
      })();
    }, [locale, setLocale]);*/

  const T = (s, u, d) => {
    let l = locale[s]?.translated || d || '';
    return u ? capitalize(l) : l;
  };


  return (
    <LocaleContext.Provider
      value={{locale, setLocale, T}}>
      {props.children}
    </LocaleContext.Provider>
  );
}

export default LocaleProvider;
