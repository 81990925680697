import React from 'react';
import s from './OverviewPage.module.css';
import GridNavigatorWithIcons from "../GridNavigatorWithIcons";
import useAuth from "../../providers/UserProvider/useAuth";
import ShowWithPermissions from "../Permissions/ShowWithPermissions";
import GridNavigatorTO from "../GridNavigatorTO";

const OverviewPage = props => {
	const {getType} = useAuth();
	return (
		<div>
			{/*<GridDragAndDrop gridName="overview"/>*/}

			<ShowWithPermissions allow={['admin']}>
				<GridNavigatorWithIcons/>
			</ShowWithPermissions>

			<ShowWithPermissions allow={['to']}>
				<GridNavigatorTO/>
			</ShowWithPermissions>

		</div>
	);
};


export default OverviewPage;
