import React, {forwardRef, useMemo} from 'react';
import s from './MySavedHotelFacilities.module.css';
import {useTranslation} from "react-i18next";
import {Box, CloseButton, Group, MultiSelect, Text} from "@mantine/core";
import {ReactComponent as WifiSVG} from "../../../resources/svg/services/wifi.svg";
import {ReactComponent as CrippleSVG} from "../../../resources/svg/services/cripple.svg";
import {ReactComponent as RoomServiceSVG} from "../../../resources/svg/services/room_service.svg";
import {ReactComponent as FridgeSVG} from "../../../resources/svg/services/fridge.svg";
import {ReactComponent as AirConditioningSVG} from "../../../resources/svg/services/air_conditioning.svg";
import {ReactComponent as ParkingSVG} from "../../../resources/svg/services/parking.svg";
import {ReactComponent as DryerSVG} from "../../../resources/svg/services/dryer.svg";
import {CgGym, CgScreen} from "react-icons/cg";
import {RiTempHotLine} from "react-icons/ri";
import {BsWater} from "react-icons/bs";
import {FaSwimmingPool} from "react-icons/fa";
import {BiDrink, BiRestaurant} from "react-icons/bi";
import {MdEmojiTransportation, MdLocalDrink, MdLuggage, MdOutlinePets} from "react-icons/md";
import {GrCurrency, GrElevator} from "react-icons/gr";
import {GiCigarette} from "react-icons/gi";


const SelectItem = forwardRef(({label, icon, value, ...props}, ref) => <div ref={ref} {...props}>
	<Group noWrap>
		<div style={{width: 30, height: 30}}>{icon}</div>
		<Text>{label}</Text>
	</Group>
</div>)

const SelectValueItem = ({value, icon, label, classNames, onRemove, ...props}) => <div className={classNames} {...props}>
	<Box className={s.selectValueContainer}>
		<div className={s.selectValueItemIcon}>{icon}</div>
		<div className="ml-5 cursor-default whitespace-nowrap">{label}</div>
		<CloseButton
			onMouseDown={onRemove}
			variant="transparent"
			size={22}
			iconSize={14}
			tabIndex={-1}
		/>
	</Box>
</div>


const MySavedHotelFacilities = ({form}) => {
	const {t} = useTranslation();

	const list = useMemo(() => {
		let a = {size: 20};
		let b = {width: 20, height:20}


		const groups = [
			{label: t('to.hotel.facilities.groups.building'), value: 'building'},
			{label: t('to.hotel.facilities.groups.services'), value: 'services'},
			{label: t('to.hotel.facilities.groups.hostelry'), value: 'hostelry'},
			{label: t('to.hotel.facilities.groups.other'), value: 'other'},
		];

		return [
			{label: t('to.hotel.facilities.pool'), value: 'pool', group: 'building', icon: <FaSwimmingPool {...a}/>},
			{label: t('to.hotel.facilities.parking'), value: 'parking', group: 'building', icon: <ParkingSVG {...b}/>},
			{label: t('to.hotel.facilities.wifi'), value: 'wifi', group: 'services', icon: <WifiSVG {...b}/>},
			{label: t('to.hotel.facilities.restaurant'), value: 'restaurant', group: 'hostelry', icon: <BiRestaurant {...a}/>},
			{label: t('to.hotel.facilities.bar'), value: 'bar', group: 'hostelry',icon: <BiDrink {...a}/>},
			{label: t('to.hotel.facilities.gym'), value: 'gym', group: 'building', icon: <CgGym {...a}/>},
			{label: t('to.hotel.facilities.spa'), value: 'spa', group: 'building', icon: <BsWater {...a}/>},
			{label: t('to.hotel.facilities.sauna'), value: 'sauna', group: 'building',icon: <RiTempHotLine {...a}/>},
			{label: t('to.hotel.facilities.airport shuttle'), value: 'airport shuttle', group: 'other', icon: <MdEmojiTransportation {...a}/>},
			{label: t('to.hotel.facilities.24/7 hour reception'), value: '24/7 hour reception', group: 'services', icon: <RoomServiceSVG {...b}/>},
			{label: t('to.hotel.facilities.disability access'), value: 'disability', group: 'building', icon: <CrippleSVG {...b}/>},
			{label: t('to.hotel.facilities.elevator'), value: 'elevator', group: 'building', icon: <GrElevator {...a}/>},
			{label: t('to.hotel.facilities.smoking area'), value: 'smoking area', group: 'building', icon: <GiCigarette {...a}/>},
			{label: t('to.hotel.facilities.air conditioning'), value: 'air conditioning', group: 'services', icon: <AirConditioningSVG {...b}/>},
			{label: t('to.hotel.facilities.pets'), value: 'pets', group: 'other', icon: <MdOutlinePets {...a}/>},
			{label: t('to.hotel.facilities.tv'), value: 'tv', group: 'building', icon: <CgScreen {...a}/>},
			{label: t('to.hotel.facilities.dryer'), value: 'dryer', group: 'building',icon: <DryerSVG {...b}/>},
			{label: t('to.hotel.facilities.luggage storage'), value: 'luggage storage', group: 'services', icon: <MdLuggage {...a}/>},
			{label: t('to.hotel.facilities.currency exchange'), value: 'currency exchange', group: 'services',icon: <GrCurrency {...a}/>},
			{label: t('to.hotel.facilities.minibar'), value: 'minibar', group: 'hostelry',icon: <MdLocalDrink {...a}/>},
			{label: t('to.hotel.facilities.fridge'), value: 'fridge', group: 'building', icon: <FridgeSVG {...b}/>},
		].map(a => ({...a, group: groups.find(b => b.value === a.group).label}))
	}, []);

	return <MultiSelect
		label={t('to.hotel.facilities.label')}
		data={list}
		searchable
		clearable
		itemComponent={SelectItem}
		valueComponent={SelectValueItem}
		{...form.getInputProps('facilities')}/>;
};


export default MySavedHotelFacilities;