import s from './ListInput.module.css';
import Label from "../../../../Inputs/Label";
import TextInput from "../TextInput/TextInput";
import {useFormContext} from "react-hook-form";
import {CgCloseO} from "react-icons/cg";
import ChipsSelectLang from "../../../../Inputs/ChipsSelectLang/ChipsSelectLang";
import {useOutletContext} from "react-router-dom";
import {MultiLangText, OutletItineraryContext} from "../../../../../types/itineraries";
import {langRender} from "../../../../../helpers/i18n";
import MultiLangInputV2 from "../../../../Inputs/MultiLangInputV2";
import Textarea from "../Textarea/Textarea";
import React from "react";

interface ListInputProps {
  label: string;
  name: string;
  placeholder?: string;
  Icon?: any;
  className?: string;
  disabled?: boolean;
  ballColor: string;

  lang?: string;
  setLang?: (lang: string) => void;
}

const ListInput = ({label, placeholder, className, disabled, name, ballColor, Icon, ...props}: ListInputProps) => {
  const {setValue, watch} = useFormContext();
  let context = useOutletContext<OutletItineraryContext>();

  let lang = props.lang || context.lang;
  let setLang = props.setLang || context.setLang;

  const list = watch(name);

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if(!value)return;
    if (e.key === 'Enter' || e.keyCode === 13) {
      let prev = list || [];
      console.log(prev);
      if(typeof prev === 'string' || (typeof prev === 'object' && !Array.isArray(prev)))prev = [prev]; // backwards compatibility
      setValue(name, [...prev, {[lang]: value}]);
      e.currentTarget.value = '';
    }
  }

  const onDelete = (index: number) => {
    let prev = list || [];
    prev.splice(index, 1);
    setValue(name, prev);
  }

  return <div className={className}>
    <div className={s.labelContainer}>
      <div className={s.labelWithIcon}>
        {Icon && <Icon className="mr-5"/>}
        <Label name={name}>{label} ({lang})</Label>
      </div>
      <ChipsSelectLang value={lang} onChange={v => setLang(v)}/>
    </div>
    <TextInput placeholder={placeholder} onKeyUp={onKeyUp} />
    <div className={s.list}>
      {Array.isArray(list) && list.filter(a => a).map((content: MultiLangText, index: number) => {
        return <div key={index} className={s.item}>
          <div className={s.contentContainer}>
            <div className={s.itemBall} style={{borderColor: ballColor}}/>
            <MultiLangInputV2
              name={`${name}.${index}`}
              className="w-full mr-5"
              disabled={context.isItineraryBlocked}
              hideLangSelect={true}
            />
            {/*<div>{langRender(content)}</div>*/}
          </div>
          <div className={s.delete} onClick={() => onDelete(index)}>
            <CgCloseO size={20}/>
          </div>
        </div>
      })}
    </div>
  </div>
}

export default ListInput;