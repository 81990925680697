import {useNavigate} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import {TableParams, TableResult} from "../../../../util/table/types";
import {
  defaultTableParams,
  handleTableChange,
  setTotalToTableParams,
  tableParamsToSearchParams
} from "../../../../util/table/table";
import useSWR from "swr";
import {Divider, Table} from "antd";
import {trainersPaymentColumns} from "./columns";
import bases from "../../../../routes/bases";
import {MoneyRender} from "../../../Table/Renders";
import {TrainersPayment, TrainersPaymentTableSum} from "./types";
import {Trainer} from "../../TDTrainers/types";
import {canTDWrite} from "../../../Permissions/VisibleWithPermissions";


interface Props {
  selectedRowKeys: React.Key[]
  onSelectionChange: (selectedRowKeys: React.Key[]) => void
  refresh?: number
  trainers: Trainer[]
  products: any[]
}

export function TDTrainersPaymentTable({refresh, trainers, products, selectedRowKeys, onSelectionChange}: Props) {
  const [tableParams, setTableParams] = useState<TableParams>(defaultTableParams);
  const {
    isLoading,
    data,
    mutate
  } = useSWR<TableResult<TrainersPayment>>(`/api/admin/td/trainers_payment?${tableParamsToSearchParams(tableParams)}`);
  const {data: sum} = useSWR<TrainersPaymentTableSum>(`/api/admin/td/trainers_payment_sum?${tableParamsToSearchParams(tableParams)}`);
  const navigate = useNavigate()

  useEffect(() => {
    setTotalToTableParams(setTableParams, data?.total)
  }, [data]);


  useEffect(() => {
    if (!refresh) return;
    mutate();
  }, [refresh]);

  const buildId = (payment: TrainersPayment) => {
    return payment._id + payment.product._id + payment.product.date + payment.product.date
  }

  const canWrite = useMemo(() => canTDWrite(), [])


  return <>
    <div className="p-10 pt-0 mb-20">
      <Divider>Pagos a los instructores</Divider>
      <Table
      rowSelection={{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectionChange
      }}
      columns={trainersPaymentColumns({canWrite, trainers, products,onEdit: (id) => {
        navigate(`/${bases.td.reservations}/${id}`)
        }})}
      dataSource={data?.data}
      rowKey={buildId}
      loading={isLoading}
      pagination={tableParams.pagination}
      onChange={handleTableChange(setTableParams)}
      summary={() => {
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={1} colSpan={3}>Total</Table.Summary.Cell>
              {/*<Table.Summary.Cell index={2} colSpan={1}><MoneyRender value={sum?.total_price}/></Table.Summary.Cell>*/}
              <Table.Summary.Cell index={2} colSpan={5}><MoneyRender value={sum?.salary}/></Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }}
    />
  </div>
    </>
}