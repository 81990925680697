import React from 'react';
import s from './PasswordResetPage.module.css';
import {ReactComponent as LogoSVG} from "../../resources/svg/logo.svg";
import {PasswordInput} from "@mantine/core";
import {useTranslation} from "react-i18next";
import {useForm} from "@mantine/form";
import Button from "../Inputs/Button";
import useAdminController from "../../controllers/useAdminController/useAdminController";
import {useNavigate, useSearchParams} from "react-router-dom";
import {toastError} from "../../helpers/ErrorManager";
import {toast} from "react-toastify";
import useWindowSize from "../../hooks/useWindowSize/useWindowSize";

const PasswordResetPage = props => {
	const {t} = useTranslation();

	const {changePassword} = useAdminController();
	const {height: windowHeight} = useWindowSize();

	const [params] = useSearchParams();

	const id = params.get('id');
	const hash = params.get('h');
	const navigate = useNavigate();


	const form = useForm({
		initialValues: {
			password: ''
		}
	});

	const onSubmit = async () => {
		const {error, success} = await changePassword({id, hash, password: form.values.password});
		if (error) return toastError(error, t);
		if(success) {
			toast.success(t('success'));
			navigate('/login');
		}else{
			toast.error(t('error'));
		}
	}

	return (
		<div className={s.container}  style={{height: windowHeight + 'px'}}>
			<div className={s.modalCenter}>
				<div className={s.logoContainer}>
					<LogoSVG/>
				</div>
				<div>
					<form>
						<PasswordInput label={t('password')} {...form.getInputProps('password')}/>
						<Button className="mt-5" type="button" label={t('submit')} onClick={onSubmit}>{t('change password')}</Button>
					</form>
				</div>
			</div>
		</div>
	);
};


export default PasswordResetPage;