import React, {forwardRef, useCallback, useEffect, useRef, useState} from 'react';
import s from './InputWithSelect.module.css';
import {useFormContext, Controller} from "react-hook-form";
import {Autocomplete, Group, Loader, Text} from "@mantine/core";
import {debounce} from "../../../helpers/util";
import {ReactComponent as LocationSVG} from "../../../resources/svg/location.svg";


interface AutoCompleteItemProps {
	value: string;
	description: string;
	icon?: React.ReactNode;
}

const AutoCompleteItem = forwardRef(({value, description, icon, ...props}: AutoCompleteItemProps, ref) => {
	// @ts-ignore
	return <div ref={ref} {...props}>
		<Group noWrap>
			{icon && <div className={s.icon}>{icon}</div>}
			<div>
				<Text>{value}</Text>
				<Text size="xs" color="dimmed">
					{description}
				</Text>
			</div>
		</Group>
	</div>
});


interface InputWithSelectProps {
	id?: string;
	name: string;
	onChange: (e: any) => Promise<any>;
	containerClassName?: string;
	onDataSelect?: (e: any) => void;
	defaultData?: any[];
	[key: string]: any;
}

const InputWithSelect = ({
													 id,
													 name,
													 onChange,
													 containerClassName,
													 onDataSelect,
													 defaultData,
													 form,
													 ...props
												 }: InputWithSelectProps) => {
	const [data, setData] = useState<any>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (defaultData) setData(defaultData);
	}, [defaultData]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const asyncChange = useCallback(debounce(async (e: any) => {
		setLoading(true);
		let data = await onChange(e);
		setData(data);
		setLoading(false);
	}, 500), []);

	const value = form.values[name];

	return (
		<Autocomplete
			name={name}
			id={id}
			value={typeof value === 'string' ? value : value?.value}
			data={data}
			onChange={e => {
				form.setFieldValue(name, e);
				//onChange && onChange(e);
				asyncChange(e);
			}}
			rightSection={loading ? <Loader size={16}/> : null}
			itemComponent={AutoCompleteItem}
			filter={a => !!a}
			onItemSubmit={item => {
				form.setFieldValue(name, item);
			}}
			{...props}
		/>
	)
};


export default InputWithSelect;
