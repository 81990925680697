const routes = {

  // clients
  clients: 'clients',
  client: 'client',
  chat: 'social',
  contact_us: 'contact_us',
  start_your_trip: 'syt',

  // sales
  purchase: 'purchase',
  ordered: 'ordered',

  // tour operator
  tour_operator: 'to',
  tour_operator_itineraries: 'to/it',
  tour_operator_new_itinerary: 'to/it_n',
  tour_operator_setup: 'to_setup',
  tour_operator_account: 'to/account',

  itineraries_to_validate: 'its_val',

  tour_operator_budgets: 'to/budgets',


  // saved things pages
  saved_hotel: 'saved_hotel',
  saved_activity: 'saved_activity',
  gallery: 'gallery',

  // configuration
  configuration: 'settings',
  config_api_hotels: 'settings/api',
  config_admin: 'settings/admin',
  config_insurance: 'settings/apis/insurance',

  admin_password_change: 'pw_reset',


  // admin
  admin: 'admin',
  admin_hotels: 'h',
  admin_itineraries: 'it',
  admin_activities: 'ac',


  td: {
    main: 'td',
    products: 'td/products',
    snorkel: 'td/snorkels',
    courses: 'td/courses',
    dives: 'td/dives',
    reservations: 'td/reservations',
    contact_us: 'td/contact_us',
    clients: 'td/clients',
    payments: 'td/payments',
    sellers: 'td/sellers',
    trainers: 'td/trainers',
    sellers_payments: 'td/sellers_payments',
    trainers_payments: 'td/trainers_payments',
    calendar_activities: 'td/calendar/activities',
    calendar_sellers: 'td/calendar/sellers',
    events: 'td/events'
  }
};



export default routes;

