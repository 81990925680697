import React, {useEffect, useMemo, useState} from 'react';
import s from './TDReservation.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useTDReservations} from "../../../controllers/useTDReservations/useTDReservations";
import {formList, useForm} from "@mantine/form";
import bases from "../../../routes/bases";
import {Button, Select, Text, Textarea, Title} from "@mantine/core";
import {useTDProducts} from "../../../controllers/useTDProducts/useTDProducts";
import {langRender} from "../../../helpers/i18n";
import TDCreateOrChooseClient from "../TDComponents/TDCreateOrChooseClient";
import {GrFormAdd} from "react-icons/gr";
import TDPointOfSell from "../TDComponents/TDPointOfSell";
import Loading from "../../Loading";
import {Payment} from "./components/Payment";
import {Product} from "./components/Product";
import {Discount} from "./components/Discount";
import {AccountingTable} from "./components/AccountingTable";
import {TDSelectSeller} from "../components/SelectSeller";
import {dateToServer, serverToDate} from "../../../util/util";
import {canTDWrite} from "../../Permissions/VisibleWithPermissions";


const defaultProduct = {
  product_id: '',
  adults: 0,
  children: 0,
  date: new Date(),
  trainer: '',
  product_salary: 0
}

const defaultPayment = {
  method: 'card',
  currency: 'MXN',
  currency_amount: 0,
  amount: 0,
  date: new Date(),
  commission: 0
}

const TDReservation = props => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {id} = useParams();
  const [products, setProducts] = useState([]);

  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [reservation, setReservation] = useState();

  const {getAllProducts} = useTDProducts();

  const {getReservation, editReservation} = useTDReservations();
  const nan = (value) => isNaN(value) ? 0 : value;

  const form = useForm({
    initialValues: {
      client_id: '',
      seller: 0,
      language: '',
      point_of_sale: '',
      description: '',
      total_price: 0,

      discount: 0,
      discount_description: '',

      products: formList([defaultProduct]),
      payments: formList([defaultPayment])
    }
  });

  useEffect(() => {
    if (Array.isArray(form.values.products) && Array.isArray(products)) {
      let price = 0;
      form.values.products.forEach(p => {
        const product = products.find(pr => pr._id === p.product_id);
        if (product) {
          price += (product.price * (p.adults + p.children)) - (p.discount || 0)
        }
      });
      if (form.values.discount) price -= form.values.discount;
      form.setFieldValue('total_price', nan(price));
    }
  }, [form.values.products, form.values.discount]);


  const loadProducts = async () => {
    const {error, products} = await getAllProducts();
    if (error) return alert(error);
    setProducts(products.filter(a => a.name));
  }


  const productsSelect = useMemo(() => Array.isArray(products) ? products.map(product => ({
    value: product._id,
    label: langRender(product.name)
  })) : [], [products]);

  const loadReservation = async () => {
    const {error, reservation} = await getReservation({id});
    if (error) return alert(error);

    let data = {};
    ['client_id', 'seller', 'language', 'point_of_sale', 'description', 'discount', 'discount_description', 'total_price'].forEach(key => {
      if (reservation[key] !== null) data[key] = reservation[key];
    });

    let percentage = 0;
    if (reservation.discount > 0) {
      percentage = Math.floor(reservation.discount / (reservation.total_price + reservation.discount) * 100);
    }

    data.discount_percentage = percentage;

    if (reservation.reservation_date) data.reservation_date = serverToDate(reservation.reservation_date);


    data.products = formList(reservation.products?.map(p => ({
      ...defaultProduct,
      ...p,
      date: serverToDate(p.date),
      date_end: serverToDate(p.date_end)
    })) || [defaultProduct]);
    data.payments = formList(reservation.payments?.map(p => ({
      ...defaultPayment,
      ...p,
      date: serverToDate(p.date)
    })) || [defaultPayment]);

    form.setValues(data);
    setReservation(reservation);
  }

  const loadAll = async () => {
    setLoading(true);
    await loadProducts();
    await loadReservation();
    setLoading(false);
  }

  useEffect(() => {
    loadAll();
  }, []);

  const onSubmit = async data => {
    setSubmitLoading(true);
    const {error} = await editReservation({
      id, reservation: {
        ...data,
        payments: data.payments.map(p => ({
          ...p,
          date: dateToServer(p.date),
        })),
        products: data.products.map(p => ({
          ...p,
          date: dateToServer(p.date),
          date_end: dateToServer(p.date_end)
        }))
      }
    });
    setSubmitLoading(false);
    if (error) return alert(error);
    navigate(`/${bases.td.reservations}`);
  }


  const languageOptions = useMemo(() => [
    {value: 'es-ES', label: t('languageList.es-ES')},
    {value: 'en-US', label: t('languageList.en-US')},
  ], []);


  if (loading) return <Loading/>;

  return (
    <div className="container m-auto pl-5" style={{marginBottom: '400px'}}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Title className="mb-5" size="l">{t('td.reservations.title')}</Title>

        <Text size="xl">{t('td.reservations.person info')}</Text>
        <hr className="my-5"/>

        <TDCreateOrChooseClient defaultClientId={reservation?.client_id} form={form}/>

        <Text size="xl" className="mt-5">{t('td.reservations.reservation info')}</Text>
        <hr className="my-5"/>
        <div className="flex sm:flex-nowrap my-5">
          <TDSelectSeller
            className={s.input}
            {...form.getInputProps('seller')}
          />

          <Select
            data={languageOptions}
            className={s.input}
            label={t('td.reservations.language')}
            {...form.getInputProps('language')}
          />
        </div>
        <TDPointOfSell className="mb-5" style={{width: '615px'}} {...form.getInputProps('point_of_sale')}/>
        <Textarea
          style={{width: '615px'}}
          label={t('td.reservations.description')}
          minRows={5}
          autosize
          {...form.getInputProps('description')}
        />

        <Text size="xl" className="mt-5">{t('td.reservations.products info')}</Text>
        <hr className="my-5"/>

        {form.values.products.map((client, index) => <Product products={products} form={form} index={index}
                                                              list={productsSelect}/>)}

        <div className={s.addButton}
             onClick={() => form.addListItem('products', defaultProduct)}>
          <Button>
            <GrFormAdd size={24}/>
          </Button>
        </div>

        <Discount products={products} form={form}/>

        <Text size="xl">{t('td.reservations.payments')}</Text>
        <hr className="my-5"/>

        {form.values.payments.map((client, index) => <Payment form={form} index={index}/>)}

        <hr className="my-5"/>

        <div className={s.addButton}
             onClick={() => form.addListItem('payments', defaultPayment)}>
          <Button>
            <GrFormAdd size={24}/>
          </Button>
        </div>


        <hr className="my-10"/>

        <AccountingTable products={products} form={form} list={products}/>


        <Button disabled={!canTDWrite()} className="mt-5" type="submit" loading={submitLoading}>{t('save')}</Button>
      </form>
    </div>
  );
};


export default TDReservation;