import React, {useEffect, useState} from 'react';
import useClientController from "../../../controllers/useClientController";
import {useTranslation} from "react-i18next";
import {Moment} from "moment/moment";
import type {TablePaginationConfig} from "antd/es/table";
import type {FilterValue, SorterResult} from "antd/es/table/interface";
import {UserOnTable} from "../types";
import {useColumns} from "./columns";
import Table from "../../NewTable/NewTable";
import ShowClient from "../../Show/ShowClient";


interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue | null>;
}

const ClientsTable = () => {
  const {t} = useTranslation();
  const {getClientsOnTable} = useClientController();

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10
    }
  });
  const [data, setData] = useState<UserOnTable[]>([]);
  const columns = useColumns()

  const fetchData = async () => {
    const {filters} = tableParams;

    const from = filters?.birthday_from && filters.birthday_from[0] ? (filters?.birthday_from[0] as unknown as Moment) : undefined;
    const to = filters?.birthday_to && filters.birthday_to[0] ? (filters?.birthday_to[0] as unknown as Moment) : undefined;

    let result = await getClientsOnTable({
      page: tableParams?.pagination?.current! - 1,
      resultsPerPage: tableParams?.pagination?.pageSize!,
      filters: {
        full_name: filters?.full_name ? filters.full_name[0] : undefined,
        email: filters?.email ? filters.email[0] : undefined,
        phone: filters?.phone ? filters.phone[0] : undefined,
        birthday_from: from?.toDate()?.toISOString(),
        birthday_to: to?.toDate()?.toISOString(),
      }
    });
    setData(result.data);
    setTableParams(prev => ({
      ...prev,
      pagination: {
        ...prev?.pagination,
        total: result.totalRecords
      }
    }))
  }

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams)]);


  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserOnTable> | SorterResult<UserOnTable>[]
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });


    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  return (
    <div>
      <Table
        rowKey="_id"
        columns={columns}
        data={data}
        title={() => t('client data.table title')}
        onChange={handleTableChange}
        pagination={tableParams?.pagination}
        expandable={{
          expandedRowRender: (record: UserOnTable) => <ShowClient client_id={record._id}/>
        }}
      />
    </div>
  );
};


export default ClientsTable;
