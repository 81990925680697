import {Button, Modal} from "antd";
import {EventCalendar} from "../types";
import React from "react";

interface Props {
  onClose: () => void
  event: EventCalendar | null
}

export function EventDetails({event, onClose}: Props) {

  return (
    <Modal
      width={800}
      footer={[
        <Button onClick={() => onClose()}>Cerrar</Button>
      ]}
      onCancel={onClose} cancelText="Cerrar" okText="Editar reserva" open={!!event} title={event?.name}>
      <div>
        {event?.description}
      </div>
    </Modal>
  )
}