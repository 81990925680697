import {Button, Form, FormProps} from "antd";
import {TrainerFormValues} from "../types";
import Input from "antd/es/input";
import {useMemo} from "react";
import {canTDWrite} from "../../../Permissions/VisibleWithPermissions";

interface TrainerFormProps extends FormProps {
  onFinish: (values: TrainerFormValues) => void
  loading?: boolean
}
export function TDTrainerForm({onFinish, initialValues, loading, ...props}: TrainerFormProps) {

  const onFinishHandler = (values: TrainerFormValues) => {
    onFinish(values)
  }

  const canWrite = useMemo(() => canTDWrite(), [])


  return <Form labelCol={{span: 2}} className="px-5" {...props} initialValues={initialValues} onFinish={onFinishHandler}>
    <Form.Item name="name" label="Nombre" rules={[{required: true}]}>
      <Input/>
    </Form.Item>
    <Form.Item name="ccc" label="CCC">
      <Input/>
    </Form.Item>

    {canWrite && <Form.Item wrapperCol={{span: 3, offset: 10}}>
      <Button loading={loading} disabled={loading} type="primary" htmlType="submit">Guardar</Button>
    </Form.Item>}
  </Form>

}