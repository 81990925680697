import {createContext} from 'react';
import {ItineraryMenuChosenType} from "./MenuProvider";


interface IMenuContext {
  chosen: ItineraryMenuChosenType;
  setChosen: (chosen: ItineraryMenuChosenType) => void;
}

export const MenuContext = createContext<IMenuContext | undefined>(undefined);
