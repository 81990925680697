import React from 'react';
import s from './Image.module.css';
import classNames from 'classnames';
import notFoundSVG from "../../resources/svg/not_found_image.svg";

let cx = classNames.bind(s);


interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  className?: string;
  src: any;
}

const Image = ({className = '', src, ...props}: ImageProps) => {
  const style = cx({
    [s.image]: true,
    [className]: true
  });
  return (
    <img src={src || notFoundSVG} onError={e => {
      console.log(e)
      e.currentTarget.src = notFoundSVG
    }} className={style} {...props} />
  );
};


export default Image;
