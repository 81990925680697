import {Form, Input, InputNumber, message, Modal} from "antd";
import {ReservationTable} from "./types";
import {useState} from "react";
import {postData} from "../../../util/req";

interface Props {
  reservation?: Pick<ReservationTable, '_id'>
  onClose: (cancelled: boolean) => void
}

export function CancelReservation({reservation, onClose}: Props){
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const initialValues = {
    refund: 90,
    reason: ''
  }

  const onSubmit = async () => {
    setLoading(true)
    const values = await form.validateFields()

    const response = await postData(`/td/reservation/${reservation!._id}/cancel`, {
      refund: values.refund,
      reason: values.reason ?? ''
    })
    setLoading(false)

    if(response.success){
      message.open({
        type: 'success',
        content: 'La reserva ha sido cancelada'
      });
      onClose(true)
    }
  }


  return <Modal onOk={onSubmit} confirmLoading={loading} title="Cancelar reserva" open={!!reservation} onCancel={() => onClose(false)}>
    <Form form={form} labelCol={{span: 8}} initialValues={initialValues}>
      <Form.Item label="Devolución" name="refund" rules={[{required: true}]}>
        <InputNumber min={1} max={100} suffix="%"/>
      </Form.Item>
      <Form.Item label="Razón de cancelación" name="reason">
        <Input.TextArea/>
      </Form.Item>
    </Form>
  </Modal>
}