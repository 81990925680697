import React, {useEffect, useMemo} from 'react';
import s from './Checkbox.module.css';

const Checkbox = React.forwardRef((
  {
    className,
    name = '',
    indeterminate,
    label,
    classNameContainer,
    containerClassName,
    ...props
  }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  className = className || containerClassName || classNameContainer ;

  const id = useMemo(() =>
    props.id || name + Math.floor(Math.random() * 10000)
    , []);

  useEffect(() => {
    if(resolvedRef.current)resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <div className={className}>
      <input
        className={s.checkbox}
        type="checkbox"
        id={id}
        name={name}
        ref={resolvedRef}
        {...props}
      />
      <label className={s.label} htmlFor={id}>
      <span>
        <svg width="12px" height="10px" viewBox="0 0 12 10">
          <polyline points="1.5 6 4.5 9 10.5 1"/>
        </svg>
      </span>
        {label && <label htmlFor={id} className="text-xl ml-5">{label}</label>}
      </label>
    </div>
  )
});


export default Checkbox;
