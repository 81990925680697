import {useTranslation} from "react-i18next";
import {ColumnsType} from "antd/es/table";
import {DateRender, DateTimeRender, EmailRender, PhoneRender} from "../../Table/Renders";
import {searchByText} from "../../NewTable/filters/searchByText";
import {searchByDateRange} from "../../NewTable/filters/searchByDateRange";
import {UserOnTable} from "../types";


export const useColumns = () => {
  const {t: _t} = useTranslation();
  const t = (s: string) => _t(`client data.${s}`);

  const columns: ColumnsType<UserOnTable> = [
    {
      dataIndex: 'full_name',
      title: t('full name'),
      ...searchByText<UserOnTable>({name: 'full_name'})
    },
    {
      dataIndex: 'email',
      title: t('email'),
      render: EmailRender,
      ...searchByText<UserOnTable>({name: 'email'})
    },
    {
      dataIndex: 'phone',
      title: t('phone'),
      render: PhoneRender,
      ...searchByText<UserOnTable>({name: 'phone'})
    },
    {
      dataIndex: 'birthday',
      title: t('birthday'),
      render: (birthday: Date, r: UserOnTable) => DateRender(birthday),
      ...searchByDateRange<UserOnTable>({name: 'birthday'})
    },
    {
      dataIndex: 'created_at',
      title: t('created_at'),
      render: DateTimeRender
    }
  ]

  return columns;
}