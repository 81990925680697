import React, {useEffect, useState} from "react";
import {TDReservationsTable} from "./table/TDReservationsTable";
import {useTDProducts} from "../../../controllers/useTDProducts/useTDProducts";
import PlaneLoader from "../../PlaneLoader";
import {getData} from "../../../util/req";
import {Seller} from "../TDSellers/types";

export function TDReservations(){
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [sellers, setSellers] = useState<Seller[]>([]);
  const [loading, setLoading] = useState(true)
  const {getAllProducts} = useTDProducts();


  const loadProducts = async () => {
    const {error, products} = await getAllProducts();
    if (error) return alert(error);
    setProducts(products.filter((a: any) => a.name));
  }

  const loadSellers = async () => {
    const result = await getData('/td/seller')
    setSellers(result.data);
  }


  const loadAll = async () => {
    setLoading(true)
    await loadSellers()
    await loadProducts()
    setLoading(false)
  }

  useEffect(() => {
    loadAll()
  }, []);

  if(loading)return <PlaneLoader/>



  return <>
    <div>
      <TDReservationsTable sellers={sellers} products={products} selectedRowKeys={selectedRowKeys}  onSelectionChange={keys => setSelectedRowKeys(keys)}/>
    </div>
  </>
}
