import {ContractedInsurance} from "../../controllers/usePurchaseController/types";
import {useTranslation} from "react-i18next";

export const fromInsuranceToVerticalTableData = (insurance: ContractedInsurance) => ({
  reference: insurance.bookingReference1,
  product: insurance.policy.product.productName + `(${insurance.policy.idDyn})`,
  policyNumber: insurance.policy.policyNumber,
  entryDate: insurance.entryDate,
  unsuscribeDate: insurance.entryDate,
  effectDate: insurance.entryDate,
  totalAmount: insurance.totalAmount,
  netPremiumAmount: insurance.netPremiumAmount,
  netRetailPrice: insurance.netRetailPrice,
  isEditable: insurance.isEditable,
  isRemovable: insurance.isRemovable,
  intermundial_id: insurance.intermundial_id,
});

export const useRoomColumns = () => {
  const {t: _t} = useTranslation();

  const t = (key: string) => _t(`purchased data.insurance data.${key}`);
  return [
    {dataIndex: 'reference', title: t('reference')},
    {dataIndex: 'product', title: t('product')},
    {dataIndex: 'policyNumber', title: t('policyNumber')},
    {dataIndex: 'entryDate', title: t('entryDate')},
    {dataIndex: 'unsuscribeDate', title: t('unsuscribeDate')},
    {dataIndex: 'effectDate', title: t('effectDate')},
    {dataIndex: 'totalAmount', title: t('totalAmount')},
    {dataIndex: 'netPremiumAmount', title: t('netPremiumAmount')},
    {dataIndex: 'netRetailPrice', title: t('netRetailPrice')},
    {dataIndex: 'isEditable', title: t('isEditable')},
    {dataIndex: 'isRemovable', title: t('isRemovable')},
    {dataIndex: 'intermundial_id', title: t('intermundial_id')},
  ]
}

export const useRoomData = (insurance: ContractedInsurance) => {

}