import {PaymentDataTableType} from "./PaymentsTable/types";

export const orderedTestDataWithHotel = {
  "_id": "6420a6e2466f2064ac62bc78",
  "order_id": "6420a6c877143664e1d605c7",
  "created_at": new Date("2023-03-26T20:11:14.154Z"),
  "total_price": 5521.74,
  "persons": [
    {
      "name": "name test",
      "surname": "surname test",
      "gender": "woman",
      "document": {
        "type": "nif",
        "number": "testXXXX"
      },
      "email": "test@test.com",
      "phone": "+342323232",
      "nationality": "ES",
      "birthday": "1990-02-03",
      "holder": true
    },
    {
      "name": "name test",
      "surname": "surname test",
      "gender": "woman",
      "document": {
        "type": "nif",
        "number": "testXXXX"
      },
      "email": "test@test.com",
      "phone": "+342323232",
      "nationality": "ES",
      "birthday": "1990-02-03",
      "holder": false
    }
  ],
  "email": "test_email@hotmail.com",
  "billing": {
    "name": "test name",
    "surname": "test surname",
    "document": {
      "type": "nif",
      "number": "testXXXX"
    },
    "email": "test@hotmail.com",
    "phone": "+342323232",
    "address": "Calle humanes 2"
  },
  "emergency_contact": {
    "name": "test name",
    "surname": "test surname",
    "phone": "+342323232",
    "email": "test@hotmail.com"
  },
  "user_id": "64122a6997cb3eef77c10eb0",
  "hotels": [
    {
      "reference": "207-6576200",
      "clientReference": "TRIPPY TREKKERS",
      "creationDate": "2023-03-26",
      "status": "CONFIRMED",
      "modificationPolicies": {
        "cancellation": true,
        "modification": true
      },
      "creationUser": "f55fee5653b58157928b5f9713363885",
      "holder": {
        "name": "NAME TEST",
        "surname": "SURNAME TEST"
      },
      "hotel": {
        "checkOut": new Date("2023-04-04T00:00:00.000Z"),
        "checkIn": new Date( "2023-03-29T00:00:00.000Z"),
        "code": 9186,
        "name": "Londra Palace",
        "categoryCode": "5EST",
        "categoryName": "5 STARS",
        "destinationCode": "VCE",
        "destinationName": "Venice (and vicinity)",
        "zoneCode": 1,
        "zoneName": "Venice",
        "latitude": "45.43406",
        "longitude": "12.34336",
        "rooms": [
          {
            "status": "CONFIRMED",
            "id": 1,
            "code": "JSU.VL",
            "name": "Junior suite lagoon view",
            "paxes": [
              {
                "roomId": 1,
                "type": "AD",
                "name": "name test",
                "surname": "surname test"
              },
              {
                "roomId": 1,
                "type": "AD",
                "name": "name test",
                "surname": "surname test"
              }
            ],
            "rates": [
              {
                "rateClass": "NOR",
                "net": "5521.74",
                "rateComments": "1x JUNIOR SUITE Estimated total amount of taxes & fees for this booking: 50.00 Euro   payable on arrival  . Kids from 10 to 16 years old will pay 50% of the city tax. Car park NO. Check-in hour 18:00 - 00:00. Due to the pandemic, accommodation and service providers may implement processes and policies to help protect the safety of all of us. This may result in the unavailability or changes in certain services and amenities that are normally available from them.More info click here https://cutt.ly/MT8BJcv (15/05/2020-30/06/2023).",
                "paymentType": "AT_WEB",
                "packaging": true,
                "boardCode": "BB",
                "boardName": "BED AND BREAKFAST",
                "cancellationPolicies": [
                  {
                    "amount": "678.27",
                    "from": "2023-03-25T23:59:00+01:00"
                  }
                ],
                "taxes": {
                  "taxes": [
                    {
                      "included": false,
                      "amount": "50.00",
                      "currency": "EUR",
                      "clientAmount": "50.00",
                      "clientCurrency": "EUR"
                    }
                  ],
                  "allIncluded": false
                },
                "rateBreakDown": {
                  "rateDiscounts": [
                    {
                      "code": "PQ",
                      "name": "Opaque Package",
                      "amount": "-599.59"
                    }
                  ]
                },
                "rooms": 1,
                "adults": 2,
                "children": 0
              }
            ]
          }
        ],
        "totalNet": "5521.74",
        "currency": "EUR",
        "supplier": {
          "name": "HOTELBEDS PRODUCT,S.L.U.",
          "vatNumber": "ESB38877676"
        }
      },
      "invoiceCompany": {
        "code": "E14",
        "company": "HOTELBEDS S.L.U.",
        "registrationNumber": "ESB57218372"
      },
      "totalNet": 5521.74,
      "pendingAmount": 5521.74,
      "currency": "EUR",
      "api": "apitude"
    }
  ],
  "completed": true,
  "updated_at": new Date("2023-03-26T20:11:18.752Z")
}

export const orderedTestDataWithInsurance = {
  "_id": "646a7f17c1f96a84b4d6129a",
  "order_id": "646a76230861c07b39d10ef8",
  "created_at": new Date("2023-05-21T20:29:11.478Z"),
  "total_price": 408.47,
  "persons": [
    {
      "name": "name test",
      "surname": "surname test",
      "gender": "woman",
      "document": {
        "type": "nif",
        "number": "testXXXX"
      },
      "email": "test@test.com",
      "phone": "+342323232",
      "nationality": "ES",
      "birthday": "1990-02-03",
      "holder": true
    },
    {
      "name": "name test",
      "surname": "surname test",
      "gender": "woman",
      "document": {
        "type": "nif",
        "number": "testXXXX"
      },
      "email": "test@test.com",
      "phone": "+342323232",
      "nationality": "ES",
      "birthday": "1990-02-03",
      "holder": false
    }
  ],
  "email": "test_email@hotmail.com",
  "billing": {
    "name": "test name",
    "surname": "test surname",
    "document": {
      "type": "nif",
      "number": "testXXXX"
    },
    "email": "test@hotmail.com",
    "phone": "+342323232",
    "address": "Calle humanes 2"
  },
  "emergency_contact": {
    "name": "test name",
    "surname": "test surname",
    "phone": "+342323232",
    "email": "test@hotmail.com"
  },
  "user_id": "64679aab6c7a7b9db11a1c65",
  "insurance": {
    "unsuscribeDate": new Date("2023-06-17T00:00:00.000Z"),
    "effectDate": new Date("2023-06-13T00:00:00.000Z"),
    "entryDate": new Date("2023-05-21T22:29:23"),
    "brokerageComissionAmount": 13.31,
    "clientComissionAmount": 5.93,
    "taxTotalAmount": 2.15,
    "companyAmount": 15.19,
    "netRetailPrice": 22.57,
    "netPremiumAmount": 26.35,
    "totalAmount": 28.5,
    "isEditable": true,
    "isRemovable": true,
    "intermundial_id": 10585002,
    "policy": {
      "idDyn": 24207,
      "policyStatus": "Alta",
      "policyNumber": "ESB09-I21-01C1",
      "product": {
        "idDyn": 7544,
        "productName": "Multiasistencia Plus con anulación"
      }
    },
    "bookingReference1": "N-346696",
    "paymentMethod": "Recibo bancario",
    "insuranceInsuredList": [
      {
        "insured": {
          "treatment": "woman",
          "documentType": "nif",
          "documentNumber": "testXXXX",
          "profile": "A",
          "dataOrigins": {
            "dynamicMap": null,
            "name": "V5",
            "description": "DATO GENERADO EN APIV5",
            "id": 2,
            "version": 0
          },
          "birthDate": "1990-02-03",
          "version": 0,
          "dischargeDate": 1684700961000,
          "surname": "surname test",
          "name": "name test",
          "addressInfoList": [
            {
              "fiscalPostalCode": null,
              "commercialCity": null,
              "fiscalCity": null,
              "fiscalProvince": null,
              "fiscalAddress": null,
              "commercialCountry": {
                "idDyn": 71,
                "name": "España"
              },
              "fiscalCountry": null,
              "commercialPostalCode": null,
              "id": 24198236,
              "commercialAddress": null,
              "commercialProvince": {
                "idDyn": null,
                "name": "Otros"
              }
            }
          ],
          "id": 23519413,
          "contactInfoList": []
        },
        "isMainInsured": true,
        "id": 20840483,
        "deleteDate": null
      },
      {
        "insured": {
          "treatment": "woman",
          "documentType": "nif",
          "documentNumber": "testXXXX",
          "profile": "D",
          "dataOrigins": {
            "dynamicMap": null,
            "name": "V5",
            "description": "DATO GENERADO EN APIV5",
            "id": 2,
            "version": 0
          },
          "birthDate": "1990-02-03",
          "version": 0,
          "dischargeDate": 1684700961000,
          "surname": "surname test",
          "name": "name test",
          "addressInfoList": [
            {
              "fiscalPostalCode": null,
              "commercialCity": null,
              "fiscalCity": null,
              "fiscalProvince": null,
              "fiscalAddress": null,
              "commercialCountry": {
                "idDyn": 71,
                "name": "España"
              },
              "fiscalCountry": null,
              "commercialPostalCode": null,
              "id": 24198237,
              "commercialAddress": null,
              "commercialProvince": {
                "idDyn": null,
                "name": "Otros"
              }
            }
          ],
          "id": 23519414,
          "contactInfoList": []
        },
        "isMainInsured": false,
        "id": 20840484,
        "deleteDate": null
      }
    ]
  },
  "completed": true,
  "updated_at": new Date("2023-05-21T20:30:10.124Z")
}


export const paymentDataTableTestData: PaymentDataTableType[] = [
  {
    "_id": "5f5d2d3a3b6d7e1d9c6c3b1a",
    "asset": "hotel",
    "type": "purchase",
    "price": 1523.32,
    ordered: orderedTestDataWithHotel,
    "tag": "207-6576200", // hotel purchased reference in this case
    "created_at": new Date(2022, 2, 12, 12, 0, 0)
  },
  {
    "_id": "5f5d2d3a3b6d7e1d9c6c3b1b",
    "asset": "insurance",
    "type": "purchase",
    "price": 123.32,
    ordered: orderedTestDataWithInsurance,
    "tag": "6f5d2d3a3b6d7e1d9c6c3b2a", // insurance id in this case
    "created_at": new Date(2022, 2, 12, 12, 0, 0)
  },
  {
    "_id": "5f5d2d3a3b6d7e1d9c6c3b1c",
    "asset": "hotel",
    "type": "purchase",
    "price": 245.32,
    "tag": "6f5d2d3a3b6d7e1d9c6c3b1a", // hotel purchased reference in this case
    "created_at": new Date(2022, 3, 12, 12, 45, 0)
  },
  {
    "_id": "12",
    "asset": "hotel",
    "type": "refund",
    "price": 545.32,
    "tag": "6f5d2d3a3b6d7e1d9c6c3b1a", //hotel purchased reference in this case
    "created_at": new Date(2022, 2, 12, 12, 0, 0)
  },
  {
    "_id": "23",
    "asset": "experience",
    "type": "purchase",
    "price": 2450.50,
    "tag": "6f5d2d3a3b6d7e1d9c6c3b15", // hotel purchased reference in this case
    "created_at": new Date(2022, 4, 12, 12, 0, 0)
  },
  {
    "_id": "5f5d2d3a3b6d7e1d9c6c3bf2",
    "asset": "activity",
    "type": "purchase",
    "price": 150.85,
    "tag": "6f5d2d3a3b6d7e1d9c6c3b1a", // hotel purchased reference in this case
    "created_at": new Date(2022, 5, 12, 12, 0, 0)
  },
  {
    "_id": "5f5d2d3a3b6d7e1d3c6c3b1a",
    "asset": "hotel",
    "type": "purchase",
    "price": 555.32,
    "tag": "6f5d2d3a3b6d7e1d9c6c3b1a", // hotel purchased reference in this case
    "created_at": new Date(2022, 2, 12, 12, 0, 0)
  },
  {
    "_id": "5f5d2d3a3b6d7e1d2c6c3b1a",
    "asset": "insurance",
    "type": "purchase",
    "price": 100.32,
    "tag": "6f5d2d3a3b6d7e1d9c6c3b2a", // insurance id in this case
    "created_at": new Date(2022, 2, 12, 12, 0, 0)
  },
  {
    "_id": "5f5d2d3a3b6d7e1d9c6c3r1a",
    "asset": "hotel",
    "type": "refund",
    "price": 555.32,
    "tag": "6f5d2d3a3b6d7e1d9c6c3b1a", // hotel purchased reference in this case
    "created_at": new Date(2022, 2, 12, 12, 0, 0)
  },
  {
    "_id": "5f5d2d3a3b6d7e1d9c6c321a",
    "asset": "insurance",
    "type": "refund",
    "price": 100.32,
    "tag": "6f5d2d3a3b6d7e1d9c6c3b2a", // insurance id in this case
    "created_at": new Date(2022, 2, 12, 12, 0, 0)
  },
]