import React, {useEffect, useState} from 'react';
import s from './TONewItinerary.module.css';
import sn from '../TONew.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams, useOutletContext} from "react-router-dom";
import bases from "../../../../routes/bases";
import {useForm, FormProvider} from "react-hook-form";
import {MultiSelect} from "@mantine/core";
import ProgressiveShow from "../../../ProgressiveShow";
import ToItineraryPlaces from "../../ToItineraryPlaces";
import ToItineraryCategories from "../../ToItineraryCategories";
import {isObject} from "formik";
import MultiLangInputV2 from "../../../Inputs/MultiLangInputV2";
import TextInput from "../../Itineraries/Inputs/TextInput/TextInput";
import Textarea from "../../Itineraries/Inputs/Textarea/Textarea";
import {FormItinerary, Itinerary, OutletItineraryContext} from "../../../../types/itineraries";
import {manageError} from "../../../../helpers/ErrorManager";
import {ErrorType} from "../../../../types/errors";
import ToItineraryCapacity from "../ToitineraryCapacity/ToItineraryCapacity";
import ListInput from "../../Itineraries/Inputs/ListInput/ListInput";


import {ReactComponent as TickSVG} from '../../../../resources/svg/it/tick.svg';
import {ReactComponent as ExcludedSVG} from '../../../../resources/svg/it/excluded.svg';
import Footer from "../../Components/Footer/Footer";
import HeaderTitle from "../../Components/HeaderTitle/HeaderTitle";

const TONewItinerary = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {id} = useParams();


  const form = useForm<FormItinerary>({
    defaultValues: {
      hashtags: []
    }
  });
  const [hashtags, setHashtags] = useState<string[]>([]);
  const {setValue, getValues, watch} = form;

  const {itinerary, saveItinerary, setStep, isItineraryBlocked} = useOutletContext<OutletItineraryContext>();


  useEffect(() => {
    setStep('itineraries');
  }, []);

  useEffect(() => {
    if (!itinerary) return;
    const keys: (keyof Itinerary)[] = ['limited_capacity', 'title', 'external_description', 'internal_description', 'capacity', 'others', 'included', 'excluded', 'observations', 'categories'];
    keys.forEach(key => {
      if (itinerary[key]) { // @ts-ignore
        setValue(`itinerary.${key}`, itinerary[key]);
      }
    });
    if (itinerary.hashtags) setHashtags(itinerary.hashtags);
  }, [itinerary]);


  const [capacityVisible, setCapacityVisible] = useState(false);

  const title = watch('itinerary.title');
  const external_description = watch('itinerary.external_description');


  useEffect(() => {
    //if (title?.length > 2 && external_description?.length > 5) setCapacityVisible(true);
    setCapacityVisible(true);
  }, [title, external_description]);

  const onSaveItinerary = async () => {
    let {itinerary, places} = getValues();
    let newItinerary: Itinerary = {
      ...itinerary,
      places: Array.isArray(places) ? places.filter(a => a && typeof a?.address === 'object').map(place => ({
        id: place.id,
        start: place.start,
        end: place.end,
        address: place?.address?.raw,
        activity_blocked: place.activity_blocked
      })) : [],
      start_point: isObject(itinerary.start_point) ? itinerary.start_point?.raw : undefined,
      hashtags,
    };

    try {
      await saveItinerary(newItinerary)
      navigate(`/${bases.tour_operator_itineraries}/${id}/availability`);
    } catch (error) {
      manageError(error as ErrorType, t);
    }
  }


  return (
    <FormProvider {...form}>
      <div className="container m-auto">
        <HeaderTitle>{t('to.header.itineraries')}</HeaderTitle>

        <div className="w-full flex flex-nowrap">
          <MultiLangInputV2
            Input={TextInput}
            name="itinerary.title"
            label={t('to.itinerary.title')}
            required
            className={`${s.input} mr-10`}
            disabled={isItineraryBlocked}
          />
          <MultiSelect
            value={hashtags}
            onChange={setHashtags}
            label={t('to.itinerary.hashtags')}
            data={hashtags}
            disabled={isItineraryBlocked}
            className={s.input}
            searchable
            creatable
            maxSelectedValues={6}
            getCreateLabel={hashtag => `${t('to.itinerary.create hashtag')} "${hashtag}"`}
            onCreate={hashtag => setHashtags([...hashtags, hashtag])}
          />
        </div>


        <div className="w-full flex flex-nowrap">
          <MultiLangInputV2
            Input={Textarea}
            name="itinerary.internal_description"
            label={t('to.itinerary.internal description')}
            minRows={3}
            className={`${s.input} mr-10`}
            disabled={isItineraryBlocked}
            /*						rightSection={<InputDescription label={t('to.itinerary.internal description tooltip')}/>}
                        rightSectionProps={{style: {bottom: 'auto', top: 10}}}*/
          />

          <MultiLangInputV2
            Input={Textarea}
            name="itinerary.external_description"
            label={t('to.itinerary.external description')}
            minRows={3}
            className={`${s.input}`}
            disabled={isItineraryBlocked}
            /*						rightSection={<InputDescription label={t('to.itinerary.internal description tooltip')}/>}
                        rightSectionProps={{style: {bottom: 'auto', top: 10}}}*/
          />
        </div>

        <ProgressiveShow visible={capacityVisible}>
          <hr className="my-10"/>

          <ToItineraryCategories/>
          <hr className="my-10"/>

          <ToItineraryPlaces/>

          <hr className="my-10"/>

          {/*<ToItineraryCapacity/>*/}
        </ProgressiveShow>

        <ProgressiveShow visible={capacityVisible}>

          <ListInput
            label={t('to.itinerary.observations')}
            name={"itinerary.observations"}
            ballColor={"rgba(113, 29, 251, 1)"}
            placeholder={t('to.itinerary.observations placeholder')}
          />

          <div className="w-full flex flex-nowrap mt-10">
            <ListInput
              label={t('to.itinerary.included')}
              name={'itinerary.included'}
              ballColor={'rgba(67, 190, 168, 1)'}
              Icon={TickSVG}
              placeholder={t('to.itinerary.included placeholder')}
              className="w-full mr-5"
            />

            <ListInput
              label={t('to.itinerary.excluded')}
              name={'itinerary.excluded'}
              ballColor={'rgba(236, 31, 70, 1)'}
              Icon={ExcludedSVG}
              placeholder={t('to.itinerary.excluded placeholder')}
              className="w-full"
            />
          </div>

          <Footer onSave={onSaveItinerary}/>
        </ProgressiveShow>
      </div>
    </FormProvider>
  );
};


export default TONewItinerary;
