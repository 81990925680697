import React, {useEffect, useState} from 'react';
import s from './GalleryPickUnOneImage.module.css';
import {useTranslation} from "react-i18next";

import notFoundSVG from "../../../resources/svg/not_found_image.svg";
import GalleryModal from "../GalleryModal";

import config from '../../../config';

const GalleryPickUnOneImage = ({label, className, onDone, defaultValue, disabled, ...props}) => {
	const {t} = useTranslation();
	label = label || t('gallery data.pick one image');

	const [opened, setOpened] = useState(false);
	const [image, setImage] = useState(notFoundSVG);


	useEffect(() => {
		if(defaultValue){
			setImage(`${config.imagesURL}/${defaultValue}`);
		}else{
			setImage(notFoundSVG);
		}
	}, [defaultValue]);


	return (
		<div style={{width: 200}}>
			<GalleryModal disabled={disabled} defaultValue={defaultValue} opened={opened} setOpened={setOpened} maxSelections={1} onDone={t => {
				setImage(`${config.imagesURL}/${t}`);
				onDone(t[0]);
			}} {...props} />
			<div className={className} onClick={() => setOpened(true)}>
				{label && <div className={s.label}>{label}</div>}
				<div className={`${s.image} ${disabled ? s.disabled_image : ''}`} style={{backgroundImage: `url(${image})`}}/>
			</div>
		</div>
	);
};


export default GalleryPickUnOneImage;