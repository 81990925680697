import React, {useEffect} from 'react';
import s from './AdminTable.module.css';
import {useTranslation} from "react-i18next";
import useAdminController from "../../../controllers/useAdminController/useAdminController";
import Table from "../../Table";
import {BooleanRender, DateTimeRender, EmailRender, FullNameRenderer} from "../../Table/Renders";
import {useNavigate, useSearchParams} from "react-router-dom";
import bases from "../../../routes/bases";
import {Button, Title} from "@mantine/core";
import {useSelectOptions} from "../../../hooks/useSelectOptions";
import useMenu from "../../../providers/MenuProvider/useMenu";

const AdminTable = props => {
  const {t} = useTranslation();
  const {getAdmins, deleteAdmin} = useAdminController();
  const navigate = useNavigate();
  const {setChosen} = useMenu();

  const {adminTypeOptions, adminSuspendedOptions} = useSelectOptions();


  useEffect(() => setChosen('administrators'), [setChosen]);


  const onNewAdmin = () => {
    navigate('/' + bases.config_admin + '/new');
  }

  const onDelete = async data => {
    if(!window.confirm(t('sure delete') + ' ' + data.name))return false;
    await deleteAdmin({id: data._id});
    return true;
  }


  return (
    <div className="container m-auto px-10">
      <div className="w-full flex flex-nowrap justify-end mb-5">
{/*        <Title order={3}>{t(`admin data.${isTo ? 'table title to' : 'table title'`)}</Title>*/}
        <Button onClick={onNewAdmin}>{t('create new')}</Button>
      </div>
      <Table
        tableName="admins"
        id="_id"
        columns={[
          /*{accessor: '_id', Header: 'ID', type: 'number'},*/
          {accessor: 'name', Header: t('name'), type: 'string', filter: true},
          {accessor: 'email', Header: t('email'), type: 'string', filter: true},
          {accessor: 'type', Header: t('admin data.type'), type: 'list', data: adminTypeOptions,filter: true},
          {accessor: 'suspended', Header: t('admin data.suspended'), type: 'list', data: adminSuspendedOptions,filter: true},
          {accessor: 'updated_at', Header: t('updated at'), type: 'string'},
          {accessor: 'created_at', Header: t('created at'), type: 'string'}
        ]}
        onEdit={row => navigate(`/${bases.admin}/${row._id}`)}
        onDelete={onDelete}
        defaultHiddenColumns={['_id', 'created_at']}
        fetchData={async ({pages, filters}) => {
          const {error, admins, totalRecords} = await getAdmins({pages, filters});
          if (error) return {data: [], totalRecords: 0};
          return {data: admins, totalRecords: totalRecords};
        }}
        renders={{
          email: EmailRender,
          updated_at: DateTimeRender,
          created_at: DateTimeRender,
          suspended: BooleanRender,
          name: (v, row) =>
            <div className="link" onClick={() => navigate(`/${bases.admin}/${row._id}`)}>{FullNameRenderer(v, row)}</div>
        }}
      />
    </div>
  );
};


export default AdminTable;
