import React, {useState} from 'react';
import s from './Button.module.css';
import {Button as MantineButton} from "@mantine/core";

const Button = ({onClick, type="button", ...props}) => {
	const [loading, setLoading] = useState(false);
	const _onClick = async () => {
		if(typeof onClick === 'function'){
			if(onClick.constructor.name === 'AsyncFunction'){
				setLoading(true);
				await onClick();
				setLoading(false);
			}else{
				onClick();
			}
		}
	}
    return (
      <MantineButton type={type} onClick={_onClick} loading={loading} {...props}/>
    );
};


export default Button;