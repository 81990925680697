import React, {useEffect, useState} from 'react';
import s from './TourOperatorFirstSetup.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useSearchParams} from "react-router-dom";
import useWindowSize from "../../../hooks/useWindowSize/useWindowSize";
import useAdminController from "../../../controllers/useAdminController/useAdminController";
import {useForm} from "@mantine/form";
import {toastError} from "../../../helpers/ErrorManager";
import {toast} from "react-toastify";
import {ReactComponent as LogoSVG} from "../../../resources/svg/logo.svg";
import {Checkbox, MultiSelect, PasswordInput, Select, TextInput} from "@mantine/core";
import Button from "../../Inputs/Button";
import {useSelectOptions} from "../../../hooks/useSelectOptions";
import i18n from "i18next";
import PhoneInput from "../../PhoneInput";


const TourOperatorFirstSetup = props => {
	const {t} = useTranslation();
	const {height: windowHeight} = useWindowSize();
	const {tourOperatorSetup, getTourOperatorSetupData} = useAdminController();
	const [params] = useSearchParams();


	const id = params.get('id');
	const hash = params.get('h');
	const navigate = useNavigate();

	const [allowedCountriesList, setAllowedCountriesList] = useState([]);

	const form = useForm({
		initialValues: {
			language: '',
			password: ''
		}
	});
	const {languageOptions} = useSelectOptions();

	const loadData = async () => {
		const {error, data} = await getTourOperatorSetupData({id, hash});
		if (error) return toastError(error, t);
		if (data) {
			form.setValues({
				email: data.email,
				web: data.web
			});

			if (Array.isArray(data.allowed_countries)) {
				setAllowedCountriesList(data.allowed_countries.map(code => ({label: t("countryList." + code), value: code})));
			}
		}
	}

	useEffect(() => {
		loadData();
	}, []);


	const onSubmit = async () => {
		const {error, success} = await tourOperatorSetup({id, hash, data: form.values});
		if (error) return toastError(error, t);
		if (success) {
			toast.success(t('success'));
			navigate('/login');
		} else {
			toast.error(t('error'));
		}
	}

	useEffect(() => {
		i18n.changeLanguage(form.values.language);
	}, [form.values.language]);


	return (
		<div className={s.container} style={{height: windowHeight + 'px'}}>
			<div className={s.modalCenter}>
				<div className={s.logoContainer}>
					<LogoSVG/>
				</div>
				<form>

					<Select
						data={languageOptions}
						label={t('settings page.language')}
						{...form.getInputProps('language')}
					/>

					{form.values.language?.length > 0 &&
						<div className="mt-5">
							<div className="w-full flex flex-nowrap">
								<TextInput
									required
									label={t('name')}
									{...form.getInputProps('name')}
									className="w-full mr-5"
								/>
								<TextInput
									label={t('surname')}
									{...form.getInputProps('surname')}
									className="w-full"
								/>
							</div>
							<TextInput
								required
								label={t('web')}
								{...form.getInputProps('web')}
								className="w-full"
							/>

							<PhoneInput
								required
								label={t('phone')}
								{...form.getInputProps('phone')}
								className="w-full"
							/>
							<Checkbox
								label={t('to.whatsapp')}
								{...form.getInputProps('whatsapp', {type: 'checkbox'})}
								className="my-5"
							/>

							<TextInput
								required
								label={t('to.job')}
								{...form.getInputProps('job')}
							/>

							<MultiSelect required searchable={true} data={allowedCountriesList}
											label={t('to.countries of work')} {...form.getInputProps('accepted_countries')}/>


							<TextInput
								required
								label={t('email')}
								{...form.getInputProps('email')}
								className="w-full mr-5"
							/>

							<PasswordInput required label={t('password')} {...form.getInputProps('password')}/>
							<Button className="mt-5" type="button" label={t('submit')}
											onClick={onSubmit}>{t('to.register button')}</Button>
						</div>
					}
				</form>

			</div>
		</div>
	);
};


export default TourOperatorFirstSetup;