import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';

import moment from 'moment';
import 'moment/locale/es'  // without this line it didn't work

import config from "../config";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(intervalPlural)
  .use(initReactI18next)
  .init({
    detection: {
      lookupQuerystring: 'lang'
    },
    fallbackLng: 'en-US',
    debug: true,
    backend: {
      crossDomain: true,
      loadPath: `${config.serverURL}/locales/{{lng}}/translation.json`
    },

    keySeparator: '.',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

i18n.on('languageChanged', lng => {
  document.cookie = `lang=${lng};path=/`;
  moment.locale(lng);
});
export default i18n;


const languages = {
  'es-ES': 'es',
  'en-US': 'eng'
}

const langRender = (t) => {
  const lang = languages[i18n.language];
  if(typeof t === 'string' || !t)return t;
  return t[lang] || t[Object.keys(t)[0]] || t;
};

export {langRender}