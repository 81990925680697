import React from 'react';
import s from './TDShowContactUs.module.css';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import useAPIDataProvider from "../../../../controllers/useAPIDataProvider";
import VerticalTable from "../../../Table/VerticalTable";
import {DateTimeRender, EmailRender} from "../../../Table/Renders";
import useTDContactUs from "../../../../controllers/useTDContactUs";

const TDShowContactUs = props => {
	const {t} = useTranslation();
	let {id: contact_us_id} = useParams();
	const {getContactUs} = useTDContactUs();

	return (
		<VerticalTable
			langGroup={'contact us data'}
			fetchData={async () => await getContactUs({id: contact_us_id})}
			valueRenders={{
				email: EmailRender,
				created_at: DateTimeRender
			}}
		/>
	);
};


export default TDShowContactUs;