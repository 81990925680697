import {useState} from "react";
import {MenuContext} from "./MenuContext";
import Menu from "../../components/Menu";


interface MenuProviderProps {
	elements: any[],
	children: any
}

export type ItineraryMenuChosenType = 'itineraries';


const MenuProvider = ({elements, children}: MenuProviderProps) => {
	const [chosen, setChosen] = useState<ItineraryMenuChosenType>('itineraries');

	return <MenuContext.Provider value={{chosen, setChosen}}>
		<Menu chosen={chosen} elements={elements}/>
		{children}
	</MenuContext.Provider>
}

export default MenuProvider;