import {useTranslation} from "react-i18next";
import {ColumnsType} from "antd/es/table";
import {EmailRender, MoneyRender, PhoneRender, RangeDateRender} from "../../Table/Renders";
import {OrderedOnTable, OrderedOnTableStatus} from "../../../controllers/usePayments/usePayments";
import {Tag} from "antd";
import {searchByText} from "../../NewTable/filters/searchByText";
import {searchByDateRange} from "../../NewTable/filters/searchByDateRange";


const statusColor: Record<OrderedOnTableStatus, string> = {
  pending: 'yellow',
  completed: 'green',
  failed: 'red'
}


export const useColumns = () => {
  const {t: _t} = useTranslation();
  const t = (s: string) => _t(`purchased data.${s}`);

  const columns: ColumnsType<OrderedOnTable> = [
    {
      dataIndex: 'tracking_number',
      title: t('tracking number'),
      ...searchByText<OrderedOnTable>({name: 'tracking_number'})
    },
    {
      dataIndex: 'full_name',
      title: t('full name'),
      ...searchByText<OrderedOnTable>({name: 'full_name'})
    },
    {
      dataIndex: 'status',
      title: t('status'),
      render: (v: OrderedOnTableStatus) => <Tag color={statusColor[v]}>{t(`status list.${v}`)}</Tag>
    },
    {
      dataIndex: 'total_price',
      title: t('price'),
      render: (v) => <MoneyRender value={v} />
    },
    {
      dataIndex: 'email',
      title: t('email'),
      render: EmailRender,
      ...searchByText<OrderedOnTable>({name: 'email'})
    },
    {
      dataIndex: 'phone',
      title: t('phone'),
      render: PhoneRender,
      ...searchByText<OrderedOnTable>({name: 'phone'})
    },
    {
      dataIndex: 'total_hotels',
      title: t('number of hotels'),
    },
    // {
    //   dataIndex: 'total_experiences',
    //   title: t('number of experiences'),
    // },
    {
      dataIndex: 'total_persons',
      title: t('number of persons'),
    },
    {
      dataIndex: 'refunded',
      title: t('refunded'),
      render: (v: boolean) => <Tag color={v ? 'red' : 'green'}>{v ? _t('yes') : _t('no')}</Tag>
    },
    {
      dataIndex: 'from',
      title: t('trip dates'),
      render: (from: Date, r: OrderedOnTable) => RangeDateRender(from, r.to),
        ...searchByDateRange<OrderedOnTable>({name: 'from'})
    },
    // {
    //   dataIndex: 'created_at',
    //   title: t('created_at'),
    //   render: DateTimeRender
    // }
  ]

  return columns;
}