import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {OrderedOnTable, usePayments} from "../../../controllers/usePayments/usePayments";
import Table from "../../NewTable/NewTable";
import {useColumns} from "./columns";

import type {TablePaginationConfig} from 'antd/es/table';
import type {FilterValue, SorterResult} from 'antd/es/table/interface';
import ShowOrdered from "../ShowOrdered/ShowOrdered";
import {Moment} from "moment";


interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue | null>;
}

export const TableOrdered = () => {
  const {t} = useTranslation();
  const {getOrdered} = usePayments();
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10
    }
  });
  const [data, setData] = useState<OrderedOnTable[]>([]);

  const columns = useColumns();

  const fetchData = async () => {
    const {filters} = tableParams;

    const from = filters?.from && filters.from[0] ? (filters?.from[0] as unknown as Moment) : undefined;
    const to = filters?.to && filters.to[0] ? (filters?.to[0] as unknown as Moment) : undefined;

    let result = await getOrdered({
      page: tableParams?.pagination?.current! - 1,
      resultsPerPage: tableParams?.pagination?.pageSize!,
      filters: {
        full_name: filters?.full_name ? filters.full_name[0] : undefined,
        tracking_number: filters?.tracking_number ? filters.tracking_number[0] : undefined,
        email: filters?.email ? filters.email[0] : undefined,
        phone: filters?.phone ? filters.phone[0] : undefined,
        from: from?.toDate()?.toISOString(),
        to: to?.toDate()?.toISOString(),
      }
    });
    setData(result.data);
    setTableParams(prev => ({
      ...prev,
      pagination: {
        ...prev?.pagination,
        total: result.totalRecords
      }
    }))
  }


  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams)]);


  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<OrderedOnTable> | SorterResult<OrderedOnTable>[]
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    console.log(filters)

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  return <div>
    <div className="showTitle">{t('purchased data.table title')}</div>
    <Table
      rowKey="_id"
      columns={columns}
      data={data}
      onChange={handleTableChange}
      pagination={tableParams?.pagination}
      expandable={{
        expandedRowRender: (record: OrderedOnTable) => <ShowOrdered ordered_id={record._id} />
      }}
    />

  </div>

}