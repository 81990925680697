import s from './SmallTransport.module.css';
import {ItineraryTransportTypes, itineraryTransportTypes, ItTransport} from "../../../types/itineraries";
import React from "react";
import Button from "../../TourOperator/Itineraries/Inputs/Button/Button";
import {AiOutlineDelete} from "react-icons/ai";
import {TransportIcon} from "../util";
import {useTranslation} from "react-i18next";
import {Popover} from "@mantine/core";



interface SingleTransportProps {
  transport: {type: ItineraryTransportTypes};

  selected?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
}

const SingleTransport = ({transport, selected, onDelete, onClick}: SingleTransportProps) => {
  const {t} = useTranslation();

  const text = t(`to.transport.${transport.type}`);
  let className = `${s.singleTransport} ${selected ? s.selected : ''}`
  if(onClick)className += ` ${s.clickable}`;


  return <div className={className} onClick={onClick}>
    <div style={{flex: '0 0 35px'}}><TransportIcon type={transport.type}/></div>
    <div style={{flex: '0 0 180px'}}>{text}</div>
    {onDelete && <Button color="white" className={s.deleteButton} leftIcon={<AiOutlineDelete size={18}/>}
												 variant="filled"
												 onClick={onDelete}/>}
  </div>
}


interface SelectTransportProps {
  selected: ItineraryTransportTypes;
  onSelect: (type: ItineraryTransportTypes) => void;
}
const SelectTransport = ({selected, onSelect}: SelectTransportProps) => {
  return (
    <div>
      {itineraryTransportTypes.map((type, i) => <SingleTransport
        selected={type === selected}
        key={i}
        transport={{type}}
        onClick={() => onSelect(type)}
        />
      )}
    </div>
  )
}


interface SmallTransportProps {
  transport: ItTransport;
  className?: string;

  onEdit?: (type: ItineraryTransportTypes) => void;
  onDelete?: (transport: ItTransport) => void;
}

const SmallTransport = ({transport, className, onEdit, onDelete}: SmallTransportProps) => {
  const [editing, setEditing] = React.useState(false);


  return (
      <Popover
        opened={editing}
        target={<SingleTransport selected={true} onClick={onEdit ? () => setEditing(true) : undefined} transport={transport} onDelete={() => onDelete && onDelete(transport)}/>}
        onClose={() => setEditing(false)}
        width={340}
        closeOnEscape={true}
        closeOnClickOutside={true}
        transitionDuration={300}
        position={'bottom'}
        placement={'center'}
        styles={{
          popover: {
            borderRadius: '16px',
            filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
        }
        }}
      >
        <SelectTransport selected={transport.type} onSelect={onEdit!}/>
      </Popover>
    );
}

export default SmallTransport;