import useAuth from "../../providers/UserProvider/useAuth";

export const useTourOperator = () => {
	const {secQuery} = useAuth();
	const base = '/api/admin/tour_operator';
	const p_base = '/api/tour_operator';

	const getTourOperators = async ({signal, pages} = {}) => {
		const {error, data} = await secQuery(base, null, 'GET', {signal});
		return {error, tour_operators: data?.tour_operators};
	}
	const getTourOperator = async ({id, signal}) => {
		const {error, data} = await secQuery(`${base}/${id}`, null, 'GET');
		return {error, tour_operator: data?.tour_operator};
	}
	const newTourOperator = async ({signal} = {}) => {
		const {error, data} = await secQuery(base, null, 'POST', {signal});
		return {error, id: data?.id};
	}
	const editTourOperator = async ({id, tourOperator, signal}) => {
		const {error, data} = await secQuery(`${base}/${id}`, {tourOperator}, 'PUT', {signal});
		return {error, success: data?.success};
	}
	const deleteTourOperator = async ({id, signal}) => {
		const {error, data} = await secQuery(`${base}/${id}`, null, 'DELETE', {signal});
		return {error, success: data?.success};
	}

	const getAccounts = async ({id, signal}) => {
		const {error, data} = await secQuery(`${base}/${id}/accounts`, null, 'GET', {signal});
		return {error, accounts: data?.accounts};
	}

	const getRequestedBudgets = async ({id, signal}) => {
		const {error, data} = await secQuery(`${p_base}/budget`, null, 'GET', {signal});
		return {error, budgets: data?.budgets, totalRecords: data?.totalRecords};
	}

	const getRequestedBudget = async ({id, signal}) => {
		const {error, data} = await secQuery(`${p_base}/budget/${id}`, null, 'GET', {signal});
		return {error, budget: data?.budget};
	}

	return {
		getTourOperators, getTourOperator, newTourOperator, editTourOperator, deleteTourOperator, getAccounts,
		getRequestedBudgets, getRequestedBudget
	};
}
