import {ColumnType} from "antd/es/table";
import {TableActions} from "../../../../util/table/components/TableActions";
import {getTextFilter} from "../../../../util/table/filters/TableTextFilter";
import {Trainer} from "../types";

interface Props {
  onEdit: (id: string) => void
  canWrite: boolean
}

export const trainerColumns = ({onEdit, canWrite}: Props) => {
  return [
    {
      title: '',
      dataIndex: '_id',
      key: 'action',
      width: 80,
      render: (_id: string) => <TableActions onEdit={canWrite ? () => onEdit(_id) : undefined} />
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      ...getTextFilter<Trainer>({name: 'name'})
    }
  ] satisfies ColumnType<Trainer>[]
}