import React, {useEffect, useState} from 'react';
import s from './VerticalTable.module.css';
import {useTranslation} from "react-i18next";
import Loading from "../../Loading";


const Error = ({error}) => <div>
  {error}
</div>

const VerticalTable = ({langGroup, fetchData = undefined, data: externalData, hiddenValues = ['_id'], addValues = undefined, keyRenders = {}, valueRenders = {}}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [entity, setEntity] = useState(false);

  const loadData = async (props) => {
    let entity;
    if(typeof fetchData === 'function') {
      setLoading(true);
      const result = await fetchData();
      if (result?.error) {
        setError(result.error);
        setLoading(false);
        return;
      }

      if (!result?.data) {
        setData([]);
        setLoading(false);
        return;
      }

      entity = result.data;
    }else if(externalData){
      entity = externalData;
    }

    let data = [];

    for(let key in entity) {
      if(hiddenValues.includes(key))continue;
      let name = t(`${langGroup}.${key}`);
      let value = entity[key];
      data.push({name, value, key});
    }

    if(addValues){
      for(let key in addValues) {
        let name = t(`${langGroup}.${key}`);
        let value = addValues[key];
        data.push({name, value, key});
      }
    }

    setData(data);
    setEntity(entity);
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if(externalData)loadData();
  }, [externalData]);

  useEffect(() => {
    setFinalData(data);
  }, [data]);

  if(error)return <Error error={error}/>
  if(loading)return <Loading/>

  return (
    <div className={s.container}>
      {finalData.map((el, i) => <div className={s.el} key={el.key}>
        <div className={s.header}>{keyRenders[el.key] ? keyRenders[el.key](el.name, entity, {t, accessor: el.key}) : el.name}</div>
        <div className={s.value}>{valueRenders[el.key] ? valueRenders[el.key](el.value, entity, {t, accessor: el.key}) : el.value}</div>
      </div>)}
    </div>
  );
};


export default VerticalTable;
