import {ColumnType} from "antd/es/table";
import {getTextFilter} from "../../../../util/table/filters/TableTextFilter";
import {getDateRangeFilter} from "../../../../util/table/filters/TableDateRangeFilter";
import React from "react";
import {DateRender, MoneyRender} from "../../../Table/Renders";
import {getListFilter} from "../../../../util/table/filters/TableListFilter";
import {Seller} from "../../TDSellers/types";
import {SellersPayment} from "./types";
import {LinkFormat} from "../../../Formatters/Formatters";
import bases from "../../../../routes/bases";
import {TableActions} from "../../../../util/table/components/TableActions";

interface Props {
  sellers: Seller[]
  onEdit: (id: string) => void
  canWrite: boolean
}

export const sellersPaymentColumns = ({sellers, onEdit, canWrite}: Props) => {
  return [
    {
      title: '',
      dataIndex: '_id',
      key: 'action',
      width: 30,
      render: (_id: string, reservation) => <TableActions onEdit={canWrite ? () => onEdit(_id) : undefined}/>
    },
/*    {
      title: 'Referencia',
      dataIndex: 'reference',
      key: 'reference',
      sorter: true,
      ...getTextFilter<SellersPayment>({name: 'name'})
    },*/
    {
      title: 'Fecha de pago',
      dataIndex: 'payment_date',
      key: 'payment_date',
      sorter: true,
      render: (_, reservation) => Array.isArray(reservation.payments) ? DateRender(reservation.payments[0]?.date) : '',
      ...getDateRangeFilter<SellersPayment>({name: 'payment_date'})
    },
    {
      title: 'Cliente',
      dataIndex: 'client',
      key: 'client',
      sorter: true,
      render: (_, row) => <LinkFormat url={`/${bases.td.clients}/${row?.client._id}`} label={row?.client?.full_name}/>,
      ...getTextFilter<SellersPayment>({name: 'client'})
    },
    {
      title: 'Precio',
      dataIndex: 'total_price',
      key: 'total_price',
      sorter: true,
      render: a => <MoneyRender value={a}/>
    },
    {
      title: 'Comisión',
      dataIndex: 'commission',
      key: 'commission',
      sorter: true,
      render: a => <MoneyRender value={a}/>
    },
    {
      title: 'Comisión TD',
      dataIndex: 'td_commission',
      key: 'td_commission',
      sorter: true,
      render: a => <MoneyRender value={a}/>
    },
    {
      title: 'Vendedor',
      dataIndex: 'seller',
      key: 'seller',
      sorter: true,
      render: (seller, row) => <LinkFormat url={`/${bases.td.sellers}/update/${seller?._id}`} label={seller?.name || ''}/>,
      ...getListFilter<SellersPayment>({
        name: 'seller',
        options: sellers.map((seller: Seller) => ({label: seller.name, value: seller._id}))
      })
    },
  ] satisfies ColumnType<SellersPayment>[]
}