import {useHotel} from "../controllers/useHotel";

export const useTourOperatorHelpers = () => {
  const {getSavedHotel, searchHotelByCode} = useHotel();


  const populateHotelData = async itinerary => {
    if (!Array.isArray(itinerary.hotels)) return itinerary;
    let hotels = [...itinerary.hotels];
    for (let i = 0; i < hotels.length; i++) {
      let h = hotels[i];
      if(h.hotel_id && h.type === 'local') {
        const {error, hotel} = await getSavedHotel(h.hotel_id);
        if(!error && hotel)hotels[i].hotel = hotel;
      }else if(h.hotel_code && h.api && h.type === 'api'){
        const {error, hotel} = await searchHotelByCode({
          code: h.hotel_code,
          api: h.api
        });
        if(!error && hotel)hotels[i].hotel = hotel;
      }
    }
    return hotels;
  }

  return {populateHotelData};
}

export default useTourOperatorHelpers;

export const itinerary_validation_status = {
  pending: 0,
  approved: 1,
  rejected: 2,
  cancelled: 5
}
