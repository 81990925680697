import React, {useMemo} from 'react';
import s from './GridNavigatorTO.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const TO = require('../../routes/groupElementsTO');

const GridNavigatorTO = props => {
	const {t} = useTranslation();
	const navigate = useNavigate();

	let data = useMemo(() => {
		let r = TO;
		let gridKeys = r.allElements.map(a => a.key);
		let elements = [];
		if(Array.isArray(gridKeys) && gridKeys.length > 0){
			elements = gridKeys.map(key => r.allElements.find(e => e.key === key));
		}

		return {
			...r,
			elements,
			gridKeys
		}
	}, []);

	const {groups, elements} = data;
	const onElementClick = element => {
		navigate('/' + element.path);
	}

	if (!Array.isArray(elements) || !elements.length) return JSON.stringify(elements);


	return (
		<div>
			{Object.keys(groups).map(key => {
				let group = groups[key];
				let els = elements.filter(el => el.group.name === group.name);
				if(!els || !els.length)return <></>
				return <div>
					<div className={s.groupTitle}>{t(`grid.groups.names.${group.name}`)}</div>
					<hr/>
					<div className={s.container}>
						{els.map(element => <div key={element.key} className={s.elementContainer}
																		 onClick={() => onElementClick(element)}>
							<div className={s.name}>{t(`grid.names.${element.name}`)}</div>
							{element.icon && <div className={s.iconContainer}>{element.icon}</div>}
						</div>)}
					</div>
				</div>
			})}

		</div>
	);
};


export default GridNavigatorTO;