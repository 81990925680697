import React from 'react';
import s from './GalleryPage.module.css';
import Gallery from "../Gallery";

const GalleryPage = props => {
	return (
		<div>
			<Gallery/>
		</div>
	);
};


export default GalleryPage;