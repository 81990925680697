import {useNavigate} from "react-router-dom";
import {useMemo, useState} from "react";
import {Seller} from "./types";
import {defaultTableParams} from "../../../util/table/table";
import {TableParams} from "../../../util/table/types";
import {deleteData} from "../../../util/req";
import {SellersTable} from "./table/SellersTable";
import {CreateButton} from "../../Buttons/CreateButton";
import {DeleteButton} from "../../Buttons/DeleteButton";
import bases from "../../../routes/bases";
import {canTDWrite} from "../../Permissions/VisibleWithPermissions";

export function TDSellers(){
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Seller[]>([]);
  const [refresh, setRefresh] = useState(0);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>(defaultTableParams);

  const onDelete = async () => {
    setDeleteLoading(true);

    for (let i = 0; i < selectedRowKeys.length; i++) {
      const id = selectedRowKeys[i]._id;
      const success = await deleteData(`/td/seller/${id}`)
      if (!success) {
        setDeleteLoading(false);
        return
      }
    }
    setDeleteLoading(false);
    setSelectedRowKeys([]);
    setRefresh(prev => prev + 1)
  }

  const canWrite = useMemo(() => canTDWrite(), [])


  return <div className="p-5">
    <div className="w-full flex justify-between py-2">
      {canWrite && <div className="flex items-center gap-2">
        <CreateButton onClick={() => navigate(`/${bases.td.sellers}/new`)}>Crear</CreateButton>
        <DeleteButton loading={deleteLoading} disabled={selectedRowKeys.length === 0}
                      onClick={onDelete}>Eliminar</DeleteButton>
      </div>}
    </div>
    <SellersTable
      refresh={refresh}
      selectedRowKeys={selectedRowKeys}
      onSelectionChange={keys => setSelectedRowKeys(keys)}
      tableParams={tableParams}
      setTableParams={setTableParams}
    />
  </div>

}