import {EditOutlined, EyeOutlined, DeleteOutlined} from "@ant-design/icons";
import s from './TableActions.module.css'
import {TbCalendarCancel} from "react-icons/tb";

interface Props {
  onEdit?: () => void
  onView?: () => void
  onDelete?: () => void
  onCalendarCancel?: () => void
}

export function TableActions({onView, onEdit, onDelete, onCalendarCancel}: Props){
  return <div className="flex gap-3">
    {onEdit && <EditOutlined className={s.edit} onClick={onEdit} />}
    {onDelete && <DeleteOutlined className={s.delete} onClick={onDelete} />}
    {onView && <EyeOutlined className={s.view}  onClick={onView}/>}
    {onCalendarCancel && <TbCalendarCancel className={s.calendarCancel} onClick={onCalendarCancel} />}
  </div>
}