import TableInner from './Table';
import Filters from "../Filters";
import React, {useState} from "react";
import {useSearchParams} from "react-router-dom";

interface TableProps {
	id: string;
	refetch?: boolean;
	defaultFilters?: any;
	tableName: string;
	columns: any;
	isFilterable?: boolean;
	renders: any;
	defaultHiddenColumns?: string[];
	fetchData: any;
}

const Table = ({refetch, defaultFilters = {}, ...props}: TableProps) => {

	const [params, setParams] = useSearchParams();
	let elements = props.columns.filter((c: any) => c.filter);

	const buildDefaultFilters = () => {
		let r: any = {};

		for (let entry of params.entries()) {
			let [key, value] = entry;
			if(key.substring(0,2) === 'f_')r[key.substring(2)] = value;
		}
		for(let key in defaultFilters){
			if(defaultFilters[key])r[key] = defaultFilters[key];
		}

		elements.forEach(({accessor}: {accessor: string}) => {
			r[accessor] = r[accessor] || '';
		});
		return r;
	}

	const [filters, setFilters] = useState(buildDefaultFilters);

	let isFilterable = props.isFilterable ?? true;

	return <div className="mt-5">
		{isFilterable &&
		<Filters defaultFilters={defaultFilters} elements={elements} name={props.tableName} onFilter={setFilters}/>}
		{/* @ts-ignore */}
		<TableInner refetch={refetch} {...props} filters={filters}/>
	</div>
}

export default Table;
