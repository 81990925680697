import {Button, Popconfirm} from "antd";
import {ButtonProps} from "antd/es/button/button";
import {AiOutlineDelete} from "react-icons/ai";

export function DeleteButton({children, onClick, ...props}: ButtonProps) {

  return <Popconfirm
    title="Eliminar las filas"
    description="¿Estás seguro de que quieres eliminar las filas seleccionadas?"
    onConfirm={(e) => onClick && onClick(e!)}
  >
    <Button type="primary" danger {...props}>
      <div className="flex items-center gap-2">
        <AiOutlineDelete size={17}/>
        {children}
      </div>
    </Button>
  </Popconfirm>
}