import {Select} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {getData} from "../../../util/req";
import {t} from "i18next";
import {Trainer} from "../TDTrainers/types";

export function TDSelectTrainer(props: any){
  const [loading, setLoading] = useState(false)
  const [trainers, setTrainers] = useState<Trainer[]>([])


  const loadSellers = async () => {
    setLoading(true)
    const result = await getData('/td/trainer')
    setLoading(false)
    setTrainers(result.data.map((trainer: Trainer) => ({
      value: trainer._id,
      label: trainer.name
    })))
  }

  useEffect(() => {
    loadSellers()
  }, [])

  return <Select
    clearable
    loading={loading}
    data={trainers}
    label={"Instructor"}
    searchable
    nothingFound={t('td.nothing found')}
    {...props}
  />

}