import useAuth from "../../providers/UserProvider/useAuth";


export const useGallery = () => {
	const {secQuery} = useAuth();
	const base = '/api/tour_operator/album';

	const getAllAlbums = async ({signal} = {}) => {
		const {error, data} = await secQuery(base, null, 'GET', {signal});
		return {error, albums: data?.albums};
	}

	const newAlbum = async ({name, signal} = {}) => {
		const {error, data} = await secQuery(base, {name}, 'POST', {signal});
		return {error, id: data?.id};
	}

	const editAlbum = async ({name, id, signal})  => {
		const {error, data} = await secQuery(`${base}/${id}`, {name}, 'PUT', {signal});
		return {error, success: data?.success};
	}

	const deleteAlbum = async ({id}) => {
		const {error, data} = await secQuery(`${base}/${id}`, null, 'DELETE');
		return {error, success: data?.success};
	}

	const getAllImages = async ({album_id}) => {
		const {error, data} = await secQuery(`${base}/${album_id}/images`, null, 'GET');
		return {error, images: data?.images};
	}

	const renameImage = async ({id, name}) => {
		const {error, data} = await secQuery(`${base}/image/${id}`, {name}, 'PUT');
		return {error, success: data?.success};
	}

	const deleteImage = async ({id}) => {
		const {error, data} = await secQuery(`${base}/image/${id}`, null, 'DELETE');
		return {error, success: data?.success};
	}


	return {
		getAllAlbums,
		editAlbum,
		newAlbum,
		deleteAlbum,
		getAllImages,
		renameImage,
		deleteImage
	}
}

