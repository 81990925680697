import {useNavigate, useParams} from "react-router-dom";
import useSWR from "swr";
import {CreateEvent, Event} from "./types";
import {useCallback, useState} from "react";
import {putData} from "../../../util/req";
import {Card, message} from "antd";
import {Loader} from "@mantine/core";
import bases from "../../../routes/bases";
import {TDEventForm} from "./TDEventForm";

export function TDEventsUpdate(){
  const {id} = useParams();
  const {isLoading, data, mutate} = useSWR<{ data: Event }>(`/api/admin/td/event/${id}`);
  const [submitLoading, setSubmitLoading] = useState(false);
  const navigate = useNavigate()

  const handleSubmit = useCallback(async (values: CreateEvent) => {
    setSubmitLoading(true);
    const response = await putData(`/td/event/${id}`, values)
    if(response){
      await mutate()
      setSubmitLoading(false);
      message.open({
        type: 'success',
        content: 'El vendedor se ha editado'
      });
      navigate(`/${bases.td.events}`)
    }else{
      setSubmitLoading(false);
    }
  }, []);


  if (isLoading) return <Loader/>


  return (
    <Card style={{maxWidth: 1000}} className="mx-auto my-5" title="Editar evento">
      <TDEventForm loading={submitLoading} initialValues={data?.data} onFinish={handleSubmit}/>
    </Card>
  );

}