import React, {useEffect, useState} from 'react';
import s from './GalleryPickUpImages.module.css';
import {useTranslation} from "react-i18next";
import notFoundSVG from "../../../resources/svg/not_found_image.svg";
import config from "../../../config";
import GalleryModal from "../GalleryModal";
import Title from "../../LayoutComponents/Title";
import {AiOutlineCloudUpload} from "react-icons/ai";

interface GalleryPickUpImagesProps {
	label?: string;
	className?: string;
	onDone: (images: string[]) => void;
	defaultValue?: string[];
	upload?: boolean;
}

const GalleryPickUpImages = ({label, className, onDone, defaultValue, ...props}: GalleryPickUpImagesProps) => {
	const {t} = useTranslation();
	label = label || t('gallery data.select image', {count: 2});

	const [opened, setOpened] = useState(false);
	const [images, setImages] = useState<any>([]);


	useEffect(() => {
		if (defaultValue) {
			setImages(defaultValue);
		} else {
			setImages([]);
		}
	}, [defaultValue]);


	return (
		<div>
			<GalleryModal defaultValue={images} opened={opened} setOpened={setOpened} onDone={t => {
				setImages(t);
				onDone(t);
			}} {...props} />
			<div className={className} onClick={() => setOpened(true)}>
				{label && <div className={s.label}>{label}</div>}
				<div className={s.outWrap}>
					<div className={s.container}>
						{!images.length && <div className="w-full flex justify-center items-center" style={{height: '200px'}}>
							<Title size={1} className="mr-10">{t('upload pictures')}</Title>
							<AiOutlineCloudUpload size={60}/>
						</div>}

						{images.map((v: string) =>
							<div
								key={v}
								className={s.image}
								style={{backgroundImage: `url(${config.imagesURL}/${v}`}}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};


export default GalleryPickUpImages;