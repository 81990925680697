import React from 'react';
import s from './SelectAddress.module.css';
import InputWithSelectOld from "../InputWithSelectOld";


interface SelectAddressOldWithControllerProps {
  id: string;
  name: string;
  searchFunction: (str: string) => Promise<any[]>;
  onChange?: (str: string) => void;
  [p: string]: any;
}

const SelectAddressOldWithController = ({id, name, searchFunction, onChange, ...props}: SelectAddressOldWithControllerProps) => {

  const searchAddress = async (str: string) => {
    onChange && onChange(str);
    if (str.length <= 1) return [];
    return await searchFunction(str);
  }

  // @ts-ignore
  return (<InputWithSelectOld
      name={name}
      id={id}
      onChange={searchAddress}
      {...props}
    />
  );
};


export default SelectAddressOldWithController;
