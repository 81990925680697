import React, {useEffect, useState} from 'react';
import s from './ShowError.module.css';
import {useTranslation} from "react-i18next";

const ShowError = ({error}) => {
  const {t} = useTranslation();
  const [msg, setMsg] = useState();

  useEffect(() => {
    let msg = false;
    if(typeof error === 'object'){
      if(error.error)msg = t(`errors list.${error.error}`);
    }else if(typeof error === 'string'){
      msg = t(`errors list.${error}`)
    }
    setMsg(msg);
  }, [error]);



  return (
    <font>
      {msg && msg}
    </font>
  );
};


export default ShowError;
