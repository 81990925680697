import React, {forwardRef, useCallback, useEffect, useRef, useState} from 'react';
import s from './InputWithSelect.module.css';
import {Controller} from "react-hook-form";
import {Autocomplete, Group, Loader, Text} from "@mantine/core";
import {debounce} from "../../../helpers/util";
import {ReactComponent as LocationSVG} from "../../../resources/svg/location.svg";


const AutoCompleteItem = forwardRef(({value, description, icon, ...props}, ref) => {
  return <div ref={ref} {...props}>
    <Group noWrap>
      {icon && <div className={s.icon}>{icon}</div>}
      <div>
        <Text>{value}</Text>
        <Text size="xs" color="dimmed">
          {description}
        </Text>
      </div>
    </Group>
  </div>
});


const InputWithSelectOldWithController = ({
                           id,
                           name,
                           onChange,
                           containerClassName,
                           onDataSelect,
                           defaultData,
                          loaderProps = {},
                           ...props
                         }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (defaultData) setData(defaultData);
  }, [defaultData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const asyncChange = useCallback(debounce(async e => {
    setLoading(true);
    let data = await onChange(e);
    setData(data);
    setLoading(false);
  }, 500), []);


  return (
    <Controller
      name={name}
      render={({field: {onChange: controllerOnChange, value}}) => (
          <Autocomplete
            name={name}
            id={id}
            value={typeof value === 'string' ? value : value?.value}
            data={data}
            onChange={e => {
              controllerOnChange(e);
              //onChange && onChange(e);
              asyncChange(e);
            }}
            rightSection={loading ? <Loader size={16} {...loaderProps}/> : null}
            itemComponent={AutoCompleteItem}
            filter={a => a}
            onItemSubmit={item => {
              controllerOnChange(item);
            }}
            {...props}
          />
      )}
    />
  )
};


export default InputWithSelectOldWithController;
