import React, {useEffect, useMemo, useState} from 'react';
import s from './TDProduct.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useTDProducts} from "../../../controllers/useTDProducts/useTDProducts";
import {formList, useForm} from '@mantine/form';
import { Button, Select, Title} from "@mantine/core";
import {randomId} from '@mantine/hooks';
import bases from "../../../routes/bases";
import TDCourse from "../TDCourse";
import {langRender} from "../../../helpers/i18n";
import {canTDWrite} from "../../Permissions/VisibleWithPermissions";


const TDProduct = props => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const [params, setParams] = useSearchParams();
	const {id} = useParams();

	const [products, setProducts] = useState([]);

	const [submitLoading, setSubmitLoading] = useState(false);

	const {getProduct, getAllProducts, editProduct} = useTDProducts();

	const form = useForm({
		initialValues: {
			prev_requirements: formList([]),
			recommended_products: formList([]),
			what_includes: formList([]),
			images: formList([]),
		}
	});

	const loadProducts = async () => {
		const {error, products} = await getAllProducts();
		if(error)return alert(error);
		setProducts(products.filter(({_id}) => _id !== id).map(product => ({value: product._id, label: langRender(product.name)})));
	}

	const loadProduct = async () => {
		const {error, product} = await getProduct({id});
		if (error) return alert(error);

		if (!product.prev_requirements) {
			product.prev_requirements = formList([{text: {es: ''}}]);
		} else {
			product.prev_requirements = formList(product.prev_requirements.map(text => {
				return typeof text === 'string' ? {text: {es: text}} : {text}
			}));
		}

		if (!product.what_includes) {
			product.what_includes = formList([{text: {es: ''}}]);
		} else {
			product.what_includes = formList(product.what_includes.map(text => {
				return typeof text === 'string' ? {text: {es: text}} : {text}
			}));
		}

		product.recommended_products = Array.isArray(product.recommended_products) ? formList(product.recommended_products) : formList([]);
		product.images = Array.isArray(product.images) ? formList(product.images) : formList([]);


		form.setValues(product);
		if(!product.group && params.has('group')){
			form.setFieldValue('group', params.get('group'));
		}
		if(typeof product.description === 'string')form.setFieldValue('description', {es: product.description});
		if(typeof product.itinerary === 'string')form.setFieldValue('itinerary', {es: product.itinerary});
		if(typeof product.name === 'string')form.setFieldValue('name', {es: product.name});
	}

	useEffect(() => {
		loadProducts();
		loadProduct();
	}, []);


	const onSubmit = async values => {
		console.log(values);
		//return;
		setSubmitLoading(true);
		const {error} = await editProduct({
			id, product: {
				...values,
				prev_requirements: values.prev_requirements.map(a => a.text),
				what_includes: values.what_includes.map(a => a.text)
			}
		});
		setSubmitLoading(false);
		if (error) return alert(error);

		if(values.group === 'course') {
			navigate(`/${bases.td.courses}`);
		}else if(values.group === 'snorkel'){
			navigate(`/${bases.td.snorkel}`);
		}else{
			navigate(`/${bases.td.dives}`);
		}
	}


	const groupTypes = useMemo(() => [
		{value: 'course', label: t('td.product.groups.course')},
		{value: 'snorkel', label: t('td.product.groups.snorkel')},
		{value: 'diving', label: t('td.product.groups.diving')},
	], []);

  const canWrite = useMemo(() => canTDWrite(), [])

  return (
		<div className="container m-auto pl-5 mb-10">
			<form onSubmit={form.onSubmit(onSubmit)}>
				<Title className="mb-5" size="l">{t('td.product.title')}</Title>
				<div className="w-full">
					<Select required className={s.inputContainer} data={groupTypes}
									label={t('td.product.group')} {...form.getInputProps('group')}/>
				</div>
				<hr className="my-10"/>
				<TDCourse form={form} products={products} group={form.values.group}/>

				<hr className="my-10"/>
				<Button disabled={!canWrite} className="mt-5" type="submit" loading={submitLoading}>{t('save')}</Button>

			</form>
		</div>
	);
};


export default TDProduct;