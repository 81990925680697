import {ItPlace} from "../../types/itineraries";

export const sortPlaces = (places: ItPlace[]) => {
  if(!places)return []
  return places.sort((a, b) => {
    if (a.start < b.start) return -1;
    if (a.start > b.start) return 1;
    return 0;
  });
}

export const createPlacesForHotels = (places: ItPlace[]) => {
  places = sortPlaces(places);
  const isDayHidden = (place: any, day: number, previous: any) => {
    return !(place.start !== place.end && place.start === day && place?.address?.label !== previous?.address?.label);
  }
  let r: any[] = [];
  let previous: any = {};
  const push = (p: any) => {
    r.push(p);
    previous = p;
  }

  for (let i = 0; i < places.length; i++) {
    let p = places[i];
    if (p.start !== p.end) {
      let toPush: any = {...p, day: p.start};
      if (p.end - p.start > 1) toPush.bullet = `${p.start} - ${p.end}`;
      if (p.address!.label === previous?.address?.label) {
        r[r.length - 1].bullet = `${previous.start} - ${p.end}`;
      } else if(!isDayHidden(p, p.start, previous)){
        push(toPush);
      }
    }
  }
  return r;
}

export const createPlacesForActivities = (places: ItPlace[]) => {
  places = sortPlaces(places);
  let r: any[] = [];

  for (let i = 0; i < places.length; i++) {
    let p = places[i];
    if (p.activity_blocked) continue;
    if (p.start !== p.end) {
      for (let j = p.start; j < p.end; j++) {
        r.push({
          day: j,
          ...p,
          //title: p.start === j ? p.title : ' ',
        });
      }
    } else {
      r.push({
        day: p.start,
        ...p,
        title: p?.address?.label
      });
    }
  }
  return r;
}

export const createPlacesForPackage = (places: ItPlace[]) => {
  places = sortPlaces(places!);
  let r: any[] = [];
  let previous: any = {};
  const push = (p: any) => {
    r.push(p);
    previous = p;
  }

  for (let i = 0; i < places.length; i++) {
    let p = places[i];
    if (p.start !== p.end) {
      let toPush: any = {...p, day: p.start};
      if (p.end - p.start > 1) toPush.bullet = `${p.start} - ${p.end}`;
      if (p.address!.label === previous?.address?.label) {
        r[r.length - 1].bullet = `${previous.start} - ${p.end}`;
      } else{
        push(toPush);
      }
    }
  }
  return r;
}

export const createPlacesForSummary = (places: ItPlace[]) => {
  places = sortPlaces(places);

  // en el resumen tiene que ser por días
  let r = [];
  let from = Math.min(...places.map(p => p.start));
  let to = Math.max(...places.map(p => p.end));


  for (let day = from; day <= to; day++) {
    let addresses_on_day = places.filter(p => day >= p.start && day <= p.end).map(p => p?.address?.label!);
    let title = '';
    // remove repeated words
    for(let i = 0;i<addresses_on_day.length;i++){
      let s = addresses_on_day[i].split(',');
      s.forEach((word, index) => {
        for(let j = 0;j<addresses_on_day.length;j++){
          if(j === i)continue;
          if(addresses_on_day[j].includes(word))s.splice(index);
        }
      });
      addresses_on_day[i] = s.join(',');
    }

    title += [...new Set(addresses_on_day.filter(a => a.trim()))].join(', ');

    r.push({
      day: day,
      title
    });
  }

  return r;
}

