import {Chip, Chips, Popover} from "@mantine/core";
import React, {useState} from "react";
import { MdLanguage } from "react-icons/md";
import {ReactComponent as LangSVG} from '../../../resources/svg/it/lang.svg';
import s from './chipsSelectLang.module.css';

interface ChipsSelectLangProps {
  languages?: string[];
  value: string;
  onChange: (value: string) => void;
}

const ChipsSelectLang = ({languages, value, onChange}: ChipsSelectLangProps) => {
  const [opened, setOpened] = useState(false);
  languages = languages || ['es', 'eng'];


  return <Popover
    width={200}
    opened={opened}
    onClose={() => setOpened(false)}
    target={<LangSVG onClick={() => setOpened(true)}/>}
    // target={<div className={s.targetContainer} onClick={() => setOpened(true)}>
    //   <div className={s.target}>{value}</div>
    // </div>}
    position="bottom"
    withArrow
  >
    <Chips value={value} onChange={(v: string) => onChange(v)} className="flex flex-nowrap">
      {languages.map((l, i) => <Chip key={i} value={l} className={l === value ? "font-bold" : ''}>{l}</Chip>)}
    </Chips>
  </Popover>
}

export default ChipsSelectLang;