import React, {useState} from 'react';
import s from './Header.module.css';
import classNames from 'classnames';
import HeaderBarItems from "../HeaderBarItems";
import GlobalSearchBar from "../GlobalSearchBar";
import HeaderProfileItems from "../HeaderProfileItems";
import GlobalSearchBarResult from "../GlobalSearchBarResult";
import useSelectionProvider from "../../providers/SelectionProvider/useSelectionProvider";

const Header = props => {
  const {drawSelected} = useSelectionProvider();


  const [state, setState] = useState({
    focused: false,
    selected: -1,
    results: []
  });
  const setSelected = selected => setState(prev => ({...prev, selected}));
  const setFocused = focused => setState(prev => ({...prev, focused}));
  const setResults = results => setState(prev => ({...prev, results}));

  const cx = classNames({
    [s.header]: true,
    [s.headerFocused]: state.focused
  });

  const resultsBarClasses = classNames({
    [s.resultsBar]: true,
    [s.resultsBarActive]: state.results.length > 0
  });

  return (
    <div className={cx}>
      <div className={s.barContainer}>
      <HeaderBarItems/>
      <GlobalSearchBar results={{results: state.results, setResults}} setFocused={setFocused} selected={{selected: state.selected, setSelected}}/>
      {state.results.length > 0 &&
      <div className={resultsBarClasses}>
        {state.results.map((result, i) =>
          <GlobalSearchBarResult key={result.key} result={result} selected={i === state.selected} />)}
      </div>
      }
      <HeaderProfileItems/>
      </div>
      <div className={s.selectedItems}>
        {drawSelected}
      </div>
    </div>
  );
};


export default Header;
