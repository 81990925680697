import Content from "../components/Pages/GlobalContent";
import LoginPage from "../components/LoginPage/LoginPage";
import PasswordResetPage from "../components/PasswordResetPage";
import bases from "./bases";
import TourOperatorFirstSetup from "../components/TourOperator/TourOperatorFirstSetup";


let routes = [
	{
		path: '/', element: <Content/>, children: [
			{index: true, element: <LoginPage/>},
			{path: 'login', element: <LoginPage/>},
			{path: bases.admin_password_change, element: <PasswordResetPage/>},
			{path: bases.tour_operator_setup, element: <TourOperatorFirstSetup/>},
			{path: '*', element: <LoginPage/>}
		],
	},
	{path: '*', element: <LoginPage/>}
];


export default routes;