import React from 'react';
import s from './AdminForm.module.css';
import {MultiSelect, PasswordInput, TextInput} from "@mantine/core";
import {useTranslation} from "react-i18next";
import {useSelectOptions} from "../../../../hooks/useSelectOptions";

const AdminForm = ({form, isNew = false}) => {
	const {t} = useTranslation();

	const {enterpriseOptions} = useSelectOptions();


	return (
		<div>
			<MultiSelect
				data={enterpriseOptions}
				label={t('admin data.select enterprises')}
				placeholder={t('admin data.select enterprises')}
				required
				{...form.getInputProps('enterprises')}
			/>

			<div className="w-full flex flex-nowrap">
				<TextInput
					required
					label={t('name')}
					{...form.getInputProps('name')}
					className="w-full mr-5"
				/>
				<TextInput
					label={t('surname')}
					{...form.getInputProps('surname')}
					className="w-full"
				/>
			</div>
			<div className="w-full flex flex-nowrap">
				<TextInput
					required
					label={t('email')}
					{...form.getInputProps('email')}
					className="w-full mr-5"
				/>

				<PasswordInput
					required={isNew}
					label={t('password')}
					{...form.getInputProps('password')}
					className="w-full"
				/>
			</div>


		</div>

	);
};


export default AdminForm;