import s from './Rating.module.css';
import React from "react";
import {ReactComponent as StarSVG} from "../../../resources/svg/star.svg";

interface RatingProps {
  stars: number | undefined;
  maxStars?: number;
  className?: string;

  onSelect?: (stars: number) => void;
}

const Rating = ({stars, maxStars = 5, className, onSelect}: RatingProps) => {
  if (stars === undefined) return <></>

  return <div className={`flex ${className}`}>{[...Array(maxStars)].map((_, i) => {
    let className = i < stars ? s.star : s.empty_star;
    if (onSelect) className += ` ${s.clickable}`;
    return <StarSVG
      className={className}
      onClick={() => onSelect && onSelect(i + 1)}
    />
  })}
  </div>
}

export default Rating;