import React, {useState} from 'react';
import s from './LoadingButton.module.css';
import classNames from 'classnames/bind';

let cx = classNames.bind(s);

const LoadingButton = (props) => {
  const [loading, setLoading] = useState(false);
  const {
    onSubmit,
    handleSubmit,
    text,
    className,
    autoLoading = false,
    ...rest
  } = props;

  let classNames = cx({
    [className]: true,
    [s.button]: true,
    [s.processing]: loading
  });


  const setLoadingExternal = (current = false) => setLoading(current);
  const internalOnClick = e => {
    if(handleSubmit){
      handleSubmit(function() {
        if(autoLoading)setLoading(true);
        onSubmit.apply(this, [...arguments, setLoadingExternal])
      })(e);
    }else{
      if(autoLoading)setLoading(true);
      onSubmit(e, setLoadingExternal);
    }
  }


  return (
    <button
      type="button"
      disabled={loading}
      className={classNames}
      onClick={internalOnClick}
      {...rest}
    >
      {text}
    </button>
  );
};


export default LoadingButton;
