import React, { useRef} from 'react';
import s from './Calendar.module.css';
import i18n from 'i18next';

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import './Calendar.css';
import ListView from './ListView';
import {useTranslation} from "react-i18next";
import useContextMenu from "../../hooks/useContextMenu";



interface CalendarProps {
  eventSources: any[];
  onDateClick?: (e: any) => void;
  contextMenu?: { text: string, onClick: (e: any) => void }[];
}

const Calendar = React.forwardRef((props: CalendarProps, ref) => {
  const {t} = useTranslation();
  const {ContextMenu, ContextTrigger, MenuItem} = useContextMenu();

  const {
    eventSources,
    onDateClick = a => a
  } = props;

  const defaultRef = useRef();
  const calendarRef = ref || defaultRef;


  const handleDateClick = (e: any) => {
    onDateClick(e);
  }

  const renderEventContent = (eventInfo: any) => {
    const {
      onClick = (a: any) => a
    } = eventInfo.event.extendedProps;

    return (
      <ContextTrigger data={eventInfo}>
        <div
          className={s.eventContainer}
          onClick={e => onClick(eventInfo)}
        >
          <p>{eventInfo.event.title}</p>
        </div>
      </ContextTrigger>
    )
  }


  return (
    <>
      <FullCalendar
        // @ts-ignore
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin, ListView]}
        dateClick={handleDateClick}
        initialView="dayGridMonth"
        weekends={true}
        /*        events={[
                  {title: 'event 1', date: '2020-12-05'},
                  {title: 'event 2', date: '2020-12-08'}
                ]}*/
        eventSources={eventSources}
        lazyFetching={true}
        locale={i18n.language}
        buttonText={{
          today: t("calendar.today"),
          month: t("calendar.month"),
          week: t("calendar.week"),
          day: t("calendar.day"),
          list: t("calendar.list")
        }}


        eventColor='transparent'
        height="100%"

        headerToolbar={{
          end: 'prevYear,prev today next,nextYear'
        }}

        eventContent={renderEventContent}
      />

      {Array.isArray(props.contextMenu) &&
      <ContextMenu>
        {props.contextMenu.map((item, i) => <MenuItem key={i} onClick={item.onClick}>{item.text}</MenuItem>)}
      </ContextMenu>
      }

    </>
  );
});

export default Calendar;
