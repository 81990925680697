import React from 'react';
import s from './ContextMenuHeaderTable.module.css';
import {useTranslation} from "react-i18next";

const ContextMenuHeaderTable = ({close, onHidden}) => {
  const {t} = useTranslation();
  return (
    <div rel="element" className={s.container}>
      <div className={s.element} onClick={() => {
        close();
        onHidden();
      }}>{t('table.hide column')}</div>
    </div>
  );
};


export default ContextMenuHeaderTable;
