import s from './VerticalHotel.module.css';
import {GenericHotel} from "../../../types/hotel";
import Image from "../../Image";
import Rating from "../../Inputs/Rating/Rating";
import {ReactComponent as StarSVG} from "../../../resources/svg/star.svg";
import {ReactComponent as LogoSVG} from "../../../resources/svg/logo_small.svg";
import {ReactComponent as CheckSVG} from "../../../resources/svg/selected_mark.svg";


interface VerticalHotelProps {
  hotel: GenericHotel;
  className?: string;
  have_tick?: boolean;
  checked?: boolean;
}

const VerticalHotel = ({hotel, have_tick = false, checked, className = ''}: VerticalHotelProps) => {

  return <div className={`${s.container} ${className}`}>
    {have_tick && <div className={s.checkedContainer}>
      <CheckSVG className={`${checked ? s.checked : s.notChecked}`}/>
    </div>}

    <div className={s.hotelTypeContainer}>
      {hotel.type === 'local' ? <StarSVG/> : <LogoSVG/>}
    </div>
    <div className={s.image}>
      <Image src={hotel.images[0]} alt={"hotel image"}/>
    </div>
    <div className="flex flex-col p-5 h-full">
      <div className="text-2xl font-semibold">{hotel.name}</div>
      {hotel.address?.label && <div>{hotel.address?.label}</div>}
      <Rating className={s.rating} stars={hotel.stars}/>
    </div>
  </div>
}

export default VerticalHotel;