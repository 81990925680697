import {OrderedBookedHotel} from "../../../controllers/usePurchaseController/types";
import PurchasedHotel from "../../Hotel/PurchasedHotel/PurchasedHotel";

interface PurchasedHotelsProps {
  hotels: OrderedBookedHotel[];
}

const PurchasedHotels = ({hotels}: PurchasedHotelsProps) => {
  return <>
    {hotels.map(hotel => <PurchasedHotel hotel={hotel}/>)}
    </>
}

export default PurchasedHotels;