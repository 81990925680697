import React from 'react';
import s from './ContactUsData.module.css';
import Table from "../Table";
import {useTranslation} from "react-i18next";
import {DateTimeRender, EmailRender} from "../Table/Renders";
import useAPIDataProvider from "../../controllers/useAPIDataProvider";
import {cutText} from "../../helpers/util";
import {useNavigate} from "react-router-dom";
import bases from "../../routes/bases";
import Title from "../LayoutComponents/Title";

const ContactUsData = ({grid}) => {
  const {t} = useTranslation();
  const {getContactUsData} = useAPIDataProvider();
  const navigate = useNavigate();

  return (
    <div>
      <Title className="ml-5">{t('contact us data.table title')}</Title>
      <Table
        tableName="contactUs"
        columns={[
          {accessor: 'name', Header: t('contact us data.name'), type: 'string'},
          {accessor: 'email', Header: t('contact us data.email'), type: 'string'},
          {accessor: 'subject', Header: t('contact us data.subject'), type: 'string'},
          {accessor: 'message', Header: t('contact us data.message'), type: 'string'},
          {accessor: 'created_at', Header: t('contact us data.created_at'), type: 'date'}
        ]}
        fetchData={async ({pages}) => {
          const {error, data} = await getContactUsData({pages});
          if (error) return {data: [], totalRecords: 0};
          return {data: data.data, totalRecords: data.totalRecords};
        }}
        onView={row => navigate(`/${bases.contact_us}/${row._id}`)}
        renders={{
          message: (v, el) => <>
            {cutText(v, 200)}
            {v?.length > 200 &&
            <div className="link" onClick={() => navigate(`/${bases.contact_us}/${el._id}`)}>{t('show more')}</div>}
          </>,
          email: EmailRender,
          created_at: DateTimeRender
        }}
      />
    </div>
  );
};


export default ContactUsData;
