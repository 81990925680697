import { Select } from "antd";
import {useLocalStorage} from "../../../hooks/useLocalStorage";
import {useTranslation} from "react-i18next";
import Label from "../Label";


export const currencies = [
  'EUR', 'USD', 'MXN'
]

export function ChooseCurrency()  {
  const {t} = useTranslation();
  const [currency, setCurrency] = useLocalStorage('currency','EUR')

  return <div className="w-full flex flex-col">
    <Label name="currency">{t('settings page.currency')}</Label>
    <Select
      onChange={v => setCurrency(v)}
      value={currency}
      options={currencies.map(c => ({value: c, label: c}))}
    >
    </Select>
      </div>
}

export default ChooseCurrency;