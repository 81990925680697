import React from 'react';
import s from './PlaneLoader.module.css';
import {FaPlane} from 'react-icons/fa';

const PlaneLoader = props => {
    return (
      <div className={s.loader} style={props.style}>
        {(Array.from({length: 20}, (v, i) =>
          (<span key={i} style={{['--i']: i}} />)))}
        <div className={s.plane}>
          <FaPlane size={18}/>
        </div>
      </div>
    );
};


export default PlaneLoader;
