import Input from "antd/es/input";
import {ColumnType} from "antd/es/table";
import {FilterDropdownProps} from "antd/es/table/interface";
import {Button, Space, Select} from "antd";
import React from "react";
import { FilterOutlined } from "@ant-design/icons";

interface Props {
  name: string
  onFilter?: (name: string, value: string) => void,
  inputProps?: React.ComponentProps<typeof Input>
  options: {label: string, value: string}[]
}


export function getListFilter<DataType>({name, options, onFilter}: Props): ColumnType<DataType> {
  return ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, close}: FilterDropdownProps) => {
      return <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
        <div style={{marginBottom: 8}}>
          <Select
            showSearch
            allowClear
            filterOption={(input, option) => option ? option.label.toLowerCase().includes(input.toLowerCase()) : true}
            style={{minWidth: 300}}
            placeholder={`Seleccionar...`}
            options={options}
            value={selectedKeys[0]}
            onChange={(value) => {
              setSelectedKeys(value ? [value] : []);
              onFilter && onFilter(name, value as string)
              confirm();
            }}
          />
        </div>
        <Space>
          <Button
            type="link"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              onFilter && onFilter(name, '')
              confirm();
              close();
            }}
          >
            Borrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    },
    filterIcon: (filtered: boolean) => <FilterOutlined size={15} />,
    onFilter: (dropValue, record) => true
  })
}