import React from 'react';
import s from './GlobalContent.module.css';
import {Routes, Route, useRoutes, BrowserRouter, Outlet} from 'react-router-dom';
import routes from '../../../routes/content';
import Footer from "../../Footer/Footer";

/*
import SideBarRoutes from "../../routes/Routes";

const GlobalContent = props => {
  return (
    <div className={s.content} id="global_content">
      <Routes>
        {SideBarRoutes.map(function render(route){
          return <Route
            path={route.path}
            exact={route.exact}
            key={route.key}
            element={<route.element/>}
            children={route?.children?.map(render)}
          />
        })}
      </Routes>
    </div>
  );
};
*/


const GlobalContent = props => {
  return (
    <div className={s.content} id="global_content">
      <Outlet/>
      {/*<Footer/>*/}
    </div>
  );
}

export default GlobalContent;
