import React, {useEffect, useState} from "react";
import {Select} from "@mantine/core";
import useTDClients from "../../../controllers/useTDClients";
import {useTranslation} from "react-i18next";


const TDSelectClient = ({value, defaultClients, onChange, ...props}) => {
	const {t} = useTranslation();
	const [clients, setClients] = useState(defaultClients || []);


	useEffect(() => {
		if(Array.isArray(defaultClients) && defaultClients.length > 0){
			setClients(prev => {
				let n = [...prev];
				defaultClients.forEach(client => {
					if (!n.find(c => c._id === defaultClients[0]._id)) {
						n.push({label: client.full_name, value: client._id});
					}
				});
				return n;
			});
		}
	}, [defaultClients]);

	const {getClients} = useTDClients();

	const searchClients = async (full_name) => {
		const {clients} = await getClients({filters: {full_name}});
		if(!Array.isArray(clients))return;
		setClients(clients.filter(c => c.full_name).map(c => ({label: c.full_name, value: c._id})));
	}


	return <Select
		data={clients}
		label={t('td.select client')}
		onChange={onChange}
		value={value}
		searchable
		nothingFound={t('td.nothing found')}
		onSearchChange={searchClients}
		{...props}
	/>

}

export default TDSelectClient;