import React, {useEffect, useState} from 'react';
import s from './ShowContactUs.module.css';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import useAPIDataProvider from "../../controllers/useAPIDataProvider";
import VerticalTable from "../Table/VerticalTable";
import {DateTimeRender, EmailRender} from "../Table/Renders";

const ShowContactUs = ({grid, contact_us_id: contact_us_id_param, showTitle = true, tableProps = {}}) => {
  const {t} = useTranslation();
  let {id: contact_us_id} = useParams();
  const {getContactUs} = useAPIDataProvider();

  return (
    <VerticalTable
      langGroup={'contact us data'}
      fetchData={async () => await getContactUs({contact_us_id: contact_us_id_param || contact_us_id})}
      valueRenders={{
        email: EmailRender,
        created_at: DateTimeRender
      }}
    />
  );
};


export default ShowContactUs;
