import React, {useState} from 'react';
import s from './MultiLangInput.module.css';
import {Chips, Chip, Popover, TextInput} from '@mantine/core';
import {useOutletContext} from "react-router-dom";
import {IoLanguage} from "react-icons/io5";

const MultiLangInput = ({
													form,
													formList = false,
													formIndex,
													Input = TextInput,
													name,
													formListName = 'text',
													lang: _lang,
													setLang: _setLang,
													languages = ['es', 'eng'],
													...props
												}) => {
	const [opened, setOpened] = useState(false);

	let context = useOutletContext();

	let lang = _lang || context.lang;
	let setLang = _setLang || context.setLang;


	let value = form.values[name] ? form.values[name][lang] : '';
	if(formList){
		value = form.values[name][formIndex] ? form.values[name][formIndex][formListName][lang] : '';
	}

	return (
		<Input
			onChange={e => {
				if (formList) {
					const list = form.values[name];
					console.log(list);
					const prevList = list[formIndex];
					let value = {
						...prevList, [formListName]: {
							...(prevList[formListName] || {}),
							[lang]: e.currentTarget.value
						}
					};

					form.setListItem(name, formIndex, value);

				} else {
					let prev = form.values[name] || {};
					let value = {...prev, [lang]: e.currentTarget.value}
					form.setFieldValue(name, value);

				}
			}}
			value={value}
			rightSection={<Popover
				width={200}
				opened={opened}
				onClose={() => setOpened(false)}
				styles={{root: {width: '100%'}}}
				target={<IoLanguage onClick={() => setOpened(true)} size={20}/>}
				position="bottom"
				withArrow
			>
				<Chips value={lang} onChange={v => setLang(v)} className={s.changeLang}>
					{languages.map(l => <Chip value={l} className={l === lang ? s.selected : ''}>{l}</Chip>)}
				</Chips>
			</Popover>}
			{...props}
		/>
	);
};


export default MultiLangInput;