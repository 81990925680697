import {useTranslation} from "react-i18next";
import {Table, Text} from "@mantine/core";
import s from "../TDReservation.module.css";
import {LanguageRender, MoneyRender} from "../../../Table/Renders";
import React, {useCallback} from "react";

export const AccountingTable = ({form, list, products}) => {
  const {t} = useTranslation();


  const calculateTotalProductPrice = useCallback(()  => {
    let price = 0;
    if (Array.isArray(form.values.products) && Array.isArray(products)) {
      form.values.products.forEach(p => {
        const product = products.find(pr => pr._id === p.product_id);
        if (product) {
          price += (product.price * (p.adults + p.children)) - (p.discount || 0)
        }
      });
    }
    return price
  }, [form.values.products, products])


  let chosen_products = form.values.products.filter(p => p.product_id);
  if (!chosen_products.length) return <></>;

  const total_payed = form.values.payments.filter(p => p.amount).reduce((acc, p) => acc + p.amount, 0) || 0;
  const total_product_price = calculateTotalProductPrice()
  const final_price = form.values.total_price;


  return <div>
    <Text className="mb-5" size="xl">{t('td.reservations.accounting table')}</Text>

    <Table className={s.table} horizontalSpacing="sm" verticalSpacing="xl" fontSize="md">
      <thead>
      <tr>
        <th>{t('td.reservations.product')}</th>
        <th>{t('td.reservations.price')}</th>
        <th>{t('td.reservations.quantity')}</th>
        <th>{t('td.reservations.discount')}</th>
        <th>{t('td.reservations.total')}</th>
      </tr>
      </thead>
      <tbody>
      {chosen_products.map((p, i) => {
        let product = list.find(item => item._id === p.product_id);
        let quantity = p.adults + p.children;
        let price = product.price * quantity;
        //total_price += price;
        return <tr className="mb-5" key={i}>
          <td>{LanguageRender(product.name)}</td>
          <td><MoneyRender value={product.price}/></td>
          <td>{quantity}</td>
          <td><MoneyRender value={(p.discount || 0) * -1}/></td>
          <td><MoneyRender value={price - (p.discount || 0)}/></td>
        </tr>
      })}

      <tr>
        <td colSpan={4} className="text-right"><b>{t('td.reservations.total')}: </b></td>
        <td><b><MoneyRender value={total_product_price}/></b></td>
      </tr>
      {form.values.discount > 0 && <>
        <tr>
          <td colSpan={4}
              className="text-right">{t('td.reservations.discount')} ({form.values.discount_percentage}%):
          </td>
          <td><MoneyRender value={(form.values.discount || 0) * -1}/></td>
        </tr>
        <tr>
          <td colSpan={4} className="text-right">{t('td.reservations.total after discount')}:</td>
          <td><MoneyRender value={final_price}/></td>
        </tr>
      </>}

      <tr>
        <td colSpan={4} className="text-right">
          <b>{t('td.reservations.total payed')} ({Math.round(total_payed / final_price * 100) || 0}%): </b></td>
        <td><b><MoneyRender value={total_payed}/></b></td>
      </tr>

      <tr>
        <td colSpan={4} className="text-right">
          <b>{t('td.reservations.total pending')} ({Math.round((final_price - total_payed) / final_price * 100) || 0}%): </b>
        </td>
        <td><b><MoneyRender value={final_price - total_payed}/></b></td>
      </tr>
      </tbody>
    </Table>
  </div>
}
