import {useEffect, useMemo, useState} from "react";
import dayjs from "dayjs";
import {Select} from "antd";
import {dateToServer} from "../../../../../util/util";
import {TableParams} from "../../../../../util/table/types";


interface Props {
  tableParams: TableParams
  setTableParams: (tableParams: any) => void
  value: string | undefined
  setValue: (value: string | undefined) => void
}
export function GroupByDates({tableParams, setTableParams, value, setValue}: Props) {
  const options = useMemo(() => {
    let options = []

    let cursor = dayjs().add(1, 'month')

    for (let i = 12; i > 0; i--) {
      let month = cursor.clone()
      const title =  month.format('MMMM') + ' ' + month.year()
      options.push({
        label: title,
        title,
        options: [
          {
            label: `1-15`,
            value: `${month.startOf('month').toISOString()}|${month.date(15).toISOString()}`
          },
          {
            label: `16-${month.daysInMonth()}`,
            value: `${month.date(16).toISOString()}|${month.endOf('month').toISOString()}`
          }
        ]
      })
      cursor = cursor.subtract(1, 'month')
    }

    return options;
  }, [])

  useEffect(() => {
    const v = value
    if(v){
      // @ts-ignore
      const payment_date = v.split('|').map((d) => dateToServer(d).toISOString())
      if(tableParams?.filters?.payment_date && tableParams?.filters?.payment_date[0] !== payment_date[0] && tableParams?.filters?.payment_date[1] !== payment_date[1]){
        setValue(undefined)
      }
    }
  }, [tableParams]);

  const onChange = (v?: string) => {
    let payment_date: any = undefined;

    if(v){
      // @ts-ignore
      payment_date = v.split('|').map((d) => dateToServer(d).toISOString())
    }
    setTableParams((prev: any) => ({
      ...prev,
      filters: {
        ...(prev.filters ?? {}),
        payment_date,
      }
    }))
  }

  useEffect(() => {
    onChange(value)
  }, [value]);

  return <Select className="mb-5" value={value} placeholder="Fecha de pago" allowClear onChange={v => setValue(v)} labelRender={(e) => {
    const a = (e.value as string).split('|')
    return `${dayjs(a[0]).format('DD/MM/YYYY')} - ${dayjs(a[1]).format('DD/MM/YYYY')}`
  }} style={{width: 300}} options={options}/>
}