import React, {useEffect, useState} from 'react';
import s from './MySavedActivities.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import useActivity from "../../../controllers/useActivity/useActivity";
import useMenu from "../../../providers/MenuProvider/useMenu";
import bases from "../../../routes/bases";
import Loading from "../../Loading";
import {Title} from "@mantine/core";

import Button from "../../Inputs/Button";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import SmallActivity from "../Views/SmallActivity";
import {Card} from "antd";

const MySavedActivities = ({
														 admin_id: external_admin_id,
														 grid = false,
														 canEdit = true,
														 canDelete = true,
														 onSelect = false,
														 onNewActivity,
														 onEditActivity,
														 defaultSelectedId
													 }) => {
	const {t} = useTranslation();
	const navigate = useNavigate();

	const {getSavedActivities, newSavedActivity, deleteSavedActivity} = useActivity();
	let {id: internal_admin_id} = useParams();


	let id = external_admin_id || internal_admin_id;

	//const {setChosen} = useMenu();

	const [activities, setActivities] = useState();
	const [loading, setLoading] = useState(true);
	const [selected, setSelected] = useState(false);


	const loadActivities = async () => {
		setLoading(true);
		const {error, activities} = await getSavedActivities({admin_id: id});
		setLoading(false);
		if (error) return alert(error);
		setActivities(activities);
	}

	useEffect(() => {
		loadActivities();
		//if (setChosen) setChosen('activities');
	}, []);

	useEffect(() => {
		if (defaultSelectedId) setSelected({_id: defaultSelectedId});
	}, [defaultSelectedId]);

	const onActivityClick = activity => {
		if (onSelect) {
			setSelected(activity);
			onSelect(activity);
		}
	}

	const _onNewActivity = async () => {
		// const {error, id} = await newSavedActivity();
		// if (error) return alert(error);
    //
		if (onNewActivity) return onNewActivity();
		navigate(`/${bases.saved_activity}/new`);
	}

	const _onEditActivity = activity => {
		if (onEditActivity) return onEditActivity(activity);
		navigate(`/${bases.saved_activity}/${activity._id}`);
	}

	const onDeleteActivity = async activity => {
		const {error, success} = await deleteSavedActivity(activity._id);
		if (error || !success) return alert(error);
		await loadActivities();
	}


	if (loading) return <Loading/>
	return (
		<div className="container m-auto my-10 px-10">
        {grid && <Title order={3}>{t('to.activity.my saved activities')}</Title>}
        {onSelect && <Title order={4}>{t('to.activity.select activity')}</Title>}
        <div className="flex flex-wrap">
          {Array.isArray(activities) && activities.map(activity => <div onClick={() => onActivityClick(activity)}
                                                                        key={activity._id}
                                                                        className={`${s.activityContainer} ${selected?._id === activity._id ? s.selected : ''}`}>
            <SmallActivity
              {...activity}
              stay_time={Math.abs(activity.to_day - activity.from_day)}
              canOpenActivity={false}
            />
            <div className={s.buttons}>
              {(canEdit || onEditActivity) &&
                <Button color="orange" variant="light" leftIcon={<AiOutlineEdit size={18}/>}
                        onClick={() => _onEditActivity(activity)}>
                  {t('to.edit')}
                </Button>}
              {canDelete && <Button color="red" variant="outline" leftIcon={<AiOutlineDelete size={18}/>}
                                    onClick={async () => await onDeleteActivity(activity)}>
                {t('to.delete')}
              </Button>}
            </div>
          </div>)}


        </div>

        <div className="mt-10">
          <Button onClick={_onNewActivity}>{t('create new activity')}</Button>
        </div>
		</div>
	);
};


export default MySavedActivities;