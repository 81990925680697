import {ColumnType} from "antd/es/table";
import {TableActions} from "../../../../util/table/components/TableActions";
import {getTextFilter} from "../../../../util/table/filters/TableTextFilter";
import {Event} from "../types";
import {DateRender} from "../../../Table/Renders";
import {getDateRangeFilter} from "../../../../util/table/filters/TableDateRangeFilter";

interface Props {
  onEdit: (id: string) => void
  canWrite: boolean
}

export const eventColumns = ({onEdit, canWrite}: Props) => {
  return [
    {
      title: '',
      dataIndex: '_id',
      key: 'action',
      width: 80,
      render: (_id: string) => <TableActions onEdit={canWrite ? () => onEdit(_id) : undefined} />
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      ...getTextFilter<Event>({name: 'name'})
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Fecha',
      dataIndex: 'from',
      key: 'from',
      sorter: true,
      render: (from, event) => {
        if(from === event.to){
          return DateRender(from)
        }
        return `${DateRender(from)} - ${DateRender(event.to)}`
      },
      ...getDateRangeFilter<Event>({name: 'from'})
    },
  ] satisfies ColumnType<Event>[]
}