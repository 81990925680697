import React from 'react';
import s from './Title.module.css';

const Title = ({size = 2, children, bold = true, className, containerClassName = ""}) => {
  className = className || containerClassName;
  containerClassName = containerClassName || className;

  let p = {
    className: `${s.container} ${bold ? 'font-bold' : ''} ${containerClassName}`
  };

  switch(size){
    case 1:return <h1 {...p} style={{fontSize: '1.9rem'}}>{children}</h1>
    case 2:return <h2 {...p} style={{fontSize: '1.7rem'}}>{children}</h2>
    default: return <h3 {...p} style={{fontSize: '1.5rem'}}>{children}</h3>
  }
};


export default Title;
