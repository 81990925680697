import React, {useMemo} from 'react';
import s from './ToSummaryItActivities.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import sn from "../../TONew/TONew.module.css";
import SmallActivity from "../../../Activity/Views/SmallActivity";
import Button from "../../../Inputs/Button_old";
import bases from "../../../../routes/bases";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import Title from "../../../LayoutComponents/Title";
import {ItActivity, ItPackage, OutletItineraryContext} from "../../../../types/itineraries";
import Activity from "../../../Activity/Activity";
import Bullet from "../../Itineraries/Bullet/Bullet";
import ToItAddBox from "../../ToItAddBox";
import {createPlacesForActivities} from "../../util";


interface ShowActivityProps {
  activity: ItActivity;
}

const ShowActivity = ({activity}: ShowActivityProps) => {
  return <Activity className="mr-5" to_load={{id: activity.activity_id}} variant="list" />
}


const ToSummaryItActivities = () => {
  const {t} = useTranslation();
  const {itinerary} = useOutletContext<OutletItineraryContext>();

  const placesActivitiesSummary = useMemo(() => {
    if (!Array.isArray(itinerary?.activities) || !Array.isArray(itinerary.places)) return [];
    return itinerary.activities.filter(activity => {
      if(!activity.activity_id)return false
      const place = itinerary.places!.find(p => p.id === activity.place_id);
      return !(!place || place.activity_blocked);
    }).map(activity => ({
      day: activity.day,
      place_id: activity.place_id,
      element: <ShowActivity  activity={activity} />
    }));
  }, [itinerary]);

  const places = useMemo(() => createPlacesForActivities(itinerary.places!), [itinerary]);


  return (
    <>
      {!places?.length && <div className="font-bold text-2xl">Primera necesitas agregar días en la pestaña de Itinerario</div>}
      {places?.map((place: any, index) => {
        let bullet = place.bullet || place.day;
        let title = place.title || place.address?.label;
        let elements = placesActivitiesSummary
          .filter((ph: any) => ph.place_id === place.id && (!ph.day || ph.day === place.day))
          .map(el => el.element);

        return <div key={index}>
          <div className="flex flex-nowrap items-center">
            <Bullet>{bullet}</Bullet>
            <div className="ml-5">{title}</div>
          </div>
          <div className="my-5 flex flex-wrap items-center">
            {elements}
          </div>
        </div>
      })}
    </>
  );
};


export default ToSummaryItActivities;
