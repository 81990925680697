import {MultiLangText} from "./itineraries";
import {Address} from "./places";
import config from "../config";
import {buildImagePath, buildStars} from "../helpers/hotels";

export type HotelTypes = 'local' | 'api';

export interface LocalHotel {
  _id: string;
  created_by:string;
  name: string;
  description?: MultiLangText;
  email?: string;
  images: string[];
  phone?: string;
  regime?: string[];
  stars: number;
  web?: string;
  facilities?: string[];
  address?: Address;
}

export interface ApiHotel {
  _id?: string;
  api: string;
  code: number;
  name: string;
  address?: {content: string};
  description?: {content: string} | string;
  facilities?: any;
  images?: {
    imageTypeCode: string;
    path: string;
    order: number;
    visualOrder: number;
  };
  coordinates?: {
    latitude: number;
    longitude: number;
  }
}
export type HotelType =  LocalHotel  | ApiHotel;


export interface GenericHotel {
  code?: number; // api
  id?: string; // local
  name: string;
  description?: MultiLangText | string;
  images: string[];
  stars: number;
  address?: Partial<Address>;
  type: HotelTypes;
}



export const transformToGenericHotel = (hotel: LocalHotel | ApiHotel): GenericHotel => {
  if((hotel as ApiHotel).api){
    hotel = hotel as ApiHotel;
    return {
      type: 'api',
      code: hotel.code,
      name: hotel.name,
      description: typeof hotel.description === 'string' ? hotel.description : hotel.description?.content,
      images: Array.isArray(hotel.images) ? hotel.images.map(image => buildImagePath(hotel, image.path)) : [],
      stars: buildStars(hotel),
      address: {
        label: hotel.address?.content,
        lat: hotel.coordinates?.longitude,
        lng: hotel.coordinates?.latitude
      }
    }
  }

  hotel = hotel as LocalHotel;

  return {
    type: 'local',
    id: hotel._id,
    name: hotel.name,
    description: hotel.description,
    images: Array.isArray(hotel.images) ? hotel.images.map(image => `${config.imagesURL}/${image}`) : [],
    stars: hotel.stars,
    address: hotel.address
  }
}