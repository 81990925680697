import React, {useState} from 'react';
import s from './LoginPage.module.css';
import {useForm} from 'react-hook-form';

import logo from '../../images/logo.png';

import useWindowSize from "../../hooks/useWindowSize/useWindowSize";

import InputWithIcon from "../InputWithIcon";
import useAuth from "../../providers/UserProvider/useAuth";
import {useTranslation} from "react-i18next";
import Button from "../Inputs/Button_old";
import ShowError from "../ShowError";

import {ReactComponent as LogoSVG} from "../../resources/svg/logo.svg";
import '../../css/logo_svg_animate.css'

const LoginPage = props => {
  const {t} = useTranslation();

  const {login} = useAuth();

  const [error, setError] = useState(false);

  const {height: windowHeight} = useWindowSize();
  const {register, handleSubmit, formState} = useForm();

  const {isSubmitting} = formState;


  const onSubmit = async (data) => {
    const {error} = await login(data.email, data.password);
    if(error)return setError(error);
    window.location.href = '/';
  }

  return (
    <div className={s.container} style={{height: windowHeight + 'px'}}>
      <div className={s.modalCenter}>
        <div className={s.logoContainer}>
          <LogoSVG/>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={s.inputContainer}>
              <InputWithIcon
                className={s.input}
                {...register('email')}
                spellCheck="false"
                placeholder={t('email')}
                autoComplete="email"
                icon="user" />

              <InputWithIcon
                className={s.input}
                {...register('password')}
                spellCheck="false"
                placeholder={t('password')}
                icon="pass"
                autoComplete="current-password"
                type="password" />
            </div>
            <p className={s.errors}>
              <ShowError error={error}/>
            </p>

            <div className={s.buttonContainer}>
              <Button
                type="submit"
                className={s.loadingButton}
                loading={isSubmitting}
              >
                {t('forms.login')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};


export default LoginPage;
