import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import s from './Table.module.css';
import Checkbox from "../Inputs/Checkbox";


import {ReactComponent as OrderDefaultSVG} from "../../resources/svg/order/default.svg";
import {ReactComponent as OrderUpSVG} from "../../resources/svg/order/up.svg";
import {ReactComponent as OrderDownSVG} from "../../resources/svg/order/down.svg";
import {CgEye} from 'react-icons/cg';
import {AiOutlineDelete, AiOutlineEdit} from 'react-icons/ai';
import Modal from "../Modal";
import {useForm} from "react-hook-form";

import {useLocalStorage} from "../../hooks/useLocalStorage";
import SmallModal from "../SmallModal";
import ContextMenuHeaderTable from "./ContextMenuHeaderTable";
import ChangeHiddenColumnsTable from "./ChangeHiddenColumnsTable";
import Loading from "../Loading";
import {ActionIcon, Pagination, Text} from "@mantine/core";

const {defaultTypeRenders} = require('./Renders');

const Error = ({error}) => <div>
	{error}
</div>

// change for SVG
const sortIcons = {
	default: <OrderDefaultSVG className={`${s.sortDefaultIcon} mr-2 inline-block transition duration-300 opacity-20`}/>,
	up: <OrderUpSVG/>,
	down: <OrderDownSVG/>
};


const DeleteActionButton = ({onDelete, data, onDeleted}) => {
	const [loading, setLoading] = useState(false);

	const _onDelete = async () => {
		setLoading(true);
		let success = await onDelete(data);
		setLoading(false);
		if (success) onDeleted();
	}

	return <ActionIcon loading={loading}>
		<AiOutlineDelete className="cursor-pointer" size={20} onClick={_onDelete}/>
	</ActionIcon>

}

const Table = ({
								 tableName,
								 refetch = false,

								 id = "id", columns, fetchData, fetchTotalData: _fetchTotalData,
								 groups, groupFilter,
								 rowsPerPage = 10,
								 NoContent,
								 renders = {},
								 sortF = {},
								 isSelectable,
								 isFilterable = true,
								 defaultHiddenColumns = [],
								 defaultRenderer = false,
								 actions = false,
								 // events
								 onSelect = () => 0,
								 onView = false,
								 onEdit = false,
								 onDelete = false,
								 hideHeaders = false,
								 filters,
								 ...props
							 }) => {
	const {t} = useTranslation();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const form = useForm();


	// data and finalData format
	// [{name = String, columns = []}]
	const [data, setData] = useState(false);
	const [finalData, setFinalData] = useState([]);


	const [order, setOrder] = useState({});
	const [selected, setSelected] = useState([]);
	//const [hiddenColumns, setHiddenColumns] = useLocalStorage(tableName + '_hiddenColumns', defaultHiddenColumns);
	const [hiddenColumns, setHiddenColumns] = useState(defaultHiddenColumns);
	const [totalRecords, setTotalRecords] = useState();
	const [page, setPage] = useState(1);

	const [totalData, setTotalData] = useState({});

	/*const [paginationData, setPaginationData] = useState({
		cursor: 0,
		totalRecords: 0
	});*/

	const [filterModalOpen, setFilterModalOpen] = useState(false);
	const [hiddenColumnsModalOpen, setHiddenColumnsModalOpen] = useState(false);

	useEffect(() => {
		// filter and order then setfinal data
		setFinalData(data);
	}, [data]);


	const loadAllData = async () => {
		await loadData();
		await loadTotalData();
	}

	useEffect(() => {
		loadAllData();
	}, []);

	useEffect(() => {
		if (refetch) loadAllData();
	}, [refetch]);

	useEffect(() => {
		loadData();
	}, [page]);
	useEffect(() => {
		loadAllData();
	}, [filters]);

	const getOnlyVisibleColumns = columns => columns.filter(column => !hiddenColumns.includes(column.accessor));


	const columnsSize = useMemo(() => {
		let c = 0;
		let rest = 100;
		let visibleColumns = getOnlyVisibleColumns(columns);
		for (let i = 0; i < visibleColumns.length; i++) {
			if (visibleColumns[i].size) {
				rest -= visibleColumns[i].size;
				continue;
			}
			c++;
		}

		return rest / c;
	}, [finalData, hiddenColumns]);


	const formatFilters = (filters) => {
		let r = {};
		Object.keys(filters).forEach(key => {
			if(filters[key])r[key] = filters[key];
		});
		return r;
	}

	const loadData = async (props) => {
		//let pagination = props?.paginationData || paginationData;

		let preparedFilters = formatFilters(filters);

		setLoading(true);
		let result;
		try {
			result = await fetchData({
				page: !page ? 0 : page - 1,
				filters: preparedFilters
			});
		}catch(error){
			return setError(error);
		}
		if (result.error) return setError(result.error);

		let group_data = [];
		if (groups && result.data && result.data.length) {
			groups.forEach(group => {
				group_data.push({
					name: group.name,
					columns: result.data.filter(groupFilter(group))
				});
			});
		} else {
			group_data.push({columns: result.data});
		}

		setData(group_data);
		setFinalData(group_data);
		setSelected([]);

		setPage(prev => {
			return prev * rowsPerPage > result.totalRecords ? Math.ceil(result.totalRecords / rowsPerPage) : prev
		});
		setTotalRecords(result.totalRecords || result.data?.length || 0);

		/*		setPaginationData(prev => ({
					cursor: prev.cursor > result.totalRecords ? result.totalRecords : prev.cursor,
					totalRecords: result.totalRecords || result.data?.length || 0
				}));*/
		setLoading(false);
	}

	const loadTotalData = async () => {
		if(!_fetchTotalData)return;
		setLoading(true);
		const result = await _fetchTotalData({
			filters: formatFilters(filters)
		});
		if (result.error) return setError(result.error);
		if(result.data)setTotalData(result.data);
		setLoading(false);
	}


	const haveContent = () => {
		if (!Array.isArray(data)) return false;
		for (let i = 0; i < data.length; i++) {
			if (data[i]?.columns?.length > 0) return true;
		}
		return false;
	}

	const getRowsLength = () => {
		if (!finalData) return 0;
		let l = 0;
		for (let i = 0; i < finalData.length; i++) {
			l += finalData[i].columns.length;
		}
		return l;
	}

	const isAllSelected = () => selected.length > 0 && getRowsLength() === selected.length;

	const toggleAllRows = () => {
		let selected = [];
		if (!isAllSelected()) finalData.forEach(({columns}) => columns.forEach(column => selected.push(column[id])));
		onSelect(selected);
		setSelected(selected);
	}

	const toggleRow = id => {
		setSelected(prev => {
			let selected = [...prev];
			const i = selected.indexOf(id);
			if (i === -1) {
				selected.push(id);
				onSelect(selected);
			} else {
				selected.splice(i, 1);
			}
			return selected;
		});
	}


	const calculateHActionsWidth = () => {
		let width = 28;
		if (onView) width += 20;
		//if (onEdit) width += 20;
		if (onDelete) width += 28;
		if (isSelectable) width += 10;
		return width;
	}

	const changeOrder = accessor => {
		setOrder(prev => {
			let n;
			switch (prev[accessor]?.pos) {
				case undefined:
					n = 'down';
					break;
				case 'down':
					n = 'up';
					break;
				default:
					n = undefined;
			}
			let next = {...prev};
			if (n === undefined) {
				let prev_order = next[accessor].order;
				delete next[accessor];
				let keys = Object.keys(next);

				if (prev_order < keys.length) {
					for (let i = 0; i < keys.length; i++) {
						if (next[keys[i]].order > prev_order) next[keys[i]].order--;
					}
				}
			} else {
				next[accessor] = {
					pos: n,
					order: prev[accessor]?.order ?? Object.keys(prev).length
				}
			}
			orderData(next);
			return next;
		})
	}

	// receive parameter in case order was not yet updated
	const orderData = (_order) => {
		let f_order = _order || order || {};

		setFinalData(data.map(group => {
			let columns = group.columns;
			const accessors = Object.keys(f_order);
			if (accessors.length > 0) {
				columns = columns.sort((a, b) => {
					for (let i = 0; i < accessors.length; i++) {
						for (let j = 0; j < accessors.length; j++) {
							let key = accessors[j];
							let accessor = f_order[key];
							if (accessor.order === i) {
								let el1 = a[key];
								let el2 = b[key];
								if (typeof sortF[key] === 'function') {
									let r = sortF[key](accessor.pos, el1, el2);
									if (r === -1 || r === 1) return r;
								} else {
									if ((el1 > el2 && accessor.pos === 'up') || (el1 < el2 && accessor.pos === 'down')) return 1;
									if ((el1 < el2 && accessor.pos === 'up') || (el1 > el2 && accessor.pos === 'down')) return -1;
								}
								break;
							}
						}
					}
					return 0;
				});
			}
			return {...group, columns};
		}));
	}

	if (error) return <Error error={error}/>
	if (loading || !Array.isArray(data)) return <Loading/>

	if (!haveContent()) return NoContent ? <NoContent/> : <div className={s.noContent}>{t('table.nothing found')}</div>

	return (
		<div className={`${props.border ? s.container : ''}`}>
			<div className={s.topContainer}>
				<div className={s.actions}>{actions && actions(selected, setSelected)}</div>
				{/*					<div className={s.table_actions}>
						{isFilterable && <div className="tableAction" onClick={() => setFilterModalOpen(true)}>
							<BsFilter style={{width: '20px', height: '20px'}}/>
							{t('table.filter')}
						</div>}
						{hiddenColumns.length > 0 &&
						<div className="tableAction ml-5" onClick={() => setHiddenColumnsModalOpen(true)}>
							{t('table.hidden columns')} ({hiddenColumns.length})
						</div>
						}
					</div>*/}
			</div>
			<div className={s.tableContainer} style={{width: props.tableWidth || '100%'}}>
				<div className={s.table}>
					{!hideHeaders && <div className={s.thead}>
						<div className={s.th}>
							<div className={s.actionsContainer} style={{width: calculateHActionsWidth()}}>

								{isSelectable && <div className={s.checkboxContainer}>
									<Checkbox checked={isAllSelected()} onChange={toggleAllRows}/>
								</div>}

							</div>
							<div className={s.tdContainer}>
								{getOnlyVisibleColumns(columns).map((column, i) => (
									<SmallModal
										containerStyle={{flexBasis: (column.size || columnsSize) + '%'}}
										containerClassName={s.td}
										rightClick={false} // cambiar a true mas tarde
										Trigger={() =>
											<div rel="trigger" className={s.td} key={i} onClick={() => changeOrder(column.accessor)}
											>
												{sortIcons[order[column.accessor]?.pos] || sortIcons.default}
												<div className={s.headerColumnContainer}>{column.icon && column.icon} {column.Header}</div>
											</div>
										}
										Element={({close}) => <ContextMenuHeaderTable close={close} onHidden={() => {
											setHiddenColumns(prev => [...prev, column.accessor]);
										}}/>}
									/>
								))}
							</div>
						</div>
					</div>}

					<div className={s.tbody}>
						{finalData.map((group, m) => <React.Fragment key={m}>
							{group.name && group.column.length > 0 && <div className={s.group_tr}>
								<p>{group.name}</p>
							</div>}

							{group.columns.map(data => {
								let checked = selected.indexOf(data[id]) !== -1;
								return (<React.Fragment key={data[id]}>
									<div className={`${s.tr} ${checked ? s.tr_checked : ''}`}>
										<div className={s.actionsContainer} style={{width: calculateHActionsWidth()}}>
											{isSelectable && <div className={s.checkboxContainer}>
												<Checkbox checked={checked} onChange={() => toggleRow(data[id])}/>
											</div>}
											{onView && <CgEye className="cursor-pointer" size={20} onClick={() => onView(data)}/>}
											{onEdit &&
											<ActionIcon variant="hover" color="orange" onClick={() => onEdit(data)}> <AiOutlineEdit
												className="cursor-pointer" size={20}/></ActionIcon>}
											{onDelete && <DeleteActionButton onDelete={onDelete} onDeleted={() => loadData()} data={data}/>}

										</div>
										<div className={s.tdContainer}>
											{getOnlyVisibleColumns(columns).map((column, j) => (
												<div className={s.td} key={j} style={{width: (columns.size || columnsSize) + '%'}}>
													{(() => {
														// choose renderer
														let r = renders[column.accessor];
														if (!r) r = defaultRenderer;
														if (!r) r = defaultTypeRenders[column.type];
														if (!r) r = () => data[column.accessor];
														return r(data[column.accessor], data, {t, accessor: column.accessor, column});
														//renders[column.accessor] ? renders[column.accessor](data[column.accessor], data) : defaultTypeRenders[column.type] ? defaultTypeRenders[column.type](data[column.accessor], data) : data[column.accessor]
													})()}
												</div>
											))}
										</div>
									</div>
								</React.Fragment>);
							})}

							{_fetchTotalData &&
							<div className={`${s.tr}`} style={{borderTop: '1px solid rgb(222, 226, 230)'}}>
								<div className={s.actionsContainer} style={{width: calculateHActionsWidth()}}><b>Total:</b> </div>
								<div className={s.tdContainer}>
									{getOnlyVisibleColumns(columns).map((column, j) => (
										<td className={s.td} key={j} style={{width: (columns.size || columnsSize) + '%'}}>
											{(() => {
												if(totalData[column.accessor] === undefined)return '';

												// choose renderer
												let r = renders[column.accessor];
												if (!r) r = defaultRenderer;
												if (!r) r = defaultTypeRenders[column.type];
												if (!r) r = () => totalData[column.accessor];
												return r(totalData[column.accessor], totalData, {t, accessor: column.accessor, column});
											})()}
										</td>
									))}
								</div>
							</div>}
						</React.Fragment>)}
					</div>
				</div>
			</div>

			<Text className="my-2">Total de filas: {totalRecords}</Text>
			<Pagination total={Math.ceil(totalRecords / rowsPerPage)} onChange={setPage} page={page}/>
			{/*{generatePages()}*/}


			{/*      {isFilterable &&
      <Modal
        isOpen={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
      >
        <FormProvider {...form}>
          <div className="p-5 pt-10">
            <FilterTable tableName={tableName} columns={columns}/>
          </div>
        </FormProvider>
      </Modal>
      }*/}

			<Modal
				isOpen={hiddenColumnsModalOpen}
				onClose={() => setHiddenColumnsModalOpen(false)}
			>
				<ChangeHiddenColumnsTable columns={columns} hiddenColumns={hiddenColumns}
																	setHiddenColumns={setHiddenColumns}/>
			</Modal>

		</div>
	)


}

export default Table;
