export const buildImagePath = (hotel, path) => {
  const baseURL = {
    'apitude': 'http://photos.hotelbeds.com/giata/bigger/'
  };
  if(baseURL[hotel.api])return `${baseURL[hotel.api]}${path}`;
  return '';
}

export const buildStars = hotel => {
  if(hotel.api === 'apitude'){
    return hotel.categoryCode ? hotel.categoryCode.charAt(0) * 1 : 0;
  }
  return 0;
}
