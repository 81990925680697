import {BillingType} from "../../../controllers/usePurchaseController/types";
import VerticalTable from "../../NewTable/VerticalTable";
import {AddIconRenderer, DocumentRender, EmailRender} from "../../Table/Renders";
import { AiOutlineMail, AiOutlinePhone} from "react-icons/ai";
import React from "react";

interface BillingDataProps {
  data: BillingType
}

const BillingData = ({data}: BillingDataProps) => {
  return <VerticalTable
    langGroup="show purchase.billing information data"
    data={data}
    valueRenders={{
      email: EmailRender,
      document: DocumentRender
    }}
    nameRenders={{
      phone: AddIconRenderer(<AiOutlinePhone size={16}/>),
      email: AddIconRenderer(<AiOutlineMail size={16}/>),
    }}
  />;
}

export default BillingData;