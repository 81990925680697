import React, {useEffect, useState, useMemo} from 'react';
import s from './TDClient.module.css';
import {Button, Select, Text, TextInput, Title} from "@mantine/core";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useForm} from "@mantine/form";
import PhoneInput from "../../PhoneInput";
import {DatePicker} from "@mantine/dates";
import SelectCountry from "../../SelectCountry";
import SelectLanguage from "../../Inputs/SelectLanguage";
import useTDClients from "../../../controllers/useTDClients";
import bases from "../../../routes/bases";
import {canTDWrite} from "../../Permissions/VisibleWithPermissions";


const DivingInfo = ({form}) => {
	const {t} = useTranslation();

	const levelList = useMemo(() => ([
		{label: 'Open Water', value: 'open water'},
		{label: 'Advanced', value: 'advanced'},
		{label: 'Especialidades', value: 'specialties'},
		{label: 'Profesional (Divemaster o Instructor)', value: 'professional'},
	]), []);

	return (
		<div>
			<Text className="mb-5" size="xl">{t('td.clients.diving info.title')}</Text>
			<div className="flex flex-nowrap w-full mt-5">
				<TextInput
					label={t('td.clients.diving info.agency id')}
					className={s.input}
					{...form.getInputProps('diving_info_agency_id')}
				/>
				<TextInput
					label={t('td.clients.diving info.diver level')}
					className={s.input}
					{...form.getInputProps('diving_info_organization')}
				/>
			</div>
			<div className="flex flex-nowrap w-full mt-5">
				<Select
					label={t('td.clients.diving info.certification level')}
					data={levelList}
					className={s.input}
					{...form.getInputProps('diving_info_certification_level')}
				/>
				<TextInput
					label={t('td.clients.diving info.other certifications')}
					className={s.input}
					{...form.getInputProps('diving_info_other_certifications')}
				/>
			</div>
		</div>
	)
}


const TDClient = ({createNew = false, client_id, onCreate, onCancel, className}) => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const params = useParams();
  const id = client_id || params.id

	const [submitLoading, setSubmitLoading] = useState(false);
	const {getClient, editClient, newClient} = useTDClients();
	const containerClassName = className || 'container m-auto pl-5 mb-10';

	const form = useForm({
		initialValues: {
			full_name: '',
			phone: '',
			emergency_number: '',
			email: '',
			birthday: null,
			nationality: '',
			language: '',
			diving_info_agency_id: '',
			diving_info_organization: '',
			diving_info_certification_level: '',
			diving_info_other_certifications: ''
		}
	});

	const loadClient = async () => {
		const {error, client} = await getClient({id});
		if (error) return alert(error);
		form.setValues(client);
		form.setFieldValue('birthday', new Date(client.birthday));
	}


	const onSubmit = async values => {
		console.log(values);
		let client_id;
		setSubmitLoading(true);
		if (createNew) {
			const {error, id} = await newClient();
			if (error) return alert(error);
			client_id = id;
		} else {
			client_id = id;
		}


		let client = {
			full_name: values.full_name,
			phone: values.phone,
			emergency_number: values.emergency_number,
			email: values.email,
			birthday: values.birthday?.toISOString(),
			nationality: values.nationality,
			language: values.language,
			diving_info_agency_id: values.diving_info_agency_id,
			diving_info_organization: values.diving_info_organization,
			diving_info_certification_level: values.diving_info_certification_level,
			diving_info_other_certifications: values.diving_info_other_certifications
		};


		const {error, success} = await editClient({id: client_id, client});
		setSubmitLoading(false);
		if (error) return alert(error);
		if (onCreate) return onCreate({...client, _id: client_id}); // change to client with id
		navigate(`/${bases.td.clients}`);
	}

	useEffect(() => {
		if (!createNew) loadClient();
	}, []);

  const canWrite = useMemo(() => canTDWrite(), [])

	return (
		<div className={containerClassName}>
			{!createNew && <Title className="mb-5">{t('td.clients.title')}</Title>}

			<div className="flex sm:flex-nowrap mt-10">
				<TextInput
					label={t('full name')}
					className={s.input}
					required
					{...form.getInputProps('full_name')}
				/>
				<TextInput
					label={t('email')}
					className={s.input}
					{...form.getInputProps('email')}
				/>
				<DatePicker
					label={t('birthday')}
					className={s.input}
					{...form.getInputProps('birthday')}
				/>
			</div>
			<div className="flex flex-nowrap w-full mt-5">
				<PhoneInput
					className={s.input}
					label={t('phone')}
					{...form.getInputProps('phone')}
				/>
				<PhoneInput
					className={s.input}
					label={t('emergency number')}
					{...form.getInputProps('emergency_number')}
				/>
			</div>

			<div className="flex sm:flex-nowrap mt-5">
				<SelectCountry
					label={t('nationality')}
					className={s.input}
					{...form.getInputProps('nationality')}
				/>
				<SelectLanguage
					label={t('td.clients.language')}
					className={s.input}
					{...form.getInputProps('language')}
				/>
			</div>

			<hr className="my-5"/>
			<DivingInfo form={form}/>

			<div className="mt-5">
				<Button color="red" className="mr-5" onClick={() => {
					if (onCancel) return onCancel();
					window.history.back();
				}}>{t('cancel')}</Button>
				<Button disabled={!canWrite} loading={submitLoading} onClick={form.onSubmit(onSubmit)}>{t('save')}</Button>
			</div>
		</div>
	);
};


export default TDClient;