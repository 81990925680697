import React, {useEffect, useState} from 'react';
import s from './SmallModal.module.css';

const SmallModal = ({Trigger, Element, containerStyle, containerClassName, rightClick = false}) => {
  const [visible, setVisible] = useState(false);

  const ref = React.useRef();

  const onClickOutside = e => {
    let container = ref.current;
    let target = e.target;

    if(!container)return;
    let trigger = container.querySelector('div[rel="trigger"]');
    let element = container.querySelector('div[rel="element"]');


    if(trigger.contains(target)){
      if(rightClick)e.preventDefault();
      return setVisible(prev => !prev);
    }
    if(!trigger.contains(target) && !element?.contains(target))setVisible(false);
  }

  const close = () => setVisible(false);

  useEffect(() => {
    if(rightClick)document.body.addEventListener('contextmenu', onClickOutside);
    document.body.addEventListener('click', onClickOutside);

    return () => {
      document.body.removeEventListener('click', onClickOutside);
      if(rightClick)document.body.removeEventListener('contextmenu', onClickOutside);
    }
  }, []);

  return (
    <div className={containerClassName ? containerClassName : 'relative'} style={containerStyle} ref={ref}>
      <Trigger close={close}/>
      {visible && <Element close={close}/>}
    </div>
  );
};


export default SmallModal;
