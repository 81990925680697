import {ColumnType} from "antd/es/table";
import {FilterDropdownProps} from "antd/es/table/interface";
import {Button, Select, Space} from "antd";
import {AiFillFilter} from "react-icons/ai";

export type TableOnFilter = (name: string, value: string) => void;

interface Props {
  name: string
  options: {value: string, label: string}[]
  onFilter?: TableOnFilter
}

export function getSelectFilter<DataType>({name, options, onFilter}: Props): ColumnType<DataType> {
  return ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, close}: FilterDropdownProps) => {
      return <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
        <div style={{marginBottom: 8}}>
          <Select
            style={{minWidth: 200}}
            showSearch
            options={options}
            value={selectedKeys}
            onChange={(value) => {
              setSelectedKeys([value as unknown as string]);
              onFilter && onFilter(name, value as unknown as string)
              confirm();
            }}
          />
        </div>
        <Space>
          <Button
            type="link"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              onFilter && onFilter(name, '')
              confirm();
              close();
            }}
          >
            Borrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    },
    filterIcon: (filtered: boolean) => <AiFillFilter size={15}/>,
    onFilter: (dropValue, record) => true
  })
}