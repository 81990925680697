import React, {useEffect, useState} from 'react';
import s from './AdminEdit.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import useAdminController from "../../../controllers/useAdminController/useAdminController";
import Loading from "../../Loading";
import {useForm} from "@mantine/form";
import {Badge, Divider , Select, TextInput} from "@mantine/core";
import Button from "../../Inputs/Button";
import {toastError} from "../../../helpers/ErrorManager";
import {toast} from "react-toastify";
import bases from "../../../routes/bases";
import {useSelectOptions} from "../../../hooks/useSelectOptions";
import {useClipboard} from "@mantine/hooks";
import ShowWithPermissions from "../../Permissions/ShowWithPermissions";
import useMenu from "../../../providers/MenuProvider/useMenu";
import AdminEditTourOperator from "../Forms/AdminTOForm";
import AdminEditAdministrator from "../Forms/AdminForm";

const AdminEdit = ({admin_id: admin_id_param, isNew = false,administratorsPage = false}) => {
	const {t} = useTranslation();
	let {id: admin_id} = useParams();
	const {getAdmin, newAdmin, editAdmin, requestPasswordChangeLink, requestTOSetupLink} = useAdminController();

	const navigate = useNavigate();
	const clipboard = useClipboard()
	const {setChosen} = useMenu();

	const {adminTypeOptions, adminSuspendedOptions} = useSelectOptions();


	const [loading, setLoading] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(true);


	const form = useForm({
		initialValues: {
			type: 'to',
			suspended: '0'
		}
	});


	let id = admin_id_param || admin_id;

	useEffect(() => {
		let r = 'administrators';
		if(administratorsPage){
			r = 'profile';
		}else if(!isNew && !admin_id){ // me
			r = 'my profile';
		}
		setChosen(r)
	}, [admin_id]);



	const loadAdmin = async () => {
		if (id) {
			setLoading(true);
			const {error, admin} = await getAdmin({id});
			console.log(admin);
			setLoading(false);
			if (error) return toastError(error, t);
			if (!admin) {
				toastError(t('errors.admin not found'), t);
				return navigate('/' + bases.config_admin);
			}

			form.setValues(admin);
			if (admin.password_change_link) form.setFieldValue('passwordChangeLink', `${window.location.origin}/${bases.admin_password_change}?id=${id}&h=${admin.password_change_link}`);
		} else {
			form.reset();
		}
	}

	useEffect(() => {
		if (id) loadAdmin();
	}, []);


	const onSubmit = async admin => {
		console.log(admin);
		setSubmitLoading(true);
		let result;

		if (id) {
			result = await editAdmin({id, admin});
		} else {
			result = await newAdmin({admin});
		}

		setSubmitLoading(false);

		if (result.error) return toastError(result.error, t);
		toast.success(t('saved'));
		navigate('/' + bases.config_admin);
	}

	const onPasswordLinkRequest = async () => {
		let {error, link} = await requestPasswordChangeLink({id});
		if (error) return toastError(error, t);
		if (link) {
			toast.success(t('success'));
			link = `${window.location.origin}/${bases.admin_password_change}?id=${id}&h=${link}`;
			form.setFieldValue('passwordChangeLink', link);
		}
	}
	const onTOSetupLinkRequest = async () => {
		let {error, link} = await requestTOSetupLink({id});
		if (error) return toastError(error, t);
		if (link) {
			toast.success(t('success'));
			link = `${window.location.origin}/${bases.tour_operator_setup}?id=${id}&h=${link}`;
			form.setFieldValue('TOSetupLink', link);
		}
	}


	if (loading) return <Loading/>

	return (
		<div className="ml-10">
			<div className="container m-auto">
				<form onSubmit={form.onSubmit(onSubmit)}>
					<ShowWithPermissions allow={['admin']}>
					<Select className="mb-5" data={adminTypeOptions} defaultValue={'to'}
									label={t('admin data.type')} {...form.getInputProps('type')}/>
					{form.values.type === 'admin' && <AdminEditAdministrator isNew={!id} form={form}/>}
					</ShowWithPermissions>

					{form.values.type === 'to' && <AdminEditTourOperator form={form}/>}

					<ShowWithPermissions allow={['admin']}>
						<Divider className="my-5"/>
						<Select data={adminSuspendedOptions} defaultValue={'0'}
										label={t('suspended')} {...form.getInputProps('suspended')}/>
					</ShowWithPermissions>

					<div className="flex mt-10" style={{width: '500px'}}>
						<Button className="mr-10" color="red" type="submit" loadButton={submitLoading}>{t('save')}</Button>
{/*						<Button type="button" onClick={async () => loadAdmin({id})}>{t('reset data')}</Button>*/}
					</div>
				</form>

				{id && <ShowWithPermissions allow={['admin']}>
					<hr className="my-5"/>
					<div className="mt-5 w-full flex flex-nowrap">
						<Button style={{minWidth: '350px'}} className="mr-5" variant="default" type="button"
										onClick={onPasswordLinkRequest}>{t('admin data.regenerate password change link')}</Button>

						<TextInput rightSectionWidth={100} rightSection={
							<Badge className="cursor-pointer" onClick={() => clipboard.copy(form.values.passwordChangeLink)}
										 color={clipboard.copied ? 'blue' : 'green'}>
								{clipboard.copied ? t('copied') : t('copy')}
							</Badge>
						} className="w-full" disabled={true} {...form.getInputProps('passwordChangeLink')}/>
					</div>

					{form.values.type === 'to' && <div className="mt-5 w-full flex flex-nowrap">
						<Button style={{minWidth: '350px'}} className="mr-5" variant="default" type="button"
										onClick={onTOSetupLinkRequest}>{t('admin data.to.setup link')}</Button>

						<TextInput rightSectionWidth={100} rightSection={
							<Badge className="cursor-pointer" onClick={() => clipboard.copy(form.values.TOSetupLink)}
										 color={clipboard.copied ? 'blue' : 'green'}>
								{clipboard.copied ? t('copied') : t('copy')}
							</Badge>
						} className="w-full" disabled={true} {...form.getInputProps('TOSetupLink')}/>
					</div>}

				</ShowWithPermissions>}
			</div>
		</div>
	);
};


export default AdminEdit;
