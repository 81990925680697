import React from 'react';
import s from './ShowStartYourTrip.module.css';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import useAPIDataProvider from "../../../controllers/useAPIDataProvider";
import VerticalTable from "../../Table/VerticalTable";
import {DateTimeRender, EmailRender, HTMLRenderer} from "../../Table/Renders";

const ShowStartYourTrip = ({grid, syt_id: syt_id_param, showTitle = true, tableProps = {}}) => {
  const {t} = useTranslation();
  let {id: syt_id} = useParams();
  const {getStartYourTrip} = useAPIDataProvider();

  return (
    <VerticalTable
      langGroup={'start your trip data'}
      fetchData={async () => await getStartYourTrip({id: syt_id_param || syt_id})}
      valueRenders={{
        email: EmailRender,
        created_at: DateTimeRender,
        message: HTMLRenderer
      }}
    />
  );
};


export default ShowStartYourTrip;
