import {Link} from "react-router-dom";
import dayjs from "dayjs";

export function LinkFormat({url, label, target}: {url: string, label?: string, target?: string}){
  return <Link className="link" target={target} to={url}>{label}</Link>
}
export function EmailFormat({email}: {email?: string}) {
  if(!email)return <></>
  return <a href={`mailto:${email}`}>{email}</a>
}

export function PhoneFormat({phone}: {phone?: string}) {
  if(!phone)return <></>
  return <a href={`tel:${phone}`}>{phone}</a>
}
export function WebsiteFormat({website}: {website?: string}) {
  if(!website)return <></>
  const href = website.startsWith('http') ? website : `https://${website}`
  return <a href={href} target="_blank" rel="noreferrer">{website}</a>
}
export function dateFormat(date: Date | string){
  return dayjs(date).format('DD/MM/YYYY')
}

export function ListRender({list, value}: {list: {label: string, value: string}[], value?: string}){
  if(!value)return <></>
  const item = list.find(i => i.value === value)
  return <>{item?.label ?? ''}</>
}

export function percentageFormat(percent?: number){
  return !percent ? '' : percent + '%'
}
