import s from './HeaderTitle.module.css';


interface HeaderTitleProps {
  children: string;
}

const HeaderTitle = ({children}: HeaderTitleProps) => {
  return <div className={s.container}>
    <div className={s.circle}/>
    <div className={s.title}>{children}</div>
  </div>
}

export default HeaderTitle;