
export const sellersTypes = ['internal', 'external'] as const;
export type SellersType = typeof sellersTypes[number];

export const sellersTypesOptions = [
  {label: 'Interno', value: 'internal'},
  {label: 'Externo', value: 'external'}
]


export interface Seller {
  _id: string
  name: string
  type: SellersType
  createdAt: Date
  createdBy: string
  updatedAt?: Date
  updatedBy?: string
  deletedAt?: Date
  deletedBy?: string
}

export interface SellerFormValues {
  name: string
  type: SellersType
}