import {useTranslation} from "react-i18next";
import {useState} from "react";
import {Select} from "@mantine/core";


const TDPointOfSell = (props) => {
	const {t} = useTranslation();
	const [options, setOptions] = useState([
		{label: "Web Trippy", value: 'web'},
		{label: "Google Ads", value: 'google_ads'},
		{label: "Instagram/Facebook", value: 'instagram_facebook'},
		{label: "Venta Externa", value: 'external'},
    {label: "Proveedores", value: 'providers'},
		{label: "Referencia (otro cliente, amigo, familiar...)", value: 'reference'},
    {label: "Cliente Trippy ya existente", value: 'tt_client'},
    {label: "SSI", value: 'ssi'},
    {label: "Walk in", value: 'walk_in'}
	]);

	return <Select
		data={options}
		label={t('td.point of sell.label')}
		creatable={true}
		getCreateLabel={query => `+ ${t('td.point of sell.add')} "${query}"`}
		onCreate={(value) => setOptions(prev => [...prev, {label: value, value}])}
		{...props}
	/>


}

export default TDPointOfSell;