import {
  AiOutlineAliwangwang, AiOutlineApartment,
  AiOutlineContacts,
  AiOutlineGitlab, AiOutlinePlusCircle,
  AiOutlineShop, AiOutlineTool,
  AiOutlineUsergroupAdd
} from "react-icons/ai";

import bases from "./bases";
import {MdLocalActivity} from "react-icons/md";
import {FaHotel, FaMoneyCheckAlt} from "react-icons/fa";
import {RiGalleryFill} from "react-icons/ri";
import {BiWorld} from "react-icons/bi";
import {GrUserAdmin} from "react-icons/gr";
import {FiMap, FiMapPin} from "react-icons/fi";


// ide autocomplete
export const groups = {
  sales: {name: 'sales'},
  clients: {name:'clients'},
  forms: {name:'forms'},
  tour_operator: {name: 'tour_operator'},
  configuration: {name:'configuration'},
  apis: {name: 'apis'},
  explore: {name: 'explore'},
  accounting: {name: 'accounting'},
  others: {name: 'others'}
}

export const allElements = [
  {
    key: 'last_sales',
    name: 'last sales',
    icon: <AiOutlineShop/>,
    group: groups.sales,
    path: bases.purchase,
  },
  // {
  //   key: 'pending_payments',
  //   name: 'pending payments',
  //   icon: <AiOutlineShop/>,
  //   group: groups.sales,
  //   path: bases.purchase
  // },
  // {
  //   key: 'completed_payments',
  //   name: 'completed payments',
  //   icon: <AiOutlineShop/>,
  //   group: groups.sales,
  //   path: bases.purchase
  // },
  {
    key: 'last_registers',
    name: 'last registers',
    icon: <AiOutlineUsergroupAdd/>,
    group: groups.clients,
    path: bases.clients
  },

  // Tour operators
  {
    key: 'tour_operators',
    name: 'tour operators',
    icon: <AiOutlineGitlab/>,
    group: groups.tour_operator,
    path: bases.tour_operator,
    disabled: true
  },
  {
    key: 'tour_itineraries',
    name: 'tour itineraries',
    icon: <AiOutlineApartment/>,
    group: groups.tour_operator,
    path: bases.tour_operator_itineraries,
    disabled: false
  },
  {
    key: 'tour_operators_crete_itinerary',
    name: 'create itinerary',
    icon: <AiOutlinePlusCircle/>,
    group: groups.tour_operator,
    path: bases.tour_operator_new_itinerary,
    disabled: false,
  },
  {
    key: 'my saved hotels',
    name: 'my saved hotels',
    icon: <FaHotel/>,
    group: groups.tour_operator,
    disabled: false,
    path: bases.saved_hotel
  },
  {
    key: 'my saved activities',
    name: 'my saved activities',
    icon: <MdLocalActivity/>,
    group: groups.tour_operator,
    disabled: false,
    path: bases.saved_activity
  },
  {
    key: 'gallery',
    name: 'gallery',
    icon: <RiGalleryFill/>,
    group: groups.tour_operator,
    disabled: false,
    path: bases.gallery
  },
  {
    key: 'itineraries to validate',
    name: 'itineraries to validate',
    icon: <AiOutlineApartment/>,
    group: groups.tour_operator,
    disabled: true,
    path: bases.itineraries_to_validate
  },
  {
    key: 'tour operator budgets',
    name: 'tour operator budgets',
    icon: <FaMoneyCheckAlt/>,
    group: groups.tour_operator,
    disabled: true,
    path: bases.tour_operator_budgets
  },

  // clients
  {
    key: 'chat',
    name: 'chat',
    icon: <AiOutlineAliwangwang/>,
    group: groups.clients,
    disabled: true,
    path: bases.chat
  },
  {
    key: 'contact_us',
    name: 'contact us',
    icon: <AiOutlineContacts/>,
    group: groups.forms,
    disabled: true,
    path: bases.contact_us
  },
  {
    key: 'start_your_trip',
    name: 'start your trip',
    icon: <BiWorld/>,
    group: groups.forms,
    disabled: true,
    path: bases.start_your_trip
  },
  // configuration
  {
    key: 'admins',
    name: 'administrators',
    icon: <GrUserAdmin/>,
    group: groups.configuration,
    disabled: true,
    path: bases.config_admin
  },
  {
    key: 'configuration',
    name: 'page',
    icon: <AiOutlineTool/>,
    group: groups.configuration,
    disabled: true,
    path: bases.configuration
  },
  {
    key: 'configuration_api_hotels',
    name: 'configuration api hotels',
    icon: <FaHotel/>,
    group: groups.configuration,
    disabled: true,
    path: bases.config_api_hotels
  },
  // tour operator
/*  {
    key: 'api_tour_operators',
    name: 'tour operators',
    icon: <AiOutlineGitlab/>,
    group: groups.apis,
    path: bases.tour_operator
  },
  {
    key: 'api_tour_itineraries',
    name: 'tour itineraries',
    icon: <AiOutlineApartment/>,
    group: groups.apis,
    path: bases.tour_operator_itineraries
  },
  {
    key: 'api_tour_operators_crete_itinerary',
    name: 'create itinerary',
    icon: <AiOutlinePlusCircle/>,
    group: groups.apis,
    path: bases.tour_operator_new_itinerary
  },*/

  // explore
  {
    key: 'points of interest',
    name: 'points of interest',
    icon: <FiMapPin/>,
    group: groups.explore,
    disabled: true,
    path: ''
  },
  {
    key: 'new point of interest',
    name: 'create point of interest',
    icon: <AiOutlinePlusCircle/>,
    group: groups.explore,
    disabled: true,
    path: ''
  },
  {
    key: 'country information',
    name: 'country information',
    icon: <FiMap/>,
    group: groups.explore,
    disabled: true,
    path: ''
  },

  // accounting
  {
    key: 'accounting by reservation',
    name: 'accounting.by reservation',
    icon: <FaMoneyCheckAlt/>,
    group: groups.accounting,
    disabled: true,
    path: ''
  },
  {
    key: 'accounting by ot',
    name: 'accounting.by ot',
    icon: <FaMoneyCheckAlt/>,
    group: groups.accounting,
    disabled: true,
    path: ''
  },
  {
    key: 'accounting by api',
    name: 'accounting.by api',
    icon: <FaMoneyCheckAlt/>,
    group: groups.accounting,
    disabled: true,
    path: ''
  },
  {
    key: 'accounting diary book',
    name: 'accounting.diary book',
    icon: <FaMoneyCheckAlt/>,
    group: groups.accounting,
    disabled: true,
    path: ''
  },
  {
    key: 'accounting ledger',
    name: 'accounting.ledger',
    icon: <FaMoneyCheckAlt/>,
    group: groups.accounting,
    disabled: true,
    path: ''
  },
  {
    key: 'accounting pyg',
    name: 'accounting.pyg',
    icon: <FaMoneyCheckAlt/>,
    group: groups.accounting,
    disabled: true,
    path: ''
  }

];
