import {toast} from "react-toastify";
import {ErrorType} from "../types/errors";

export const toastError = (error: any, t: any) => {
  let msg = error.message ?? error;
  if(typeof msg === 'string')msg = "errors." + msg.toLowerCase();
  if(typeof msg === 'object'){
    try{
      msg = JSON.stringify(error);
    }catch(_){}
  }
  toast.error(t(msg));
}

export const manageError = (error: ErrorType, t: any) => {
  console.log(error.message);
  if(error.message)toastError(error.message, t);
}

