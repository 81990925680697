import useAuth from "../../providers/UserProvider/useAuth";


const useTDContactUs = props => {
	const {secQuery} = useAuth();
	const base = '/api/admin/td/contact_us';

	const getContactUsData = async ({page = 0, filters = {},signal} = {}) => {
		const {error, data} = await secQuery(`${base}_data?page=${page}`, {filters}, 'POST', {signal});
		return {error, data: data?.data, totalRecords: data?.totalRecords};
	}

	const getContactUs = async ({signal, id} = {}) => {
		const {error, data} = await secQuery(`${base}/${id}`, null, 'GET', {signal});
		return {error, data: data?.contact_us};
	}

	return {getContactUs, getContactUsData};
}

export default useTDContactUs;