import React, {useEffect, useMemo, useState} from 'react';
import s from './TDProducts.module.css';
import {useTranslation} from "react-i18next";
import {Button, Title} from "@mantine/core";
import {useTDProducts} from "../../../controllers/useTDProducts/useTDProducts";
import {useNavigate} from "react-router-dom";
import bases from "../../../routes/bases";
import Table from "../../Table";
import {canTDWrite} from "../../Permissions/VisibleWithPermissions";

const TDProducts = props => {
	const {t} = useTranslation();

	const navigate = useNavigate();
	const [loadingButton, setLoadingButton] = useState(false);

	const {getProducts, newProduct} = useTDProducts();

	const onNewProduct = async () => {
		setLoadingButton(true);
		const {error, id} = await newProduct();
		setLoadingButton(false);

		if(error)return alert(error);
		navigate(`/${bases.td.products}/${id}`);
	}

	const levelTypes = useMemo(() => [
		{value: '1', label: t('td.product.levels.first')},
		{value: '2', label: t('td.product.levels.second')},
		{value: '3', label: t('td.product.levels.third')}
	], []);
	const certificationType = useMemo(() => [
		{value: 'scuba diving', label: t('td.product.certification types.scuba diving')}
	], []);


  const canWrite = useMemo(() => canTDWrite(), [])

  return (
		<div className="container m-auto">
			<div className="mb-5">
				<Title order={2} className="float-left">{t('td.products.title')}</Title>
        {canWrite && <Button className="float-right" onClick={onNewProduct} loading={loadingButton}>{t('td.products.create new')}</Button>}
			</div>
			<Table
				defaultFilters={{
					group: 'course'
				}}
				tableName="td_products"
				id="_id"
				columns={[
					{accessor: '_id', Header: t('id'), type: 'string'},
					{accessor: 'name', Header: t('td.product.name'), type: 'string', filter: true},
					{accessor: 'level', Header: t('td.product.level'), type: 'string'},
					{accessor: 'certificationType', Header: t('td.product.certification type'), type: 'string'},
				]}
				defaultHiddenColumns={['_id']}
				fetchData={async ({page, filters}) => {
					const {error, products, totalRecords} = await getProducts({page, filters});
					return {data: products ?? [], totalRecords};
				}}
				renders={{
					level: (_, row) => levelTypes.find(v => v.value === _)?.label,
					certificationType: (_, row) => certificationType.find(v => v.value === _)?.label
				}}
				onEdit={canWrite ? row => navigate(`/${bases.td.products}/${row._id}`) : undefined}
			/>
		</div>
	);
};


export default TDProducts;