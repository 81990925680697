import React, {useEffect, useState} from 'react';
import s from './MySavedActivity.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import bases from "../../../../routes/bases";
import Button from "../../../Inputs/Button";
import useActivity from "../../../../controllers/useActivity/useActivity";
import {toastError} from "../../../../helpers/ErrorManager";
import {Textarea, TextInput} from "@mantine/core";
import {AiOutlineMail, AiOutlinePhone} from "react-icons/ai";
import {GoLocation} from "react-icons/go";
import {formatSearchBarResults} from "../../../../helpers/util";
import {useHotel} from "../../../../controllers/useHotel";
import SelectAddressOld from "../../../Inputs/SelectAddressOld";
import PhoneInput from "../../../PhoneInput";
import GalleryPickUpImages from "../../../Gallery/GalleryPickUpImages";
import ToItineraryCategories from "../../../TourOperator/ToItineraryCategories";
import MultiLangInputV2 from "../../../Inputs/MultiLangInputV2";

const MySavedActivity = ({id: _id, onSave, onCancel, grid = false}) => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const {id: param_id} = useParams();
	const {searchHotelsBar} = useHotel();
	const [lang, setLang] = useState('es');

	const {getSavedActivity, editSavedActivity, newSavedActivity} = useActivity();
	const [loading, setLoading] = useState(true);


	const form = useForm();
	const {register, unregister, setValue, getValues} = form;

	let activity_id = _id || param_id;

	const loadActivity = async () => {
    if(activity_id === 'new'){
      setLoading(false)
      return
    }
		setLoading(true);
		const {error, activity} = await getSavedActivity(activity_id);
		setLoading(false);
		if (error) return toastError(error, t);


		if(activity.address){
			form.setValue('address', {
				value: activity.address.label,
				raw: activity.address,
				icon: <GoLocation/>
			});
		}

		['categories','name', 'description', 'optional', 'price', "include", "not_include", "email", "web", 'instructions', 'schedule', 'notes','images'].forEach(key => {
			if (activity[key]) setValue(key, activity[key]);
		});

/*		let phone = activity.phone;
		if(phone){
			for(let i = 10; i > phone.length; i--){
				phone = '0' + phone;
			}
			console.log(phone);
			setValue('phone', phone);
		}*/

		if (Array.isArray(activity.available)) {
			for (let i = 0; i < 1; i++) {
				activity.available.forEach((availability, i) => {
					const name = `available.${availability.id}`;
					setValue(`${name}.id`, availability.id);
					setValue(`${name}.place`, availability.place.address.label);
					setValue(`${name}.day`, availability.day);
				})
			}
		}
	}

	useEffect(() => {
		loadActivity();
	}, []);


	const availability = form.watch(`available`);
	const activityImagesForm = form.watch('images');
	const optional_activity = form.watch(`optional`);
	const timeTypeOptions = [
		{value: 'day_hour', label: t('to.activity.day and hour')},
		{value: 'day', label: t('to.activity.day')}
	];

	const removeDay = (id) => {
		unregister(`available.${id}`);
	}

	const addDay = () => {
		let id = 0;
		let n = 0;
		if (Array.isArray(availability)) {
			id = availability.reduce((c, v) => Math.max(c, v.id), 0) + 1;
			n = availability.filter(a => a).length;
		}

		const name = `available.${id}`;
		setValue(`${name}.id`, id);
		/*		if (itinerary) {
					setValue(`${name}.place`, itinerary.places[0].address.label);
					setValue(`${name}.day`, itinerary.places[0].start);
				}*/
	}

	const onActivitySave = async () => {
		let data = getValues();

		console.log(data);

		data.address = data?.address?.raw;


		if (Array.isArray(data.available)) {
			data.available = data.available.filter(a => a).map(a => {
				let r = a;
				/*        if (a.start.type === 'day') r.start = {type: 'day', day: a.start.day};
								if (a.end.type === 'day') r.end = {type: 'day', day: a.end.day}*/
				//r.place = itinerary.places.find(place => place.address.label === r.place);


				return r;
			});
		}

		const result = await (activity_id === 'new' ? newSavedActivity(data) : editSavedActivity(activity_id, data));
		if (result.error) return toastError(result.error, t);
    console.log(result)
		if (onSave) return onSave(result.id ?? activity_id);
		navigate(`/${bases.saved_activity}`);
	}

	/*	const placesOptions = useMemo(() => buildPlacesOptions(itinerary, t, {mix: true}), [itinerary]);
		const createDaysOptions = place => {
			if (!itinerary) return [];
			//let p = place ? itinerary.places.find(p => p.address.label === place) : itinerary.places[0];
			let p = place ? placesOptions.find(p => p.value === place) : placesOptions[0];
			let r = [];
			if (p) {
				for (let i = p.start; i <= p.end; i++) {
					r.push({label: i, value: i});
				}
			}
			return r;
		}*/

	const searchAddress = async str => {
		if (str.length <= 1) return [];
		const {error, results} = await searchHotelsBar({q: str});
		if(error)return [];
		let r = formatSearchBarResults(results);
		return r;
	}

	return (
		<div className="w-full p-10">
			<FormProvider {...form}>
				<div className="mb-5">
					<TextInput
						label={t('to.activity.name')}
						{...register(`name`)}
						className={s.input}
					/>
					<MultiLangInputV2
						lang={lang}
						setLang={setLang}
						Input={Textarea}
						label={t('to.activity.description')}
						name="description"
						minRows={5}
						autoSize
					/>
				</div>

				<div className="w-full flex">
					<PhoneInput
						label={t('to.activity.phone')}
						{...register('phone')}
						icon={<AiOutlinePhone  size={16}/>}
						className={`${s.input} mr-10`}
						style={{height: 36}}
					/>
					<TextInput
						label={t('to.activity.email')}
						{...register('email')}
						className={s.input}
						icon={<AiOutlineMail size={16}/>}
					/>
				</div>
				<div className="w-full flex">
					<TextInput
						label={t('to.activity.web')}
						{...register('web')}
						className={`${s.input} mr-10`}
					/>
					<SelectAddressOld
						id={`to_activity_address`}
						name={`address`}
						icon={<GoLocation size={16} />}
						searchFunction={searchAddress}
						label={t('to.activity.address')}
						className="w-full"
						form={form}
					/>
				</div>

				<div className="w-full flex">
					<MultiLangInputV2
						Input={Textarea}
						lang={lang}
						setLang={setLang}
						label={t('to.activity.what includes')}
						name="include"
						minRows={5}
						autoSize
						className="w-full mr-10"
					/>
					<MultiLangInputV2
						Input={Textarea}
						lang={lang}
						setLang={setLang}
						label={t('to.activity.what not includes')}
						name="not_include"
						minRows={5}
						autoSize
						className="w-full"
					/>
				</div>
				<div className="w-full flex">
					<MultiLangInputV2
						Input={Textarea}
						lang={lang}
						setLang={setLang}
						label={t('to.activity.instructions')}
						name="instructions"
						minRows={5}
						autoSize
						className="w-full mr-10"
					/>
					<MultiLangInputV2
						Input={Textarea}
						lang={lang}
						setLang={setLang}
						label={t('to.activity.notes')}
						name="notes"
						minRows={5}
						autoSize
						className="w-full"
					/>
				</div>
				<MultiLangInputV2
					Input={Textarea}
					lang={lang}
					setLang={setLang}
					label={t('to.activity.schedule')}
					name="schedule"
					minRows={5}
					autoSize
				/>
				<ToItineraryCategories name="categories" className="mt-5"/>
				<GalleryPickUpImages
					onDone={t => form.setValue('images', t)}
					label={t('to.activity.pictures')}
					defaultValue={activityImagesForm}
				/>

				<div className="mt-10 flex flex-nowrap">
					<Button onClick={onActivitySave} className={s.button}>{t('save')}</Button>
					{onCancel && <Button onClick={() => onCancel()} className={s.button}>{t('cancel')}</Button>}
				</div>
			</FormProvider>
		</div>
	);
};


export default MySavedActivity;