import useAuth from "../../providers/UserProvider/useAuth";


export const useTDClients = () => {
	const {secQuery} = useAuth();
	const base = '/api/admin/td/client';


	const getClients = async ({page= 0, filters = {}, signal} = {}) => {
		const {error, data} = await secQuery(`${base}s?page=${page}`, {filters}, 'POST', {signal});
		return {error, clients: data?.clients, totalRecords: data?.totalRecords};
	}

	const newClient = async ({signal} = {}) => {
		const {error, data} = await secQuery(base, {}, 'POST', {signal});
		return {error, id: data?.id};
	}
	const getClient = async ({id}) => {
		const {error, data} = await secQuery(`${base}/${id}`, null, 'GET');
		return {error, client: data?.client};
	}
	const editClient = async ({id, client}) => {
		const {error, data} = await secQuery(`${base}/${id}`, {client}, 'PUT');
		return {error, success: data?.success};
	}
	const deleteClient = async ({id}) => {
		const {error, data} = await secQuery(`${base}/${id}`, null, 'DELETE');
		return {error, success: data?.success};
	}

	const getTotalData = async ({filters}) => {
		const {error, data} = await secQuery(`${base}/total_data`, ({filters}), 'POST');
		return {error, data: data};
	}

	return {
		getClients,
		newClient,
		getClient,
		editClient,
		deleteClient,
		getTotalData
	}

}