import {ItineraryCategories, MultiLangText} from "./itineraries";
import {Address} from "./places";
import config from "../config";

export interface ActivityType {
  _id: string;
  name: MultiLangText;
  description: MultiLangText;
  price: number;
  included?: MultiLangText;
  not_include?: MultiLangText;
  categories: ItineraryCategories[];

  email?: string;
  web?: string;
  phone?: string;

  instructions?: MultiLangText;
  schedule?: MultiLangText;
  notes?: MultiLangText;
  images: string[];
  address?: Address;

  stars?: number; // not implemented yet

  optional?: boolean;
}

export interface GenericActivity extends ActivityType {}

export const transformToGenericActivity = (activity: ActivityType): GenericActivity => {
  return {
    ...activity,
    images: Array.isArray(activity.images) ? activity.images.map(image => `${config.imagesURL}/${image}`) : [],
  }
}