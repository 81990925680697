//import {useState, useEffect} from 'react';
import config from '../../config';

const sleep = async (t) => new Promise(r => setTimeout(r,t))

export const encodeURIParams = (params) => {
  return Object.keys(params)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
    .join('&');
}


const allowedStatus = [200];

// ESTA APENAS SE USA
// HAY QUE ELIMINARLA
export const query = async (query, data, method = 'POST', {headers = {}, signal} = {}) => {
  method = method.toUpperCase();
  let options = {
    method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      ...headers
    },
    mode: 'cors',
    signal
  };
  if(method !== 'GET')options.body = JSON.stringify(data);

  let result, raw, error = false;
  for(let i =0;i<3;i++) {
    try {
      error = false;
      raw = await fetch(`${config.serverURL}${query}${(method === 'GET') ? '?' + encodeURIParams(data) : ''}`, options);
      result = await raw.json();
      if (raw.ok || allowedStatus.includes(raw.status)) break;
      error = result;
      if(raw.status === 500)await sleep(1000);
      else break;
    }catch(err){
      error = err;
    }
  }
  if(error){
    console.log(error);

    if(error.name === 'AbortError')return {aborted: true};
    if(error.statusCode === 401)return {error: 'Unauthorized'};
    if(error.statusCode === 403){
      return {error: 'Forbidden', logout: true, statusCode: 403};
    }
    return {error};
  }

  if(!allowedStatus.includes(raw.status))return result;
  return {data: result, statusCode: raw.status, ok: raw.ok, raw};
}

export default query;
