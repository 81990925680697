import useAuth from "../../providers/UserProvider/useAuth";
import {ControllersTableProps, ControllerTableResult} from "../../types/controllers";
import {OrderedType} from "../usePurchaseController/types";

export enum OrderedOnTableStatus {
  'PENDING' = 'pending',
  'COMPLETED' = 'completed',
  'FAILED' = 'failed',
}

export interface OrderedOnTable {
  _id: string;
  user?: {
    _id: string;
    name: string;
    surname?: string;
  };
  tracking_number: string;
  full_name: string;
  email: string;
  phone: string;
  from: Date;
  to: Date;

  created_at: Date;
  updated_at: Date;
  completed: boolean;

  total_price: number;
  total_persons: number;
  refunded: boolean;
  total_hotels: number;
  total_activities: number;
  status: OrderedOnTableStatus;
}


export const usePayments = () => {
  const {secQuery} = useAuth();


  interface GetOrderedProps extends ControllersTableProps{
    filters?: any;
  }
  const getOrdered = async ({filters, page, resultsPerPage}: GetOrderedProps = {page: 0, resultsPerPage: 10}) => {
    const {error, data} = await secQuery('/api/admin/ordered', {filters, page, resultsPerPage}, 'POST');
    if(error){
      throw new Error(error);
    }
    return data as ControllerTableResult<OrderedOnTable>;
  }

  const getOrderedById = async (id: string) => {
    const {error, data} = await secQuery(`/api/admin/ordered/${id}`,null, 'GET');
    if(error)throw new Error(error);
    return data as OrderedType;
  }


  return {getOrdered, getOrderedById}
}