import React, {useEffect, useState} from 'react';
import s from './SideBar.module.css';

import whiteLogo from '../../images/LogoWhite.png';
import blackLogo from '../../images/logo.png';

import SideBarItems from "../SideBarItems";

import useTheme from "../../providers/ThemeProvider/useTheme";
import classNames from 'classnames';
import {useConfigStorage} from "../../hooks/useConfigStorage";


const cx = classNames.bind(s);

const SideBar = props => {
  const {setCSSVar, theme} = useTheme();
  const [compact, setCompact] = useConfigStorage("sidebar_compact", true);
  const toggleCompact = () => setCompact(active => !active);

  useEffect(() => {
    setCSSVar('side-bar-width', compact ? '60px' : '190px');
  }, [compact]);

  const arrowStyle = cx({
    [s.arrowStyle]: true,
    [s.arrowCompact]: compact
  });

  const logoContainerStyle = React.useMemo(() => ({
    marginTop: '20px',
    marginLeft: compact ? '15px' : '10px',
  }), [compact]);




  return (
    <div className={s.sideBarContainer} /*onMouseEnter={() => setCompact(false)} onMouseLeave={() => setCompact(true)}*/>
{/*      <div className={s.compactContainer} onClick={toggleCompact}>
        <BsChevronDoubleLeft size={compact ? 14 : 18} className={arrowStyle}/>
      </div>*/}
      <div className={s.logoContainer} style={logoContainerStyle}>
        <img
          src={theme === 'dark' ? whiteLogo : blackLogo}
          style={{height: '28.55px'}}
          width={compact ? '25px' : '50px'}
          height={compact ? '50px' : '140px'}
          alt="Logo"
          draggable={false}
        />
      </div>

      <div className={s.menu}>
        <SideBarItems compact={compact}/>
      </div>
    </div>
  );
};


export default SideBar;
