import React, {useEffect} from 'react';
import s from './TourOperatorAnswerBudget.module.css';
import {Textarea, TextInput} from "@mantine/core";
import {useTranslation} from "react-i18next";
import {useForm} from "@mantine/form";
import MoneyInput from "../Inputs/MoneyInput";

const TourOperatorAnswerBudget = ({budget}) => {
	const {t} = useTranslation();

	const form = useForm({
		initialValues: {}
	});

	const {register, setFieldValue, values} = form;

	const totalPerson = budget.distribution.reduce((d, t) => d.adults + d.children + t, 0);

	const changePricePerPerson = v => {
		setFieldValue('price_per_person', v);
		setFieldValue('total_price', v * totalPerson);
	}
	const changeTotalPrice = v => {
		setFieldValue('total_price', v);
		setFieldValue('price_per_person', v / totalPerson);
	}

	return (
		<div>
			<TextInput
				label={t('tour operator data budget.budget name')}
				{...register('name')}
			/>
			<Textarea
				label={t('tour operator data budget.additional information for the client')}
				{...register('info')}
			/>
			<div className="w-full flex flex-nowrap">
				<MoneyInput
					label={t('tour operator data budget.price per person')}
					onChange={v => changePricePerPerson(v)}
					hideControls
				/>
				<MoneyInput
					label={t('tour operator data budget.total price')}
					onChange={v => changeTotalPrice(v)}
					hideControls
				/>
			</div>

		</div>
	);
};


export default TourOperatorAnswerBudget;