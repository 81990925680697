import {BsHouseDoor, BsGear, BsCalendar, BsBarChart, BsPeople, BsWallet} from "react-icons/bs";
import OverviewPage from "../components/OverviewPage";
import CalendarPage from "../components/Calendar/CalendarPage";
import AnalyticsPage from "../components/Analytics/AnalyticsPage";
import SettingsPage from "../components/Settings/SettingsPage";
import NotFoundPage from "../components/Pages/NotFoundPage";
import Chat from "../components/Social/Chat";

let routes = [
  {
    key: 1,
    path: 'home',
    name: 'home',
    image: BsHouseDoor(),
    element: OverviewPage
  },
  // {
  //   key: 2,
  //   path: '',
  //   name: 'calendar',
  //   image: BsCalendar(),
  //   element: CalendarPage
  // },
  // {
  //   key: 3,
  //   path: '',
  //   name: 'analytics',
  //   image: BsBarChart(),
  //   element: AnalyticsPage
  // },
  // {
  //   key: 4,
  //   path: 'social',
  //   name: 'social',
  //   image: BsPeople(),
  //   element: Chat
  // },
  // {
  //   key: 5,
  //   path: '',
  //   name: 'payments',
  //   image: BsWallet(),
  //   element: NotFoundPage
  // },
  {
    key: 2,
    path: 'settings',
    name: 'settings',
    image: BsGear(),
    element: SettingsPage
  }
];

export default routes

export function findParentRoute(location) {
  if(location.pathname.includes('settings')){
    //return {index: 5, hidden: false, route: routes[5]}
    return {index: 1, hidden: false, route: routes[1]}
  }else{
    return {index: 0, hidden: false, route: routes[0]}
  }
  for (let i = 0; i < routes.length; i++) {
    if (!i || routes[i].path === location.pathname.substring(1, routes[i].path.length + 1)) {
      return {index: i, route: routes[i], hidden: routes[i].hidden};
    }
  }
  return {};
}
