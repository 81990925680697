import React, {useEffect, useState} from 'react';
import s from './SmallHotel.module.css';
import {useTranslation} from "react-i18next";
import {ReactComponent as StarSVG} from "../../../resources/svg/star.svg";
import {cutText} from "../../../helpers/util";
import Loading from "../../Loading";
import {useHotel} from "../../../controllers/useHotel";
import ShowError from "../../ShowError";
import {buildImagePath, buildStars} from "../../../helpers/hotels";
import {openHotel} from "../../../helpers/externalPage";

import {langRender} from "../../../helpers/i18n";
import Image from "../../Image";
import sn from "../../TourOperator/TONew/TONew.module.css";
import Button from "../../TourOperator/Itineraries/Inputs/Button/Button";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";


interface SmallHotelProps {
  loading?: boolean;
  hotelToLoad?: { code: number, api: string };
  code?: string;
  errorMessage?: string;
  api?: string;
  name?: string;
  description?: string;
  picture?: string;
  stars?: number;
  address?: { content: string };
  stay_time?: string | number;
  distance?: number;
  compact?: boolean;
  canOpenHotel?: boolean;
  onDelete?: () => void;
  onEdit?: () => void;
}

const SmallHotel = ({
                      loading,
                      hotelToLoad,
                      code,
                      errorMessage,
                      api,
                      name,
                      description,
                      picture,
                      stars,
                      address,
                      stay_time,
                      distance,
                      compact: _compact = false,
                      canOpenHotel = true,
                      onEdit, onDelete,
                      ...rest
                    }: SmallHotelProps) => {
  const {t} = useTranslation();
  const [hotel, setHotel] = useState<object>();
  const [error, setError] = useState();
  const [compact, setCompact] = useState(_compact);

  const {searchHotelByCode} = useHotel();
  const height = 190;


  const loadHotel = async () => {
    if (!hotelToLoad) return;
    const {error, hotel} = await searchHotelByCode({
      code: hotelToLoad.code,
      api: hotelToLoad.api
    });
    if (error || !hotel) return setError(error || 'hotel not found');
    if (hotel) setHotel(hotel);
  }

  useEffect(() => {
    loadHotel();
  }, [hotelToLoad]);


  if (error) return <ShowError error={error}/>
  if (hotelToLoad && !hotel) return <Loading/>
  if (hotelToLoad && hotel) {
    return <SmallHotel
      {...hotel}
      compact={_compact}
      // @ts-ignore
      picture={buildImagePath(hotel, hotel.images[0]?.path)}
      stars={buildStars(hotel)}
    />
  }


  let compactClass = compact ? s.compact : s.notCompact;

  return <div onMouseEnter={() => _compact && setCompact(false)} onMouseLeave={() => _compact && setCompact(!compact)}
              className={s.hotel} onClick={() => {
    if (canOpenHotel && api && api !== 'local') openHotel({api, code})
  }}>

    {errorMessage ? <div>{errorMessage}</div> :

      (loading ? <Loading/> : <>

          <div className={s.verBar}/>
          <div className={s.leftContainer}>
            <div><Image src={picture} width={200} alt={t('small hotel.picture alt')}/></div>
            <div className="p-2">
              <div className="font-bold text-2xl mb-1">{langRender(name)}</div>
              {!!stay_time &&
								<div>{t('small hotel.stay time')}: <b>{stay_time} {t('night', {count: stay_time as number})}</b></div>}

              {!!stars && <div className="flex">{[...Array(stars)].map(n => <StarSVG className={s.star}/>)}</div>}

            </div>
          </div>

          <div className={s.rightContainer}>
            <div className={s.description}>{cutText(langRender(description), 200)}</div>
            <div className={s.buttons}>
              {onDelete && <Button color="white" className={s.deleteButton} leftIcon={<AiOutlineDelete size={18}/>}
																	 variant="filled"
																	 onClick={onDelete}/>}

              {onEdit &&
								<Button color="green" variant="outline" className={s.editButton} rightIcon={<AiOutlineEdit size={18}/>}
												onClick={onEdit}>
                  {t('to.edit')}
								</Button>
              }
            </div>
          </div>
        </>
      )}
  </div>
}


export default SmallHotel;
