import useAuth from "../../providers/UserProvider/useAuth";
export const useCalendarController = () => {
  const {secQuery} = useAuth();

  const postEvent = async event => {
    const {data, error} = await secQuery(`/admin/calendar_event`, event, 'POST');
    return {data, error};
  }
  const putEvent = async (id, event) => {
    const {data, error} = await secQuery(`/admin/calendar_event/${id}`, event, 'PUT');
    return {data, error};
  }
  const deleteEvent = async id => {
    const {error} = await secQuery(`/admin/calendar_event/${id}`, {}, 'DELETE');
    return {error};
  }
  const getRangeDateEvents = async (start, end) => {
    const {data, error} = await secQuery(`/admin/calendar_event/rangeDate`, {start, end}, 'GET');
    return {data, error};
  }

  return {postEvent, putEvent, deleteEvent, getRangeDateEvents};
};

export default useCalendarController;

