import {useNavigate} from "react-router-dom";
import {useCallback, useState} from "react";
import {TrainerFormValues} from "./types";
import {postData} from "../../../util/req";
import {TDTrainerForm} from "./components/TDTrainerForm";
import {Card} from "antd";

export default function TDTrainersCreate(){
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async (values: TrainerFormValues) => {
    setLoading(true);
    const response = await postData(`/td/trainer`, values)
    setLoading(false);
    if(response.id){
      navigate(`/td/trainers`)
    }
  }, []);

  return (
    <Card style={{maxWidth: 1000}} className="mx-auto my-5" title="Nuevo instructor">
      <TDTrainerForm onFinish={handleSubmit} loading={loading}/>
    </Card>

  );
}