import {ColumnType} from "antd/es/table";
import {FilterDropdownProps} from "antd/es/table/interface";
import {Button, DatePicker, Space} from "antd";
import dayjs, {Dayjs} from "dayjs";
import {AiFillFilter} from "react-icons/ai";

const {RangePicker} = DatePicker;

type DateValue = [Dayjs, Dayjs] | [Dayjs] | []
export type TableOnFilter = (name: string, value: DateValue) => void;

interface Props {
  name: string
  onFilter?: TableOnFilter
}


const createValue = (selectedKeys: any[]) => {
  const r = [];
  if (selectedKeys[0]) r.push(dayjs(selectedKeys[0]));
  if (selectedKeys[1]) r.push(dayjs(selectedKeys[1]));
  return r;
}

export function getDateRangeFilter<DataType>({name, onFilter}: Props): ColumnType<DataType> {
  return ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, close}: FilterDropdownProps) => {
      return <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
        <div style={{marginBottom: 8}}>
          <RangePicker
            // @ts-ignore
            value={createValue(selectedKeys)}
            onChange={(value) => {
              // @ts-ignore
              setSelectedKeys(value);
              onFilter && onFilter(name, value as [Dayjs, Dayjs])
              confirm();
            }}
          /></div>
        <Space>
          <Button
            type="link"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              onFilter && onFilter(name, [])
              confirm();
              close();
            }}
          >
            Borrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    },
    filterIcon: (filtered: boolean) => {
      return <AiFillFilter size={15}/>
    },
    onFilter: (dropValue, record) => true
  })
}