import React from 'react';
import s from './AdminToForm.module.css';
import {TextInput} from "@mantine/core";
import {useTranslation} from "react-i18next";
import SelectCountry from "../../../SelectCountry";

const AdminTOForm = ({form}) => {
	const {t} = useTranslation();
	return (
		<div>
			<div className="w-full flex flex-nowrap">
				<TextInput
					required
					label={t('name')}
					{...form.getInputProps('name')}
					className="w-full mr-5"
				/>
				<TextInput
					required
					label={t('web')}
					{...form.getInputProps('web')}
					className="w-full"
				/>
			</div>
			<TextInput
				required
				label={t('email')}
				{...form.getInputProps('email')}
				className="w-full mr-5"
			/>

			<SelectCountry
				required
				multiple={true}
				label={t('admin data.to.allowed countries')}
				{...form.getInputProps('allowed_countries')}
			/>



		</div>
	);
};


export default AdminTOForm;