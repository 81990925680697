import {ResponseError} from "./types";
import {message} from "antd";

export const handleError = (error: ResponseError) => {
  message.open({
    type: 'error',
    content: error.error.message
  });
}

export const handleRequestError = async (request: any) => {
  let error: ResponseError | undefined = undefined
  let data: any
  try {
    const response = await request
    if (response.error) {
      error = response
    } else {
      data = response
    }
  } catch (err: any) {
    let message = err?.message

    message = message === 'Failed to fetch' ? 'failedToFetch' : 'unknown'

    error = {error: {message}}
  }

  if (error) {
    handleError(error);
    return false
  }
  return data
}

