import React, {useEffect, useState} from "react";

import {TDTrainersPaymentTable} from "./TDTrainersPaymentTable";
import {getData} from "../../../../util/req";
import {Trainer} from "../../TDTrainers/types";
import PlaneLoader from "../../../PlaneLoader";
import {useTDProducts} from "../../../../controllers/useTDProducts/useTDProducts";

export function TDTrainersPayment(){
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [trainers, setTrainers] = useState<Trainer[]>([]);
  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState<any[]>([]);
  const {getAllProducts} = useTDProducts();


  const loadProducts = async () => {
    const {error, products} = await getAllProducts();
    if (error) return alert(error);
    setProducts(products.filter((a: any) => a.name));
  }

  const loadTrainers = async () => {
    const result = await getData('/td/trainer_all')
    setTrainers(result.data);
  }

  const loadAll = async () => {
    setLoading(true)
    await loadTrainers()
    await loadProducts()
    setLoading(false)
  }

  useEffect(() => {
    loadAll()
  }, []);

  if(loading)return <PlaneLoader/>


  return <>
    <div>
      <TDTrainersPaymentTable products={products} trainers={trainers} selectedRowKeys={selectedRowKeys} onSelectionChange={keys => setSelectedRowKeys(keys)}/>
    </div>
  </>
}
