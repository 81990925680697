import React, {useEffect} from 'react';
import s from './ToPackages.module.css';
import sn from '../TONew/TONew.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {useForm, FormProvider} from "react-hook-form";
import useTourOperatorItinerary from "../../../controllers/useTourOperatorItinerary";
import {manageError} from "../../../helpers/ErrorManager";
import Footer from "../Components/Footer/Footer";
import HeaderTitle from "../Components/HeaderTitle/HeaderTitle";
import {OutletItineraryContext} from "../../../types/itineraries";
import bases from "../../../routes/bases";
import ListPackage from "../Packages/ListPackage/ListPackage";
import Button from "../Itineraries/Inputs/Button/Button";
import {GrAddCircle} from "react-icons/gr";

const ToPackages = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const form = useForm();
  const {id} = useParams();

  const {editItinerary, newPackage, deletePackage} = useTourOperatorItinerary();

  const {setValue, getValues} = form;
  const {itinerary, setStep, setItinerary, isItineraryBlocked} = useOutletContext<OutletItineraryContext>();

  const packages = itinerary.packages;

  useEffect(() => {
    setStep('packages');
  }, []);

  const onSubmit = () => {
  }
  /*
    const onSubmit = async () => {
      let formData = getValues();
      console.log(formData);

      let hotelPrices = {};
      for (let key in formData.prices) {
        if (!Array.isArray(formData.prices[key])) continue;
        hotelPrices[key] = formData.prices[key].filter(a => a);
      }


      let data = {
        price_type: formData.price_type,
        features: formData.features,
        hotelPrices,
        activityPrices: formData.activities
      };


      const {error, success} = await editItinerary({id, itinerary: data});
      if (error || !success) return toastError(error, t);

      setItinerary(prev => ({...prev, ...data}));
      toast.success(t('to.itinerary.package.saved'));
    }*/


  const onNewPackage = async () => {
    const {error, id: package_id, itinerary} = await newPackage({itinerary_id: id!});
    if (error || !package_id) return manageError(error, t);

    setItinerary(itinerary);
    navigate(`/${bases.tour_operator_itineraries}/${id}/package/${package_id}`);
  }


  const onDeletePackage = async (package_id: string) => {
    const {error, itinerary} = await deletePackage({itinerary_id: id!, package_id});
    if (error || !itinerary) return manageError(error, t);
    setItinerary(itinerary);
  }


  return (
    <div className="container m-auto">
      <HeaderTitle>{t('to.header.packages')}</HeaderTitle>

      <FormProvider {...form}>
        {packages && <div className="flex flex-wrap">

          {packages.map(_package => <ListPackage
            key={_package._id}
            _package={_package}
            onDelete={async () => await onDeletePackage(_package._id)}
            onEdit={async () => navigate(`/${bases.tour_operator_itineraries}/${id}/package/${_package._id}`)}
          />)}

				</div>}
        {!packages && <div className="text-2xl semibold">{t('to.itinerary.package.no packages found')}</div>}
        <div className="mt-10">

          <Button disabled={isItineraryBlocked} color="yellow" variant="filled" shadow_border={true}
                  rightIcon={<GrAddCircle className={s.add_circle_stroke} size={20}/>}
                  onClick={onNewPackage}>{t('to.itinerary.package.add new package')}</Button>
        </div>
        <Footer onSave={onSubmit}/>
      </FormProvider>
    </div>
  );
};


export default ToPackages;
