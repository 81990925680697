import React, {Suspense} from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import './helpers/i18n';

import AllProviders from "./providers";
import AuthContext from "./providers/UserProvider/AuthContext";
import {configure} from 'react-hotkeys';
import PlaneLoader from "./components/PlaneLoader";
import 'react-dates/initialize';
import HomePage from "./components/HomePage";
import {Flip, ToastContainer} from "react-toastify";

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';



import useServerController from "./controllers/useServerController";
import {BrowserRouter} from "react-router-dom";
import SelectionProvider from "./providers/SelectionProvider/SelectionProvider";
import NonAuthRoutes from "./components/NonAuthRoutes";
import dayjs from "dayjs";
import 'dayjs/locale/es';


configure({
  ignoreTags: []
});


const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone') // dependent on utc plugin

dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.tz.setDefault("UTC")


function App() {
  const {isActive} = useServerController();
  return (
    <BrowserRouter /*basename="admin"*/>
      <Suspense fallback={<PlaneLoader/>}>

        <AllProviders>
          <AuthContext.Consumer>
            {({isAuthenticated, getAdmin}) =>
              <SelectionProvider>

                {(!isAuthenticated() ? <NonAuthRoutes/> : <HomePage/>)}
                <ToastContainer
                  position="bottom-right"
                  pauseOnHover={true}
                  closeOnClick={true}
                  draggable={false}
                  delay={3000}
                  transition={Flip}
                />
              </SelectionProvider>

            }

          </AuthContext.Consumer>
        </AllProviders>
      </Suspense>

    </BrowserRouter>
  );
}

export default App;
