import {
	 AiOutlineApartment,
	 AiOutlinePlusCircle,
	 AiOutlineTool,
} from "react-icons/ai";
import bases from "./bases";
import {MdLocalActivity} from "react-icons/md";
import {RiGalleryFill} from "react-icons/ri";
import {FaHotel} from "react-icons/fa";
import {FiMap, FiMapPin} from "react-icons/fi";
import {GrUserAdmin} from "react-icons/gr";


// ide autocomplete
export const groups = {
	activities: {name: 'activities'},
	configuration: {name: 'configuration'},
	explore: {name: 'explore'},
}

export const allElements = [
	{
		key: 'tour_itineraries',
		name: 'tour itineraries',
		icon: <AiOutlineApartment/>,
		group: groups.activities,
		path: bases.tour_operator_itineraries
	},
	{
		key: 'tour_operators_crete_itinerary',
		name: 'create itinerary',
		icon: <AiOutlinePlusCircle/>,
		group: groups.activities,
		path: bases.tour_operator_new_itinerary
	},
	{
		key: 'my saved hotels',
		name: 'my saved hotels',
		icon: <FaHotel/>,
		group: groups.activities,
		path: bases.saved_hotel
	},
	{
		key: 'my saved activities',
		name: 'my saved activities',
		icon: <MdLocalActivity/>,
		group: groups.activities,
		path: bases.saved_activity
	},
	{
		key: 'gallery',
		name: 'gallery',
		icon: <RiGalleryFill/>,
		group: groups.activities,
		path: bases.gallery
	},


	// explore
	{
		key: 'points of interest',
		name: 'points of interest',
		icon: <FiMapPin/>,
		group: groups.explore,
		path: ''
	},
	{
		key: 'new point of interest',
		name: 'create point of interest',
		icon: <AiOutlinePlusCircle/>,
		group: groups.explore,
		path: ''
	},
	{
		key: 'country information',
		name: 'country information',
		icon: <FiMap/>,
		group: groups.explore,
		path: ''
	},
	// configuration
	{
		key: 'my profile',
		name: 'my profile',
		icon: <GrUserAdmin/>,
		group: groups.configuration,
		path: bases.admin + '/me'
	},
/*	{
		key: 'accounts',
		name: 'accounts',
		icon: <MdSupervisorAccount/>,
		group: groups.configuration,
		path: bases.tour_operator_account
	},*/
	{
		key: 'configuration',
		name: 'page',
		icon: <AiOutlineTool/>,
		group: groups.configuration,
		path: bases.configuration
	}
];
