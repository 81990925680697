import {createContext, useContext, useEffect, useState} from "react";
import {Exchange} from "./types";
import useAuth from "../UserProvider/useAuth";
import {handleRequestError} from "../../util/errorManager/errorManager";

export const CurrencyContext = createContext({})

interface CurrencyProviderProps {
  children: React.ReactNode
}

export function CurrencyProvider({children}: CurrencyProviderProps) {
  const {secQuery} = useAuth();
  const [exchanges, setExchanges] = useState<Exchange[]>([]);

  const baseCurrency = 'EUR'

  const loadExchange = async () => {
    const {data} = await handleRequestError(secQuery('/api/currency/exchanges', undefined, 'GET'))
    setExchanges(data?.exchanges || [])
  }

  useEffect(() => {
    loadExchange()
  }, [])

  useEffect(() => {
    console.log(convertTo(100, 'USD', 'MXN'))
  }, [exchanges]);

  const convertTo = (value: number, from: string, to: string) => {
    const fromExchange = exchanges.find(a => a.code === from);
    const toExchange = exchanges.find(a => a.code === to);
    const baseExchange = exchanges.find(a => a.code === baseCurrency);

    if (!fromExchange || !toExchange || !baseExchange) return 0;

    const toBase = value / fromExchange.value;
    return toBase * toExchange.value;
  }


  return <CurrencyContext.Provider value={{exchanges, convertTo}}>
    {children}
  </CurrencyContext.Provider>
}

export const useCurrencyProvider = () => ({...useContext(CurrencyContext)});
