import React from 'react';
import s from './TourOperatorRequestedBudgets.module.css';
import {useTranslation} from "react-i18next";
import {useTourOperator} from "../../../controllers/useTourOperator/useTourOperator";
import Table from "../../Table";
import {useNavigate} from "react-router-dom";
import bases from "../../../routes/bases";

const TourOperatorRequestedBudgets = props => {
	const {t} = useTranslation();
	const navigate = useNavigate();

	const {getRequestedBudgets} = useTourOperator();

	const loadData = async ({pages}) => {
		const {error, budgets, totalRecords} = await getRequestedBudgets({pages});
		console.log(budgets);
		return {error, data: budgets, totalRecords};
	}

	return (
		<div>
			<div className="showTitle">{t('tour operator data budget.table title')}</div>
			<Table
				tableName="tourOperatorRequestedBudgets"
				id="_id"
				fetchData={async ({pages}) => await loadData({pages})}
				columns={[
					{accessor: 'email', Header: t('tour operator data budget.email')},
					{accessor: 'whatsapp', Header: t('tour operator data budget.whatsapp')},
					{accessor: 'origin', Header: t('tour operator data budget.origin')},
					{accessor: 'created_at', Header: t('tour operator data budget.created_at'), type: 'date'},
				]}
				renders={{
					origin: v => v?.label
				}}
				onView={row => navigate(`/${bases.tour_operator_budgets}/${row._id}`)}
			/>
		</div>

	);
};


export default TourOperatorRequestedBudgets;