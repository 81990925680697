import {useMemo} from "react";
import Table from "./NewTable";
import {useTranslation} from "react-i18next";
import {TableProps as AntTableProps} from "antd/es/table/InternalTable";

interface VerticalTableProps<T> extends Omit<AntTableProps<T>, 'dataSource' | 'columns'> {
  data: T;
  langGroup?: string;
  valueRenders?: Record<keyof T, any>;
  nameRenders?: Record<keyof T, any>;
  dataChildren?: Record<keyof T, any>;
}

const VerticalTable = ({langGroup, valueRenders = {}, data, nameRenders = {}, ...props}: VerticalTableProps<any>) => {
  const {t} = useTranslation();
  const dataSource = useMemo(() => {
    let r: { name: string, key: string, value: any }[] = [];
    for (let key in data) {
      const name = langGroup ? t(`${langGroup}.${key}`) : key
      r.push({
        key,
        name: nameRenders[key] ? nameRenders[key](name, data) : name,
        value: valueRenders[key] ? valueRenders[key](data[key], data) : data[key]
      });
    }
    return r;
  }, [langGroup, t, valueRenders, data]);

  return <Table
    data={dataSource}
    columns={[
      {
        dataIndex: 'name',
        title: t('name'),
      },
      {
        dataIndex: 'value',
        title: t('value'),
      }
    ]}
    pagination={false}
    {...props}
  />
}

export default VerticalTable;