import {InputProps} from "@mantine/core";
import {Textarea as MantineInput} from "@mantine/core";
import Input from "../Input/Input";



const Textarea = (props: InputProps<'TextInput'>) => {
  return <Input
    InputType={MantineInput}
    {...props}
  />






  /*  return <div className={`w-full ${props.className}`}>
      {props.label && <div className={s.label}>{props.label}</div>}
      <div className={s.container}>
      {props.leftSection && <div className={s.leftSection}>{props.leftSection}</div>}
      <input type="text" className={s.input}/>
      {props.rightSection && <div className={s.rightSection}>{props.rightSection}</div>}
      </div>

    </div>*/
}

export default Textarea;