import React, {useEffect, useState} from 'react';
import s from './ToItineraryValidation.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useOutletContext} from "react-router-dom";
import Button from "../../Inputs/Button";
import ToItineraryValidationChatGroups from "./ToItineraryValidationChatGroups";
import useTourOperatorItinerary from "../../../controllers/useTourOperatorItinerary";
import {toastError} from "../../../helpers/ErrorManager";
import {toast} from "react-toastify";
import {itinerary_validation_status} from "../../../helpers/tourOperators";

const ShowValidationMessage = ({status, onClick}) => {
	const {t} = useTranslation();

	let msg, buttonMsg;

	switch (status){
		case itinerary_validation_status.pending:
			msg = t('to.itinerary.validation data.messages.waiting validation');
			buttonMsg = t('to.itinerary.validation data.cancel validation');
		break;
			case itinerary_validation_status.approved:
			msg = t('to.itinerary.validation data.messages.active');
			break;
		case itinerary_validation_status.rejected:
			msg = t('to.itinerary.validation data.messages.rejected');
			buttonMsg = t('to.itinerary.validation data.send again to validation');
		break;
		default:
			msg = t('to.itinerary.validation data.messages.ready to send');
			buttonMsg = t('to.itinerary.validation data.send to validation');
	}

	return <div className={s.messageStatus}>
		<div className="mb-5">{msg}</div>
		{buttonMsg && <Button onClick={onClick}>{buttonMsg}</Button>}
	</div>

}


const ToItineraryValidation = props => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const {itinerary, setStep, setItinerary} = useOutletContext();

	const {sendToValidation} = useTourOperatorItinerary();

	useEffect(() => {
		setStep('validation');
	}, []);


	const onValidateClick = async () => {
		let want_validation = itinerary.validationStatus !== itinerary_validation_status.pending;

		const {error, status} = await sendToValidation({
			itinerary_id: itinerary._id,
			want_validation
		});
		if (error) return toastError(error, t);
		setItinerary(prev => ({
			...prev,
			validationStatus: want_validation ? itinerary_validation_status.pending : itinerary_validation_status.cancelled
		}));
		if(want_validation){
			toast.success(t('to.itinerary.validation data.thank you for your submission'));
		}else{
			toast.success(t('to.itinerary.validation data.your itinerary validation has been canceled'));
		}
	}



	return (
		<div>
			<ShowValidationMessage onClick={onValidateClick} status={itinerary.validationStatus}/>
			<hr className="my-10"/>
			<ToItineraryValidationChatGroups />
		</div>
	);
};


export default ToItineraryValidation;