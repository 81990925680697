import s from './Activity.module.css';
import {ActivityType, transformToGenericActivity} from "../../types/activity";
import VerticalActivity from "./Views/VerticalActivity/VerticalActivity";
import ListActivity from "./Views/ListActivity/ListActivity";
import {useTranslation} from "react-i18next";
import useActivity from "../../controllers/useActivity/useActivity";
import {manageError} from "../../helpers/ErrorManager";
import {useEffect, useState} from "react";
import {Loader} from "@mantine/core";

interface ActivityProps {
  activity?: ActivityType;
  variant: 'list' | 'vertical';
  className?: string;

  have_tick?: boolean;
  checked?: boolean;

  onClick?: (activity: ActivityType) => void;
  onEdit?: (activity: ActivityType) => void;
  onDelete?: (activity: ActivityType) => void;

  to_load?: {
    id?: string;
  }

}

const Activity = ({
                    activity,
                    variant,
                    className,
                    have_tick,
                    checked,
                    to_load,
                    onClick,
                    onEdit,
                    onDelete
                  }: ActivityProps) => {
  const {t} = useTranslation();
  const {getSavedActivity} = useActivity();

  const [savedActivity, setSavedActivity] = useState<ActivityType | undefined>();

  if (onClick) className += ` ${s.clickable}`;


  const loadSavedActivity = async () => {
    if (!to_load?.id) return;
    const {error, activity: savedActivity} = await getSavedActivity(to_load.id);
    if (error || !savedActivity) return manageError(error || 'activity not found', t)
    setSavedActivity(transformToGenericActivity(savedActivity));
  }

  useEffect(() => {
    if (to_load?.id) {
      loadSavedActivity();
    } else if (activity) {
      setSavedActivity(transformToGenericActivity(activity));
    }
  }, [to_load]);

  if (!savedActivity) return <Loader/>;

  let props = {
    activity: savedActivity,
    have_tick,
    checked,
    onEdit, onDelete
  };

  return <div className={className} onClick={() => onClick && onClick(savedActivity)}>
    {variant === 'vertical' && <VerticalActivity {...props}/>}
    {variant === 'list' && <ListActivity {...props}/>}
  </div>
}

export default Activity;