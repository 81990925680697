import TextInput from "../TextInput/TextInput";
import s from "./Input.module.css";
import React from "react";


export type InputProps<T extends React.ElementType> = {
  InputType: T;
} & React.ComponentProps<T>;

const Input = <T extends React.ElementType>({
   InputType = TextInput, 
   ...props
}: InputProps<T>) => {
  return <InputType
    classNames={{
      input: s.input
    }}
    {...props}
  />
}

export default Input;