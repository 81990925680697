import s from './Footer.module.css';
import Button from "../../Itineraries/Inputs/Button/Button";
import {useTranslation} from "react-i18next";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {ItinerarySteps, OutletItineraryContext} from "../../../../types/itineraries";

import {BiSkipNext, BiSkipPrevious} from "react-icons/bi";
import React from "react";
import bases from "../../../../routes/bases";

interface FooterProps {
  onSave: () => Promise<void> | void;
  arrows?: boolean;
}
const Footer = ({onSave, arrows = true}: FooterProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {id} = useParams();

  const {steps, step, setStep, isItineraryBlocked} = useOutletContext<OutletItineraryContext>();

  const index = steps.findIndex(s => s.text === step);
  const isFirstStep = index === 0;
  const isLastStep = index === steps.length - 1;

  const goToStep = (step: ItinerarySteps) => {
    setStep(step);
    navigate(`/${bases.tour_operator_itineraries}/${id}/${step}`);
  }

  return <div className={s.container}>
    {!isFirstStep && arrows && <div className={s.stepButton} onClick={() => goToStep(steps[index - 1].text)}>
      <BiSkipPrevious size={25}/>
    </div>}
    <Button shadow_border={true} disabled={isItineraryBlocked} onClick={onSave} className={s.button}>{t('to.save')}</Button>
    {!isLastStep && arrows && <div className={s.stepButton} onClick={() => goToStep(steps[index + 1].text)}>
      <BiSkipNext size={25}/>
    </div>}
  </div>
}

export default Footer;