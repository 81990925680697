import {useOutletContext} from "react-router-dom";
import {ItHotel, ItPackage, OutletItineraryContext} from "../../../../../types/itineraries";
import React, {useMemo, useState} from "react";
import {createPlacesForPackage} from "../../../util";
import Bullet from "../../../Itineraries/Bullet/Bullet";
import Hotel from "../../../../Hotel/Hotel";
import Button from "../../../Itineraries/Inputs/Button/Button";
import Title from "../../../../LayoutComponents/Title";
import {t} from "i18next";


interface ShowHotelProps {
  hotel: ItHotel;
  disabled?: boolean;
  selected: boolean;
  onSelect: (id: string) => void;

  [key: string]: any;
}

const ShowHotel = ({hotel, onDeleteHotel, selected, onSelect, disabled = false, ...props}: ShowHotelProps) => {
  return <Hotel
    className="mr-5 mb-5"
    variant={'list'}
    to_load={{
      id: hotel.hotel_id,
      api: hotel.api,
      code: hotel.hotel_code,
      type: hotel.api ? 'api' : 'local'
    }}
    customElement={<Button disabled={disabled} color="green" variant={selected ? 'filled' : 'outline'}
                           onClick={() => onSelect(hotel._id)}>
      {selected ? 'Eliminar selección' : 'Seleccionar'}
    </Button>}
  />
}

interface PackagePlacesProps {
  selectedHotels: string[];
  setSelectedHotels: React.Dispatch<React.SetStateAction<string[]>>;
}

const PackagePlaces = ({selectedHotels, setSelectedHotels}: PackagePlacesProps) => {
  const {itinerary, isItineraryBlocked} = useOutletContext<OutletItineraryContext>();

  const onSelect = (id: string) => {
    setSelectedHotels(prev => {
      if (prev.includes(id)) return prev.filter(i => i !== id);
      return [...prev, id]
    })
  }

  const placesHotelsSummary = useMemo(() => {
    if (!Array.isArray(itinerary?.hotels)) return [];
    return itinerary.hotels.filter(hotel => hotel.place).map(hotel => ({
      label: hotel.place.address.label, // se usa para placesSummary
      place_id: hotel.place_id,
      day: hotel.day,
      element: <ShowHotel disabled={isItineraryBlocked} hotel={hotel}
                          selected={!!selectedHotels.find(id => hotel._id === id)} onSelect={onSelect}/>
    }));
  }, [itinerary, selectedHotels]);


  const places = useMemo(() => createPlacesForPackage(itinerary.places!), [itinerary]);

  return <div>
    <Title size={3} className="my-10">{t('to.header.hotels')}</Title>
    {places?.map((place: any, index) => {
      let bullet = place.bullet || place.day;
      let title = place.title || place.address?.label;
      let content = placesHotelsSummary
        .filter((ph: any) => ph.place_id === place.id && (!ph.day || ph.day === place.day))
        .map(el => el!.element);

      return <div key={index}>
        <div className="flex flex-nowrap items-center">
          <Bullet>{bullet}</Bullet>
          <div className="ml-5">{title}</div>
        </div>
        <div className="my-5 flex flex-wrap items-center">
          {content}
        </div>
      </div>
    })}
  </div>
}

export default PackagePlaces;