import React, {useEffect, useMemo} from 'react';
import s from './PageSettings.module.css';
import Fieldset from "../../Fieldset";
import {useTranslation} from "react-i18next";
import i18n from 'i18next';
import useTheme from "../../../providers/ThemeProvider/useTheme";
import {Select} from "@mantine/core";
import useMenu from "../../../providers/MenuProvider/useMenu";
import {useSelectOptions} from "../../../hooks/useSelectOptions";
import ChooseCurrency from "../../Inputs/ChooseCurrency/ChooseCurrency";

const selectStyles = {container: () => ({width: '212px', position: 'relative', marginRight: '20px'})};

const PageSettings = props => {
  const {t} = useTranslation();
  const {listThemes, theme, setTheme} = useTheme();
  const {setChosen} = useMenu();

  const {languageOptions} = useSelectOptions();


  useEffect(() => setChosen('page'), []);



  const themeOptions = listThemes().map(value => ({value, label: t(`themes.${value}`)}));

  const changeLanguage = (value) => {
    i18n.changeLanguage(value);
  }
  const changeTheme = (value) => {
    setTheme(value);
  }

  return (
    <div className="m-5">
      <form>
        <Fieldset legend={t("appearance")}>

          <div className="flex" style={{width: '450px'}}>
            <Select
              data={languageOptions}
              value={i18n.language}
              label={t('settings page.language')}
              onChange={changeLanguage}
              className="w-full mr-10"
            />
            <ChooseCurrency/>
{/*            <Select
              options={themeOptions}
              label={t("settings page.theme")}
              value={themeOptions.filter(option => option.value === theme)}
              onChange={changeTheme}
              containerClassName="w-full"
            />*/}
          </div>
        </Fieldset>
      </form>
    </div>
  );
};


export default PageSettings;
