import React from 'react';
import s from './GalleryModal.module.css';
import {Modal} from "@mantine/core";
import Gallery from "../index";
import {useTranslation} from "react-i18next";


interface GalleryModalProps {
	opened: boolean;
	setOpened: (v: boolean) => void;
	onDone: (images: string[]) => void;
	title?: string;
	disabled?: boolean;
	[key: string]: any;
}
const GalleryModal = ({title, opened, setOpened, onDone, disabled = false, ...props}: GalleryModalProps) => {
	const {t} = useTranslation();

	title = title || t('gallery data.gallery title')

	return (
		<Modal
			opened={opened && !disabled}
			onClose={() => setOpened(false)}
			title={title}
			size="1070px"
		>
			<Gallery defaultValue={undefined} onDone={(v: any) => {
				setOpened(false);
				onDone(v);
			}} {...props}/>
		</Modal>
	);
};


export default GalleryModal;