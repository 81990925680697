import {ItPackageDistribution, OutletItineraryContext} from "../../../../../../../types/itineraries";
import {useTranslation} from "react-i18next";
import s from "./SingleDistribution.module.css";
import {MoneyRender} from "../../../../../../Table/Renders";
import {VscChromeClose} from "react-icons/vsc";
import {AiOutlineEdit} from "react-icons/ai";
import React from "react";

import {ReactComponent as HotelSVG} from "../../../../../../../resources/svg/it/place_hotel.svg";
import {ReactComponent as PersonSVG} from "../../../../../../../resources/svg/it/person.svg";
import MoneyInputWithController from "../../../../../../Inputs/MoneyInputWithController";
import MoneyInput from "../../../../../../Inputs/MoneyInput";
import {useOutletContext} from "react-router-dom";

interface SingleDistributionProps {
  distribution: ItPackageDistribution;
  n: number;
  onRemove?: () => void;
  onEdit: () => void;
  onPriceChange: (price_per_person: number, total_price: number) => void;
}

export const SingleDistribution = ({distribution, n, onRemove, onEdit, onPriceChange}: SingleDistributionProps) => {
  const {t} = useTranslation();
  const {isItineraryBlocked} = useOutletContext<OutletItineraryContext>();

  const rooms = distribution.persons_per_room.length;
  const persons = distribution.persons_per_room.reduce((t, n) => t + n, 0);

  return <div className={s.container}>
    <div className="mb-5 text-xl">{t('to.itinerary.package.distribution title')} {n}</div>
    <div className="flex flex-nowrap">
      <div>
        <div className="flex flex-nowrap">
          <div className={s.dLabel}>Nª {rooms}</div>
          <div className={s.dLabel}>Nª {persons}</div>
        </div>
        <div className="flex flex-nowrap">
          <div className={s.dLabel}>
            <HotelSVG className={s.icon}/>
            <div>{t('to.itinerary.package.room')}</div>
          </div>
          <div className={s.dLabel}>
            <PersonSVG className={s.icon}/>
            <div>{t('to.itinerary.package.persons', {count: persons})}</div>
          </div>
        </div>

        <div className="flex flex-nowrap">
          <div className="flex flex-col flex-nowrap items-center justify-center">
            <div className="font-semibold text-2xl my-5">{t('to.itinerary.package.price per person')}</div>
            <div className={s.dLabel}>
              <MoneyInput
                value={distribution.price_per_person}
                onChange={(price_per_person: number) => {
                  const total_price = price_per_person * persons;
                  onPriceChange(price_per_person, total_price);
                }}
                disabled={isItineraryBlocked}
                hideControls
                styles={{input: {border: '0 !important'}}}
              />
              {/*<MoneyRender value={distribution.price_per_person}/>*/}
            </div>
          </div>
          <div className="flex flex-col flex-nowrap items-center justify-center">
            <div className="font-semibold text-2xl my-5">{t('to.itinerary.package.total price')}</div>
            <div className={s.dLabel}>
              <MoneyInput
                value={distribution.total_price}
                onChange={(total_price: number) => {
                  const price_per_person = Math.round(total_price / persons * 100) / 100;
                  onPriceChange(price_per_person, total_price);
                }}
                disabled={isItineraryBlocked}
                hideControls
                styles={{input: {border: '0 !important'}}}
              />
              {/*<MoneyRender value={distribution.total_price}/>*/}
            </div>
          </div>
        </div>


      </div>
      <div className={s.verBar}/>
      <div className="flex flex-col items-center justify-between">
        {onRemove && <div className="flex flex-col items-center justify-center ml-5" style={{flex: '0 0 60px'}}>
					<div className={s.buttonLabel}>{t('remove')}</div>
					<div className={s.button} onClick={() => onRemove()}>
						<VscChromeClose size={25}/>
					</div>
				</div>}

        <div className="flex flex-col items-center justify-center ml-5" style={{flex: '0 0 60px'}}>
          <div className={s.buttonLabel}>{t('edit')}</div>
          <div className={s.button} onClick={() => onEdit()}>
            <AiOutlineEdit size={25}/>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default SingleDistribution;