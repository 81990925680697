import s from './Select.module.css';
import {Select as MantineSelect, SelectProps as MantineSelectProps} from '@mantine/core';

interface SelectProps extends MantineSelectProps {

}

const Select = (props: SelectProps) => {
  return <MantineSelect
    classNames={{
      input: s.input,
      item: s.item,
      selected: s.selected
    }}
    {...props}
  />
}

export default Select;