import {TextInput as MantineInput, TextInputProps as MantineTextInputProps} from "@mantine/core";
import Input from "../Input/Input";


type TextInputProps = Omit<MantineTextInputProps, 'InputType'>;

const TextInput = (props: TextInputProps) => {
  return <Input
    InputType={MantineInput}
    {...props}
  />
}

export default TextInput;