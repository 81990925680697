import React from 'react';
import s from './Fieldset.module.css';
import classNames from 'classnames';
const cx = classNames.bind(s);


const Fieldset = props => {
  const styleContainer = cx({
    [props.className]: true,
    [s.fieldset]: true
  });

  return (
    <fieldset className={styleContainer}>
      <legend className={s.legend}>{props.legend}</legend>
      {props.children}
    </fieldset>
  );
};


export default Fieldset;
