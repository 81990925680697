import {useLocalStorage} from "../useLocalStorage";


export const useConfigStorage = (key, def) => {
  const [value, setValue] = useLocalStorage("config", {});

  const update = val => {
    const newVal = val instanceof Function ? val(value[key]) : val;
    console.log(newVal);
    setValue(prev => ({...prev, [key]: newVal}));
  }

  return [value[key] ?? def, update];
}

export default useConfigStorage;
