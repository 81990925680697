import React, {useState} from 'react';
import s from './ConfigAPIHotels.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useForm} from "@mantine/form";
import {Button, Divider, Title} from "@mantine/core";
import useServerController from "../../../../controllers/useServerController";

const ConfigAPIHotels = props => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const {APIDownloadHotelData} = useServerController();

	const form = useForm({
		initialValues: {}
	});

	const onSubmit = data => {
		console.log(data);
	}

	const downloadHotelData = async () => {
		setLoading(true);
		const {error, success} = await APIDownloadHotelData();
		console.log(error);
		console.log(success);

		setLoading(false);

		if(error)return alert(error);
		alert(t('success'));
	};



	return (
		<div className="w-full m-auto lg:container px-5 mb-10">
			<form onSubmit={form.onSubmit(onSubmit)}>
				<Title order={3}>{t('config.api.hotels.danger zone')}</Title>
				<Divider my="sm"/>
				<Button onClick={downloadHotelData} loading={loading}>{t('config.api.hotels.again download apitude data')}</Button>
			</form>
		</div>
	);
};


export default ConfigAPIHotels;