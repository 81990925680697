import React, {useEffect, useMemo, useState} from 'react';
import s from './ToNewPackage.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {useForm, FormProvider} from "react-hook-form";
import sn from "../TONew.module.css";
import Button from "../../../Inputs/Button_old";
import useTourOperatorItinerary from "../../../../controllers/useTourOperatorItinerary";

import ToPackageFeatures from "../../ToPackageFeatures";
import MultiLangInputV2 from "../../../Inputs/MultiLangInputV2";
import {ItPackageDistribution, OutletItineraryContext} from "../../../../types/itineraries";
import HeaderTitle from "../../Components/HeaderTitle/HeaderTitle";
import Footer from "../../Components/Footer/Footer";
import Textarea from "../../Itineraries/Inputs/Textarea/Textarea";
import TextInput from "../../Itineraries/Inputs/TextInput/TextInput";
import RoomDistribution from "./RoomDistribution/RoomDistribution";
import { v4 } from 'uuid';
import PackagePlaces from "./PackagePlaces/PackagePlaces";
import {toastError} from "../../../../helpers/ErrorManager";
import {toast} from "react-toastify";


// const WithTick = ({f, show}) => <div className="relative">
// 	{f}
// 	{show && <div className={s.tick}><MdDone size={20}/></div>}
// </div>

const ToNewPackage = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const {itinerary, setStep, setItinerary, isItineraryBlocked} = useOutletContext<OutletItineraryContext>();
	const form = useForm();
	const {getValues, setValue, register} = form;

	const {editPackage} = useTourOperatorItinerary();


	const [selected, setSelected] = useState([]);
	const [regimes, setRegimes] = useState([]);
	const [optionalPrices, setOptionalPrices] = useState([]);
	const [distributions, setDistributions] = useState<ItPackageDistribution[]>([{
		id: v4(),
		persons_per_room: [2],
		price_per_person: 10,
		total_price: 10,
    createdAt: new Date()
	}]);
  const [selectedHotels, setSelectedHotels] = useState<string[]>([])


	const activities = itinerary.activities;

	const {id, package_id} = useParams();


	useEffect(() => {
		setStep('packages');
	}, []);


	const _package = useMemo(() => {
		if (!itinerary || !itinerary.packages) return false;
		return itinerary.packages.find(p => p._id === package_id);
	}, [itinerary]);

/*
	useEffect(() => {
		if (!_package) return;
		setValue('name', _package.name || (t('to.itinerary.package.package') + ' ' + itinerary.packages.length));
		setValue('price', _package.price);
		setValue('description', _package.description);
		if (_package.distributions) setDistributions(_package.distributions);

		setSelected(prev => {
			let r = [];
			if (Array.isArray(_package.hotels)) _package.hotels.forEach(_id => r.push({type: 'hotel', _id}));
			if (Array.isArray(_package.activities)) _package.activities.forEach(_id => r.push({type: 'activity', _id}));
			if (Array.isArray(_package.transports)) _package.transports.forEach(_id => r.push({type: 'transport', _id}));
			return r;
		});

		setRegimes(_package.regimes || []);
		setOptionalPrices(_package.optionalPrices || []);


	}, [_package]);

	const toggleSelection = (type, el, day) => {
		setSelected(prev => {
			let n = [...prev];
			let i = n.findIndex(a => (a.type === type && a._id === el._id && (a.type === 'activity' ? a.day === day : true)));
			if (i === -1) {
				n.push({type, _id: el._id, day});
			} else {
				n.splice(i, 1);
			}
			return n;
		});
	}

	const setOptionalPrice = (type, el, price, day) => {
		setOptionalPrices(prev => {
			let n = [...prev];
			let i = n.findIndex(a => (a.type === type && a._id === el._id && (a.type === 'activity' ? a.day === day : true)));
			if (i === -1) {
				n.push({type, _id: el._id, day, price});
			} else {
				n[i].price = price;
			}
			return n;
		});
	}


	const onPackageSave = async () => {
		let data = getValues();
		const translate = {activity: 'activities', hotel: 'hotels', transport: 'transports'};
		selected.forEach(({type, _id}) => {
			let t = translate[type];
			if (!data[t]) data[t] = [];
			data[t].push(_id);
		});
		data.regimes = regimes;
		data.optionalPrices = optionalPrices;
		data.distributions = distributions;


		const {error, _package} = await editPackage({
			itinerary_id: id,
			package_id,
			_package: data
		});
		if (error || !_package) return toastError(error, t);

		setItinerary(prev => ({
			...prev,
			packages: prev.packages.map(__package => __package._id !== _package._id ? __package : _package)
		}));
		navigate(`/${bases.tour_operator_itineraries}/${id}/prices`);
	}




	const filterDataElement = (place, d) => {
		if (d.day) return place.index * 1 === d.day * 1;
	}*/

  const onPackageSave = async () => {
    const data = {
      ...getValues(),
      distributions,
      selected_hotels: selectedHotels
    };
    const {error, _package} = await editPackage({
      itinerary_id: id!,
      package_id: package_id!,
      _package: data
    });
    if (error || !_package) return toastError(error, t);

    setItinerary(prev => ({
      ...prev,
      packages: prev.packages ? prev.packages.map(__package => __package._id !== _package._id ? __package : _package) : []
    }));
    toast.success(t('saved'));
  }

  useEffect(() => {
    if(!_package)return
    setDistributions(_package.distributions || [])
    setValue('name', _package.name)
    setValue('description', _package.description)
    setSelectedHotels(_package.selected_hotels ?? [])
  }, [_package]);


	if(!_package) return <></>;

	return (
		<FormProvider {...form}>
			<div className="container m-auto">
				<HeaderTitle>{t('to.header.packages')}</HeaderTitle>

				<div className={s.nameDescContainer}>
					<MultiLangInputV2
						Input={TextInput}
						name="name"
						label={t('to.itinerary.package.name')}
						required
						className={s.input}
						disabled={isItineraryBlocked}
					/>
					<MultiLangInputV2
						Input={Textarea}
						name="description"
						label={t('to.itinerary.package.description')}
						minRows={3}
						className={s.input}
						disabled={isItineraryBlocked}
					/>
				</div>

				<RoomDistribution distributions={distributions} setDistributions={setDistributions} className="mt-10" />
				<ToPackageFeatures _package={_package}/>
				<PackagePlaces setSelectedHotels={setSelectedHotels} selectedHotels={selectedHotels}/>
        <Footer onSave={async () => await onPackageSave()}/>



			</div>

		</FormProvider>
		)

//
// 	return (
// 		<FormProvider {...form}>
// 			<div>
// 				<div className="flex flex-nowrap">
// 					<div>
// 						<div className="w-full flex mb-5">
// 							<MultiLangInputV2
// 								Input={TextInput}
// 								name="name"
// 								form={form}
// 								label={t('to.itinerary.package.name')}
// 								className="w-full"
// 								disabled={isItineraryBlocked}
// 							/>
// {/*							<MoneyInputWithController
// 								name="price"
// 								label={t('to.itinerary.package.price')}
// 								className="mr-5"
// 								disabled={isItineraryBlocked}
// 							/>*/}
// 						</div>
// 						<MultiLangInputV2
// 							Input={Textarea}
// 							name="description"
// 							form={form}
// 							label={t('to.itinerary.package.description')}
// 							style={{width: '400px'}}
// 							minRows={8}
// 							disabled={isItineraryBlocked}
// 							autosize
// 						/>
// 					</div>
// 				</div>
//
//
// {/*
// 				<ToItineraryPlacesSummary allDays={true} pricesUse={true} data={places}/>
// */}
//
// 				<Footer onSave={async () => undefined}/>
// 			</div>
// 		</FormProvider>
// 	);
};


export default ToNewPackage;
