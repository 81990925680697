import React from 'react';
import {TextInput} from "@mantine/core";
import {useFormContext} from "react-hook-form";
import {useOutletContext} from "react-router-dom";
import {OutletItineraryContext} from "../../../types/itineraries";
import ChipsSelectLang from "../ChipsSelectLang/ChipsSelectLang";
import {UseFormReturnType} from "@mantine/form/lib/use-form";


interface BaseProps {
  leftSection?: any;
  rightSection?: any;
  label?: string;
  className?: string;
  Input?: any;
  languages?: string[];


  name: string;
  lang?: string;
  setLang?: (lang: string) => void;

  hideLangSelect?: boolean

  [key: string]: any;
}

interface ReactHookFormProps extends BaseProps {
  formType?: 'react-hook-form' | 'mantine'
  form?: UseFormReturnType<any>;
  formListName?: 'string';
  formIndex?: number;
  formList?: boolean;
}

interface MantineFormProps extends BaseProps {
  formType?: 'mantine'
}

type Props = ReactHookFormProps | MantineFormProps;


const MultiLangInputV2 = (props: Props) => {

  const {
    name,
    languages = ['es', 'eng'],
    Input = TextInput,
    formType = 'react-hook-form',
    label,
    ...restProps
  } = props;

  // mantine form
  const {form, formList = false, formIndex, formListName = 'text'} = props;


  let context = useOutletContext<OutletItineraryContext>();

  let lang = props.lang || context.lang;
  let setLang = props.setLang || context.setLang;

  const reactHookForm = useFormContext();
  let prev = reactHookForm ? reactHookForm.watch(name) : undefined;


  // compatible con inputs normales previos
  let value = '';


  if (formType === 'react-hook-form') {

    if (typeof prev === 'object') {
      value = prev[lang!];
    } else if (typeof prev === 'string') {
      value = prev;
    }

    if ((value === null || value === undefined) && typeof prev === 'object') {
      languages.forEach(l => {
        if (prev[l]) value = prev[l];
      });
    }
  } else if (formType === 'mantine' && props.form) {
    value = form.values[name] ? form.values[name][lang] : '';
    if (formList) {
      value = form.values[name][formIndex] && form.values[name][formIndex][formListName] ? form.values[name][formIndex][formListName][lang] : '';
    }
  }


  const reactHookFormOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    let v = typeof prev === 'object' ? {...prev} : {};
    v[lang] = e.currentTarget.value;
    reactHookForm.setValue(name, v);
  }
  const mantineFormOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (formList) {
      const list = form.values[name];
      console.log(list);
      const prevList = list[formIndex];
      let value = {
        ...prevList, [formListName]: {
          ...(prevList[formListName] || {}),
          [lang]: e.currentTarget.value
        }
      };

      form.setListItem(name, formIndex, value);

    } else {
      let prev = form.values[name] || {};
      let value = {...prev, [lang]: e.currentTarget.value}
      form.setFieldValue(name, value);

    }
  }


  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (formType === 'react-hook-form') {
      reactHookFormOnChange(e);
    } else {
      mantineFormOnChange(e);
    }
  }


  return (
    <Input
      onChange={onChange}
      value={value}
      rightSection={!props.hideLangSelect ? <ChipsSelectLang value={lang} onChange={lang => setLang(lang)}/> : undefined}
      label={label ? label + ` (${lang})` : undefined}
      {...restProps}
    />
  );
};


export default MultiLangInputV2;