import React from 'react';
import s from './Menu.module.css';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Menu = ({chosen, elements, base = '/'}) => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	return (
		<div className={s.menu}>
			{elements.map(e => <div className={`${s.el} ${chosen === e.name ? s.el_chosen : ''}`} onClick={() => navigate(`${base}${e.path}`)} >
				{t(`pages.${e.name}`)}
			</div>)}
		</div>
	);
};


export default Menu;