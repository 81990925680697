import React from 'react';
import s from './NonAuthRoutes.module.css';
import {useRoutes} from "react-router-dom";
import ErrorCallbackComponent from "../ErrorCallbackComponent";
import {ErrorBoundary} from "react-error-boundary";
import nonAuthContent from "../../routes/nonAuthContent";

const RouteWrapper = () => useRoutes(nonAuthContent);

const NonAuthRoutes = props => {
	return (
		<div>
			<ErrorBoundary FallbackComponent={ErrorCallbackComponent}>
				<RouteWrapper/>
			</ErrorBoundary>
		</div>
	);
};


export default NonAuthRoutes;