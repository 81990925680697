import s from './VerticalActivity.module.css';
import {ActivityType} from "../../../../types/activity";

import {ReactComponent as CheckSVG} from "../../../../resources/svg/selected_mark.svg";
import Image from "../../../Image";
import Rating from "../../../Inputs/Rating/Rating";
import {langRender} from "../../../../helpers/i18n";

interface ActivityListProps {
  activity: ActivityType;
  className?: string;
  have_tick?: boolean;
  checked?: boolean;
}

const VerticalActivity = ({activity, have_tick = false, checked, className = ''}: ActivityListProps) => {
  return <div className={`${s.container} ${className}`}>
    {have_tick && <div className={s.checkedContainer}>
			<CheckSVG className={`${checked ? s.checked : s.notChecked}`}/>
		</div>}
    <div className={s.image}>
      <Image src={activity.images[0]} alt={"activity image"}/>
    </div>
    <div className="flex flex-col p-5 h-full">
      <div className="text-2xl font-semibold">{langRender(activity.name)}</div>
      {activity.address?.label && <div className="">{activity.address?.label}</div>}
      {activity.stars && <Rating className={s.rating} stars={activity.stars}/>}
    </div>
  </div>
}

export default VerticalActivity;