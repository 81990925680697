import React, {useState} from 'react';
import s from './DateInput.module.css';
import 'react-dates/lib/css/_datepicker.css';
import './DateInput-style.css'

import {SingleDatePicker} from 'react-dates';
import moment from "moment";
import Select from '../../Select';
import {useTranslation} from "react-i18next";

const monthStyles = {
  container: (styles) => ({
    ...styles,
    width: '140px',
    top: '-5px',
    marginLeft: '6px',
    marginRight: '10px',
    fontSize: '11px',
    outlineColor: 'transparent !important',
    display: 'inline-block'
  }),
  indicatorSeparator: (styles) => ({
    display: 'none'
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
  }),
  menuPortal: styles => ({
    ...styles,
    zIndex: 22
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: null,
    backgroundColor: 'var(--modal-background-color)'
  })
};
const yearStyles = {
  ...monthStyles,
  container: (styles) => ({
    ...styles,
    width: '80px',
    top: '-5px',
    marginLeft: '0x',
    marginRight: '0px',
    fontSize: '11px',
    outlineColor: 'transparent !important',
    display: 'inline-block'
  })
};

const months = moment.months().map((m, i) => ({label: m, value: i}));
const year = moment().year();
const years = [...Array(150).keys()].map(y => ({label: year - y, value: year - y}));

const MonthElement = ({month, onMonthSelect, onYearSelect}) => {
  return (
    <>
      <Select
        value={months.filter(m => m.value === month.month())}
        options={months}
        onChange={({value}, {action}) => {
          if (action === 'select-option') onMonthSelect(month, value);
        }}
        onInputChange={(input) => {
        }}
        menuPortalTarget={document.body}
        closeOnSelect={false}
        isSearchable={true}
        styles={monthStyles}
      />
      <Select
        value={years.filter(m => m.value === month.year())}
        options={years}
        onChange={({value}, {action}) => {
          if (action === 'select-option') onYearSelect(month, value);
        }}
        onInputChange={(input) => {
        }}
        menuPortalTarget={document.body}
        closeOnSelect={false}
        isSearchable={true}
        styles={yearStyles}
      />
    </>
  );
};

const DateInput = ({
                     DateType = SingleDatePicker,
                     numberOfMonths = 1,
                     displayFormat = 'DD/MM/YYYY',
                     isOutsideRange = () => false,
                     showDefaultInputIcon = true,
                     hideKeyboardShortcutsPanel = true,
                     onDatesChange = false,
                     onDateChange = false,
                     classNameContainer,
                     fullSize = false,
                     name,
                     startDate,
                     endDate,
                     ...props
                   }) => {
  const {t} = useTranslation();
  const [state, setState] = useState({
    focused: false,
    focusedInput: null,
    date: props.date || moment(),
    startDate: startDate,
    endDate: endDate,
    id: props.id || Math.floor(Math.random() * 10000)
  });

  const _onDateChange = date => {
    setState(prev => ({...prev, date}));
    if (onDateChange) onDateChange(date);
  };

  const _onDatesChange = ({startDate, endDate}) => {
    setState(prev => ({...prev, startDate, endDate}));
    if (onDatesChange) onDatesChange({startDate, endDate});
  }

  const onFocusChange = (focusedInput) => {
    if(focusedInput && focusedInput.focused !== undefined){
      setState(prev => ({...prev, focused: focusedInput.focused}));
    }else{
      setState(prev => ({...prev, focusedInput}));
    }
  }

  const nameElement = DateType.WrappedComponent.name;

  const commonProps = {
    displayFormat,
    isOutsideRange,
    showDefaultInputIcon,
    renderMonthElement: MonthElement,
    daySize: 50,
    withPortal: true,
    hideKeyboardShortcutsPanel: true,
    onFocusChange
  };

  if (nameElement === 'SingleDatePicker') return (
    <div className={classNameContainer}>
      <DateType
        {...commonProps}
        numberOfMonths={numberOfMonths}
        focused={state.focused}
        onDateChange={_onDateChange}
        date={state.date || moment()}
        {...props}
      />
    </div>
  );

  if (nameElement === 'DateRangePicker') return (
    <div className={classNameContainer}>
      <DateType
        {...commonProps}
        startDatePlaceholderText={t("calendar.start date")}
        endDatePlaceholderText={t("calendar.end date")}

        startDate={state.startDate}
        endDate={state.endDate}
        onDatesChange={_onDatesChange}
        startDateId={'startDateDateRangePicker' + state.id}
        endDateId={'endDateDateRangePicker' + state.id}
        focusedInput={state.focusedInput}
        {...props}
      />
    </div>
  );

  return <div>INPUT NOT FOUND</div>
};


export default DateInput;

