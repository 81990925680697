import React from 'react';
import useAuth from "../../../providers/UserProvider/useAuth";
import AdminEdit from "../AdminEdit";

const AdminMe = props => {
  const {getAdmin} = useAuth();
    return (
        <AdminEdit admin_id={getAdmin()._id}/>
    );
};


export default AdminMe;
