import {OrderedType} from "../../../controllers/usePurchaseController/types";
import React, {useEffect, useState} from "react";
import {usePayments} from "../../../controllers/usePayments/usePayments";
import {manageError} from "../../../helpers/ErrorManager";
import {ErrorType} from "../../../types/errors";
import {useTranslation} from "react-i18next";
import ToggleComponent from "../../ToggleComponent";
import ShowClient from "../../Show/ShowClient";
import PurchasedHotels from "./PurchasedHotels";
import BillingData from "./BillingData";
import EmergencyContactData from "./EmergencyContactData";
import PurchasedInsurance from "../../Insurance/PurchasedInsurance";

interface ShowOrderedProps {
  ordered?: OrderedType;
  ordered_id?: string;
}

const ShowOrdered = (props: ShowOrderedProps) => {
  const {t} = useTranslation();
  const {getOrderedById} = usePayments();

  const [ordered, setOrdered] = useState<OrderedType>();

  const fetchOrdered = async (ordered_id: string) => {
    try {
      const ordered = await getOrderedById(ordered_id);
      if (ordered) setOrdered(ordered);
    } catch (error) {
      manageError(error as ErrorType, t);
    }
  }

  useEffect(() => {
    if (props.ordered) return setOrdered(ordered);
    if (props.ordered_id) fetchOrdered(props.ordered_id);
  }, [props.ordered, props.ordered_id]);


  if (!ordered) return null;


  return <>
    {ordered.user_id && <ToggleComponent title={t('show purchase.buyer account')}>
      <ShowClient tableProps={{hideHeader: true}} showTitle={false} client_id={ordered.user_id}/>
    </ToggleComponent>}
    {ordered.billing && <ToggleComponent title={t('show purchase.billing information')}>
      <BillingData data={ordered.billing}/>
    </ToggleComponent>}
    {ordered.emergency_contact && <ToggleComponent title={t('show purchase.emergency contact')}>
      <EmergencyContactData data={ordered.emergency_contact}/>
    </ToggleComponent>}

    {Array.isArray(ordered?.hotels) && ordered.hotels.length > 0 && <ToggleComponent title={t('show purchase.hotels')}>
      <PurchasedHotels hotels={ordered.hotels}/>
    </ToggleComponent>}

    {ordered.insurance && <ToggleComponent title={t('show purchase.insurance')}>
      <PurchasedInsurance insurance={ordered.insurance}/>
    </ToggleComponent>}
  </>

}

export default ShowOrdered;