import React, {useState} from 'react';
import s from './Input.module.css';
import classNames from 'classnames';

import ReactTooltip from "react-tooltip";

import {BsExclamationDiamondFill} from 'react-icons/bs'

const cx = classNames.bind(s);

const Input = React.forwardRef(({
  type = 'text',
  onChange,
  validate,
  info,
  className = '',
  classNameContainer = '',
  ...props
}, ref) => {

  const [error, setError] = useState("");

  const classNames = cx({
    [s.input]: true,
    [className]: true
  });
  const containerClassNames = cx({
    [s.inputContainer]: true,
    [classNameContainer]: true
  });


  const onChangeInternal = (e) => {
    if(e.target.value.length > 0 && !e.target.checkValidity()){
      setError(e.target.validationMessage);
      console.log(e.target.validationMessage);
    }else{
      setError("");
    }
    onChange && onChange(e);
  }

  return (
    <div className={containerClassNames}>
    <input
      onChange={onChangeInternal}
      className={classNames}
      type={type}
      ref={ref}
      {...props}
    />

    {error && <div className={s.tooltip}>
      <a data-tip={error}><div className={`${s.tooltipIcon} ${s.tooltipError}`}><BsExclamationDiamondFill size={17}/></div></a>
      <ReactTooltip type="error" effect="float"/>
    </div>}

    {info && <div className={s.tooltip}>
      <a data-tip={info}><div className={`${s.tooltipIcon} ${s.tooltipInfo}`}><BsExclamationDiamondFill size={17} /></div></a>
      <ReactTooltip type="info" effect="float"/>
    </div>}
  </div>
  );
});


export default Input;
