import React, {useState, useRef, useEffect} from 'react';
import s from './CalendarPage.module.css';
import Calendar from "../Calendar";
import useWindowSize from "../../../hooks/useWindowSize/useWindowSize";
import useCalendarController from "../../../controllers/useCalendarController";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {toastError} from "../../../helpers/ErrorManager";
import moment from 'moment';
import EventModal from './EventModal';

const format = 'YYYY-MM-DD';
const events = new Map();

const CalendarPage = props => {
  const {t} = useTranslation();

  const {height} = useWindowSize();
  const {postEvent, putEvent, getRangeDateEvents, deleteEvent} = useCalendarController();

  const [state, setState] = useState({
    eventModal: false,
    deleteEvent: false
  });

  const calendarRef = useRef();

  useEffect(() => {
    return () => events.clear();
  }, []);


  const refreshEvents = () => {
    events.clear();
    calendarRef.current.getApi().refetchEvents();
  }

  const onDateClick = e => {
    setState(prev => ({
      ...prev,
      eventModal: {
        start: moment(e.dateStr),
        end: moment(e.dateStr)
      }
    }));
  }

  const handleSubmit = async (data, e, setLoading) => {
    console.log(data);
    if (!data.name || !data.description || !data.date || (data.manyDays && !data.date.startDate)) {
      toast.error(t("please") + "," + t("fill all data"));
      return setLoading(false);
    }

    data.from_date = data.manyDays ? data.date.startDate.format(format) : data.date.format(format);
    data.to_date = data.date.endDate ? data.date.endDate.format(format) : data.from_date;

    let result;
    if (state.eventModal && state.eventModal.event_id) {
      result = await putEvent(state.eventModal.event_id, data);
    } else {
      result = await postEvent(data);
    }
    setLoading(false);
    if (result.error) return toastError(result.error, t);

    setState(prev => ({...prev, eventModal: false}));
    toast.success(t("saved"));
    refreshEvents();
  }

  const onEventClick = async ({event}) => {
    const {event_id, description, manyDays} = event.extendedProps;
    const {title, start, end} = event;

    setState(prev => ({
        ...prev,
        eventModal: {
          event_id,
          description,
          name: title,
          start: moment(start),
          end: moment(end),
          manyDays
        }
      })
    )
  }

  const onDeleteClick = (e, {event}) => {
    setState(prev => ({...prev, deleteEvent: event.extendedProps}));
  }
  const onDeleteConfirm = async ({confirm}) => {
    const event_id = state.deleteEvent?.event_id;
    if (confirm && event_id) {
      const {error} = await deleteEvent(state.deleteEvent.event_id);
      if (error) return toastError(error, t);
      toast(t("deleted"));
      refreshEvents();
    }
    setState(prev => ({...prev, deleteEvent: false}));
  }

  const fetchAdminEvents = async (fetchInfo, resolve, reject) => {
    const date = {
      start: fetchInfo.start.valueOf(),
      end: fetchInfo.end.valueOf()
    }
    const key = date.start + date.end;
    if (events.has(key)) return resolve(events.get(key));

    const {data, error} = await getRangeDateEvents(fetchInfo.start.valueOf(), fetchInfo.end.valueOf());
    if (error) return reject(error);
    if (!(data instanceof Array)) return resolve([]);

    events.set(key, data.map(event => ({
      extendedProps: {
        event_id: event.calendar_event_id,
        description: event.description,
        manyDays: event.from_date !== event.to_date,
        onClick: onEventClick
      },
      title: event.name,
      start: new Date(event.from_date),
      end: new Date(event.to_date)
    })));
    resolve(events.get(key));
  }

  const eventSources = [
    {events: fetchAdminEvents}
  ];

  return (
    <div className="m-5" style={{height: `calc(${height}px - 30px - var(--header-height))`}}>
      <Calendar
        ref={calendarRef}
        onDateClick={onDateClick}
        eventSources={eventSources}
        contextMenu={[
          {text: t("edit"), onClick: (e, data) => onEventClick(data)},
          {text: t("delete"), onClick: onDeleteClick}
        ]}
      />
      <EventModal
        event={state.eventModal}
        onSubmit={handleSubmit}
      />
{/*      <Prompt
        isOpen={state.deleteEvent !== false}
        title={t("attention")}
        message={t("are you sure you want to delete this event")}
        options={[
          {confirm: true, label: t('yes'), style: 'danger'},
          {confirm: false, label: t('cancel'), style: 'blue'}
        ]}
        onChoose={onDeleteConfirm}
      />*/}
    </div>
  );
};


export default CalendarPage;
