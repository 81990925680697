import {HiTemplate} from "react-icons/hi";
import bases from "./bases";
import {GoCalendar} from "react-icons/go";
import {GiPoolDive, GiSnorkel} from "react-icons/gi";
import { AiOutlineUsergroupAdd} from "react-icons/ai";
import {BsPersonRaisedHand} from "react-icons/bs";
import {MdEventAvailable, MdOutlinePayment} from "react-icons/md";
import {FaCalendarAlt} from "react-icons/fa";
import {IoIosSchool} from "react-icons/io";


export const groups = {
  calendars: {name: 'calendars'},
  reservations: {name: 'reservations'},
  services: {name: 'services'},
  forms: {name: 'forms'},
  other: {name: 'other'},
}


export const allElements = [
	{
		key: 'courses',
		name: 'courses',
		icon: <HiTemplate/>,
		group: groups.services,
		path: bases.td.courses
	},
	{
		key: 'snorkel',
		name: 'snorkel',
		icon: <GiSnorkel/>,
		group: groups.services,
		path: bases.td.snorkel
	},
	{
		key: 'dives',
		name: 'dives',
		icon: <GiPoolDive/>,
		group: groups.services,
		path: bases.td.dives
	},
	{
		key: 'reservations',
		name: 'reservations',
		icon: <GoCalendar/>,
		group: groups.reservations,
		path: bases.td.reservations
	},
  {
    key: 'sellers_payments',
    name: 'sellers_payments',
    icon: <MdOutlinePayment />,
    group: groups.reservations,
    path: bases.td.sellers_payments
  },
  {
    key: 'trainers_payments',
    name: 'trainers_payments',
    icon: <MdOutlinePayment />,
    group: groups.reservations,
    path: bases.td.trainers_payments
  },
	// {
	// 	key: 'payments',
	// 	name: 'payments',
	// 	icon: <MdPayment/>,
	// 	group: groups.reservations,
	// 	path: bases.td.payments
	// },
	// {
	// 	key: 'contact_us',
	// 	name: 'contact us',
	// 	icon: <AiOutlineContacts/>,
	// 	group: groups.forms,
	// 	path: bases.td.contact_us
	// },
  {
    key: 'sellers',
    name: 'sellers',
    icon: <BsPersonRaisedHand />,
    group: groups.reservations,
    path: bases.td.sellers
  },
  {
    key: 'clients',
    name: 'clients',
    icon: <AiOutlineUsergroupAdd/>,
    group: groups.reservations,
    path: bases.td.clients
  },
  {
    key: 'trainers',
    name: 'trainers',
    icon: <IoIosSchool />,
    group: groups.reservations,
    path: bases.td.trainers
  },
  {
    key: 'calendar_activities',
    name: 'calendar activities',
    icon: <FaCalendarAlt />,
    group: groups.calendars,
    path: bases.td.calendar_activities
  },
  {
    key: 'events',
    name: 'events',
    icon: <MdEventAvailable />,
    group: groups.other,
    path: bases.td.events
  },
]