import React, {useEffect, useMemo} from 'react';
import s from './MySavedHotelRegimen.module.css';
import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {MultiSelect} from "@mantine/core";
import {useSelectOptions} from "../../../hooks/useSelectOptions";

const MySavedHotelRegimen = ({hotel, form}) => {
	const {t} = useTranslation();


	const {hotelRegimeOptions} = useSelectOptions();

	const name = 'regime';


/*
	useEffect(() => {
		if(!hotel)return;
		let regimes = hotel?.regime;
		if(!Array.isArray(regimes)){
			return addRegime();
		}
		regimes.forEach(regime => {
			setValue(`${name}.${regime.id}.id`, regime.id);
			setValue(`${name}.${regime.id}.label`, regime.label);
		});
	}, [hotel]);



	const regimes = watch(name);


	const addRegime = () => {
		let id = 0;
		if (Array.isArray(regimes)) {
			id = regimes.reduce((c, v) => Math.max(c, v.id), 0) + 1;
		}

		const regime = `${name}.${id}`;

		setValue(`${regime}.id`, id);
		setValue(`${regime}.label`, '');
	}
	const removeRegime = id => {
		unregister(`${name}.${id}`);
	}
*/




	return (
		<div className="my-10">
			<div className="flex flex-wrap">
				{<MultiSelect data={hotelRegimeOptions} {...form.getInputProps(name)} label={t('to.hotel.regime')} />}

			</div>
{/*			<div className="mt-10" style={{width: "300px"}}>
				<Button variant="outline" leftIcon={<GrAddCircle className={s.add_circle_stroke} size={18}/>} onClick={addRegime}>{t('to.hotel.add regime')}</Button>
			</div>*/}

		</div>
	);
};


export default MySavedHotelRegimen;