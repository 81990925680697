import React from 'react';
import s from './HeaderBarItems.module.css';
import {NavLink, useLocation} from 'react-router-dom';
import Routes from "../../routes/menu";
import {useTranslation} from "react-i18next";
import classNames from 'classnames';
import useAuth from "../../providers/UserProvider/useAuth";

const cx = classNames.bind(s);

const HeaderBarItems = props => {
  const location = useLocation();
  const {t} = useTranslation();
  const {getAdmin} = useAuth();
  let pathname_parts = location.pathname.split('/');
  pathname_parts.splice(0, 1);
  const route = Routes.map(route => {
    let route_parts = route.path.split('/');
    let found = true;
    for(let i = 0;i<route_parts.length;i++){
      if(route_parts[i].charAt(0) === ':')route_parts[i] = pathname_parts[i];
      if(route_parts[i] !== pathname_parts[i]){
        found = false;
        break;
      }
    }

    if(!found)return false;
    let r = {...route, path: route_parts.join('/')};
    //r.path = r.path.charAt(r.path.length - 1) === '/' ? r.path.substring(0, r.path.length-1) : r.path;
    return r;
    //return route.path === location.pathname.substring(1, route.path.length + 1)
  }).filter(a => a)[0];
  const defaultSelected = fullPath => fullPath === location.pathname;

  return (
    <div className={s.container}>
{/*      {route?.children?.map(children => {
        let fullPath = '/' + route.path;
        if(children.path)fullPath += '/' + children.path;
        const passInfo = {
          pathname: location.pathname,
          fullPath,
          admin_id: getAdmin().admin_id
        };
        const linkClassNames = cx({
          [s.children]: true,
          [s.childrenSelected]: children.isSelected ? children.isSelected(passInfo) : defaultSelected(fullPath)
        });

        return (<NavLink to={fullPath} className={linkClassNames} key={children.key}>
          {children.image}
          <div className={s.itemName}>{t("pages." + children.name)}</div>
        </NavLink>);
      })}*/}
    </div>
  );
};


export default HeaderBarItems;
