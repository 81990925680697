import {Button} from "antd";
import {ButtonProps} from "antd/es/button/button";
import {IoIosAdd} from "react-icons/io";

export function CreateButton({children, ...props}: ButtonProps){
  return <Button type="primary" {...props}>
    <div className="flex items-center">
      <IoIosAdd size={20}/>
      {children}
    </div>
  </Button>
}