import s from './RoomDistribution.module.css';
import {useTranslation} from "react-i18next";
import {ItPackageDistribution, OutletItineraryContext} from "../../../../../types/itineraries";
import React from "react";
import Button from "../../../Itineraries/Inputs/Button/Button";
import {GrAddCircle} from "react-icons/gr";
import {useOutletContext} from "react-router-dom";
import {v4} from "uuid";
import {Modal} from "@mantine/core";
import SingleDistribution from "./Views/SingleDistribution/SingleDistribution";
import EditDistribution from "./Views/EditDistribution/EditDistribution";

interface RoomDistributionProps {
  className?: string;
  distributions: ItPackageDistribution[];
  setDistributions: React.Dispatch<React.SetStateAction<ItPackageDistribution[]>>;
}

const RoomDistribution = ({distributions, setDistributions, className}: RoomDistributionProps) => {
  const {t} = useTranslation();

  const {isItineraryBlocked} = useOutletContext<OutletItineraryContext>();
  const [editDistribution, setEditDistribution] = React.useState<ItPackageDistribution | undefined>(undefined);

  console.log(distributions)

  const addDistribution = () => {
    setDistributions([...distributions, {
      id: v4(),
      persons_per_room: [2],
      price_per_person: 10,
      total_price: 10,
      createdAt: new Date()
    }]);
  }

  const removeDistribution = (id: string) => {
    setDistributions(prev => prev.filter(p => p.id !== id));
  }

  const saveDistribution = (id:string, newDistribution: ItPackageDistribution) =>{
    setDistributions(prev => [
      ...prev.filter(p => p.id !== id),
      newDistribution
    ]);

    // setDistributions(prev => prev.map(p => {
    //   if(p.id === id){
    //     return {
    //       ...p,
    //       newDistribution
    //     }
    //   }else {
    //     return p
    //   }
    // }))

    setEditDistribution(undefined);
  }

  return <>
    <div className={className}>
      <div className="flex flex-wrap">
        {distributions!.sort((a, b) => a.createdAt < b.createdAt ? -1 : 1 ).map((distribution, i) => <SingleDistribution
          key={distribution.id}
          distribution={distribution}
          n={i + 1}
          onRemove={distributions.length > 1 ? () => removeDistribution(distribution.id) : undefined}
          onEdit={() => setEditDistribution(distribution)}
          onPriceChange={(price_per_person, total_price) => {
            saveDistribution(distribution.id, {
              ...distribution,
              price_per_person,
              total_price
            })
          }}
        />)}
      </div>
      <Button disabled={isItineraryBlocked} color="yellow" variant="filled" shadow_border={true}
              rightIcon={<GrAddCircle className={s.add_circle_stroke} size={20}/>}
              onClick={addDistribution}
              className="mt-10"
      >{t('to.itinerary.package.add distribution')}</Button>
    </div>
    <Modal
      opened={!!editDistribution}
      onClose={() => setEditDistribution(undefined)}
      size="600px"
      centered
      withCloseButton={false}
      styles={{
        modal: {
          backgroundColor: 'var(--it-light-green)'
        }
      }}
    >
      <EditDistribution
        distribution={editDistribution!}
        onCancel={() => setEditDistribution(undefined)}
        onSave={(d) => saveDistribution(editDistribution?.id!, {
          ...d,
          price_per_person: Math.round(d.total_price / d.persons_per_room.reduce((t, n) => t + n, 0) * 100) / 100
        })}
      />
    </Modal>
  </>
}

export default RoomDistribution;