import React, {useMemo, useState} from 'react';
import s from './TDClients.module.css';
import {useTranslation} from "react-i18next";
import {Button, Title} from "@mantine/core";
import bases from "../../../routes/bases";
import {useNavigate} from "react-router-dom";
import useTDClients from "../../../controllers/useTDClients";
import Table from "../../Table";
import {DateRender, EmailRender, LangListRenderer, PhoneRender} from "../../Table/Renders";
import {canTDWrite} from "../../Permissions/VisibleWithPermissions";

const TDClients = props => {
	const {t} = useTranslation();
	const [loading, setLoading] = useState(true);
	const [loadingButton, setLoadingButton] = useState(false);

	const navigate = useNavigate();

	const {newClient, getClients, getTotalData, deleteClient} = useTDClients();

	const onNewClient = async () => {
		setLoadingButton(true);
		const {error, id} = await newClient();
		setLoadingButton(false);
		if(error)return alert(error);
		navigate(`/${bases.td.clients}/${id}`);
	}

	const onDelete = async reservation => {
		if (!window.confirm('¿Está seguro de que desea eliminar la reserva?')) return false;
		const {error, success} = await deleteClient({id: reservation._id});
		return success;
	}

  const canWrite = useMemo(() => canTDWrite(), [])


	return (
		<div className="container m-auto pl-10">
			<div className="mb-10">
				<Title order={2} className="float-left">{t('td.clients.title')}</Title>
        {canWrite && <Button className="float-right" onClick={onNewClient}
								loading={loadingButton}>{t('td.clients.create new')}</Button>}
			</div>


			<Table
				tableName="td_clients"
				id="_id"
				columns={[
					{accessor: '_id', Header: t('id'), type: 'string'},
					{accessor: 'full_name', Header: t('full name'), type: 'string', filter: true},
					{accessor: 'email', Header: t('email'), type: 'string', filter: true},
					{accessor: 'phone', Header: t('phone'), type: 'string', filter: true},
					{accessor: 'birthday', Header: t('birthday'), type: 'date', filter: 'range_date'},
					{accessor: 'language', Header: t('td.clients.language'), type: 'string', filter: 'language'}
				]}
				onEdit={canWrite ? row => navigate(`/${bases.td.clients}/${row._id}`) : undefined}
				onDelete={canWrite ? row => onDelete(row) : undefined}
				defaultHiddenColumns={['_id']}
				fetchData={async ({page, filters}) => {
					const {error, clients, totalRecords} = await getClients({page, filters});
					return {error, data: clients, totalRecords};
				}}
/*				fetchTotalData={async ({filters}) => {
					const {error, data} = await getTotalData({filters});
					return {data}
				}}*/
				renders={{
					email: EmailRender,
					phone: PhoneRender,
					birthday: DateRender,
					language: LangListRenderer
				}}
			/>
		</div>
	);
};


export default TDClients;