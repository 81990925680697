import React, {useEffect, useState} from 'react';
import s from './ItinerariesToValidate.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Title} from "@mantine/core";
import useTourOperatorItinerary from "../../controllers/useTourOperatorItinerary";
import {toastError} from "../../helpers/ErrorManager";
import SmallItinerary from "../SmallItinerary";
import Button from "../Inputs/Button";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import bases from "../../routes/bases";

const ItinerariesToValidate = props => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const {getItinerariesToValidate} = useTourOperatorItinerary();


	const [itineraries, setItineraries] = useState();

	const loadItineraries = async () => {
		const {error, itineraries} = await getItinerariesToValidate();
		if (error) return toastError(error, t);
		setItineraries(itineraries);
	}
	useEffect(() => {
		loadItineraries();
	}, []);

	return (
		<div className="container px-10 m-auto">
			<Title order={2}>{t('to.itineraries')}</Title>
			{(!itineraries || !itineraries.length) && <Title order={4}>{t('to.no itineraries to validate found')}</Title>}

			{Array.isArray(itineraries) && itineraries.length > 0 &&
				<div className="flex flex-wrap">
					{itineraries.map(itinerary => <div
						onClick={() => navigate(`/${bases.tour_operator_itineraries}/${itinerary._id}`)}
						className={s.itineraryContainer}>
						<SmallItinerary key={itinerary._id} itinerary={itinerary}/>
					</div>)}
			</div>}
		</div>
	);
};


export default ItinerariesToValidate;