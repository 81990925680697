import useAuth from "../../providers/UserProvider/useAuth";


export const useTDProducts = () => {
	const {secQuery} = useAuth();
	const base = '/api/admin/td/product';

	const getAllProducts = async ({signal} = {}) => {
		const {error, data} = await secQuery(base, null, 'GET', {signal});
		return {error, products: data?.products};
	}

	const getProducts = async ({page = 0, filters = {}, signal} = {}) => {
		const {error, data} = await secQuery(`${base}s?page=${page}`, {filters}, 'POST', {signal});
		return {error, products: data?.products, totalRecords: data?.totalRecords};
	}

	const newProduct = async ({signal} = {}) => {
		const {error, data} = await secQuery(base, null, 'POST', {signal});
		return {error, id: data?.id};
	}

	const getProduct = async ({id}) => {
		const {error, data} = await secQuery(`${base}/${id}`, null, 'GET');
		return {error, product: data?.product};
	}
	const editProduct = async ({id, product}) => {
		const {error, data} = await secQuery(`${base}/${id}`, {product}, 'PUT');
		return {error, success: data?.success};
	}

	const deleteProduct = async ({id}) => {
		const {error, data} = await secQuery(`${base}/${id}`, null, 'DELETE');
		return {error, success: data?.success};
	}

	return {getProducts, newProduct, getProduct, editProduct,deleteProduct, getAllProducts};
}