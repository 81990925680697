import {BsHouseDoor, BsGear, BsCalendar, BsBarChart, BsPeople, BsWallet} from "react-icons/bs";
import * as path from "path";


let routes = [
  {
    key: 1,
    path: 'home',
    name: 'home',
    image: BsHouseDoor(),
  },
  {
    key: 2,
    path: 'calendar',
    name: 'calendar',
    image: BsCalendar(),
  },
  {
    key: 3,
    path: 'analytics',
    name: 'analytics',
    image: BsBarChart(),
  },
  {
    key: 4,
    path: 'social',
    name: 'social',
    image: BsPeople(),
  },
  {
    key: 5,
    path: 'payments',
    name: 'payments',
    image: BsWallet(),
  },
  {
    key: 6,
    path: 'settings',
    name: 'settings',
    image: BsGear(),
    children: [
      {
        key: 60,
        path: 'page',
        name: 'page'
      },
      {
        key: 61,
        path: 'admin/me',
        name: 'my profile',
/*        isSelected: ({pathname, admin_id}) => {
          let match = pathname.match(/\/settings\/admin\/(\d)/i);
          return match !== null && match[1] * 1 === admin_id;
        }*/
      },
      {
        key: 62,
        path: 'admin',
        name: 'administrators',
      },
/*      {
        key: 63,
        path: 'role',
        name: 'roles'
      }*/

    ]
  },
  {
    key: 7,
    path: 'client_profile/:client_id',
    name: 'client_profile',
    hidden: true,
  },
  {
    key: 8,
    path: 'settings/admin_profile',
    hidden: true,
    name: 'profile',
  },
  {
    key: 9,
    path: 'client/:id',
    hidden: true,
    name: 'client',
    children: [
      {
        key: 90,
        path: '',
        name: 'client'
      },
      {
        key: 91,
        path: 'purchase',
        name: 'purchases'
      },
      {
        key: 92,
        path: 'calendar',
        name: 'calendar'
      }
    ]
  }
];

export default routes

export function findParentRoute(location) {
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].path === location.pathname.substring(1, routes[i].path.length + 1)) {
      return {index: i, route: routes[i], hidden: routes[i].hidden};
    }
  }
  return {};
}
