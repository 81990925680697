import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {useOutletContext} from "react-router-dom";
import Title from "../../../LayoutComponents/Title";
import {ItHotel, ItPackage, OutletItineraryContext} from "../../../../types/itineraries";
import {createPlacesForHotels} from "../../util";
import Hotel from "../../../Hotel/Hotel";
import Bullet from "../../Itineraries/Bullet/Bullet";


interface ShowHotelProps {
  hotel: ItHotel;
}

const ShowHotel = ({hotel}: ShowHotelProps) => {
  return <Hotel
    className="mr-5 mb-5"
    variant={'list'}
    to_load={{
      id: hotel.hotel_id,
      api: hotel.api,
      code: hotel.hotel_code,
      type: hotel.api ? 'api' : 'local'
    }}
  />
}

interface Props {
  selectedPackage: ItPackage;
}

const ToSummaryItHotels = ({selectedPackage}: Props) => {
  const {t} = useTranslation();
  const {itinerary} = useOutletContext<OutletItineraryContext>();

  const placesHotelsSummary = useMemo(() => {
    if (!Array.isArray(itinerary?.hotels)) return [];
    return itinerary.hotels.filter(hotel => hotel.place).map(hotel => ({
      label: hotel.place.address.label, // se usa para placesSummary
      place_id: hotel.place_id,
      day: hotel.day,
      hotel_id: hotel._id,
      element: <ShowHotel hotel={hotel}/>
    }));
  }, [itinerary]);
  const places = useMemo(() => createPlacesForHotels(itinerary.places!), [itinerary]);


  return (
    <>
      {!places?.length &&
        <div className="font-bold text-2xl">Primera necesitas agregar días en la pestaña de Itinerario</div>}


      {places?.map((place: any, index) => {
        let bullet = place.bullet || place.day;
        let title = place.title || place.address?.label;
        let elements = placesHotelsSummary
          .filter((ph: any) => selectedPackage.selected_hotels.includes(ph.hotel_id) && ph.place_id === place.id && (!ph.day || ph.day === place.day))
          .map(el => el.element);

        return <div key={index}>
          <div className="flex flex-nowrap items-center">
            <Bullet>{bullet}</Bullet>
            <div className="ml-5">{title}</div>
          </div>
          <div className="my-5 flex flex-wrap items-center">
            {elements}
          </div>
        </div>
      })}
    </>
  );
};


export default ToSummaryItHotels;
