import React from 'react';
import s from './MoneyInput.module.css';
import {NumberInput} from "@mantine/core";

const MoneyInput = props => {
    return (
      <NumberInput
        parser={(value) => value.replace(/\€\s?|(,*)/g, '')}
        formatter={(value) =>
          !Number.isNaN(parseFloat(value))
            ? `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : '€ '
        }
        min={0}
        {...props}
      />
    );
};


export default MoneyInput;