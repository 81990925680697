import React from 'react';
import s from './InputWithIcon.module.css';
import Input from "../Inputs/Input/Input";

import classNames from 'classnames';

let cx = classNames.bind(s);

const icon = {
  user: (<path d="M0,20 a10,8 0 0,1 20,0z M10,0 a4,4 0 0,1 0,8 a4,4 0 0,1 0,-8"/>),
  pass: (<path d="M0,20 20,20 20,8 0,8z M10,13 10,16z M4,8 a6,8 0 0,1 12,0"/>)
};


const InputWithIcon = React.forwardRef((props, ref) => {
  const groupStyle = cx({
    [s.group]: true,
    [props.className]: true
  });
  return (
    <div className={groupStyle}>
      <label htmlFor={props.id}>
      {(icon[props.icon] &&
        <svg className={s.svgIcon} viewBox="0 0 20 20">
          {icon[props.icon]}
        </svg>
      ) || <div className={s.iconContainer}>{props.icon}</div>}
      </label>
      <Input className={s.input} ref={ref} {...props} />
      <span className={s.bar}/>
    </div>
  )
});


export default InputWithIcon;
