import React from 'react';
import s from './AnalyticsPage.module.css';
import GeneralProfitsChart from "../../GeneralProfitsChart";
import GeneralProfitsPerYearChart from "../../GeneralProfitsPerYearChart";
import {BsFillPersonLinesFill, BsPersonPlusFill} from "react-icons/bs";
import {useTranslation} from "react-i18next";
import AnalyticSmallCounter from "../AnalyticSmallCounter";
import {FaRegMoneyBillAlt} from "react-icons/fa";

const AnalyticsPage = props => {
  const {t} = useTranslation();
  const counters = [
    {
      title: t('active users'),
      backgroundColor: 'var(--secondary-color)',
      Icon: BsFillPersonLinesFill,
      number: 3654,
      growRate: 12.5
    },
    {
      title: t('new registers'),
      backgroundColor: 'var(--primary-color)',
      Icon: BsPersonPlusFill,
      data: [
        {label: t('time.hourWithCount', {count: 24}), value: {value: 24, growRate: 5.0}},
        {label: t('time.weekWithCount', {count: 1}), value: {value: 155, growRate: 13.0}},
        {label: t('time.monthWithCount', {count: 1}), value: {value: 340, growRate: 14.5}}
      ]
    },
    {
      title: t('diary profit'),
      backgroundColor: 'var(--tertiary-color)',
      Icon: FaRegMoneyBillAlt,
      number: 1339,
      symbol: '€',
      growRate: 2.3,
      formatNumber: value => value.toFixed(2)
    }
  ];
  return (<>
    <div className={s.smallCounters}>
      {counters.map((counter, i) =>
        <AnalyticSmallCounter key={i} {...counter} style={{marginRight: '20px'}} />)}
    </div>
{/*    <div className={s.tmpcharts}>
      <GeneralProfitsChart/>
      <GeneralProfitsPerYearChart/>
    </div>*/}
  </>);
};


export default AnalyticsPage;
