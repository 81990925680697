import s from './Hotel.module.css';
import {GenericHotel, HotelType, HotelTypes, transformToGenericHotel} from "../../types/hotel";
import VerticalHotel from "./VerticalHotel/VerticalHotel";
import {Loader} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {useHotel} from "../../controllers/useHotel";
import {manageError} from "../../helpers/ErrorManager";
import {useTranslation} from "react-i18next";
import ListHotel from "./ListHotel/ListHotel";


interface HotelProps {
  hotel?: HotelType;
  variant: 'vertical' | 'list';
  className?: string;

  have_tick?: boolean;
  checked?: boolean;

  onClick?: (hotel: HotelType) => void;
  onEdit?: (hotel: HotelType) => void;
  onDelete?: (hotel: HotelType) => Promise<void>;
  customElement?: React.ReactNode;

  to_load?: {
    id?: string;
    code?: number;
    api?: string;
    type: HotelTypes;
  }
}

const Hotel = (props: HotelProps) => {
  const {
    hotel,
    have_tick = false,
    checked,
    onClick,
    onEdit,
    onDelete,
    customElement,
    to_load,
    variant = 'vertical'
  } = props;
  let {className} = props;

  const {t} = useTranslation();
  const {searchHotelByCode, getSavedHotel} = useHotel();
  const [transformedHotel, setTransformedHotel] = useState<GenericHotel | undefined>();

  if (onClick) className += ` ${s.clickable}`;

  const loadHotel = async () => {
    if (!to_load || (!to_load.id && !to_load.code)) return;
    if (to_load.type === 'api') {
      const {error, hotel: loadedHotel} = await searchHotelByCode({
        code: to_load.code!,
        api: to_load.api!,
      });
      if (error || !loadedHotel) return manageError(error || 'hotel not found', t);
      setTransformedHotel(transformToGenericHotel(loadedHotel));
    } else {
      const {error, hotel: loadedHotel} = await getSavedHotel(to_load.id!);
      if (error || !loadedHotel) return manageError(error || 'hotel not found', t);
      setTransformedHotel(transformToGenericHotel(loadedHotel));
    }
  }

  useEffect(() => {
    if (to_load) {
      loadHotel();
    } else if (hotel) {
      setTransformedHotel(transformToGenericHotel(hotel!));
    }
  }, [to_load]);


  if (!transformedHotel) return <Loader/>;

  let viewProps = {
    hotel: transformedHotel,
    have_tick,
    checked,
    onEdit: onEdit ? () => onEdit(hotel!) : undefined,
    onDelete: onDelete ? async () => await onDelete(hotel!) : undefined,
    customElement
  };

  return <div className={className} onClick={() => onClick && onClick(hotel!)}>
    {variant === 'vertical' && <VerticalHotel {...viewProps} />}
    {variant === 'list' && <ListHotel {...viewProps}/>}
  </div>
}

export default Hotel;