import useAuth from "../../providers/UserProvider/useAuth";
import {ApiHotel, LocalHotel, transformToGenericHotel} from "../../types/hotel";
import {manageError} from "../../helpers/ErrorManager";

export const useHotel = () => {
  const {secQuery} = useAuth();
  const searchHotelsBar = async ({q, signal}: { q: string, signal?: AbortSignal }) => {
    const {error, data} = await secQuery('/api/sb/hotels', {q}, 'POST', {signal});
    return {error, results: data?.results};
  }

  const searchHotelInRadius = async ({name, coordinates, radius, signal}: {
    name: string,
    coordinates: any,
    radius: number,
    signal?: AbortSignal
  }) => {
    const {error, data} = await secQuery(`/api/admin/hotel/radius_search`, {
      name,
      coordinates,
      radius
    }, 'POST', {signal});
    return {error, hotels: data?.hotels as ApiHotel[]};
  }

  const searchHotelByCode = async ({code, api, signal}: { code: number, api: string, signal?: AbortSignal }) => {
    const {error, data} = await secQuery('/api/admin/hotel/code_search', {hotel: {code, api}}, 'POST', {signal});
    return {error, hotel: data?.hotel};
  }


  const getBoards = async () => {
    const {error, data} = await secQuery('/api/hotel/types/boards');
    return {error, boards: data?.boards};
  }


  const getSavedHotels = async ({admin_id}: { admin_id: string }) => {
    let url = `/api/tour_operator/hotel`;
    if (admin_id) url += `?admin_id=${admin_id}`;
    const {error, data} = await secQuery(url, null, 'GET');
    return {error, hotels: data?.hotels as LocalHotel[]};
  }
  const newSavedHotel = async () => {
    const {error, data} = await secQuery('/api/tour_operator/hotel', null, 'POST');
    return {error, id: data?.id};
  }
  const getSavedHotel = async (id: string) => {
    const {error, data} = await secQuery(`/api/tour_operator/hotel/${id}`, null, 'GET');
    return {error, hotel: data?.hotel};
  }
  const editSavedHotel = async (id: string, hotel: any) => {
    const {error, data} = await secQuery(`/api/tour_operator/hotel/${id}`, {hotel}, 'PUT');
    return {error, success: data?.success};
  }

  const deleteSavedHotel = async (id: string) => {
    const {error, data} = await secQuery(`/api/tour_operator/hotel/${id}`, null, 'DELETE');
    return {error, success: data?.success};
  }


  interface getHotelType {
    id?: string;
    code?: number;
    api?: string;
    signal?: AbortSignal
  }

  const getHotel = async ({id, code, api, signal}: getHotelType) => {
    return await (api ? searchHotelByCode({
      code: code!,
      api: api!,
    }) : getSavedHotel(id!));
  }


  return {
    searchHotelsBar, searchHotelInRadius, searchHotelByCode,
    getBoards, getHotel,
    getSavedHotels, newSavedHotel, getSavedHotel, deleteSavedHotel, editSavedHotel
  };
}

export default useHotel;
