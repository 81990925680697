import React, {useRef} from 'react';
import s from './HeaderProfileItems.module.css';
import {BsBell, BsPerson, BsPower} from 'react-icons/bs';
import {useAuth} from '../../providers/UserProvider/useAuth';
import {VscTriangleDown} from "react-icons/vsc";
import {Menu} from "@mantine/core";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const HeaderProfileItems = props => {
  const {t} = useTranslation();
  const usernameContainer = useRef();
  const navigate = useNavigate();
  const {getAdmin, logout} = useAuth();


  return (
    <div className={s.container}>
      <BsBell className={s.ringIcon}/>

      <Menu control={      <div className={s.user}>
        <BsPerson className={s.userIcon}/>

        <div ref={usernameContainer} className={s.usernameContainer}>
          <div className={s.username}>{getAdmin().name}</div>
          <VscTriangleDown/>
          {/*          <AdminModal triggerRef={usernameContainer}/>*/}

        </div>
      </div>}>
        <Menu.Item icon={<BsPerson size={14} />} onClick={() => navigate('settings/admin/me')}>
          {t('my profile')}
        </Menu.Item>
        <Menu.Item icon={<BsPower size={14} />} onClick={() => {
          navigate('/');
          logout();
        }}>
          {t('logout')}
        </Menu.Item>
      </Menu>


    </div>
  );
};


export default HeaderProfileItems;
