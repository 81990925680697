import {SellersPayment} from "./types";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)


// ¿Cuando se paga? Se paga los dias 15 y 30 de cada mes. El dia 15 pagas las reservas efectuadas del 16 al 30/31 del mes anterior y el 30 pagos las reservas del 1 al 15 de este mes.
export function canPayToSeller(reservation: SellersPayment): boolean {
  if(reservation.commission){
    return false;
  }
  const date = dayjs(reservation.payments[0].date)
  const today = dayjs()
  if(today.isBefore(date)){
    return false
  }

  if(date.year() < today.year()){
    return true
  }

  // mismo anio

  if(today.month() > date.month()){
    if(today.month() - date.month() === 1){
      return date.date() >= 16
    }else {
      return true
    }
  }

  // mismo mes

  if(today.date() >= 30){
    return date.date() <= 15
  }

  return false
}