import React, {useEffect, useMemo, useState} from 'react';
import s from './TONewHotel.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import bases from "../../../../routes/bases";
import useTourOperatorItinerary from "../../../../controllers/useTourOperatorItinerary";
import Select from "../../../Select";
import {manageError, toastError} from "../../../../helpers/ErrorManager";
import {ItHotel, Itinerary, OutletItineraryContext} from "../../../../types/itineraries";
import Footer from "../../Components/Footer/Footer";
import Bullet from "../../Itineraries/Bullet/Bullet";
import TextInput from "../../Itineraries/Inputs/TextInput/TextInput";
import {GoLocation} from "react-icons/go";
import {AiOutlineSearch} from "react-icons/ai";
import Switch from "../../Itineraries/Inputs/Switch/Switch";
import {useHotel} from "../../../../controllers/useHotel";
import SelectAddressOld from "../../../Inputs/SelectAddressOld";
import {formatSearchBarResults} from "../../../../helpers/util";
import {ErrorType} from "../../../../types/errors";
import Button from "../../Itineraries/Inputs/Button/Button";
import Hotel from "../../../Hotel/Hotel";
import {ApiHotel, HotelType} from "../../../../types/hotel";
import {Loader} from "@mantine/core";
import {Address} from "../../../../types/places";


const TONewHotel = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {id, hotel_id} = useParams();

  const {editHotel} = useTourOperatorItinerary();
  const {itinerary, setStep, setItinerary} = useOutletContext<OutletItineraryContext>();
  const {getSavedHotels, searchHotelInRadius} = useHotel();

  const [hotels, setHotels] = useState<any[] | undefined>();
  const {searchPlaceByString} = useTourOperatorItinerary();



  const hotel = useMemo(() => {
    if (!itinerary || !itinerary.hotels) return;
    return itinerary.hotels.find(hotel => hotel._id === hotel_id)
  }, [itinerary]);

  const place = useMemo(() => {
    if (!Array.isArray(itinerary?.places) || !hotel) return false;
    return itinerary.places.find(p => p.id === hotel.place_id);
  }, [itinerary, hotel]);


  const form = useForm<{
    api_hotels: boolean,
    saved_hotels: boolean,
    name: string,
    address?: { raw?: Address },
    radius: number
  }>({
    defaultValues: {
      api_hotels: true,
      saved_hotels: true,
      name: '',
      radius: 50000,
      address: place ? {raw: place.address} : undefined
    }
  });

  const {setValue, watch} = form;

  const api_hotels = watch('api_hotels');
  const saved_hotels = watch('saved_hotels');



  useEffect(() => {
    setStep('hotels');
  }, []);

  useEffect(() => {
    if (!hotel || !itinerary.places) return;
    let place = itinerary.places.find(p => p.id === hotel.place_id);


    if (!place) return;

    if (hotel._id) setSelected(hotel);
    // @ts-ignore
    setValue('address', {raw: place.address, value: place?.address?.label});

    console.log(hotel)
    console.log(hotels)


    // @ts-ignore
    if(hotel.hotel_code){
      const to_load = {
        ...hotel,
        _id: hotel.hotel_id,
        code: hotel.hotel_code
      }

      // @ts-ignore
      setHotels(prev => prev ? [{...hotel,to_load}, ...(prev ?? [])] : [{...hotel,to_load}])
    }
  }, [hotel]);


  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<ItHotel | undefined>(hotel);


  const loadSavedHotels = async () => {
    setLoading(true);
    const {error, hotels} = await getSavedHotels({admin_id: id!});
    setLoading(false);
    if (error) {
      manageError(error, t);
      return false;
    }
    return hotels
  }

  const searchHotels = async () => {
    console.log(saved_hotels)
    let data = form.getValues();
    console.log(data)
    const name = data.name;

    if (api_hotels && !data.address) return manageError({message: 'select your address'}, t);

    let result: HotelType[] = [];
    if (saved_hotels) {
      let saved_hotels = await loadSavedHotels();
      if (saved_hotels) result = saved_hotels;
    }
    if (api_hotels) {
      let coordinates = {
        latitude: data?.address?.raw?.lat,
        longitude: data?.address?.raw?.lng
      };

      const {error, hotels} = await searchHotelInRadius({
        name: name || '',
        radius: data.radius,
        coordinates
      });
      if (!error && Array.isArray(hotels)) result = [...result, ...hotels];
      console.log(hotels);
      if (error) manageError(error, t);
    }

    setHotels(result)

    // @ts-ignore
    //setHotels(result.filter(hotel => hotel.place));
  }

  const rangeRadiusOptions = [
    {value: 50000, label: '50 km'},
    {value: 100000, label: '100 km'},
    {value: 150000, label: '150 km'},
    {value: 200000, label: '200 km'}
  ];

  const onHotelSelect = (hotel: HotelType) => {
    if (!place) return;
    let selected: ItHotel = {
      _id: id!,
      from_day: place.start,
      to_day: place.end,
      place_id: place.id,
      place: {address: place.address!},
      hotel_id: hotel._id,
    };
    const apiHotel = hotel as ApiHotel;
    if (apiHotel.api) {
      selected.api = apiHotel.api;
      selected.hotel_code = apiHotel.code;
    }

    setSelected(selected);
  }


  const onHotelSave = async () => {
    if (!place || !selected) return;


    const {error, hotel} = await editHotel({itinerary_id: id!, hotel_id: hotel_id!, hotel: selected});
    if (error) return manageError(error, t);

    setItinerary((prev: Itinerary) => ({
      ...prev,
      hotels: prev.hotels!.map(_hotel => _hotel._id !== hotel._id ? _hotel : hotel)
    }));

    navigate(`/${bases.tour_operator_itineraries}/${id}/hotels`);
  }

  const searchAddress = async (str: string) => {
    if (str.length <= 1) return [];
    try {
      const results = await searchPlaceByString({place: str});
      return formatSearchBarResults(results);
    } catch (error) {
      manageError(error as ErrorType, t);
    }

    return [];
  }

  const onSwitchChange = (name: 'api_hotels' | 'saved_hotels', value: boolean) => {
    const other_value = name === 'api_hotels' ? saved_hotels : api_hotels;
    if (!other_value && !value) {
      setValue(name === 'api_hotels' ? 'saved_hotels' : 'api_hotels', true)
    }
    setValue(name, value);
  }

  const isHotelSelected = (hotel: HotelType) => {
    console.log(selected)
    if (!selected) return false;
    return hotel._id === selected.hotel_id ||  hotel._id === selected._id;
  }


  const firstSearchHotels = async () => {
    const savedHotels = await loadSavedHotels()
    if(savedHotels)setHotels(prev => prev ? [...(prev ?? []), ...savedHotels] : savedHotels)
  }

  useEffect(() => {
    firstSearchHotels()
  }, []);

  if (!place || !id) return <></>;

  return (
    <FormProvider {...form}>
      <div className="container m-auto mt-10">
        <div style={{width: '600px'}}>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Bullet>{place.end === place.start ? place.start : `${place.start} - ${place.end}`}</Bullet>
              <div className="ml-5">{place.address?.label}</div>
            </div>
            {/*<div className={s.radiusSearchContainer}>*/}
            {/*  <div>{t('search api hotels in radius.radius')}</div>*/}
            {/*  <div style={{width: '150px'}}>*/}
            {/*    <Select*/}
            {/*      name={`radius`}*/}
            {/*      options={rangeRadiusOptions}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>

          <div className="flex items-center w-full">
            <SelectAddressOld
              id={'to_new_hotel'}
              name={'address'}
              icon={<GoLocation size={16}/>}
              searchFunction={searchAddress}
              placeholder={t('search api hotels in radius.address')}
              className="w-full"
              classNames={{
                input: s.addressInput
              }}
            />
            <TextInput
              onChange={e => setValue('name', e.currentTarget.value)}
              className="w-full ml-5 mt-5"
              placeholder={t('to.hotel.search hotel')}
            />
          </div>

          <div className={s.filterContainer}>
            <Switch onChange={(e) => onSwitchChange('api_hotels', e.currentTarget.checked)}
                    checked={api_hotels}>{t('to.hotel.api trippy hotels')}</Switch>
            <Switch onChange={(e) => onSwitchChange('saved_hotels', e.currentTarget.checked)}
                    checked={saved_hotels}>{t('to.hotel.my hotels')}</Switch>
          </div>

          <Button rightIcon={<AiOutlineSearch size={20}/>} className="mt-5" onClick={searchHotels} shadow_border={true}
                  color="yellow" variant="filled">{t('search')}</Button>
        </div>

        {loading && <Loader/>}

        {hotels && hotels?.length > 0 && <div className={s.hotelsContainer}>

          {!hotels && !loading && (selected?.hotel_code || selected?.hotel_id) && <Hotel variant={"vertical"} have_tick={true} checked={true} to_load={{
            type: selected?.api ? 'api' : 'local',
            code: selected?.hotel_code,
            api: selected?.api,
            id: selected?.hotel_id
          }}
					/>}

          {hotels && hotels.length > 0 && !loading && hotels.map(hotel => <Hotel have_tick={true}
                                                                                 checked={isHotelSelected(hotel)}
                                                                                 onClick={onHotelSelect}
                                                                                 className="mr-10 mb-10"
                                                                                 variant={"vertical"}
                                                                                 hotel={hotel}
                                                                                 to_load={hotel.to_load}
          />)}
        </div>}
      </div>
      <Footer arrows={false} onSave={onHotelSave}/>

    </FormProvider>
  );
};


export default TONewHotel;
