import React from 'react';
import s from './MoneyInputWithController.module.css';
import {Controller} from "react-hook-form";
import MoneyInput from "../MoneyInput";

const MoneyInputWithController = ({name, onChange: _onChange, ...props}) => {
  return (
    <Controller
      name={name}
      render={({field: {onChange, value}}) => <MoneyInput
        onChange={v => {
          onChange(v);
          _onChange && _onChange(v);
        }}
        value={value}
        {...props}
      />}
    />
  );
};


export default MoneyInputWithController;
