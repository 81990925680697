import React from 'react';
import s from './Textarea.module.css';
import {Controller} from "react-hook-form";


const Textarea = ({name, label, containerClassName, defaultValue, placeholder, rows = 5, cols = 4, ...props}) => {
  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      render={({field: {onChange: _onChange, value}}) => (
        <div className={`${s.container} ${containerClassName}`}>
          {label && <div className="label">{label}</div>}
          <textarea
            placeholder={placeholder}
            rows={rows}
            cols={cols}
            onChange={e => {
              _onChange(e.target.value)
            }}
            value={value}
          >
          {value}
        </textarea>
        </div>
      )}
    />
  );
};


export default Textarea;
