import React, {useEffect, useState} from 'react';
import s from './TourOperator.module.css';
import {useTranslation} from "react-i18next";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {useForm, FormProvider} from "react-hook-form";
import bases from "../../routes/bases";
import {useTourOperator} from "../../controllers/useTourOperator/useTourOperator";

const TourOperator = props => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {id} = useParams();

  const form = useForm();

  const {getTourOperator} = useTourOperator();

  const [tourOperator, setTourOperator] = useState();
  const [step, setStep] = useState('tour_operator');
  const steps = React.useMemo(() => ['tour_operator', 'hotels', 'itineraries'], []);


  const loadTourOperator = async () => {
    const {error, tour_operator} = await getTourOperator({id});
    if(error){
      alert(error);
      return false;
    }
    setTourOperator(tour_operator);
    return true;
  }

  const onSubmit = data => {
    console.log(data);
  }


  useEffect(() => {
    loadTourOperator();
  }, []);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className={s.header}>
          {steps.map((name, i) => <div key={i} onClick={() => navigate(`/${bases.tour_operator}/${id}/${name}`)}
                                  className={step === name ? s.active_step : ''}>{t(`tour operators data.header.${name}`)}</div>)}
        </div>
        <div className={s.container}>
          <Outlet context={{setStep, tourOperator, setTourOperator, loadTourOperator}}/>
        </div>
      </form>
    </FormProvider>
  );
};


export default TourOperator;
