import {useTranslation} from "react-i18next";
import TDSelectClient from "./TDSelectClient";
import {Button} from "@mantine/core";
import {useEffect, useState} from "react";
import TDClient from "../TDClient";
import s from './TDComponents.module.css';
import useTDClients from "../../../controllers/useTDClients";
import {canTDWrite} from "../../Permissions/VisibleWithPermissions";


const TDCreateOrChooseClient = ({form, className, defaultClientId, client_id = 'client_id', ...props}) => {
  const {t} = useTranslation();
  const [editing, setEditing] = useState(false);
  const [newClients, setNewClients] = useState([]);
  const [createNew, setCreateNew] = useState(false);

  const selected_client = form.values[client_id]

  const {getClient} = useTDClients();

  const {setFieldValue} = form;


  const onCreate = (client) => {
    setNewClients(prev => {
      if (prev.find(c => c._id === client._id)) return prev;
      return [...prev, client];
    })
    setFieldValue(client_id, client._id);
    setEditing(false);
    setCreateNew(false)
  }

  const loadDefaultClient = async id => {
    const {error, client} = await getClient({id});
    if (error) return;
    onCreate(client);
  }

  useEffect(() => {
    if (!defaultClientId) return;
    loadDefaultClient(defaultClientId);
  }, [defaultClientId]);

  return <div className={className}>
    <div className="flex items-end">
      <TDSelectClient defaultClients={newClients} {...form.getInputProps(client_id)}/>
      {canTDWrite() ? <>
        <Button className="ml-5" onClick={() => {
          setCreateNew(true)
          setEditing(true)
        }
        }>{t('td.create new client')}</Button>
        <Button disabled={!selected_client} variant="default" className="ml-5" onClick={() => {
          setEditing(true)
          setCreateNew(false)
        }}>Editar</Button>
      </> : <></>}
    </div>
    {editing && <div>
      <hr className="my-2"/>
      <div className={s.chooseClientBox}>
        <TDClient client_id={selected_client} onCreate={onCreate} onCancel={() => {
          setCreateNew(false)
          setEditing(false)
        }} createNew={createNew}/>
      </div>
      <hr className="my-2"/>
    </div>}
  </div>
}

export default TDCreateOrChooseClient;