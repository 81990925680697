import {Dispatch, SetStateAction, useEffect, useMemo} from "react";

import useSWR from "swr";
import {Table} from "antd";
import {useNavigate} from "react-router-dom";
import {Trainer} from "../types";
import {TableParams, TableResult} from "../../../../util/table/types";
import {handleTableChange, setTotalToTableParams, tableParamsToSearchParams} from "../../../../util/table/table";
import {trainerColumns} from "./columns";
import bases from "../../../../routes/bases";
import {canTDWrite} from "../../../Permissions/VisibleWithPermissions";

interface Props {
  selectedRowKeys: Trainer[]
  onSelectionChange: (selectedRowKeys: Trainer[]) => void
  refresh?: number
  tableParams: TableParams
  setTableParams: Dispatch<SetStateAction<TableParams>>
}

export function TrainersTable({refresh, selectedRowKeys, onSelectionChange, tableParams, setTableParams}: Props){
  const navigate = useNavigate()
  const {isLoading, data, mutate} = useSWR<TableResult<Trainer>>(`/api/admin/td/trainer?${tableParamsToSearchParams(tableParams)}`);

  useEffect(() => {
    setTotalToTableParams(setTableParams, data?.total)
  }, [data]);

  useEffect(() => {
    if(!refresh)return;
    mutate();
  }, [refresh]);

  const canWrite = useMemo(() => canTDWrite(), [])


  return <Table
    rowSelection={{
      selectedRowKeys: selectedRowKeys.map(operation => operation._id),
      onChange: (keys) => onSelectionChange(data?.data.filter(operation => keys.includes(operation._id)) || [])
    }}
    columns={trainerColumns({canWrite, onEdit: (id: string) => navigate(`/${bases.td.trainers}/update/${id}`)})}
    dataSource={data?.data}
    rowKey={trainer => trainer._id}
    loading={isLoading}
    pagination={tableParams.pagination}
    onChange={handleTableChange(setTableParams)}
  />
}