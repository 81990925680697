import React, {useState} from 'react';
import s from './MySavedActivitiesSelector.module.css';
import MySavedActivity from "../MySavedActivity";
import MySavedActivities from "../MySavedActivities";

const MySavedActivitiesSelector = ({onSelect, admin_id, defaultSelected}) => {
	const [editing, setEditing] = useState(false);
	const [selectedId, setSelectedId] = useState(defaultSelected);


	const _onSelect = activity => {
		setSelectedId(activity._id);
		onSelect && onSelect(activity);
	}

	const _onEditActivity = activity => {
		setEditing(activity._id);
		setSelectedId(activity._id);
	}


	if (editing) return <MySavedActivity id={"new"} onSave={activity_id => {
		setEditing(false)
		setSelectedId(activity_id);
	}} onCancel={() => setEditing(false)}/>

	return <MySavedActivities onEditActivity={_onEditActivity} admin_id={admin_id} defaultSelectedId={selectedId}
												onSelect={_onSelect} onNewActivity={() => setEditing(true)}/>
};


export default MySavedActivitiesSelector;