import React from 'react';
import s from './ShowPurchaseHotels.module.css';
import SmallHotel from "../../../Hotel/SmallHotel";
import VerticalTable from "../../../Table/VerticalTable";
import {BooleanRender, MoneyRender} from "../../../Table/Renders";
import Title from "../../../LayoutComponents/Title";
import {useTranslation} from "react-i18next";
import ToggleComponent from "../../../ToggleComponent";

const ShowPurchaseHotels = ({hotels}) => {
  const {t} = useTranslation();

  const createRoomData = () => {

  }
  return hotels.map(hotel => <div>
    <SmallHotel hotelToLoad={{...hotel}}/>
    <div className="mt-10">
      <hr/>
      <VerticalTable
        langGroup="show purchase.hotel booking data"
        data={{
          reference: hotel.booking.reference,
          creationDate: hotel.booking.creationDate,
          status: hotel.booking.status,
          canModify: hotel.booking.modificationPolicies.modification,
          canCancel: hotel.booking.modificationPolicies.cancellation,
          holder: (hotel.booking.holder.name || '') + ' ' + (hotel.booking.holder.surname || ''),
          invoiceCompany: hotel.booking.invoiceCompany.code + ' ' + hotel.booking.invoiceCompany.company + ' ' + hotel.booking.invoiceCompany.registrationNumber,
          totalNet: hotel.booking.totalNet,
          pendingAmount: hotel.booking.pendingAmount
        }}
        valueRenders={{
          canModify: BooleanRender,
          canCancel: BooleanRender,
          totalNet: MoneyRender,
          pendingAmount: MoneyRender
        }}
      />
      {Array.isArray(hotel.rooms) && <ToggleComponent title={`${t('show purchase.show purchase hotels.rooms')} (${hotel.rooms.length})`}>
        {hotel.rooms.map(room => <>
          <VerticalTable
            langGroup="show purchase.show purchase hotels data"
            fetchData={async () => {
              let data = {
                name: room.name
              };
              if (Array.isArray(room.rates)) {
                let rate = room.rates[0];
                data.netPrice = rate.net;
                //data.rateComments = rate.rateComments;
                data.adults = rate.adults;
                if (data.children) data.children = rate.children;
              }
              return {data};
            }}
            valueRenders={{
              netPrice: MoneyRender,
              name: value => <b>{value}</b>
            }}
          />
          <hr/>
        </>)}
      </ToggleComponent>
      }
    </div>
  </div>)
};


export default ShowPurchaseHotels;
