import React, {useEffect} from 'react';
import s from './Select.module.css';
import ReactSelect, {components as componentsSelect} from 'react-select';
import {useTranslation} from "react-i18next";
import {Controller, useFormContext} from "react-hook-form";

const selectStyle = {
  menuPortal: styles => ({
    ...styles,
    zIndex: 20
  }),
  container: () => ({
    width: '100%',
    /*
        minWidth: '100px',
    */
    position: 'relative',
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: 'var(--modal-background-color)'
  }),
  menuList: styles => ({
    ...styles,
    border: '1px solid #6f6f6f'
  }),
  option: (styles, state) => {
    return ({
      ...styles,
      backgroundColor: state.isSelected ? 'var(--select-input-option-background-color)' : '',
      ':hover': {
        backgroundColor: 'var(--select-input-option-hover-background-color)'
      }
    })
  },
  control: (styles, state) => ({
    ...styles,
    flexWrap: 'nowrap',
    background: 'var(--input-background-color)',
    borderColor: 'transparent',
    boxShadow: 'var(--shadow-input)',
    ':hover': {
      boxShadow: state.isFocused ? '0 0 4px var(--shadow-selected-color)' : '0 0 4px var(--shadow-hover-color)',
      borderColor: 'transparent'
    }
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'var(--text-color)'
  }),
  multiValue: (styles) => ({
    ...styles,
    ' div': {
      color: 'var(--text-color)'
    },
    backgroundColor: 'var(--select-multi-value-background-color)'
  })
};

const Select = React.forwardRef(({
                                   name,
                                   id,
                                   usePortal = true,
                                   label,
                                   options,
                                   onChange: _onChange,
                                   components,
                                   containerClassName,
                                   defaultValue,
                                   styles = {},
                                   placeholder,
                                   ...props
                                 }, ref) => {
  const {t} = useTranslation();
  const NoOptionsMessage = props => {
    return <componentsSelect.NoOptionsMessage {...props}>No encontrado</componentsSelect.NoOptionsMessage>
  }
  let _props = {};
  if (usePortal) _props.menuPortalTarget = document.body;

  const context = useFormContext();
  const {control, setValue} = context || {};


  return (
    <div className={`${s.container} ${containerClassName ? containerClassName : ''}`}>
      {label && <label className="label" htmlFor={name}>{label}</label>}
      {context &&
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || options[0]?.value}
        render={({field: {onChange, value, ref}}) => {
          let v = options.find(c => c.value === value);
          if (!v) {
            v = options[0]?.value;
            setValue(name, v);
          }
          return <ReactSelect
            inputRef={ref}
            inputProps={{id}}
            components={{...components, NoOptionsMessage}}
            value={v}
            onChange={val => {
              onChange(val.value);
              _onChange && _onChange(val.value);
            }}
            options={options}
            placeholder={placeholder || t('select')}
            styles={{...selectStyle, ...styles}}
            ref={ref}
            {..._props}
            {...props}
          />
        }
        }/>
      }
      {!context && <ReactSelect
        inputRef={ref}
        inputProps={{id}}
        components={{...components, NoOptionsMessage}}
        options={options}
        placeholder={placeholder || t('select')}
        styles={{...selectStyle, ...styles}}
        ref={ref}
        onChange={a => _onChange && _onChange(a)}
        {..._props}
        {...props}
      />}
    </div>
  );
});


export default Select;
