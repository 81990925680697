import React from 'react';
import s from './TDContactUsData.module.css';
import {useTranslation} from "react-i18next";
import {Button, Title} from "@mantine/core";
import Table from "../../../Table";
import bases from "../../../../routes/bases";
import useTDContactUs from "../../../../controllers/useTDContactUs";
import {DateTimeRender, EmailRender} from "../../../Table/Renders";
import {cutText} from "../../../../helpers/util";
import {useNavigate} from "react-router-dom";

const TDContactUsData = props => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const {getContactUsData} = useTDContactUs();

	return (
		<div className="container m-auto">
			<Title order={2}>{t('td.contact us.title')}</Title>
			<Table
				tableName="td_contact_us"
				id="_id"
				columns={[
					{accessor: '_id', Header: t('id'), type: 'string'},
					{accessor: 'name', Header: t('td.contact us.name'), type: 'string', filter: true},
					{accessor: 'email', Header: t('email'), type: 'string', filter: true},
					{accessor: 'whatsapp', Header: t('whatsapp'), type: 'string', filter: true},
					{accessor: 'subject', Header: t('td.contact us.subject'), type: 'string'},
					{accessor: 'message', Header: t('td.contact us.message'), type: 'string'},
				]}
				onView={({_id}) => navigate(`/${bases.td.contact_us}/${_id}`)}
				defaultHiddenColumns={['_id']}
				fetchData={async ({page, filters}) => {
					const {error, data, totalRecords} = await getContactUsData({page, filters});
					return {data: data ?? [], totalRecords};
				}}
				renders={{
					email: EmailRender,
					message: (v, el) => <>
						{cutText(v, 200)}
						{v?.length > 200 &&
						<div className="link" onClick={() => navigate(`/${bases.td.contact_us}/${el._id}`)}>{t('show more')}</div>}
					</>,
					created_at: DateTimeRender,
				}}
			/>
		</div>
	);
};


export default TDContactUsData;