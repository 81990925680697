import {ItineraryTransportTypes, ItTransport} from "../../types/itineraries";
import s from "../Activity/Activity.module.css";
import SmallTransport from "./SmallTransport/SmallTransport";

export interface TransportProps {
  transport: ItTransport;
  variant: 'small',
  className?: string;

  onClick?: (activity: ItTransport) => void;
  onEdit?: (activity: ItineraryTransportTypes) => void;
  onDelete?: (activity: ItTransport) => void;
}

const Transport = ({transport, variant, className, onClick, onEdit, onDelete}: TransportProps) => {
  if(onClick)className += ` ${s.clickable}`;

  let props = {
    transport,
    onEdit, onDelete
  };

  return <div className={className} onClick={() => onClick && onClick(transport)}>
    {variant === 'small' && <SmallTransport {...props}/> }
  </div>

}

export default Transport;