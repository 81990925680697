import {Select} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {Seller} from "../TDSellers/types";
import {getData} from "../../../util/req";
import {t} from "i18next";

export function TDSelectSeller(props: any){
  const [loading, setLoading] = useState(false)
  const [sellers, setSellers] = useState<Seller[]>([])


  const loadSellers = async () => {
    setLoading(true)
    const result = await getData('/td/seller')
    setLoading(false)
    setSellers(result.data.map((seller: Seller) => ({
      value: seller._id,
      label: seller.name
    })))
  }

  useEffect(() => {
    loadSellers()
  }, [])

  return <Select
    clearable
    loading={loading}
    data={sellers}
    label={"Vendedor"}
    searchable
    nothingFound={t('td.nothing found')}
    {...props}
  />

}