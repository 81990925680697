import React from 'react';
import {useViewportSize} from "@mantine/hooks";


const Chat = props => {
  const {height} = useViewportSize();
  return <iframe style={{height: height - 45, width: '100%'}} src="https://chat.trippytrekkers.com/app/accounts/2/dashboard"/>
};

export default Chat;
