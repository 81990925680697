import InputWithLabel from "../../../InputWithLabel";
import {Controller, useForm} from "react-hook-form";
import DateInput from "../../../Inputs/DateInput";
import {DateRangePicker} from "react-dates";
import Textarea from "../../../Textarea";
import s from "../CalendarPage.module.css";
import LoadingButton from "../../../LoadingButton/LoadingButton";
import Modal from "../../../Modal";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Checkbox from "../../../Inputs/Checkbox";
import moment from "moment";


const EventModal = ({onSubmit, event}) => {
  const {t} = useTranslation();

  const {
    register: registerPostEvent,
    handleSubmit: handlePostEventSubmit,
    control: controlEvent
  } = useForm();

  const [state, setState] = useState({event});

  useEffect(() => {
    setState(prev => ({...prev, event}));
  }, [event]);


  return (
    <Modal
      isOpen={!!state.event}
      onClose={() => setState({event: false})}
    >
      <h3 className="py-8 capitalize text-3xl">
        {state.event?.event_id ? t('edit event') : t("create event")}
      </h3>
      <InputWithLabel
        label={t("name")}
        {...registerPostEvent('name', {
          required: true,
          maxLength: 90
        })}
        defaultValue={state.event?.name}
        className="w-full" />
      <Controller
        name="date"
        control={controlEvent}
        roles={{required: true}}
        defaultValue={state.event?.start}
        render={({onChange, value, ref}) => (
          state.event?.manyDays ? <InputWithLabel
            label={t("date")}
            name="date"
            startDate={moment(value) || state.event?.start}
            endDate={state.event?.end}
            classNameContainer="fullSizeDatePicker"
            Component={DateInput}
            DateType={DateRangePicker}
            onDatesChange={onChange}
            noID={true}
          /> : (
            <InputWithLabel
              label={t("date")}
              name={"date"}
              date={moment(value) || state.event?.start}
              classNameContainer="fullSizeDatePicker"
              Component={DateInput}
              onDateChange={onChange}
              startDate={moment(value) || state.event?.start}
              noID={true}
            />)
        )}
      />
      <Controller
        name="manyDays"
        control={controlEvent}
        defaultValue={state.event.manyDays}

        render={({field: {value, onChange, ref}}) => (
          <InputWithLabel
            label={t("many days")}
            name="manyDays"
            Component={Checkbox}
            className="inline"
            classNameContainer={s.manyDaysCheckbox}
            checked={value}
            ref={ref}
            onChange={({target}) => {
              onChange(target.checked);
              setState(prev => {
                return {...prev, event: {...(prev.event || {}), manyDays: target.checked}};
              })
            }
            }
          />
        )}>
      </Controller>
      <InputWithLabel
        label={t("description")}
        {...registerPostEvent('description', {
          required: true,
          maxLength: 250
        })}
        defaultValue={state.event?.description}
        Component={Textarea}
        classNameContainer="my-5"
        className={s.createEventDescription} />
      <div className="flex items-center justify-center">
        <LoadingButton
          handleSubmit={handlePostEventSubmit}
          onSubmit={onSubmit}
          autoLoading={true}
          text={t('save')}
        />
      </div>
    </Modal>
  );
}

export default EventModal;
