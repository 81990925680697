import {useTranslation} from "react-i18next";
import {useCurrencyProvider} from "../../../../providers/CurrencyProviders/CurrencyProvider";
import React, {useEffect, useMemo} from "react";
import {NumberInput, Select} from "@mantine/core";
import s from "../TDReservation.module.css";
import {DatePicker} from "@mantine/dates";
import {BiTrash} from "react-icons/bi";

export const paymentMethods = [
  {value: 'cash', label: 'Efectivo'},
  {value: 'transfer', label: 'Transferencia'},
  {value: 'card', label: 'Tarjeta'},
  {value: 'stripe', label: 'Stripe'},
  {value: 'paypal', label: 'Paypal'},
  {value: 'link', label: 'Link'}
];

// in percentages
const commissionPerPaymentMethod = {
  paypal: 5,
  link: 5
}


export const Payment = ({form, index}) => {
  const {t} = useTranslation();
  const {exchanges, convertTo} = useCurrencyProvider()

  const nan = (value) => isNaN(value) ? 0 : value;

  const currenciesPerPaymentMethod = {
    transfer: ['MXN', 'USD', 'CAD', 'GBP', 'AUD', 'EUR'],
    link: ['MXN'],
    paypal: ['MXN']
  }


  const paymentMethod = form.values.payments[index].method;

  const allowedCurrencies = useMemo(() => {
    const selectedPaymentForm = form.values.payments[index].method;
    return  currenciesPerPaymentMethod[selectedPaymentForm] ?? exchanges.map(a => a.code);
  }, [paymentMethod])


  // const priceParser = useCallback((value) => {
  //   const regex = new RegExp(`\\${form.values.payments[index].currency}\\s?|(,*)`, 'g');
  //   return value.replace(regex, '')
  // }, [form.values.payments[index].currency])
  //
  // const priceFormatter = useCallback((value) => {
  //   const selectedCurrency = form.values.payments[index].currency;
  //   return !Number.isNaN(parseFloat(value))
  //     ? `${selectedCurrency} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  //     : `${selectedCurrency} `
  // }, [form.values.payments[index].currency])
  //
  // console.log(allowedCurrencies)

  useEffect(() => {
    const currency = form.values.payments[index].currency;
    const currency_amount = form.values.payments[index].currency_amount;

    let amount = convertTo(currency_amount, currency, 'MXN');
   // const commissionPercentage = commissionPerPaymentMethod[paymentMethod]
    //const commission = commissionPercentage ? form.values.payments[index].currency_amount * commissionPercentage / 100 : 0;
    //amount -= commission

    amount = nan(Math.round(amount * 100) / 100);

    // let currency_percentaje = currency_amount / form.values.total_price * 100;
    //
    // if(isNaN(currency_percentaje)){
    //   currency_percentaje = 0
    // }


    form.setListItem('payments', index, {
      ...form.values.payments[index],
      amount,/*currency_percentaje*/
    });
  }, [form.values.payments[index].currency_amount, form.values.payments[index].currency, paymentMethod]);

  //
  // useEffect(() => {
  //   const total_price = form.values.total_price;
  //   const currency_percentaje = form.values.payments[index].currency_percentaje;
  //   let currency_amount = total_price * currency_percentaje / 100;
  //   console.log(total_price)
  //   if(isNaN(currency_amount)){
  //     currency_amount = 0
  //   }
  //   if(form.values.payments[index].currency_amount !== currency_amount){
  //     form.setListItem('payments', index, {
  //       ...form.values.payments[index],
  //       currency_amount
  //     });
  //   }
  //
  // }, [form.values.payments[index].currency_percentaje]);

  return <div className="flex items-end sm:flex-nowrap mt-5">
    <Select
      style={{width: 150}}
      data={paymentMethods}
      className={s.input}
      label={t('td.reservations.payment method placeholder')}
      placeholder={t('td.reservations.payment method placeholder')}
      {...form.getListInputProps('payments', index, 'method')}
    />

    {/*<NumberInput*/}
    {/*  label="Porcentaje"*/}
    {/*  className={s.input}*/}
    {/*  style={{width: 150}}*/}
    {/*  min={0}*/}
    {/*  max={100}*/}
    {/*  precision={0}*/}
    {/*  {...form.getListInputProps('payments', index, 'currency_percentaje')}*/}
    {/*/>*/}


    <NumberInput
      label="Precio"
      className={s.input}
      style={{width: 150}}
      precision={2}
      {...form.getListInputProps('payments', index, 'currency_amount')}
    />

    <Select
      searchable
      data={allowedCurrencies.map(a => ({label: a, value: a}))}
      className={s.input}
      label="Moneda"
      defaultValue="MXN"
      style={{width: 100}}
      {...form.getListInputProps('payments', index, 'currency')}
    />
    {/*<NumberInput*/}
    {/*  disabled={true}*/}
    {/*  label="Comisión"*/}
    {/*  style={{width: 150}}*/}
    {/*  precision={2}*/}
    {/*  className={s.input}*/}
    {/*  {...form.getListInputProps('payments', index, 'commission')}*/}
    {/*/>*/}

    <NumberInput
      disabled={true}
      label="Resultado"
      style={{width: 150}}
      precision={2}
      // parser={(value) => value.replace(/\MXN\s?|(,*)/g, '')}
      // formatter={(value) =>
      //   !Number.isNaN(parseFloat(value))
      //     ? `MXN ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      //     : `MXN `
      // }
      formatter={(value) => `MXN ${value}`}
      className={s.input}
      {...form.getListInputProps('payments', index, 'amount')}
    />



    {/*<NumberInput*/}
    {/*  label={t('td.reservations.percentage')}*/}
    {/*  className={s.input}*/}
    {/*  parser={(value) => value.replace('%', '')}*/}
    {/*  formatter={(value) => `% ${value}`}*/}
    {/*  min={0}*/}
    {/*  max={100}*/}
    {/*  value={(form.values.payments[index].amount / (form.values.total_price - form.values.discount) * 100) || 0}*/}
    {/*  onChange={(value) => {*/}
    {/*    form.setListItem('payments', index, {*/}
    {/*      ...form.values.payments[index],*/}
    {/*      amount: (form.values.total_price - form.values.discount) * value / 100*/}
    {/*    })*/}
    {/*  }}*/}
    {/*/>*/}
    <DatePicker
      label="Fecha de pago"
      className={s.input}
      {...form.getListInputProps('payments', index, 'date')}
    />
    {!!index &&
      <div className={s.removeButton}><BiTrash size={20} onClick={() => form.removeListItem('payments', index)}/></div>}
  </div>
}
