import React from 'react';
import s from './SideBarItems.module.css';
import {NavLink} from 'react-router-dom';
import SideBarRoutes, {findParentRoute} from "../../routes/Routes";

import {useLocation} from 'react-router-dom';
import {useTranslation} from "react-i18next";


const routes = SideBarRoutes.filter(r => !r.hidden);

const SideBarItems = props => {
  const {t} = useTranslation();
  const location = useLocation();
  let {index: activeIndex, hidden} = findParentRoute(location);

  const onLinkClick = () => {
    document.querySelectorAll('.errorCallbackButton ').forEach(button => button.click())
  };

  const linkStyle = {
    height: (props.compact ? 41 : 48) + 'px',
    top: ((props.compact ? 15 + 42 : 15 + 47) * (hidden ? 0 : activeIndex)) + 'px'
  };


  return (
    <div className={s.animationContainer}>
      {!hidden &&  <div className={s.animationBar} style={linkStyle}/>}
      {routes.map((route, i) => (
        <NavLink to={route.path} key={route.key} className={s.link} activeClassName={s.activeLink} onClick={onLinkClick}>
          <div className={s.imageContainer}>
            {route.image}
          </div>
          {!props.compact && <div className={s.itemName}>{t("pages." + route.name)}</div>}
        </NavLink>
      ))}
    </div>
  );
};


export default SideBarItems;
