import {Button, Descriptions, message, Modal, Select} from "antd";
import {CalendarActivity} from "../types";
import routes from "../../../../../routes/bases";
import {dateFormat, LinkFormat, percentageFormat} from "../../../../Formatters/Formatters";
import {MoneyRender} from "../../../../Table/Renders";
import {CancelReservation} from "../../CancelReservation";
import React, {useEffect, useMemo, useState} from "react";
import {isCancellable} from "../../util";
import {Trainer} from "../../../TDTrainers/types";
import {getData, putData} from "../../../../../util/req";
import {canTDWrite} from "../../../../Permissions/VisibleWithPermissions";

interface Props {
  onClose: () => void
  onRefresh: () => void
  activity: CalendarActivity | null
}

export function ActivityDetails({activity, onRefresh, onClose}: Props){
  const [cancel, setCancel] = React.useState(false)
  const [trainer, setTrainer] = useState(activity?.product?.trainer?._id)
  const [trainerLoading, setTrainerLoading] = useState(false)


  useEffect(() => {
    setTrainer(activity?.product?.trainer?._id)
  }, [activity]);

  const [trainers, setTrainers] = useState<Trainer[]>([])


  const loadSellers = async () => {
    const result = await getData('/td/trainer_all')
    if(Array.isArray(result.data)){
      setTrainers(result.data.map((trainer: Trainer) => ({
        value: trainer._id,
        label: trainer.name
      })))
    }
  }

  useEffect(() => {
    loadSellers()
  }, [])

  const onReserveEdit = () => {
    window.open(`/${routes.td.reservations}/${activity!._id}`, '_blank')
    //navigate(`/${routes.td.reservations}/${activity!._id}`)
  }

  let title = activity?.product?.name?.es
  if(activity?.product.code){
    title += ` - ${activity?.product.code}`
  }
  if(activity?.product.date){
    title += ` (${dateFormat(activity?.product.date)}`;
  }
  if(activity?.product.date_end){
    title += ` - ${dateFormat(activity?.product.date_end)})`;
  }else{
    title += ')';
  }

  const onSelectTrainer = async (id: string) => {
    setTrainerLoading(true)
    const response = await putData(`/td/reservation/${activity?._id}/product/${activity?.product!.reservation_product_id}/trainer/${id}`, 'PUT')
    if(response.success){
      setTrainer(id)
      onRefresh()
      message.open({
        type: 'success',
        content: 'El entrenador se ha editado'
      });
    }else{
      message.open({
        type: 'error',
        content: 'Hubo un error al editar el entrenador'
      });
    }
    setTrainerLoading(false)
  }

  const canWrite = useMemo(() => canTDWrite(), [])

  return (
    <>
    <Modal
      width={800}
      footer={[
        activity && isCancellable(activity!) && <Button type="primary" danger onClick={() => setCancel(true)}>Cancelar reserva</Button>,
        canWrite && <Button type="primary" onClick={onReserveEdit}>Editar reserva</Button>,
        <Button onClick={() => onClose()}>Cerrar</Button>
      ]}
      onCancel={onClose} cancelText="Cerrar" okText="Editar reserva" open={!!activity} title={title}>

      <Descriptions layout="vertical" bordered column={3}>
        <Descriptions.Item span={1} label="Cliente"><LinkFormat target="_blank" url={`/${routes.td.clients}/${activity?.client?._id}`} label={activity?.client?.full_name}/></Descriptions.Item>

        <Descriptions.Item span={1} label="Instructor">
          {canWrite ? <Select
            value={trainer}
            style={{minWidth: 150}}
            options={trainers}
            loading={trainerLoading}
            onChange={(v: string) => onSelectTrainer(v)}
          /> :
            <LinkFormat target="_blank" url={`/${routes.td.trainers}/${activity?.product?.trainer?._id}`} label={activity?.product?.trainer?.name}/>
          }

        </Descriptions.Item>
        <Descriptions.Item span={1} label="Vendedor"><LinkFormat target="_blank" url={`/${routes.td.sellers}/${activity?.seller?._id}`} label={activity?.seller?.name}/></Descriptions.Item>
        <Descriptions.Item span={1} label="Adultos">{activity?.product.adults}</Descriptions.Item>
        <Descriptions.Item span={1} label="Niños">{activity?.product.children}</Descriptions.Item>
        <Descriptions.Item span={3} label="Descripción de la reserva">{activity?.description}</Descriptions.Item>
      </Descriptions>


      {activity?.cancellation &&<Descriptions className="mt-5" title="Cancelación" layout="vertical" bordered column={3}>
        <Descriptions.Item label="Fecha de cancelación">{dateFormat(activity.cancellation.cancelledAt)}</Descriptions.Item>
        <Descriptions.Item label="Devolución">
          {percentageFormat(activity.cancellation.refund)}
          <span> </span>
          (<MoneyRender value={activity.total_price * activity.cancellation.refund / 100}/>)
        </Descriptions.Item>
        <Descriptions.Item label="Motivo">{activity.cancellation.reason}</Descriptions.Item>
      </Descriptions>}

    </Modal>

  <CancelReservation reservation={cancel ? activity! : undefined} onClose={(cancelled) => {
    setCancel(false)
    if(cancelled){
      onRefresh()
      onClose()
    }
  }}/>
  </>
  )
}