import useAuth from "../../providers/UserProvider/useAuth";


export const useActivity = () => {
  const {secQuery} = useAuth();

  const searchActivityByCode = async ({code, api, signal}) => {
    const {error, data} = await secQuery('/api/admin/activity/code_search', {activity: {code, api}}, 'POST', {signal});
    return {error, activity: data?.activity};
  }

  const getSavedActivities = async ({admin_id}) => {
    let url = `/api/tour_operator/activity`;
    if(admin_id)url += `?admin_id=${admin_id}`;
    const {error, data} = await secQuery(url, null, 'GET');
    return {error, activities: data?.activities};
  }
  const newSavedActivity = async (activity) => {
    const {error, data} = await secQuery('/api/tour_operator/activity', {activity}, 'POST');
    return {error, id: data?.id};
  }
  const getSavedActivity = async id => {
    const {error, data} = await secQuery(`/api/tour_operator/activity/${id}`, null, 'GET');
    return {error, activity: data?.activity};
  }
  const editSavedActivity = async (id, activity) => {
    const {error, data} = await secQuery(`/api/tour_operator/activity/${id}`, {activity}, 'PUT');
    return {error, success: data?.success};
  }

  const deleteSavedActivity = async id => {
    const {error, data} = await secQuery(`/api/tour_operator/activity/${id}`, null, 'DELETE');
    return {error, success: data?.success};
  }


  return {searchActivityByCode, getSavedActivities, newSavedActivity, getSavedActivity, editSavedActivity, deleteSavedActivity};
}

export default useActivity;
