import React, {useEffect} from 'react';
import s from './ToItineraryPlaces.module.css';
import {useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Controller, useFormContext} from "react-hook-form";
import {NumberInput} from "@mantine/core";
import SelectAddressOld from "../../Inputs/SelectAddressOld";
import {GoLocation} from "react-icons/go";
import {VscChromeClose} from "react-icons/vsc";
import {formatSearchBarResults} from "../../../helpers/util";
import {GrAddCircle} from "react-icons/gr";
import {isObject} from "formik";
import {OutletItineraryContext} from "../../../types/itineraries";
import useTourOperatorItinerary from "../../../controllers/useTourOperatorItinerary";
import {manageError} from "../../../helpers/ErrorManager";
import {ErrorType} from "../../../types/errors";


import {ReactComponent as HotelSVG} from '../../../resources/svg/it/place_hotel.svg';
import {ReactComponent as ActivitySVG} from '../../../resources/svg/it/place_activity.svg';
import {ReactComponent as PlaneSVG} from '../../../resources/svg/it/place_plane.svg';
import {ReactComponent as StartTripSVG} from '../../../resources/svg/it/places_map_ping.svg';
import {ReactComponent as GridSVG} from '../../../resources/svg/it/places_grid.svg';
import Label from "../../Inputs/Label";
import Button from "../Itineraries/Inputs/Button/Button";


const PlaceWithActivity = ({name}: { name: string }) => {
  const {t} = useTranslation();
  const {watch, setValue} = useFormContext();

  const activity_blocked = watch(name);

  return <div className={`${s.clickable_icon} ${activity_blocked ? s.icon_active : s.clickable_icon}`}
              onClick={() => setValue(name, !activity_blocked)}>
    <ActivitySVG/>
    {t('to.itinerary.places list.activities')}
  </div>
}

const ToItineraryPlaces = () => {
  const {t} = useTranslation();
  const {itinerary, isItineraryBlocked} = useOutletContext<OutletItineraryContext>();
  const {unregister, setValue, getValues, watch} = useFormContext();
  const {searchPlaceByString} = useTourOperatorItinerary();


  const name = `places`;

  useEffect(() => {
    if (!getValues(name)) {
      addPlace();
    }
  }, []);


  useEffect(() => {
    let places = itinerary?.places;
    if (!Array.isArray(places)) return;
    places.sort((a, b) => {
      if (a.start < b.start) {
        return -1;
      } else if (a.start > b.start) {
        return 1;
      }
      return 0;
    }).forEach((place, i) => {
      let pName = `${name}.${i}`;
      setValue(`${pName}.id`, place.id || i);
      setValue(`${pName}.start`, place.start);
      setValue(`${pName}.end`, place.end);
      setValue(`${pName}.activity_blocked`, place.activity_blocked);
      if (place.address) {
        setValue(`${pName}.address`, {
          value: place.address.label,
          raw: place.address,
          icon: <GoLocation/>
        });
      }
    });
    if (isObject(itinerary.start_point)) {
      setValue('itinerary.start_point', {
        value: itinerary.start_point.label,
        raw: itinerary.start_point,
        icon: <GoLocation/>
      });
    }
  }, [itinerary]);


  const places = watch(name);

  const findPrevPlaceId = (start_id: number) => {
    let cur = start_id - 1;
    while (cur >= 0) {
      if (getValues(`${name}.${cur}`) !== undefined) return cur;
      cur--;
    }
    return false;
  }

  const findNextPlaceId = (start_id: number) => {
    let cur = start_id + 1;
    let len = places?.length;
    if (!len) return false;
    while (cur <= len) {
      if (getValues(`${name}.${cur}`) !== undefined) return cur;
      cur++;
    }
    return false;
  }

  const addPlace = () => {
    let id = 0;
    if (Array.isArray(places)) {
      id = places.reduce((c, v) => Math.max(c, v.id), 0) + 1;
    }

    const place = `${name}.${id}`;
    let prevPlaceId = findPrevPlaceId(id);
    let startValue = prevPlaceId === false ? 1 : getValues(`${name}.${prevPlaceId}.end`);

    setValue(`${place}.id`, id);
    setValue(`${place}.start`, startValue);
    setValue(`${place}.end`, startValue + 1);
    setValue(`${place}.address`, '');
    setValue(`${place}.activity_blocked`, false);
  }

  const removePlace = (id: number) => {
    /*    unregister(`${name}.${id}.id`);
        unregister(`${name}.${id}.start`);
        unregister(`${name}.${id}.end`);
        unregister(`${name}.${id}.address`);*/
    unregister(`${name}.${id}`);

    fixDays()

  }

  const findFirstPlace = () => {
    let id = 0;
    if (Array.isArray(places)) {
      id = places.reduce((c, v) => Math.max(c, v.id), 0) + 1;
    }

    do {
      const newId = findPrevPlaceId(id)
      if (newId === false) {
        return id
      }
      id = newId
    } while (1)
  }

  const fixDays = () => {
    //console.log(findFirstPlace())
    //console.log(places)
  }


  const searchAddress = async (str: string) => {
    if (str.length <= 1) return [];
    try {
      const results = await searchPlaceByString({place: str});
      return formatSearchBarResults(results);
    } catch (error) {
      manageError(error as ErrorType, t);
    }

    return [];
  }

  const checkEndDay = (start_id: number, value: number) => {
    let next = findNextPlaceId(start_id);

    //if(v > getValues(`${name}.${next}.start`)){
    if (next) {
      setValue(`${name}.${next}.start`, value);
      checkStartDay(next, value);
    }
  }
  const checkStartDay = (id: number, value: number) => {
    if (getValues(`${name}.${id}.end`) < value) {
      setValue(`${name}.${id}.end`, value + 1);
      checkEndDay(id, value + 1);
    }
  }

  let last_place = Array.isArray(places) ? Math.max(...places.filter(a => !isNaN(a?.end)).map(a => a?.end), 0) : 1;


  return (
    <div>
      <div className={s.startYourTripIn}>
        <div className={s.verLineStartPoint}/>
        <div className="flex items-center mr-20" style={{flex: '0 0 200px'}}>
          <StartTripSVG/>
          <div className="ml-5 text-2xl font-semibold">{t('to.itinerary.start your trip in')}</div>
        </div>
        <SelectAddressOld
          id={`it_start_point_id`}
          name={`itinerary.start_point`}
          icon={<GoLocation size={16}/>}
          searchFunction={searchAddress}
          label={t('to.itinerary.places.address')}
          placeholder={t('to.itinerary.places.start trip placeholder')}
          className="w-full"
          disabled={isItineraryBlocked}
          classNames={{
            input: s.addressInput
          }}
          loaderProps={{color: 'var(--it-green)'}}
        />
      </div>

      {Array.isArray(places) && places.filter(a => a).map((place, i) => <div key={place.id}
                                                                             className={s.placeContainer}>
        {/*<GridSVG width="21px" height="34px" className={s.grid}/>*/}
        <div className={s.verLine}/>
        <div className={s.iconsContainer}>
          <PlaceWithActivity name={`${name}.${place.id}.activity_blocked`}/>


          <div
            className={`${s.icon} ${getValues(`${name}.${place.id}.start`) !== getValues(`${name}.${place.id}.end`) ? s.icon_active : ''}`}>
            <HotelSVG/>
            <div>{t('to.itinerary.places list.hotels')}</div>
          </div>


          <div className={`${s.icon} ${!i ? s.icon_active : ''}`}>
            <PlaneSVG/>
            <div>{t('to.itinerary.places list.airplanes')}</div>
          </div>
        </div>

        <Controller
          name={`${name}.${place.id}.start`}
          render={({
                     field: {onChange, value, name}
                   }) => (
            <NumberInput
              // disabled={!i || isItineraryBlocked}
              disabled={isItineraryBlocked}
              name={name}
              value={value}
              onChange={v => {
                if (v) checkStartDay(place.id, v);
                onChange(v);
              }}
              label={t('to.itinerary.places.start day')}
              className={`${s.numberInput} mr-10`}
            />
          )}
        />
        <Controller
          name={`${name}.${place.id}.end`}
          render={({
                     field: {onChange, value, name}
                   }) => (
            <NumberInput
              name={name}
              value={value}
              // disabled={!i || isItineraryBlocked}
              disabled={isItineraryBlocked}
              onChange={v => {
                /*                const name = 'itinerary.places';
                                if(getValues(`${name}.${place.id}.start`) > v)setValue(`${name}.${place.id}.start`, v);


                                let prevId = findNextPlaceId(place.id);
                                if(prevId !== false){
                                  setValue(`${name}.${prevId}.start`, v);
                                  if(getValues(`${name}.${prevId}.end`) < v)setValue(`${name}.end`, v + 1);
                                }*/
                onChange(v);
                if (v) checkEndDay(place.id, v);
              }}
              label={t('to.itinerary.places.end day')}
              className={`${s.numberInput} mr-10`}
            />
          )}
        />
        <SelectAddressOld
          id={`${name}.${place.id}.address_id`}
          name={`${name}.${place.id}.address`}
          icon={<GoLocation size={16}/>}
          searchFunction={searchAddress}
          label={t('to.itinerary.places.address')}
          placeholder={t('to.itinerary.places.placeholder')}
          className={s.selectAddress}
          classNames={{
            input: s.selectAddressInput
          }}
          disabled={isItineraryBlocked}
          loaderProps={{color: 'var(--it-green)'}}
        />
        {!isItineraryBlocked && places.filter(a => a).length > 1 &&
          <div className="flex flex-col items-center justify-center ml-5" style={{flex: '0 0 60px'}}>
            <Label name="">{t('remove')}</Label>
            <div className={s.removeButton} onClick={() => removePlace(place.id)}>
              <VscChromeClose size={25}/>
            </div>
          </div>}
      </div>)}

      <div className="mt-10">

        <Button disabled={isItineraryBlocked} color="yellow" variant="filled" shadow_border={true}
                rightIcon={<GrAddCircle className={s.add_circle_stroke} size={20}/>}
                onClick={addPlace}>{t('to.itinerary.places.add')}</Button>
      </div>
    </div>
  );
};


export default ToItineraryPlaces;
