import s from './ListActivity.module.css';
import Image from "../../../Image";
import {ActivityType} from "../../../../types/activity";
import {langRender} from "../../../../helpers/i18n";
import React from "react";
import Rating from "../../../Inputs/Rating/Rating";
import {cutText} from "../../../../helpers/util";
import Button from "../../../TourOperator/Itineraries/Inputs/Button/Button";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import {useTranslation} from "react-i18next";

interface ListActivityProps {
  activity: ActivityType;
  className?: string;

  have_tick?: boolean;
  checked?: boolean;

  onEdit?: (activity: ActivityType) => void;
  onDelete?: (activity: ActivityType) => void;
}

const ListActivity = ({activity, onEdit, onDelete}: ListActivityProps) => {
  const {t} = useTranslation();

  const haveButtons = !!onDelete || !!onEdit

  return (
    <div className={s.container}>
      <div className={s.verBar}/>
      <div className={s.leftContainer}>
        <div className={s.image}>
          <Image src={activity.images[0]} width={200} alt={'activity image'}/>
        </div>
        <div className="p-2">
          <div className="text-2xl font-semibold">{langRender(activity.name)}</div>
          {activity.address?.label && <div className="">{activity.address?.label}</div>}
          <Rating stars={activity.stars}/>
        </div>
      </div>
      <div className={s.rightContainer}>
        <div
          className={s.description}>{cutText(langRender(activity.description), haveButtons ? 200 : 270) || t('to.activity.no description')}</div>
        {haveButtons && <div className={s.buttons}>
          {onDelete && <Button color="white" className={s.deleteButton} leftIcon={<AiOutlineDelete size={18}/>}
                               variant="filled"
                               onClick={() => onDelete(activity)}/>}

          {onEdit &&
            <Button color="green" variant="outline" className={s.editButton} rightIcon={<AiOutlineEdit size={18}/>}
                    onClick={() => onEdit(activity)}>
              {t('to.edit')}
            </Button>
          }
        </div>}
      </div>
    </div>
  );
}

export default ListActivity;