import config from "../config";
import {handleRequestError} from "./errorManager/errorManager";


const getToken = () => {
  try{
    const rawAuthData = window.localStorage.getItem('authData')
    const data = JSON.parse(rawAuthData!)
    return data.token
  }catch(e){
    return ''
  }
}

export async function getData(url: string){
  return handleRequestError(fetch(config.serverURL + config.adminPath + url, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    }
  }).then(res => res.json()))
}

export async function putData(url: string, data: unknown){
  return handleRequestError(fetch(config.serverURL + config.adminPath + url, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    }
  }).then(res => res.json()))
}

export async function postData(url: string, data: unknown){
  return handleRequestError(fetch(config.serverURL + config.adminPath + url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    }
  }).then(res => res.json()))
}

export async function deleteData(url: string){
  return handleRequestError(fetch(config.serverURL + config.adminPath + url, {
    method: 'DELETE',
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  }).then(res => res.json()))
}