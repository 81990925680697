import React, {useCallback, useEffect, useRef} from 'react';
import s from './GlobalSearchBar.module.css';
import {BsSearch} from "react-icons/bs";

import InputWithIcon from "../InputWithIcon";
import {useTranslation} from "react-i18next";
import {openSpotlight} from "@mantine/spotlight";


const GlobalSearchBar = props => {
  const {t} = useTranslation();

  return (
    // <div className={s.container} onClick={() => openSpotlight()}>
      <div className={s.container}>
      <BsSearch size={18} className="mr-5"/>
      {t('search')}...
    </div>
  );
};


export default GlobalSearchBar;
