import React from "react";
import OverviewPage from "../components/OverviewPage";
import CalendarPage from "../components/Calendar/CalendarPage";
import AnalyticsPage from "../components/Analytics/AnalyticsPage";
import NotFoundPage from "../components/Pages/NotFoundPage";
import SettingsPage from "../components/Settings/SettingsPage";
import Content from "../components/Pages/GlobalContent";
import AdministratorsMe from "../components/Admin/AdminMe";
import Clients from "../components/Clients/ClientsTable/ClientsTable";
import PageSettings from "../components/Settings/PageSettings";
import Social from "../components/Social";
import ShowClient from "../components/Show/ShowClient";
import {Outlet} from "react-router-dom";
import ClientCalendar from "../components/Calendar/ClientCalendar";
import ContactUsData from "../components/ContactUsData";
import bases from "./bases";
import StartYourTripData from "../components/StartYourTripData";
import TONewItinerary from "../components/TourOperator/TONew/TONewItinerary";
import TONewHotel from "../components/TourOperator/TONew/TONewHotel";
import TONewActivity from "../components/TourOperator/TONew/TONewActivity";
import TOHotels from "../components/TourOperator/TOHotels";
import TOActivities from "../components/TourOperator/TOActivities";
import TOTransports from "../components/TourOperator/TOTransports";
import TOItineraries from "../components/TourOperator/TOItineraries";
import TONew from "../components/TourOperator/TONew/TONew";
import ToPrices from "../components/TourOperator/ToPackages/ToPackages";
import ShowPurchase from "../components/Show/ShowPurchase";
import ShowContactUs from "../components/ShowContactUs";
import ShowStartYourTrip from "../components/StartYourTripData/ShowStartYourTrip";
import AdminData from "../components/Admin/AdminTable";
import AdminEdit from "../components/Admin/AdminEdit";
import MySavedHotels from "../components/MySavedHotels";
import MySavedHotel from "../components/MySavedHotels/MySavedHotel";
import ToNewPackage from "../components/TourOperator/TONew/ToNewPackage/ToNewPackage";
import ToSummaryIt from "../components/TourOperator/ToSummaryIt";
import TourOperators from "../components/TourOperator/TourOperators";
import EditTourOperator from "../components/EditTourOperator";
import TourOperator from "../components/TourOperator";
import TDProducts from "../components/TD/TDProducts";
import TDProduct from "../components/TD/TDProduct";
import TDReservation from "../components/TD/TDReservation";
import TDCourses from "../components/TD/TDCourses";
import TDSnorkels from "../components/TD/TDSnorkels";
import TDDives from "../components/TD/TDDives";
import TDContactUsData from "../components/TD/TDContactUs/TDContactUsData";
import TDShowContactUs from "../components/TD/TDContactUs/TDShowContactUs";
import ConfigAPIHotels from "../components/Settings/ConfigAPI/ConfigAPIHotels";
import CreateNewItinerary from "../components/TourOperator/CreateNewItinerary";
import Administrator from "../components/Admin/AdminPage";
import ToFeatures from "../components/TourOperator/ToFeatures";
import ToAvailability from "../components/TourOperator/ToAvailability";
import MySavedActivities from "../components/Activity/MySavedActivities";
import MySavedActivity from "../components/Activity/MySavedActivities/MySavedActivity";
import ToItineraryValidation from "../components/TourOperator/ToItineraryValidation";
import ItinerariesToValidate from "../components/ItinerariesToValidate";
import GalleryPage from "../components/GalleryPage";
import Chat from "../components/Social/Chat";
import TourOperatorRequestedBudgets from "../components/TourOperator/TourOperatorRequestedBudgets";
import TourOperatorRequestedBudget from "../components/TourOperatorRequestedBudget";
import ConfigAPIInsurance from "../components/Settings/ConfigAPI/ConfigAPIInsurance";
import TDClients from "../components/TD/TDClients";
import TDClient from "../components/TD/TDClient";
import TDPayments from "../components/TD/TDPayments";
import {TableOrdered} from "../components/Payments/TableOrdered/TableOrdered";
import PaymentsTable from "../components/Payments/PaymentsTable/PaymentsTable";
import {TDCalendarActivities} from "../components/TD/TDReservations/Calendar/TDCalendarActivities";
import {TDReservations} from "../components/TD/TDReservations/TDReservations";
import {TDSellers} from "../components/TD/TDSellers/Sellers";
import {TDSellersUpdate} from "../components/TD/TDSellers/SellersUpdate";
import TDSellersCreate from "../components/TD/TDSellers/SellersCreate";
import {TDSellersPayment} from "../components/TD/TDReservations/SellersPaymentTable/TDSellersPayment";
import {TDTrainers} from "../components/TD/TDTrainers/Trainers";
import TDTrainersCreate from "../components/TD/TDTrainers/TrainersCreate";
import {TDTrainersUpdate} from "../components/TD/TDTrainers/TrainersUpdate";
import {TDTrainersPayment} from "../components/TD/TDReservations/TrainersPaymentTable/TDTrainersPayment";
import {TDEvents} from "../components/TD/TDEvents/Events";
import TDEventsCreate from "../components/TD/TDEvents/EventsCreate";
import {TDEventsUpdate} from "../components/TD/TDEvents/EventsUpdate";


let routes = [
	{
		path: '/', element: <Content/>, children: [
			{index: true, element: <OverviewPage/>},
			{path: 'home', element: <OverviewPage/>},
			{path: 'calendar', element: <CalendarPage/>},
			{path: 'analytics', element: <AnalyticsPage/>},
			{
				path: 'social', element: <Social/>,
				children: [
					{index: true, element: <Chat/>}
				]
			},
			{path: 'payments', element: <NotFoundPage/>},
			{path: bases.configuration, element: <SettingsPage/>,
				children: [
					{index: true, element: <PageSettings/>},
					{path: 'page', element: <PageSettings/>},
					{
						path: 'admin', element: <Outlet/>,
						children: [
							{index: true, element: <AdminData/>},
							{path: ':id', element: <AdminEdit/>},
							{path: 'new', element: <AdminEdit isNew={true}/>},
							{path: 'me', element: <AdministratorsMe/>}
						]
					},
					{
						path: 'apis', element: <Outlet/>,
						children: [
							{index: true, element: <ConfigAPIInsurance/>},
							{path: 'insurance', element: <ConfigAPIInsurance/>},
						]
					},
					{
						path: 'api', element: <Outlet/>,
						children: [
							{index: true, element: <ConfigAPIHotels/>}
						]
					}
				]
			},
			{
				path: `${bases.admin}/:id`, element: <Administrator/>,
				children: [
					{index: true, element: <AdminEdit administratorsPage={true}/>},
					{path: bases.admin_hotels, element: <MySavedHotels/>},
					{path: bases.admin_activities, element: <MySavedActivities/>},
					{path: bases.admin_itineraries, element: <TOItineraries/>}
				]
			},
			{
				path: bases.clients, element: <Clients/>
			},
			/*      {path: 'client', element: <ClientTable/>,
							children: [
								{index: true, element: <ClientsData/>},
								{path: ':id', element: <ClientEditProfile/>}
							]
						},*/
			{
				path: bases.client, element: <Outlet/>, children: [
					/*{index: true, element: <ClientTable/>},*/
					{path: ':id', element: <ShowClient/>},
					{path: ':id/purchase', element: <TableOrdered/>},
					{path: ':id/calendar', element: <ClientCalendar/>}
				]
			},
			{
				path: bases.ordered, element: <Outlet/>, children: [
					{index: true, element: <TableOrdered/>},
					]
			},
			{
				path: bases.purchase, element: <Outlet/>, children: [
					{index: true, element: <TableOrdered/>},
					{path: ':id', element: <ShowPurchase/>},
					{path: 'real', element: <PaymentsTable/>}
				]
			},
			{
				path: bases.tour_operator, element: <Outlet/>, children: [
					{index: true, element: <TourOperators/>},
					{
						path: ':id', element: <TourOperator/>, children: [
							{index: true, element: <AdminEdit/>},
							{path: 'tour_operator', element: <AdminEdit/>},
							/*							{path: 'hotels', element: <TourOperatorHotels/>},
														{path: 'itineraries', element: <TourOperatorItineraries/>}*/
						]
					},
					{path: 'edit/:id', element: <EditTourOperator/>},
					{
						path: 'it', element: <Outlet/>, children: [
							{index: true, element: <TOItineraries/>},
							{
								path: ':id', element: <TONew/>, children: [
									{index: true, element: <TONewItinerary/>},
									{path: 'itineraries', element: <TONewItinerary/>},

									{path: 'hotels', element: <TOHotels/>},
									{path: 'hotel/:hotel_id', element: <TONewHotel/>},

									{path: 'activities', element: <TOActivities/>},
									{path: 'activity/:activity_id', element: <TONewActivity/>},

									{path: 'transport', element: <TOTransports/>},

									{path: 'features', element: <ToFeatures/>},

									{path: 'packages', element: <ToPrices/>},

									{path: 'package/:package_id', element: <ToNewPackage/>},

									{path: 'summary', element: <ToSummaryIt/>},

									{path: 'availability', element: <ToAvailability/>},

									{path: 'validation', element: <ToItineraryValidation/>}
								]
							}
						]
					},
					{path: 'it_n', element: <CreateNewItinerary/>},
					{path: 'budgets', element: <Outlet/>, children: [
						{index: true, element: <TourOperatorRequestedBudgets/>},
						{path: ':id', element: <TourOperatorRequestedBudget/>}
						]}
				]
			},
			{
				path: bases.itineraries_to_validate, element: <ItinerariesToValidate/>
			},
			{
				path: bases.saved_hotel, element: <Outlet/>, children: [
					{index: true, element: <MySavedHotels grid={true}/>},
					{path: ':id', element: <MySavedHotel grid={true}/>}
				]
			},
			{
				path: bases.saved_activity, element: <Outlet/>, children: [
					{index: true, element: <MySavedActivities grid={true}/>},
					{path: ':id', element: <MySavedActivity grid={true}/>}
				]
			},
			{
				path: bases.gallery, element: <GalleryPage/>
			}
		]
	},
	{
		path: bases.contact_us, element: <Outlet/>, children: [
			{index: true, element: <ContactUsData/>},
			{path: ':id', element: <ShowContactUs/>}
		]
	},
	{
		path: bases.start_your_trip, element: <Outlet/>, children: [
			{index: true, element: <StartYourTripData/>},
			{path: ':id', element: <ShowStartYourTrip/>}
		]
	},
	{
		path: bases.admin, element: <Outlet/>, children: [
			{index: true, element: <AdminData/>},
			{path: ':id', element: <AdminEdit/>}
		]
	},
	{
		path: bases.td.main, element: <Outlet/>, children: [
      {
        path: 'sellers', element: <Outlet/>, children: [
          {index: true, element: <TDSellers/>},
          {path: 'new', element: <TDSellersCreate/>},
          {path: 'update/:id', element: <TDSellersUpdate/>}
          ]
      },
      {
        path: 'trainers', element: <Outlet/>, children: [
          {index: true, element: <TDTrainers/>},
          {path: 'new', element: <TDTrainersCreate/>},
          {path: 'update/:id', element: <TDTrainersUpdate/>}
        ]
      },
      {
        path: 'events', element: <Outlet/>, children: [
          {index: true, element: <TDEvents/>},
          {path: 'new', element: <TDEventsCreate/>},
          {path: 'update/:id', element: <TDEventsUpdate/>}
        ]
      },
      {
        path: 'sellers_payments', element: <Outlet/>, children: [
          {index: true, element: <TDSellersPayment/>},
        ]
      },
      {
        path: 'trainers_payments', element: <Outlet/>, children: [
          {index: true, element: <TDTrainersPayment/>},
        ]
      },
			{
				path: 'products', element: <Outlet/>, children: [
					{index: true, element: <TDProducts/>},
					{path: ':id', element: <TDProduct/>},
				]
			},
			{path: 'courses', element: <TDCourses/>},
			{path: 'snorkels', element: <TDSnorkels/>},
			{path: 'dives', element: <TDDives/>},
			{
				path: 'contact_us', element: <Outlet/>, children: [
					{index: true, element: <TDContactUsData/>},
					{path: ':id', element: <TDShowContactUs/>}
				]
			},
      {
        path: 'calendar', element: <Outlet/>, children: [
          {path: 'activities', element: <TDCalendarActivities/>},
          {path: 'sellers', element: <TDCalendarActivities/>},
          ]
      },
			{
				path: 'reservations', element: <Outlet/>, children: [
					{index: true, element: <TDReservations/>},
					{path: ':id', element: <TDReservation/>}
				]
			},
			{
				path: 'clients', element: <Outlet/>, children: [
					{index: true, element: <TDClients/>},
					{path: ':id', element: <TDClient/>}
				]
			},
			{
				path: 'payments', element: <TDPayments/>
			}
		]
	},
	{path: '*', element: <NotFoundPage/>}
];

export default routes;
