import React, {useState} from "react";
import {SelectionContext} from "./SelectionContext";
import {useTranslation} from "react-i18next";
import s from './SelectionProvider.module.css';

import {IoIosClose} from 'react-icons/io';
import {useNavigate} from "react-router-dom";
import bases from "../../routes/bases";

const SelectionProvider = props => {
  const {t} = useTranslation();

  const navigate = useNavigate();

  const [client, setClient] = useState(false);
  const [flight, setFlight] = useState(false);
  const [hotel, setHotel] = useState(false);
  const [activity, setActivity] = useState(false);
  const [purchase, setPurchase] = useState(false);

  const hasSelection = () => !!client || !!flight || !!hotel || !!activity || !!purchase;
  const resetSelections = () => {
    setClient(false);
    setFlight(false);
    setHotel(false);
    setActivity(false);
    setPurchase(false);
  }

  const drawSelected = React.useMemo(() => (<div>
      <div className={s.selections}>
        {hasSelection() && <div className={s.remove_selections}
                                onClick={() => resetSelections()}>{t('selection provider.remove selections')}</div>}
        {client &&
        <div className={s.selection}
             style={{backgroundColor: '#2684ff'}}
        >
          <div
            onClick={() => navigate(`/${bases.client}/${client.user_id}`)}>{t('selection provider.client')}: {client.name}</div>
          <div className={s.close_icon} onClick={() => setClient(false)}>
            <IoIosClose/>
          </div>
        </div>}

        {hotel && <div className={s.selection}
                       style={{backgroundColor: '#d83f87'}}
        >
          <div
            onClick={() => navigate(`/${bases.hotel}/${hotel.id}`)}>{t('selection provider.hotel')}: {hotel.name}</div>
          <div className={s.close_icon} onClick={() => setHotel(false)}>
            <IoIosClose/>
          </div>
        </div>}
        {activity && <div className={s.selection}>{activity.name}</div>}
        {flight && <div className={s.selection}>{flight.name}</div>}
        {purchase && <div className={s.selection} style={{backgroundColor: '#e939ee'}}>
          <div onClick={() => navigate(`/${bases.purchase}/${purchase.payment_id}`)}>
            {t('selection provider.purchase')}: {purchase.payment_id}
          </div>
          <div className={s.close_icon} onClick={() => setPurchase(false)}>
            <IoIosClose/>
          </div>
        </div>}
      </div>
    </div>
  ), [client, hotel, activity, flight, purchase]);


  return <SelectionContext.Provider value={{
    client, setClient,
    flight, setFlight,
    hotel, setHotel,
    activity, setActivity,
    purchase, setPurchase,
    hasSelection, resetSelections,
    drawSelected
  }}>
    {props.children}
  </SelectionContext.Provider>
}

export default SelectionProvider;
