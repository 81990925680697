import {TableParams} from "../../../../util/table/types";
import {useState} from "react";
import {Badge, Button, Form, Modal, Select} from "antd";
import {paymentMethods} from "../../TDReservation/components/Payment";
import {AdditionalFiltersForm} from "../types";

interface Props {
  tableParams: TableParams
  setTableParams: (tableParams: TableParams) => void
}

export function TDReservationsTableMoreFilters({tableParams, setTableParams}: Props){
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm<AdditionalFiltersForm>();
  const [enabledFilters, setEnabledFilters] = useState<number>(0)

  const onFilter = (data: AdditionalFiltersForm) => {
    setEnabledFilters(Object.keys(data).filter((key) => data[key as keyof AdditionalFiltersForm]).length)
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        ...data
      }
    })
    setModalVisible(false)
  }

  const resetFilters = () => {
    form.resetFields();
    setEnabledFilters(0)
    setTableParams({
      ...tableParams,
      filters: {}
    })
  }

  return <>
    <div className="flex items-center">
      <Button className="mr-5" onClick={() => setModalVisible(true)}>
        Filtros adicionales
        <Badge style={{position: 'absolute', top: '-27px', right: '-24px'}} count={enabledFilters}/>
      </Button>
      <Button type="dashed" onClick={() => resetFilters()}>Restablecer filtros</Button>
    </div>
    <Modal
      title="Filtros adicionales"
      open={modalVisible}
      onOk={() => form.submit()}
      onCancel={() => setModalVisible(false)}>
      <Form onFinish={onFilter} form={form}>
        <Form.Item name="payment_method" label="Método de pago">
          <Select
            showSearch
            allowClear
            options={paymentMethods}
          />
        </Form.Item>

        <Form.Item name="payment_method" label="Método de pago">
          <Select
            showSearch
            allowClear
            options={paymentMethods}
          />
        </Form.Item>
        {/*<Form.Item name="activity_date" label="Fecha de actividad">*/}
        {/*  <RangePicker*/}

        {/*  />*/}
        {/*</Form.Item>*/}
      </Form>
    </Modal>
  </>
}