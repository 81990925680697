import {EmergencyContactType} from "../../../controllers/usePurchaseController/types";
import VerticalTable from "../../NewTable/VerticalTable";
import {AddIconRenderer, EmailRender, PhoneRender} from "../../Table/Renders";
import React from "react";
import {AiOutlineMail, AiOutlinePhone} from "react-icons/ai";

interface ContactDataProps {
  data: EmergencyContactType
}

const EmergencyContactData = ({data}: ContactDataProps) => {
  return <VerticalTable
    langGroup="show purchase.emergency contact data"
    data={data}
    valueRenders={{
      phone_number: PhoneRender,
      email: EmailRender
    }}
    nameRenders={{
      phone: AddIconRenderer(<AiOutlinePhone size={16}/>),
      email: AddIconRenderer(<AiOutlineMail size={16}/>),
    }}
  />;
}

export default EmergencyContactData;