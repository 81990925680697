import useAuth from "../../providers/UserProvider/useAuth";


export const useTDReservations = () => {
	const {secQuery} = useAuth();
	const base = '/api/admin/td/reservation';

	const getReservations = async ({page = 0, filters = {}, signal} = {}) => {
		const {error, data} = await secQuery(`${base}s?page=${page}`, {filters}, 'POST', {signal});
		return {error, reservations: data?.reservations, totalRecords: data?.totalRecords};
	}
	const newReservation = async ({signal} = {}) => {
		const {error, data} = await secQuery(base, {}, 'POST', {signal});
		return {error, id: data?.id};
	}

	const getReservation = async ({id}) => {
		const {error, data} = await secQuery(`${base}/${id}`, null, 'GET');
		return {error, reservation: data?.reservation};
	}
	const editReservation = async ({id, reservation}) => {
		const {error, data} = await secQuery(`${base}/${id}`, {reservation}, 'PUT');
		return {error, success: data?.success};
	}

	const deleteReservation = async ({id}) => {
		const {error, data} = await secQuery(`${base}/${id}`, null, 'DELETE');
		return {error, success: data?.success};
	}

	const getSellers = async () => {
		const {error, data} = await secQuery(`${base}/set_sellers`, null, 'GET');
		let sellers = data?.sellers;
		if (Array.isArray(sellers)) sellers = sellers.filter(a => a);
		return {error, sellers};
	}
	const getTotalData = async ({filters}) => {
		const {error, data} = await secQuery(`${base}/total_data`, ({filters}), 'POST');
		return {error, data: data};
	}


	const getPayments = async ({page = 0, filters = {}}) => {
		const {error, data} = await secQuery(`/api/admin/td/payments?page=${page}`, ({filters}), 'POST');
		return {error, payments: data?.payments, totalRecords: data?.totalRecords};
	}

	const getPaymentTotalData = async ({filters}) => {
		const {error, data} = await secQuery(`/api/admin/td/payment/total_data`, ({filters}), 'POST');
		return {error, data};
	}


	return {
		getReservations,
		newReservation,
		getReservation,
		editReservation,
		deleteReservation,
		getSellers,
		getTotalData,
		getPayments,
		getPaymentTotalData
	}
}
