import React, {useEffect, useState} from 'react';
import s from './MySavedHotels.module.css';
import {useTranslation} from "react-i18next";
import {useHotel} from "../../controllers/useHotel";
import Loading from "../Loading";
import SmallHotel from "../Hotel/SmallHotel";
import {useNavigate, useParams} from "react-router-dom";
import bases from "../../routes/bases";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import Button from "../Inputs/Button";
import {Title} from "@mantine/core";
import useMenu from "../../providers/MenuProvider/useMenu";

import config from '../../config';
import {LocalHotel} from "../../types/hotel";
import Hotel from "../Hotel/Hotel";


interface MySavedHotelsProps {
  admin_id?: string;
  grid?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  onSelect?: (hotel: LocalHotel) => void;
  onNewHotel?: (hotel: {_id: string}) => void;
  onEditHotel?: (hotel: LocalHotel) => void;
  defaultSelectedId?: string;
}

const MySavedHotels = (props: MySavedHotelsProps) => {
  const {
    admin_id: external_admin_id,
    grid = false,
    canEdit = true,
    canDelete = true,
    onSelect = false,
    onNewHotel,
    onEditHotel,
    defaultSelectedId
  } = props;


  const {t} = useTranslation();
  const navigate = useNavigate();
  const {getSavedHotels, newSavedHotel, deleteSavedHotel} = useHotel();
  let {id: internal_admin_id} = useParams();


  let id = external_admin_id || internal_admin_id!;


  const [hotels, setHotels] = useState<LocalHotel[]>();
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState<{_id: string}>();


  const loadHotels = async () => {
    setLoading(true);
    const {error, hotels} = await getSavedHotels({admin_id: id});
    setLoading(false);
    if (error) return alert(error);
    setHotels(hotels);
  }

  useEffect(() => {
    loadHotels();
  }, []);

  useEffect(() => {
    if (defaultSelectedId) setSelected({_id: defaultSelectedId});
  }, [defaultSelectedId]);

  const onHotelClick = (hotel: LocalHotel) => {
    if (onSelect) {
      setSelected(hotel);
      onSelect(hotel);
    }
  }

  const _onNewHotel = async () => {
    const {error, id} = await newSavedHotel();
    if (error) return alert(error);

    if (onNewHotel) return onNewHotel({_id: id});
    navigate(`/${bases.saved_hotel}/${id}`);
  }

  const _onEditHotel = (hotel: LocalHotel) => {
    if (onEditHotel) return onEditHotel(hotel);
    navigate(`/${bases.saved_hotel}/${hotel._id}`);
  }

  const onDeleteHotel = async (hotel: LocalHotel) => {
    const {error, success} = await deleteSavedHotel(hotel._id);
    if (error || !success) return alert(error);
    await loadHotels();
  }


  if (loading) return <Loading/>

  return (
    <div className="container m-auto my-10 px-10">
      {grid && <Title order={3}>{t('to.hotel.my saved hotels')}</Title>}
      {onSelect && <Title order={4}>{t('to.hotel.select hotel')}</Title>}

      <div className="flex flex-wrap">

        {Array.isArray(hotels) && hotels.map(hotel => <Hotel
          variant={'list'}
          className="m-5"
          hotel={hotel}
          onEdit={hotel => _onEditHotel(hotel as LocalHotel)}
          onDelete={async hotel => await onDeleteHotel(hotel as LocalHotel)}
          />
        )}
      </div>

      <div className="mt-10">
        <Button onClick={_onNewHotel}>{t('create new hotel')}</Button>
      </div>
    </div>
  );
};


export default MySavedHotels;
