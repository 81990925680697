import {useMemo, useState} from "react";
import s from './Button.module.css';
import {Loader} from "@mantine/core";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void | Promise<void>;
  type?: 'button' | 'submit' | 'reset';
  children?: any;

  variant?: 'filled' | 'outline' | 'default';
  color?: 'white' | 'yellow' | 'red' | 'green';
  shadow_border?: boolean;
  no_border?: boolean;

  className?: string;
  style?: any;

  leftIcon?: any;
  rightIcon?: any;
}

const Button = ({
                  onClick,
                  type = 'button',
                  variant = 'default',
                  color = 'yellow',
                  shadow_border,
                  no_border,
                  style,
                  className,
                  children,
                  ...props
                }: ButtonProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = async () => {
    if (typeof onClick === 'function') {
      if (onClick.constructor.name === 'AsyncFunction') {
        setLoading(true);
        await onClick();
        setLoading(false);
      } else {
        onClick();
      }
    }
  }

  const _className = useMemo(() => {
    let result = `${s.button} `;
    if (color) result += `${s[`button_${variant}_${color}`]} `;
    if (shadow_border) result += `${s.shadow_border} `;
    if (no_border) result += `${s.no_border} `;
    if (className) result += `${className} `;
    return result;
  }, [variant, shadow_border, color, className]);


  return <button type={type} className={_className} style={style} onClick={handleClick}>
    <div className={s.container}>
      {loading ? <Loader color={color} size="xs"/> : <>
        {props.leftIcon && <div className={`${children ? 'mr-2' : ''}`}>{props.leftIcon}</div>}
        {children && children}
        {props.rightIcon && <div className={`${children ? 'ml-2' : ''}`}>{props.rightIcon}</div>}
      </>}
    </div>
  </button>
};

export default Button;