import React, {useEffect, useState} from "react";

import {TDSellersPaymentTable} from "./TDSellersPaymentTable";
import {Seller} from "../../TDSellers/types";
import {getData} from "../../../../util/req";
import PlaneLoader from "../../../PlaneLoader";

export function TDSellersPayment(){
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [sellers, setSellers] = useState<Seller[]>([]);
  const [loading, setLoading] = useState(true)


  const loadSellers = async () => {
    setLoading(true)
    const result = await getData('/td/seller')
    setSellers(result.data);
    setLoading(false)
  }

  const loadAll = async () => {
    await loadSellers()
  }

  useEffect(() => {
    loadAll()
  }, []);

  if(loading)return <PlaneLoader/>

  return <>
    <div>
      <TDSellersPaymentTable sellers={sellers} selectedRowKeys={selectedRowKeys}  onSelectionChange={keys => setSelectedRowKeys(keys)}/>
    </div>
  </>
}
