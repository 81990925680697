import {Button, Form, FormProps, Select} from "antd";
import {SellerFormValues, sellersTypesOptions} from "../types";
import Input from "antd/es/input";
import {useMemo} from "react";
import {canTDWrite} from "../../../Permissions/VisibleWithPermissions";

interface SellerFormProps extends FormProps {
  onFinish: (values: SellerFormValues) => void
  loading?: boolean
}
export function TDSellerForm({onFinish, initialValues, loading, ...props}: SellerFormProps) {

  const onFinishHandler = (values: SellerFormValues) => {
    onFinish(values)
  }

  const canWrite = useMemo(() => canTDWrite(), [])

  return <Form labelCol={{span: 2}} className="px-5" {...props} initialValues={initialValues} onFinish={onFinishHandler}>
    <Form.Item name="name" label="Nombre" rules={[{required: true}]}>
      <Input/>
    </Form.Item>
    <Form.Item name="type" label="Tipo" rules={[{required: true}]}>
      <Select options={sellersTypesOptions}/>
    </Form.Item>


    {canWrite && <Form.Item wrapperCol={{span: 3, offset: 10}}>
      <Button loading={loading} disabled={loading} type="primary" htmlType="submit">Guardar</Button>
    </Form.Item>}
  </Form>

}