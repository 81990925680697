import s from './Table.module.css';
import {ColumnsType} from "antd/es/table";
import {Table as AntTable, TableProps as AntTableProps} from "antd";


interface TableProps<T> extends Omit<AntTableProps<T>, 'dataSource' | 'columns'>{
  data: T[];
  columns: ColumnsType<T>;
}

const Table = ({columns, data, ...props}: TableProps<any>) => {


  return <AntTable
    columns={columns}
    dataSource={data}
    {...props}
  />
}

export default Table;