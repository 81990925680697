import React, {useMemo, useState} from 'react';
import s from './TDCourse.module.css';
import {ActionIcon, Badge, Button, Group, NumberInput, Select, Text, Textarea, TextInput} from "@mantine/core";
import {useTranslation} from "react-i18next";
import {BsTrash} from "react-icons/bs";
import MultiLangInput from "../../MultiLangInput";

const TDCourse = ({form, products, group}) => {
	const {t} = useTranslation();
	const [lang, setLang] = useState('es');

	const levelTypes = useMemo(() => [
		{value: '1', label: t('td.product.levels.first')},
		{value: '2', label: t('td.product.levels.second')},
		{value: '3', label: t('td.product.levels.third')}
	], []);
	const certificationType = useMemo(() => [
		{value: 'scuba diving', label: t('td.product.certification types.scuba diving')}
	], []);



	const requirements = form.values.prev_requirements.map((item, index) => (
		<Group key={index} className="mb-5">
			<MultiLangInput
				formList={true}
				formIndex={index}
				Input={TextInput}
				name="prev_requirements"
				lang={lang}
				setLang={setLang}
				form={form}
				required
				label={t('td.product.name')}
				autosize
				minRows={4}
				className={s.inputContainer}
				placeholder={t('td.product.requirement')}
			/>

{/*			<TextInput
				placeholder={t('td.product.requirement')}
				required
				className={s.inputContainer}
				{...form.getListInputProps('prev_requirements', index, 'requirement')}
			/>*/}
			<ActionIcon
				color="red"
				variant="hover"
				onClick={() => form.removeListItem('prev_requirements', index)}
			>
				<BsTrash size={16}/>
			</ActionIcon>
		</Group>
	));

	const what_includes = form.values.what_includes.map((item, index) => (
		<Group key={index} className="mb-5">
			<MultiLangInput
				formList={true}
				formIndex={index}
				Input={TextInput}
				name="what_includes"
				lang={lang}
				setLang={setLang}
				form={form}
				required
				placeholder={t('td.product.name')}
				autosize
				minRows={4}
				className={s.inputContainer}
			/>
{/*			<TextInput
				required
				className={s.inputContainer}
				{...form.getListInputProps('what_includes', index, 'text')}
			/>*/}
			<ActionIcon
				color="red"
				variant="hover"
				onClick={() => form.removeListItem('what_includes', index)}
			>
				<BsTrash size={16}/>
			</ActionIcon>
		</Group>
	));


	const images = form.values.images.map((item, index) => (
		<Group key={index} className="mb-5">
			<TextInput
				placeholder={t('td.product.url')}
				required
				className={s.inputContainer}
				{...form.getListInputProps('images', index, 'url')}
			/>
			<ActionIcon
				color="red"
				variant="hover"
				onClick={() => form.removeListItem('images', index)}
			>
				<BsTrash size={16}/>
			</ActionIcon>
		</Group>
	));


	const recommended_products = form.values.recommended_products.map((item, index) =>
		<Group key={index} mt="s" className="mb-5">
			<Select
				required
				className={s.inputContainer}
				data={products}
				placeholder={t('td.product.select product')}
				{...form.getListInputProps('recommended_products', index, '_id')}
			/>
			<ActionIcon
				color="red"
				variant="hover"
				onClick={() => form.removeListItem('recommended_products', index)}
			>
				<BsTrash size={16}/>
			</ActionIcon>
		</Group>
	);

	return (
    <>
      <div className="w-full flex">
        <MultiLangInput
          Input={TextInput}
          name="name"
          lang={lang}
          setLang={setLang}
          form={form}
          required
          label={t('td.product.name')}
          autosize
          minRows={4}
          className={s.inputContainer}
        />

        {/*				<TextInput className={s.inputContainer} required label={t('td.product.name')} {...form.getInputProps('name')}/>*/}

        {group === 'course' && <Select required className={s.inputContainer} data={levelTypes}
                                       label={t('td.product.level')} {...form.getInputProps('level')}/>}

        {group === 'course' && <Select required className={s.inputContainer} data={certificationType}
                                       label={t('td.product.certification type')} {...form.getInputProps('certificationType')}/>}
      </div>

      <div className="flex flex-nowrap items-baseline my-10">
        <div className={s.inputContainer}>
        <TextInput
            label="Código del producto"
            className="mt-5"
            {...form.getInputProps('code')}
          />
        </div>
          <NumberInput
            label="Salario del trabajador"
            parser={(value) => value.replace(/\MXN\s?|(,*)/g, '')}
            formatter={(value) =>
              !Number.isNaN(parseFloat(value))
                ? `MXN ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : 'MXN '
            }
            className={s.inputContainer}
            {...form.getInputProps('salary')}
          />
      </div>

      <div className="mt-5">
        <MultiLangInput
          Input={Textarea}
          name="description"
          lang={lang}
          setLang={setLang}
          form={form}
          required
          label={t('td.product.description')}
          autosize
          minRows={4}
        />

      </div>

      <div className="mt-5">
        <MultiLangInput
          Input={Textarea}
          name="itinerary"
          lang={lang}
          setLang={setLang}
          form={form}
          required
          label={t('td.product.itinerary')}
          autosize
          minRows={4}
        />
      </div>
      <div>
        <hr className="my-5"/>
        <Text size="xl">{t('td.product.info')}</Text>
        <div className="flex flex-nowrap mt-5">
          <div className={s.inputContainer}>
            <NumberInput
              label={t('td.product.min age')}
              {...form.getInputProps('min_age')}
            />
          </div>
          <div className={s.inputContainer}>
            <NumberInput
              label={t('td.product.max deep in meters')}
              {...form.getInputProps('max_deep_meters')}
            />
          </div>

          <div className={s.inputContainer}>
            <NumberInput
              label={t('td.product.max deep in feet')}
              {...form.getInputProps('max_deep_feet')}
            />
          </div>
        </div>
        <div className="flex flex-nowrap my-10">
          <div className={s.inputContainer}>
            <NumberInput
              label={t('td.product.academic sessions')}
              {...form.getInputProps('academic_sessions')}
            />
          </div>
          <div className={s.inputContainer}><NumberInput
            label={t('td.product.diving sessions')}
            {...form.getInputProps('diving_sessions')}
          />
          </div>
          <div className={s.inputContainer}>
            <NumberInput
              label={t('td.product.open water sessions')}
              {...form.getInputProps('open_water_sessions')}
            />
          </div>
        </div>

        <div className="flex flex-nowrap my-10">
          <NumberInput
            className={s.inputContainer}
            label={t('td.product.duration in days')}
            {...form.getInputProps('duration')}
          />
          <NumberInput
            label={t('td.product.price')}
            parser={(value) => value.replace(/\MXN\s?|(,*)/g, '')}
            formatter={(value) =>
              !Number.isNaN(parseFloat(value))
                ? `MXN ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : 'MXN '
            }
            className={s.inputContainer}
            {...form.getInputProps('price')}
          />
        </div>

      </div>


      <div className="mb-5">
        <hr className="my-5"/>
        <Text size="xl" className="mb-5">{t('td.product.requirements')}</Text>
        {requirements}
        <Button variant="default" className="mt-5"
                onClick={() => form.addListItem('prev_requirements', {text: {es: ''}})}>
          {t('td.product.add requirement')}
        </Button>
      </div>


      <div className="mb-5">
        <hr className="my-5"/>
        <Text size="xl" className="mb-5">{t('td.product.what includes')}</Text>
        {what_includes}
        <Button variant="default" className="mt-5"
                onClick={() => form.addListItem('what_includes', {text: {es: ''}})}>
          {t('td.product.add')}
        </Button>
      </div>

      <div className="mb-5">
        <hr className="my-5"/>
        <Text size="xl" className="mb-5">{t('td.product.recommended products')}</Text>
        {recommended_products}
        <Button variant="default" className="mt-5"
                onClick={() => form.addListItem('recommended_products', {_id: products[0]._id})}>
          {t('td.product.add')}
        </Button>
      </div>

      <div className="mb-5">
        <hr className="my-5"/>
        <Text size="xl" className="mb-5">{t('td.product.images')}</Text>
        {images}
        <Button variant="default" className="mt-5"
                onClick={() => form.addListItem('images', {url: ''})}>
          {t('td.product.add')}
        </Button>
      </div>
    </>
  );
};


export default TDCourse;