import React, {useEffect} from 'react';
import s from './CreateNewItinerary.module.css';
import {useNavigate} from "react-router-dom";
import useTourOperatorItinerary from "../../../controllers/useTourOperatorItinerary";
import {toastError} from "../../../helpers/ErrorManager";
import bases from "../../../routes/bases";
import {useTranslation} from "react-i18next";
import Loading from "../../Loading";

const CreateNewItinerary = props => {
	const {t} = useTranslation();

	const navigate = useNavigate();
	const {newItinerary} = useTourOperatorItinerary();


	const onNewItinerary = async () => {
		const {error, id} = await newItinerary();
		if (error || !id) return toastError(error, t);
		navigate(`/${bases.tour_operator_itineraries}/${id}`);
	}

	useEffect(() => onNewItinerary(), []);

	return (
		<Loading/>
	);
};


export default CreateNewItinerary;