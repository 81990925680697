import svg_aquatic from '../resources/svg/it_categories/aquatic.svg';
import svg_diving from '../resources/svg/it_categories/diving.svg';
import svg_partner from '../resources/svg/it_categories/partner.svg';
import svg_history from '../resources/svg/it_categories/history.svg';
import svg_adventure from '../resources/svg/it_categories/adventure.svg';
import svg_leisure from '../resources/svg/it_categories/leisure.svg';
import svg_volunteering from '../resources/svg/it_categories/volunteering.svg';
import svg_ski from '../resources/svg/it_categories/ski.svg';
import svg_fauna from '../resources/svg/it_categories/fauna.svg';
import svg_flora from '../resources/svg/it_categories/flora.svg';
import {Address} from "./places";




export const itineraryCategories = [
  "aquatic",
  "diving",
  "partner",
  "history",
  "adventure",
  "leisure",
  "volunteering",
  "ski",
/*  "fauna",
  "flora"*/
] as const;

export type ItineraryCategories = typeof itineraryCategories[number];


export const itineraryCategoriesIcons = [
  svg_aquatic,
  svg_diving,
  svg_partner,
  svg_history,
  svg_adventure,
  svg_leisure,
  svg_volunteering,
  svg_ski,
/*  svg_fauna,
  svg_flora*/
];




export const langList = ['eng', 'es'] as const;
export type LangListType = typeof langList[number];

export type MultiLangText = {
  [lang in LangListType]?: string;
}


export interface ItPlace {
  id: number;
  start: number;
  end: number;
  address?: Address;
  activity_blocked?: boolean;
}


export interface ItHotel {
  _id: string; // ObjectId in mongo. Not the hotel id
  api?: string;
  hotel_code?: number;
  hotel_id?: string;
  from_day: number;
  to_day: number;
  place_id: number;
  day?: number;
  place: {
    address: Address;
  }
}

export interface ItActivity {
  _id: string; // ObjectId in mongo. Not the activity id
  activity_id: string;
  created_at: Date;
  available: {
    id: number;
    day: number;
    place: {
      id: number;
      day: number;
      start: number;
      end: number;
      address: Address;
      activity_blocked?: boolean;
    }
  }[]
  place_id: number;
  day: number;
}


export const itineraryTransportTypes = [
  'airplane',
  'bicycle',
  'motorcycle',
  'car',
  'bus',
  'train',
  'boat',
] as const;

export type ItineraryTransportTypes = typeof itineraryTransportTypes[number];

export interface ItTransport {
  _id: string; // ObjectId in mongo. Not the transport id
  created_at: Date;
  day: number;
  place_id: number;
  type: ItineraryTransportTypes;
}

export interface ItFeature {
  _id: string; // ObjectId in mongo. Not the feature id
  created_at: Date;
  name?: MultiLangText;
  description?: MultiLangText;
  key?: string;
  price?: number;
  image?: string;
}

export interface ItPackageDistribution {
  id: string; // not mongo id. the packages are created locally
  persons_per_room: number[];
  price_per_person: number;
  total_price: number;
  createdAt: Date
}

export interface ItPackage {
  _id: string; // ObjectId in mongo. Not the package id
  created_at: Date;
  name: MultiLangText;
  description: MultiLangText;
  price?: number;

  distributions?: ItPackageDistribution[];
  selected_hotels: string[]
}

export interface Itinerary {
  _id: string;
  created_by: string;
  enabled: boolean
  title: MultiLangText;
  internal_description?: MultiLangText;
  external_description?: MultiLangText;

  places?: ItPlace[];
  validationStatus: number;
  hashtags?: string[];
  limited_capacity?: boolean;
  capacity?: number;
  others?: MultiLangText[];
  included?: MultiLangText[];
  excluded?: MultiLangText[];
  observations?: MultiLangText[];
  categories?: ItineraryCategories[];
  start_point?: Address;

  hotels?: ItHotel[];
  activities?: ItActivity[];
  transports?: ItTransport[];
  features?: ItFeature[];
  packages?: ItPackage[];

  availability?: {
    all_year: boolean;
    dates: {
      date: string[];
    }[]
  };
}

export type FormAddress = Omit<ItPlace, 'address'> & {
  address?: { raw?: Address };
}

export interface FormItinerary {
  itinerary: Omit<Itinerary, 'start_point' | 'hashtags' | 'places'> & {
    start_point?: {
      raw?: Address
    };
  },
  hashtags: string[],
  places?: (Omit<ItPlace, 'address'> & FormAddress)[]
}




export type ItinerarySteps =
  'itineraries'
  | 'availability'
  | 'hotels'
  | 'activities'
  | 'transport'
  | 'features'
  | 'packages'
  | 'summary'
  | 'validation';

export interface OutletItineraryContext {
  itinerary: Itinerary;
  setItinerary: (itinerary: Itinerary | ((a: Itinerary) => Itinerary)) => void;
  loadItinerary: (id: string) => Promise<true | void>;
  saveItinerary: (itinerary: Partial<Itinerary>) => Promise<boolean>;
  steps: {text: ItinerarySteps}[];
  step: ItinerarySteps;
  setStep: (step: ItinerarySteps) => void;
  isItineraryBlocked: boolean;
  lang: string;
  setLang: (lang: string) => void;
}