import React, {useMemo, useState} from 'react';
import s from './AnalyticSmallCounter.module.css';
import {BsFillCaretDownFill, BsFillCaretUpFill} from "react-icons/bs";
import Select from "../../Select";
import AnimatedNumber from "animated-number-react";


const upStyle = {
  color: 'green'
};

const downStyle = {
  color: 'red'
};
const AnalyticSmallCounter = ({
  backgroundColor,
  Icon,
  number,
  title,
  data,
  style,
  symbol = "",
  growRate,
  formatNumber = v => Math.floor(v)
                              }) => {
  const [state, setState] = useState({
    amount: number ?? data[0].value.value,
    growRate: growRate ?? data[0].value.growRate
  });
  const caret = state.growRate > 0;
  const growStyle = caret ? upStyle : downStyle;


  return (
    <div className={s.container} style={style}>
      <div className={s.iconContainer} style={{backgroundColor}}>
        <Icon size={20} />
      </div>
      <div className={s.textContainer}>
        <div className={s.counterContainer}>
          <div className={s.counter}>
            <AnimatedNumber
              value={state.amount}
              formatValue={formatNumber}
            />
             {" " + symbol}
          </div>
          <div className={s.caret}>{caret ?
            <BsFillCaretUpFill style={growStyle} /> :
            <BsFillCaretDownFill style={growStyle} />
          }</div>
        </div>
        {state.growRate && <div className={s.growRate} style={growStyle}>
          <AnimatedNumber value={Math.abs(state.growRate)} formatValue={formatNumber} /> %
        </div>}
        <div className={s.title}>{title}</div>
      </div>

      {data &&
      <div className={s.selectContainer}>
          <Select
            className={s.select}
            options={data}
            value={data.filter(d => d.value.value === state.amount)}
            onChange={({value}) => setState(prev => ({...prev, amount: value.value, growRate: value.growRate}))}
          />
      </div>}
    </div>
  );
};


export default AnalyticSmallCounter;
