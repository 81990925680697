// convertLocalToUTCDate
import dayjs from "dayjs";

export function dateToServer(date: string | Date): Date {
  if(!date){
    return date as any
  }
  date = new Date(date)
  date = dayjs(date).add(date.getTimezoneOffset() * -1, 'minutes').toDate()

  return date
}

export function serverToDate(date: Date): Date{
  if(!date){
    return date
  }
  date = new Date(date)

  date = dayjs(date).add(date.getTimezoneOffset(), 'minutes').toDate()

  return date
}