import {SpotlightProvider} from '@mantine/spotlight';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {allElements as TD} from "../../routes/groupElementsTD";
import {allElements as TT} from "../../routes/groupElements";
import {allElements as TO} from "../../routes/groupElementsTO";
import {useMemo} from "react";
import useAuth from "../UserProvider/useAuth";
import {useLocalStorage} from "@mantine/hooks";


export const MantineSpotlightProvider = ({children}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {getType} = useAuth();
  const [category, setCategory] = useLocalStorage({
    key: 'grid_category',
    defaultValue: 'TT'
  });

  let type = getType();

  const actions = useMemo(() => {
    let allElements = type === 'to' ? TO : category === 'TD' ? TD : TT;
    return allElements.filter(el => el.path).map(el => ({
      title: t(`grid.names.${el.name}`),
      description: t(`grid.description.${el.name}`),
      icon: el.icon,
      group: t(`grid.groups.names.${el.group.name}`),
      onTrigger: () => navigate(el.path)
    }));
  }, [type, category]);



  return <SpotlightProvider
    actions={actions}
    closeOnActionTrigger={true}
    highlightQuery={true}
  >
    {children}
  </SpotlightProvider>
}
