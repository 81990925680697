import {ColumnsType} from "antd/es/table";
import {PaymentDataTableType} from "./types";
import {DateTimeRender, MoneyRender} from "../../Table/Renders";
import {useTranslation} from "react-i18next";


const PriceRender = (v: number, payment: PaymentDataTableType) => {
  const m = payment.type === 'purchase' ? 1 : -1;
  return <p style={{color: payment.type === 'purchase' ? 'green' : 'red'}}><MoneyRender value={v * m}/></p>
}

export const useColumns = () => {
  const {t} = useTranslation();

  const columns: ColumnsType<PaymentDataTableType> = [
    {
      dataIndex: 'asset',
      title: t('payments data.asset'),
      render: asset => t(`payments data.assets list.${asset}`)
    },
    {
      dataIndex: 'price',
      title: t('payments data.price'),
      render: PriceRender
    },
    {
      dataIndex: 'created_at',
      title: t('payments data.date'),
      render: DateTimeRender
    }
  ]

  return columns;
}