import Input from "antd/es/input";
import {ColumnType} from "antd/es/table";
import {FilterDropdownProps} from "antd/es/table/interface";
import {Button, Space} from "antd";
import {AiFillFilter} from "react-icons/ai";
import React from "react";


export type TableOnFilter = (name: string, value: string) => void;

interface Props<DataType> {
  name: string
  onFilter?: TableOnFilter,
  inputProps?: React.ComponentProps<typeof Input>
}


export function searchByText<DataType>({name, onFilter, inputProps = {}}: Props<DataType>): ColumnType<DataType> {
  return ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}: FilterDropdownProps) => {
      return <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          placeholder={`Buscar...`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            onFilter && onFilter(name, e.target.value)
          }}
          onPressEnter={() => {
            confirm();
          }}
          style={{marginBottom: 8, display: 'block'}}
          {...inputProps}
        />
        <Space>
          <Button
            type="link"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              onFilter && onFilter(name, '')
              confirm();
              close();
            }}
          >
            Borrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    },
    filterIcon: (filtered: boolean) => <AiFillFilter size={15}/>,
    onFilter: (dropValue, record) => true
  })
}