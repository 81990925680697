import s from './ListHotel.module.css';
import {GenericHotel, HotelType} from "../../../types/hotel";
import {useTranslation} from "react-i18next";
import Image from "../../Image";
import {langRender} from "../../../helpers/i18n";
import React from "react";
import Rating from "../../Inputs/Rating/Rating";
import {cutText} from "../../../helpers/util";
import Button from "../../TourOperator/Itineraries/Inputs/Button/Button";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";

interface ListHotelProps {
  hotel: GenericHotel;
  className?: string;

  onEdit?: (hotel: GenericHotel) => void;
  onDelete?: (hotel: GenericHotel) => Promise<void>;
  customElement?: React.ReactNode;
}

const ListHotel = ({hotel, onDelete, onEdit, customElement}: ListHotelProps) => {
  const {t} = useTranslation();

  const haveButtons = !!onDelete || !!onEdit || !!customElement

  return <div className={s.container}>
    <div className={s.verBar}/>
    <div className={s.leftContainer}>
      <div className={s.image}>
        <Image src={hotel.images[0]} width={200} alt={t('small hotel.picture alt')}/>
      </div>
      <div className="p-2">
        <div className="font-bold text-2xl mb-1">{langRender(hotel.name)}</div>

        <Rating stars={hotel.stars}/>
      </div>
    </div>
    <div className={s.rightContainer}>
      <div className={s.description}>{cutText(langRender(hotel.description), haveButtons ? 200 : 270)}</div>
      {haveButtons && <div className={s.buttons}>
        {onDelete && <Button color="white" className={s.deleteButton} leftIcon={<AiOutlineDelete size={18}/>}
														 variant="filled"
														 onClick={() => onDelete(hotel)}/>}

        {onEdit &&
					<Button color="green" variant="outline" className={s.editButton} rightIcon={<AiOutlineEdit size={18}/>}
									onClick={() => onEdit(hotel)}>
            {t('to.edit')}
					</Button>
        }

        {!!customElement && customElement}
      </div>}
    </div>
  </div>
}

export default ListHotel;