import React, {useEffect, useState} from 'react';
import s from './ShowPurchase.module.css';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import usePurchaseController from "../../../controllers/usePurchaseController";
import Loading from "../../Loading";
import ShowClient from "../ShowClient";
import ToggleComponent from "../../ToggleComponent";
import {
  EmailRender,
  PhoneRender
} from "../../Table/Renders";
import ShowPurchaseHotels from "./ShowPurchaseHotels";
import {OrderedType} from "../../../controllers/usePurchaseController/types";
import VerticalTable from "../../NewTable/VerticalTable";

const ShowPurchase = () => {
  const {t} = useTranslation();
  let {id: purchase_id} = useParams();
  const {getPurchase} = usePurchaseController();

  const [purchase, setPurchase] = useState<OrderedType>();
  const [loading, setLoading] = useState(true);

  const loadPurchase = async (purchase_id: string) => {
    setLoading(true);
    const {error, purchase} = await getPurchase({purchase_id});
    console.log(purchase);
    if (!error && purchase) {
      setPurchase(purchase);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (purchase_id) loadPurchase(purchase_id);
  }, []);

  if (loading) return <Loading/>

  if(!purchase) return <div>{t('show purchase.purchase not found')}</div>


  return (
    <div>
      {purchase.user_id && <ToggleComponent title={t('show purchase.buyer account')}>
        <ShowClient tableProps={{hideHeader: true}} showTitle={false} client_id={purchase.user_id}/>
      </ToggleComponent>}
      {purchase.billing && <ToggleComponent title={t('show purchase.billing information')}>
        <VerticalTable
          langGroup="show purchase.billing information data"
          data={purchase.billing}
          valueRenders={{
            email: EmailRender
          }}
        />
      </ToggleComponent>}

      {purchase.emergency_contact && <ToggleComponent title={t('show purchase.emergency contact')}>
        <VerticalTable
          langGroup="show purchase.emergency contact data"
          data={purchase.emergency_contact}
          valueRenders={{
            phone_number: PhoneRender,
            email: EmailRender
          }}
        />
      </ToggleComponent>}
      {Array.isArray(purchase?.hotels) && purchase.hotels.length > 0 && <ToggleComponent title={t('show purchase.hotels')}>
        <div className="p-10">
          <ShowPurchaseHotels hotels={purchase.hotels}/>
        </div>
      </ToggleComponent>}


    </div>
  );
};


export default ShowPurchase;
