import React, {useEffect, useState} from 'react';
import s from './ConfigAPIInsurance.module.css';
import {useTranslation} from "react-i18next";
import useInsurance from "../../../../controllers/useInsurance";
import Loading from "../../../Loading";
import useMenu from "../../../../providers/MenuProvider/useMenu";

const ConfigAPIInsurance = props => {
	const {t} = useTranslation();
	const {setChosen} = useMenu();

	useEffect(() => setChosen('insurance'), [setChosen]);

	const {getPolicies} = useInsurance();

	const [policies, setPolicies] = useState();
	const [loading, setLoading] = useState();

	const loadPolicies = async () => {
		setLoading(true);
		const {error, policies} = await getPolicies();
		setLoading(false);

		if(error)return alert(error);
		setPolicies(policies);
	}
	useEffect(() => {
		loadPolicies();
	} ,[]);

	if(loading)return <Loading/>

	return (
		<div>

    </div>
	);
};


export default ConfigAPIInsurance;