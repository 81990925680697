import React from 'react';
import s from './PhoneInput.module.css';
import {TextInput} from "@mantine/core";
import InputMask from 'react-input-mask';


const PhoneInput = React.forwardRef(({label, id, ...props}, ref) => {
	return (
			<TextInput
				ref={ref}
				component={InputMask}
				label={label}
				mask="+(999) 999-999-999"
				id={id}
				{...props}
			/>
	);
});


export default PhoneInput;