import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect} from "react";
import {NumberInput, Text, Textarea} from "@mantine/core";
import s from "../TDReservation.module.css";

export const Discount = ({form, products}) => {
  const {t} = useTranslation();

  const nan = (value) => isNaN(value) ? 0 : value;

  const calculateTotalPrice = useCallback(()  => {
    let price = 0;
    if (Array.isArray(form.values.products) && Array.isArray(products)) {
      form.values.products.forEach(p => {
        const product = products.find(pr => pr._id === p.product_id);
        if (product) {
          price += (product.price * (p.adults + p.children)) - (p.discount || 0)
        }
      });
    }
    return nan(price)
  }, [form.values.products, products])

  const calculateDiscountByPercentage = (percentage) => {
    form.setFieldValue('discount_percentage', nan(percentage))
    form.setFieldValue('discount', nan(percentage * calculateTotalPrice() / 100));
  }
  const calculateDiscountByValue = (value) => {
    form.setFieldValue('discount', nan(value));
    const total_price = calculateTotalPrice()

    let percentage = 0;
    if (value > 0) {
      percentage = Math.floor(value / total_price * 100);
    }

    form.setFieldValue('discount_percentage', nan(percentage));
  }

  useEffect(() => {
    calculateDiscountByValue(form.values.discount);
  }, [form.values.products]);

  return <div>
    <Text className="mt-5" size="lg">{t('td.reservations.discount')}</Text>
    <hr className="my-5"/>
    <div className="flex flex-nowrap items-center">
      <NumberInput
        label={t('td.reservations.percentage')}
        className={s.input}
        parser={(value) => value.replace('%', '')}
        formatter={(value) => `% ${value}`}
        min={0}
        max={100}
        value={form.values.discount_percentage}
        onChange={(value) => calculateDiscountByPercentage(value)}
      />

      <NumberInput
        label={t('td.reservations.value')}
        parser={(value) => value.replace(/\MXN\s?|(,*)/g, '')}
        formatter={(value) =>
          !Number.isNaN(parseFloat(value))
            ? `MXN ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : 'MXN '
        }
        className={s.input}
        value={form.values.discount}
        onChange={(value) => calculateDiscountByValue(value)}
      />
      <Textarea
        style={{width: '615px'}}
        label={t('td.reservations.description')}
        minRows={3}
        autosize
        {...form.getInputProps('discount_description')}
      />

    </div>
  </div>
}
