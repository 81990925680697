import React, {useEffect, useState} from 'react';
import s from './ToItineraryValidationChat.module.css';
import {useTranslation} from "react-i18next";
import {Button, Textarea} from "@mantine/core";
import {useForm} from "@mantine/form";
import useTourOperatorItinerary from "../../../../controllers/useTourOperatorItinerary";
import {toastError} from "../../../../helpers/ErrorManager";
import dayjs from "dayjs";
import useAuth from "../../../../providers/UserProvider/useAuth";

import logo from '../../../../images/logo.png';

const ToItineraryValidationChat = ({chat_id, itinerary}) => {
	const {t} = useTranslation();

	const {sendNewMessage, getChatMessages} = useTourOperatorItinerary();

	const {getAdmin} = useAuth();

	const [loading, setLoading] = useState(false);
	const [messages, setMessages] = useState();
	const [sendingMessage, setSendingMessage] = useState(false);

	const form = useForm({
		initialValues: {
			message: ''
		}
	});

	const loadMessages = async () => {
		setLoading(true);
		const {error, messages} = await getChatMessages({
			chat_id,
			itinerary_id: itinerary._id
		});
		if (error) return toastError(error, t);
		setMessages(messages);
		setLoading(false);
	}

	useEffect(() => {
		if (itinerary) loadMessages();
	}, [itinerary]);


	const onSubmit = async () => {
		setSendingMessage(true);

		const {error} = await sendNewMessage({
			chat_id,
			itinerary_id: itinerary._id,
			message: form.values.message
		});
		if (error) {
			toastError(error, t);
		} else {
			form.reset();
			await loadMessages();
		}
		setSendingMessage(false);
	}

	return (
		<div>
			<div className="w-10/12 m-auto flex flex-wrap">
				{Array.isArray(messages) && messages.length === 0 && <div>{t('to.itinerary.chat data.no messages')}</div>}
				{Array.isArray(messages) && messages.length > 0 && messages.map(message => {
					let is_tt = getAdmin()._id === message.created_by?._id;
					return <div className="w-full flex flex-nowrap">
						{!is_tt && <div className={s.logo_cl}>{getAdmin().name.substring(0,1)}</div>}
						<div className={`${s.message} ${is_tt ? s.message_tt : s.message_cl}`}>
							<div className="flex flex-nowrap">
								<div className={s.sender}>{message.created_by?.name}</div>
								<div>{dayjs(message.created_at).format('DD/MM/YYYY HH:mm')}</div>
							</div>
							<div className={s.text}>{message.text}</div>
						</div>
						{is_tt && <div style={{backgroundImage: `url(${logo})`}} className={s.logo}/>}
					</div>
				})}

			</div>
			<form onSubmit={form.onSubmit(onSubmit)}>
				<Textarea
					placeholder={t('to.itinerary.chat data.message placeholder')}
					{...form.getInputProps('message')}
					autosize={true}
					minRows={2}
					className="my-5"
				/>
				<Button loading={sendingMessage} onClick={onSubmit}>
					{t('to.itinerary.chat data.send message')}
				</Button>
			</form>
		</div>
	);
};


export default ToItineraryValidationChat;