import React from 'react';
import s from './ToItAddBox.module.css';
import Button from "../Itineraries/Inputs/Button/Button";
import {IoAddCircleOutline} from "react-icons/io5";

const ToItAddBox = ({...props}) => {
	return (
		<div className={s.container}>
			<Button className={s.button} variant="filled" color="yellow" shadow_border={true} {...props}>
				<IoAddCircleOutline size={24}/>
			</Button>
		</div>
	);
};


export default ToItAddBox;