import {query} from "../../hooks/useQuery";
import useAuth from "../../providers/UserProvider/useAuth";

export const useServerController = () => {
  const {secQuery} = useAuth();

  const isActive = async () => {
    const {statusCode} = await query(`/status`, {}, 'GET');
    return statusCode === 200;
  }

  const APIDownloadHotelData = async () => await secQuery('/api/admin/server/api/hotel/download/data', {}, 'POST', {parse: true});
  const APIDownloadHotels = async () => await secQuery('/api/admin/server/api/hotel/download/hotels', {}, 'POST', {parse: true});
  const APIDownloadHotelAll = async () => await secQuery('/api/admin/server/api/hotel/download/all', {}, 'POST', {parse: true});

  const APIUpdateHotelData = async () => await secQuery('/api/admin/server/api/hotel/update/data', {}, 'POST', {parse: true});
  const APIUpdateHotels = async () => await secQuery('/api/admin/server/api/hotel/update/hotels', {}, 'POST', {parse: true});
  const APIUpdateHotelAll = async () => await secQuery('/api/admin/server/api/hotel/update/all', {}, 'POST', {parse: true});

  return {
    isActive,
    APIDownloadHotelData, APIDownloadHotels, APIDownloadHotelAll,
    APIUpdateHotelData, APIUpdateHotels, APIUpdateHotelAll
  };
};

export default useServerController;

