import useAuth from "../../providers/UserProvider/useAuth";
import {itinerary_validation_status} from "../../helpers/tourOperators";
import {ItFeature, ItHotel, Itinerary, ItineraryTransportTypes, ItTransport} from "../../types/itineraries";
import {SearchPlaceResult} from "../../types/places";
import {TableResult} from "../../util/table/types";


class ItineraryError extends Error {
  constructor(message: string) {
    super();
    this.message = message;
  }
}

export const useTourOperatorItinerary = () => {
  const {secQuery} = useAuth();

  const base = '/api/tour_operator/itinerary';


  const getItinerariesToValidate = async ({signal}: {signal?: AbortSignal} = {}) => {
    const {error, data} = await secQuery(`${base}/to_validate`, null, 'GET', {signal});
    return {error, itineraries: data?.itineraries};
  }

  const getItineraries = async ({admin_id,signal}: {admin_id?: string, signal?: AbortSignal} = {}) => {
    let url = `${base}`;
    if(admin_id)url += `?admin_id=${admin_id}`;
    const {error, data} = await secQuery(url, null, 'GET', {signal});
    if(error)throw new ItineraryError(error);
    return data as TableResult<Itinerary>;
  }

  const newItinerary = async ({signal}: {signal?: AbortSignal} = {}) => {
    const {error, data} = await secQuery(base, null, 'POST', {signal});
    if(error || !data?.id)throw new ItineraryError(error || 'unknown error');
    return data?.id as string;
  }

  const getItinerary = async ({id}: {id: string}) => {
    const {error, data} = await secQuery(`${base}/${id}`, null, 'GET');
    if(error)throw new ItineraryError(error);
    return data?.data as Itinerary;
  }
  const editItinerary = async ({id, itinerary}: {id: string, itinerary: any}) => {
    const {error, data} = await secQuery(`${base}/${id}`, {itinerary}, 'PUT');
    if(error || !data?.success)throw new ItineraryError(error || 'unknown error');
  }

  const deleteItinerary = async ({id}: {id: string}) => {
    const {error, data} = await secQuery(`${base}/${id}`, null, 'DELETE');
    if(error || !data?.success)throw new ItineraryError(error || 'unknown error');
  }

  const duplicateItinerary = async ({id}: {id: string}) => {
    const {error, data} = await secQuery(`${base}/${id}/duplicate`, null, 'POST');
    if(error || !data?.success)throw new ItineraryError(error || 'unknown error');
  }

  // hoteles

  const newHotel = async ({itinerary_id, place_id, day, signal}: {itinerary_id: string, place_id: string, day: number, signal?: AbortSignal}) => {
    const {error, data} = await secQuery(`${base}/${itinerary_id}/hotel`, {place_id, day}, 'POST', {signal});
    return {error, id: data?.id, itinerary: data?.itinerary};
  }
  const deleteHotel = async ({itinerary_id, hotel_id, signal}: {itinerary_id: string, hotel_id:string, signal?: AbortSignal}) => {
    const {error, data} = await secQuery(`${base}/${itinerary_id}/hotel/${hotel_id}`, null, 'DELETE', {signal});
    return {error, itinerary: data?.itinerary};
  }
  const editHotel = async ({itinerary_id, hotel_id, hotel, signal}: {itinerary_id: string, hotel_id: string, hotel: ItHotel, signal?: AbortSignal}) => {
    const {error, data} = await secQuery(`${base}/${itinerary_id}/hotel/${hotel_id}`, {hotel}, 'PUT', {signal});
    return {error, hotel: data?.hotel as ItHotel};
  }


  // actividades

  const newActivity = async ({itinerary_id, available, place_id, day, signal}: {itinerary_id: string, available:boolean,day:number, place_id: string, signal?: AbortSignal}) => {
    const {error, data} = await secQuery(`${base}/${itinerary_id}/activity`, {available, place_id, day}, 'POST', {signal});
    return {error, id: data?.id, itinerary: data?.itinerary};
  }
  const deleteActivity = async ({itinerary_id, activity_id, signal}: {itinerary_id: string, activity_id: string, signal?: AbortSignal}) => {
    const {error, data} = await secQuery(`${base}/${itinerary_id}/activity/${activity_id}`, null, 'DELETE', {signal});
    return {error, itinerary: data?.itinerary};
  }
  const editActivity = async ({itinerary_id, activity_id, activity, signal}: {itinerary_id: string, activity_id: string, activity: any, signal?: AbortSignal}) => {
    const {
      error,
      data
    } = await secQuery(`${base}/${itinerary_id}/activity/${activity_id}`, {activity}, 'PUT', {signal});
    return {error, activity: data?.activity};
  }

  // transporte

  const newTransport = async ({itinerary_id, day, place_id,type, signal}: {itinerary_id: string, day: number, type: ItineraryTransportTypes, place_id: string, signal?: AbortSignal}) => {
    const {error, data} = await secQuery(`${base}/${itinerary_id}/transport`, {day, place_id, type}, 'POST', {signal});
    return {error, id: data?.id, itinerary: data?.itinerary};
  }
  const deleteTransport = async ({itinerary_id, transport_id, signal}: {itinerary_id: string, transport_id: string, signal?: AbortSignal}) => {
    const {error, data} = await secQuery(`${base}/${itinerary_id}/transport/${transport_id}`, null, 'DELETE', {signal});
    return {error, itinerary: data?.itinerary};
  }
  const editTransport = async ({itinerary_id, transport_id, transport, signal}: {itinerary_id: string, transport_id: string, transport: any, signal?: AbortSignal}) => {
    const {
      error,
      data
    } = await secQuery(`${base}/${itinerary_id}/transport/${transport_id}`, {transport}, 'PUT', {signal});
    if(error)throw new ItineraryError(error);
    return data?.transport as ItTransport;
  }

  // features

  const newFeature = async ({itinerary_id, feature, signal}: {itinerary_id: string, signal?: AbortSignal, feature: Partial<ItFeature>}) => {
    const {error, data} = await secQuery(`${base}/${itinerary_id}/feature`, {feature}, 'POST', {signal});
    return {error, id: data?.id as string, itinerary: data?.itinerary as Itinerary};
  }
  const deleteFeature = async ({itinerary_id, feature_id, signal}: {itinerary_id: string, signal?: AbortSignal, feature_id: string}) => {
    const {error, data} = await secQuery(`${base}/${itinerary_id}/feature/${feature_id}`, null, 'DELETE', {signal});
    return {error, itinerary: data?.itinerary as Itinerary};
  }
  const editFeature = async ({itinerary_id, feature_id, feature, signal}: {itinerary_id: string, signal?: AbortSignal, feature_id: string, feature:Partial<ItFeature>}) => {
    const {
      error,
      data
    } = await secQuery(`${base}/${itinerary_id}/feature/${feature_id}`, {feature}, 'PUT', {signal});
    return {error, feature: data?.feature as ItFeature};
  }


  // paquetes

  const newPackage = async ({itinerary_id, signal}: {itinerary_id: string, signal?: AbortSignal}) => {
    const {error, data} = await secQuery(`${base}/${itinerary_id}/package`, null, 'POST', {signal});
    return {error, id: data?.id, itinerary: data?.itinerary};
  }
  const deletePackage = async ({itinerary_id, package_id, signal}: {itinerary_id: string, signal?: AbortSignal, package_id: string}) => {
    const {error, data} = await secQuery(`${base}/${itinerary_id}/package/${package_id}`, null, 'DELETE', {signal});
    return {error, itinerary: data?.itinerary};
  }
  const editPackage = async ({itinerary_id, package_id, _package, signal}: {itinerary_id: string, signal?: AbortSignal, package_id: string, _package: any}) => {
    const {error, data} = await secQuery(`${base}/${itinerary_id}/package/${package_id}`, {_package}, 'PUT', {signal});
    return {error, _package: data?._package};
  }




  // validacion
  const sendToValidation = async ({itinerary_id, want_validation = true, signal}: {itinerary_id: string, signal?: AbortSignal, want_validation: boolean}) => {
    const {error, data} = await secQuery(`${base}/${itinerary_id}/validation`, {want_validation}, 'POST', {signal});
    return {error, success: data?.success};
  }

  // chat
  const newChat = async ({itinerary_id, title, signal}: {itinerary_id: string, signal?: AbortSignal, title: string}) => {
    const {error, data} = await secQuery(`${base}/${itinerary_id}/chat`, {title}, 'POST', {signal});
    return {error, id: data?.id};
  }
  const getChats = async ({itinerary_id, signal}: {itinerary_id: string, signal?: AbortSignal}) => {
    const {error, data} = await secQuery(`${base}/${itinerary_id}/chat`, null, 'GET', {signal});
    return {error, chats: data?.chats};
  }

  const getChatMessages = async ({itinerary_id, chat_id, signal}: {itinerary_id: string, signal?: AbortSignal, chat_id: string}) => {
    const {error, data} = await secQuery(`${base}/${itinerary_id}/chat/${chat_id}`, null ,'GET', {signal});
    return {error, messages: data?.messages};
  }
  const sendNewMessage = async ({itinerary_id, chat_id, message, signal}: {itinerary_id: string, signal?: AbortSignal, chat_id: string, message: string}) => {
    const {error, data} = await secQuery(`${base}/${itinerary_id}/chat/${chat_id}`, {message} ,'POST', {signal});
    return {error, message: data?.message};
  }




  // places
  const searchPlaceByString = async ({place, signal}: {place: string, signal?: AbortSignal}) => {
    const {error, data} = await secQuery('/api/sb/hotels', {q: place}, 'POST', {signal});
    if(error)throw new ItineraryError(error);
    return data?.results as SearchPlaceResult[];
  }



  return {
    getItinerariesToValidate,
    getItineraries,
    newItinerary,
    getItinerary,
    editItinerary,
    deleteItinerary,
    duplicateItinerary,

    newHotel,
    deleteHotel,
    editHotel,

    newActivity,
    deleteActivity,
    editActivity,

    newTransport,
    editTransport,
    deleteTransport,

    newFeature,
    editFeature,
    deleteFeature,

    newPackage,
    deletePackage,
    editPackage,

    sendToValidation,

    newChat,
    getChats,
    getChatMessages,
    sendNewMessage,

    searchPlaceByString
  };
}

export default useTourOperatorItinerary;
