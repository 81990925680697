import React, {useMemo} from 'react';
import s from './SelectLanguage.module.css';
import i18n from 'i18next';

import languages from "../../../resources/json/languageList.json";
import {MultiSelect, Select} from "@mantine/core";

const SelectLanguage = ({multiple = false, ...props}) => {
	const list = useMemo(() => {
		let r = languages[i18n.language] || languages['es-ES'];
		r = r[0] || r;
		return r ? r.filter(l => l.label && l.alpha2).map(l => ({label: l.label, value: l.alpha2})) : [];
	}, [languages, i18n.language]);


	return (
		multiple ?
			<MultiSelect
				data={list}
				searchable={true}
				{...props}
			/>
			:
			<Select
				data={list}
				searchable={true}
				{...props}
			/>
	);
};


export default SelectLanguage;