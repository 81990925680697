import {ReactComponent as AirPlaneSVG} from '../../resources/svg/transport/airplane.svg';
import {ReactComponent as BicycleSVG} from '../../resources/svg/transport/bicycle.svg';
import {ReactComponent as BoatSVG} from '../../resources/svg/transport/boat.svg';
import {ReactComponent as BusSVG} from '../../resources/svg/transport/bus.svg';
import {ReactComponent as CarSVG} from '../../resources/svg/transport/car.svg';
import {ReactComponent as MotorcycleSVG} from '../../resources/svg/transport/motorcycle.svg';
import {ReactComponent as TrainSVG} from '../../resources/svg/transport/train.svg';
import {ItineraryTransportTypes} from "../../types/itineraries";


export const TransportIcon = ({ type }: { type: ItineraryTransportTypes }) => {
  switch (type) {
    case 'airplane':
      return <AirPlaneSVG />;
    case 'bicycle':
      return <BicycleSVG />;
    case 'boat':
      return <BoatSVG />;
    case 'bus':
      return <BusSVG />;
    case 'car':
      return <CarSVG />;
    case 'motorcycle':
      return <MotorcycleSVG />;
    case 'train':
      return <TrainSVG />;
    default:
      return null;
  }
};