import React, {useEffect} from 'react';
import {AuthContext} from "./AuthContext";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import {query} from "../../helpers/query";
import logger from '../../helpers/Logger';


export function getAdmin(){
  const authData = localStorage.getItem('authData');
  try{
    return JSON.parse(authData || '{}').admin;

  }catch(error){
    return null
  }
}

const AuthProvider = props => {
  const [data, setData, clearData] = useLocalStorage("authData", {});


  useEffect(() => {
    downloadAdmin();
  }, []);

  const downloadAdmin = async () => {
    const {error, data} = await query('/api/admin/me', {}, 'GET');
    if(error){
      logger.error('download admin failed');
      setData(prev => ({...prev, admin: false}));
    }
    setData(prev => ({...prev, admin: data.admin}));
  }

  const login = async (email, password) => {
    let {error, data, ...r} = await query('/api/admin/login', {email, password});

    if(error === 'Forbidden' && r.logout && r.statusCode === 403){
      await logout();
      clearData();
      return {error: 'Forbidden'};
    }

    error = error || data.error;

    if(error)return {error};
    if(data.success){
      await downloadAdmin();
      return {success: true};
    }
  };

  const logout = async () => {
    await query('/api/admin/logout', {}, 'POST');
    clearData();
  }
  const getAdmin = () => data.admin;
  const isAuthenticated = () => !!data.admin;
  const getType = () => { // tmp fix
    let type = data?.admin?.type;
    if(type === 1)return 'admin';
    if(type === 2)return 'to';
  }
  const secQuery = async (path, params, method, {headers = {}, signal, parse = false} = {}) => {
    headers.Authorization = `Bearer ${data.token}`;
    let r = await query(path, params, method, {headers, signal});


    if(r.error === 'Unauthorized' && r.statusCode === 401){
      await logout();
      clearData();
      return {error: 'Unauthorized'};
    }
/*    if(r.error === 'Forbidden' && r.logout && r.statusCode === 403){
      await logout();
      clearData();
      return {error: 'Forbidden'};
    }*/

    if(!parse)return r;
    return {error: r.error || r.data?.error, ...(r?.data || {})};
  }

  return (
    <AuthContext.Provider value={{login, logout, getAdmin, isAuthenticated, secQuery, getType}}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
