import React, {useState} from 'react';
import s from './StartYourTripData.module.css';
import {useTranslation} from "react-i18next";
import Table from "../Table";
import {DateTimeRender, EmailRender} from "../Table/Renders";
import {cutText, removeHTMLTags} from "../../helpers/util";
import {NavLink, useNavigate} from "react-router-dom";
import bases from "../../routes/bases";
import useAPIDataProvider from "../../controllers/useAPIDataProvider";
import Title from "../LayoutComponents/Title";

const StartYourTripData = props => {
  const {t} = useTranslation();
  const {getStartYourTripData} = useAPIDataProvider();
  const navigate = useNavigate();

  return (
    <>
      <Title className="ml-5">{t('start your trip data.table title')}</Title>
      <Table
      tableName="contactUs"
      columns={[
        {accessor: 'email', Header: t('start your trip data.email'), type: 'string'},
        {accessor: 'created_at', Header: t('start your trip data.created_at'), type: 'date'},
        {accessor: 'message', Header: t('start your trip data.message'), type: 'string'}
      ]}
      fetchData={async ({pages}) => {
        const {error, data} = await getStartYourTripData({pages});
        if (error) return {data: [], totalRecords: 0};
        return {data: data.data, totalRecords: data.totalRecords};
        /*        return {
                  data: data.splice(pages.from, pages.to),
                  totalRecords: data.length
                }*/
      }}
      onView={row => navigate(`/${bases.start_your_trip}/${row._id}`)}
      renders={{
        name: (v, row) => <NavLink to={`${bases.start_your_trip}/${row._id}`} className="link">{v}</NavLink>,
        message: (v, row) => typeof v !== 'string' ? v : <>
          <div dangerouslySetInnerHTML={{__html: cutText(v.replace(/<br(\/)?>/g, ' '), 280)}}/>
          {v?.length > 280 ? <div className="link" onClick={() => navigate(`/${bases.start_your_trip}/${row._id}`)}>{t('show more')}</div> : ""}
          </>,
        email: EmailRender,
        created_at: DateTimeRender
      }}
    />
      </>
  );
};


export default StartYourTripData;
