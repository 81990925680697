import React from 'react';
import s from './SearchBarResults.module.css';
import classNames from 'classnames';

const SearchBarResults = props => {
  const {result, onSelect = () => {}} = props;
  const resultClassNames = classNames({
    [s.result]: true,
    [s.resultSelected]: props.selected
  });

  return (
    <div className={resultClassNames} onMouseDown={(e) => onSelect(result, e)}>
      {result.image &&
      <div className={s.imageContainer}>
        {result.image}
      </div>}
      <div className={s.itemName}>{result.name}</div>
    </div>
  );
};


export default SearchBarResults;
