import useAuth from "../../providers/UserProvider/useAuth";

export const useAdminController = () => {
	const {secQuery, getType} = useAuth();


	const serializeAdmin = admin => ({
		...admin,
		suspended: admin.suspended === '1',
		type: admin.type === 'admin' ? 1 : 2
	})
	const deserializeAdmin = admin => {
		console.log(admin);
		return ({
			...admin,
			suspended: admin.suspended ? '1' : '0',
			type: admin.type  === 1 ? 'admin' : 'to'
		});
	}


	const deleteAdmin = async ({id}) => {
		const {error, data, aborted} = await secQuery(`/api/admin/admin/${id}`, {}, 'DELETE');
		if (aborted) return {error, success: false};
		return {error, success: data?.success};
	}
	const setSuspended = async (id, suspended) => {
		const {error, data} = await secQuery(`/api/admin/admin/${id}`, {suspended: suspended === '1'}, 'PUT');
		return {error, data};
	}

	const getAdminRoles = async id => {
		const {error, data} = await secQuery(`/api/admin/admin/${id}/roles`, {}, 'GET');
		return {error, roles: data?.roles};
	}

	const checkSession = async () => {
		const {data} = await secQuery('/api/admin/checkSession', {}, 'POST');
		return !!data?.token;
	}

	const getAdmins = async ({page = 0, filters = {}}) => {
		const {error, data} = await secQuery('/api/admin/admins', {page, filters}, 'POST');

		// format admins
		if(Array.isArray(data?.admins)){
			data.admins = data.admins.map(admin => deserializeAdmin(admin));
		}

		return {error, admins: data?.admins, totalRecords: data?.totalRecords};
	}
	const getAdmin = async ({id}) => {
		const {error, data} = await secQuery(`/api/admin/admin/${id}`, null, 'GET');
		return {error, admin: data?.admin ? deserializeAdmin(data.admin) : null};
	}

	const newAdmin = async ({admin}) => {
		const {error, data} = await secQuery('/api/admin/admin', serializeAdmin(admin), 'POST');
		return {error, id: data?.id};
	}
	const editAdmin = async ({id, admin}) => {
		admin = serializeAdmin(admin);
		const {error, data} = await secQuery(`/api/admin/admin/${id}`, admin, 'PUT');
		return {error, success: data?.success};
	}

	const requestPasswordChangeLink = async ({id}) => {
		const {error, data} = await secQuery(`/api/admin/admin/${id}/requestPasswordChangeLink`, null, 'POST');
		return {error, link: data?.link};
	}

	const changePassword = async ({id, hash, password}) => {
		const {error, data} = await secQuery(`/api/admin/admin/${id}/changePassword`, {hash, password}, 'POST');
		return {error, success: data?.success};
	}

	const requestTOSetupLink = async ({id}) => {
		const {error, data} = await secQuery(`/api/admin/admin/${id}/requestTOSetupLink`, null, 'POST');
		return {error, link: data?.link};
	}

	const tourOperatorSetup = async ({id, hash, data: _data}) => {
		const {error, data} = await secQuery(`/api/admin/admin/${id}/tourOperatorSetup`, {hash, ..._data}, 'POST');
		return {error, success: data?.success};
	}

	const getTourOperatorSetupData = async ({id, hash}) => {
		const {error, data} = await secQuery(`/api/admin/admin/${id}/tourOperatorSetupData`, {hash}, 'POST');
		return {error, data};
	}


	return {
		deleteAdmin,
		setSuspended,
		getAdminRoles,
		checkSession,
		getAdmins,
		getAdmin,
		newAdmin,
		editAdmin,
		requestPasswordChangeLink,
		changePassword,
		requestTOSetupLink,
		tourOperatorSetup,
		getTourOperatorSetupData
	};
};

export default useAdminController;

