import s from './ToSummaryTable.module.css';
import {ItPackage, OutletItineraryContext} from "../../../../types/itineraries";
import {useTranslation} from "react-i18next";
import React from "react";

import {ReactComponent as NameSVG} from '../../../../resources/svg/it/summary/name.svg';
import {ReactComponent as InternalDescriptionSVG} from '../../../../resources/svg/it/summary/internal_description.svg';
import {ReactComponent as ExternalDescriptionSVG} from '../../../../resources/svg/it/summary/external_description.svg';
import {ReactComponent as DaysSVG} from '../../../../resources/svg/it/summary/days.svg';
import {ReactComponent as ObservationsSVG} from '../../../../resources/svg/it/summary/observations.svg';
import {ReactComponent as FeaturesSVG} from '../../../../resources/svg/it/summary/features.svg';
import {ReactComponent as IncludedSVG} from '../../../../resources/svg/it/tick.svg';
import {ReactComponent as NotIncludedSVG} from '../../../../resources/svg/it/excluded.svg';
import {useOutletContext} from "react-router-dom";
import {langRender} from "../../../../helpers/i18n";


interface ToSummaryTableProps {
  selectedPackage: ItPackage;
}

const ToSummaryTable = ({selectedPackage}: ToSummaryTableProps) => {
  const {t} = useTranslation();
  const {itinerary} = useOutletContext<OutletItineraryContext>();

  if(!itinerary.places)return <></>;

  const startNights = Math.min(...itinerary.places.map(p => p.start));
  const endNights = Math.max(...itinerary.places.map(p => p.end));
  const totalNights = endNights - startNights + 1;

  return <div className={s.container}>
    <div className={s.item} style={{paddingLeft: '10px'}}>
      <div className={s.title}>
        <NameSVG/>
        <div>{t('to.itinerary.package.description list.name')}</div>
      </div>
      <div className={s.content}>
        <div className={s.circle} style={{borderColor: 'var(--it-yellow)'}}/>
        <div>{langRender(selectedPackage.name)}</div>
      </div>
    </div>

    <div className={s.item}>
      <div className={s.title}>
        <DaysSVG/>
        <div>{t('to.itinerary.package.description list.nights')}</div>
      </div>
      <div className={s.content}>
        <div className={s.circle} style={{marginLeft: '15px',borderColor: 'var(--it-green)'}}/>
        <div>{totalNights} {t('nights', {count: totalNights})}</div>
      </div>
    </div>

    <div className={s.item}>
      <div className={s.title}>
        <InternalDescriptionSVG/>
        <div>{t('to.itinerary.package.description list.internal description')}</div>
      </div>
      <div className={s.content}>
        <div className={s.circle} style={{marginLeft: '15px',borderColor: 'var(--it-green)'}}/>
        <div>{langRender(itinerary.internal_description)}</div>
      </div>
    </div>

    <div className={s.item}>
      <div className={s.title}>
        <ExternalDescriptionSVG/>
        <div>{t('to.itinerary.package.description list.external description')}</div>
      </div>
      <div className={s.content}>
        <div className={s.circle} style={{marginLeft: '15px',borderColor: 'var(--it-green)'}}/>
        <div>{langRender(itinerary.external_description)}</div>
      </div>
    </div>

    <div className={s.item}>
      <div className={s.title}>
        <IncludedSVG/>
        <div>{t('to.itinerary.package.description list.included')}</div>
      </div>
      {(Array.isArray(itinerary.included) ? itinerary.included : []).map((included, index) => <div className={s.listContent} key={index}>
        <div className={s.circle} style={{marginLeft: '15px',borderColor: 'var(--it-green-places)'}}/>
        <div>{langRender(included)}</div>
      </div>)}
    </div>

    <div className={s.item}>
      <div className={s.title}>
        <NotIncludedSVG/>
        <div>{t('to.itinerary.package.description list.excluded')}</div>
      </div>
      {(Array.isArray(itinerary.excluded) ? itinerary.excluded : []).map((excluded, index) => <div className={s.listContent} key={index}>
        <div className={s.circle} style={{marginLeft: '15px',borderColor: 'var(--it-red)'}}/>
        <div>{langRender(excluded)}</div>
      </div>)}
    </div>


    <div className={s.item}>
      <div className={s.title}>
        <ObservationsSVG/>
        <div>{t('to.itinerary.package.description list.observations')}</div>
      </div>
      {(Array.isArray(itinerary.observations) ? itinerary.observations : []).map((observation, index) => <div className={s.listContent} key={index}>
        <div className={s.circle} style={{marginLeft: '15px',borderColor: 'var(--it-green)'}}/>
        <div>{langRender(observation)}</div>
      </div>)}
    </div>

    <div className={s.item}>
      <div className={s.title}>
        <FeaturesSVG/>
        <div>{t('to.itinerary.package.description list.features')}</div>
      </div>
      {(Array.isArray(itinerary.features) ? itinerary.features : []).map((feature, index) => <div className={s.listContent} key={index}>
        <div className={s.circle} style={{marginLeft: '15px',borderColor: 'var(--it-lavanda)'}}/>
        <div>{langRender(feature.name)}</div>
      </div>)}
    </div>

  </div>

}

export default ToSummaryTable;