import useAuth from "../../providers/UserProvider/useAuth";


export const useInsurance = () => {
	const {secQuery} = useAuth();
	const getPolicies = async () => {
		const {error, data} = await secQuery('/api/insurance/policies', false, 'GET');
		return {error, policies: data?.policies};
	}

	return {getPolicies};
}