import React from 'react';
import s from './NotFoundPage.module.css';
import {useTranslation} from "react-i18next";

const NotFoundPage = props => {
  const {t} = useTranslation();
    return (
        <div>{t('Page not found')}</div>
    );
};


export default NotFoundPage;
