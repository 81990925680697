import React, {useMemo} from 'react';
import s from './SettingsPage.module.css';
import { Outlet} from 'react-router-dom';
import bases from "../../../routes/bases";
import MenuProvider from "../../../providers/MenuProvider/MenuProvider";
import useAuth from "../../../providers/UserProvider/useAuth";
import {canTDWrite as checkTDWrite} from "../../Permissions/VisibleWithPermissions";


const SettingsPage = props => {
  const {getType} = useAuth();

  let type = getType();

  const canTDWrite = useMemo(() => checkTDWrite(), [])


  let elements = useMemo(() => {
    let r = [
      {name: 'page', path: bases.configuration},
    ];
    if(canTDWrite){
      r.push({name: 'my profile', path: bases.config_admin + '/me'})
    }

    if(type === 'admin' && canTDWrite) {
      r.push(...[
        {name: 'administrators', path: bases.config_admin},
        {name: 'insurance', path: bases.config_insurance}
      ]);
    }
    return r;
  }, [type]);


  //if(location.pathname === '/settings')return <Navigate to={"/settings/page"}/>
  return <MenuProvider elements={elements}>
    <Outlet/>
  </MenuProvider>
};


export default SettingsPage;
