import useAuth from "../../providers/UserProvider/useAuth";
import {getAdmin} from "../../providers/UserProvider/AuthProvider";
// Todo: move interfaces to authProvider

export enum RolesType {
  Read = 1,
  Write = 2
}

export enum TokenType {
  Admin = 1,
  TourOperator = 2,
  User = 3
}

export enum EnterprisesType {
  TT = 'TT',
  TD = 'TD'
}


interface Admin {
  type: TokenType,
  enterprises: EnterprisesType[],
  roles: RolesType[]
}



interface hasPermissionsProps {
  enterprises?: EnterprisesType[]
  roles?: RolesType[]
  admin: Admin
}
export function hasPermissions({enterprises, roles, admin}: hasPermissionsProps){
  if(Array.isArray(enterprises) && enterprises.filter(enterprise => admin.enterprises.includes(enterprise)).length !== enterprises.length){
    return false
  }

  return !(roles && roles.filter(role => admin.roles.includes(role)).length !== roles.length);
}

export function canTDWrite(){
  return hasPermissions({admin: getAdmin() as Admin, enterprises: [EnterprisesType.TD], roles: [RolesType.Write]})
}


interface VisibleWithPermissionsProps {
  enterprises?: EnterprisesType[]
  roles?: RolesType[]
  children: React.ReactNode
}

export function VisibleWithPermissions({enterprises, roles, children}: VisibleWithPermissionsProps){
  const {getAdmin} = useAuth();
  const admin = getAdmin() as Admin

  if(!hasPermissions({enterprises, roles, admin})){
    return <></>
  }

  return <>{children}</>
}