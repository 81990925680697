import moment from "moment";

export const config = {
  baseUrl: 'http://localhost:1057'
};

export const openActivity = ({api, code}) => {
  let today = encodeURIComponent(new Date().toISOString());
  window.open(`${config.baseUrl}/i/activity?to=${today}&from=${today}&persons=${encodeURIComponent(JSON.stringify([18]))}&code=${code}&api=${api}`);
}

export const openHotel = ({api, code}) => {
  let from = encodeURIComponent(new Date().toISOString());
  let to = encodeURIComponent(moment().add('1', 'day').toISOString());
  window.open(`${config.baseUrl}/i/hotel?to=${to}&from=${from}&adults=1&children=0&rooms=1&code=${code}&api=${api}`);
}
