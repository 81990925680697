import React, {useEffect, useMemo} from 'react';
import s from './GridNavigatorWithIcons.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Select} from "@mantine/core";
import {useLocalStorage} from "@mantine/hooks";
import {useSelectOptions} from "../../hooks/useSelectOptions";
import useAuth from "../../providers/UserProvider/useAuth";
import cn from "classnames";

const TT = require('../../routes/groupElements');
const TD = require('../../routes/groupElementsTD');

const GridNavigatorWithIcons = props => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [category, setCategory] = useLocalStorage({
    key: 'grid_category',
    defaultValue: 'TT'
  });
  //const [gridKeys, setGridKeys] = useLocalStorage('GridNavigatorWithIcons', []);
  //const [gridKeys, setGridKeys] = useState('GridNavigatorWithIcons', []);

  const {enterpriseOptions} = useSelectOptions();

  const {getAdmin} = useAuth();

  const allowed_enterprises = getAdmin().enterprises;

  useEffect(() => {
    if(!Array.isArray(allowed_enterprises) || !allowed_enterprises.length === 0)return;
    if(!allowed_enterprises.includes(category))setCategory(allowed_enterprises[0]);
  }, [allowed_enterprises]);

  let data = useMemo(() => {
    let r = category === 'TT' ? TT : TD;
    let gridKeys = r.allElements.map(a => a.key);
    let elements = [];
    if(Array.isArray(gridKeys) && gridKeys.length > 0){
      elements = gridKeys.map(key => r.allElements.find(e => e.key === key));
    }

    return {
      ...r,
      elements,
      gridKeys
    }
  }, [category]);

  const {allElements, groups, elements, gridKeys} = data;



/*

  const elements = useMemo(() => {
    console.log(gridKeys);
    if (!Array.isArray(gridKeys) || !gridKeys.length) return [];
    return gridKeys.map(key => allElements.find(e => e.key === key));
  }, [gridKeys, allElements, groups]);


  const loadDefaultKeys = () => setGridKeys(allElements.map(a => a.key));
*/

  const onElementClick = element => {
    if(element.disabled)return
    navigate('/' + element.path);
  }

/*
  useEffect(() => {
    //if (!Array.isArray(gridKeys) || !gridKeys.length) loadDefaultKeys();
    loadDefaultKeys();
  }, [allElements]);*/

  if (!Array.isArray(elements) || !elements.length) return JSON.stringify(elements);



  return (
    <div>
      {Array.isArray(allowed_enterprises) && allowed_enterprises.length > 1 && <Select data={enterpriseOptions.filter(({value}) => allowed_enterprises.includes(value))} label={t('grid.category label')} value={category} onChange={v => setCategory(v)} placeholder={t('grid.category placeholder')} className="m-5" style={{width: '300px'}}/>}
      {Object.keys(groups).map(key => {
        let group = groups[key];
        let els = elements.filter(el => el.group.name === group.name);
        if(!els || !els.length)return <></>
        return <div>
          <div className={s.groupTitle}>{t(`grid.groups.names.${group.name}`)}</div>
          <hr/>
          <div className={s.container}>
            {els.map(element => <div key={element.key} className={cn(s.elementContainer, {[s.elementContainerDisabled]: element.disabled})}
                                     onClick={() => onElementClick(element)}>
              <div className={s.name}>{t(`grid.names.${element.name}`)}</div>
              {element.icon && <div className={s.iconContainer}>{element.icon}</div>}
            </div>)}
          </div>
        </div>
      })}

    </div>
  );
};


export default GridNavigatorWithIcons;
