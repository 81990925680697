import React, {useEffect, useState} from 'react';
import s from './ToItineraryValidationChatGroups.module.css';
import {useNavigate, useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useTourOperatorItinerary from "../../../../controllers/useTourOperatorItinerary";
import {toastError} from "../../../../helpers/ErrorManager";
import Loading from "../../../Loading";
import Button from "../../../Inputs/Button";
import ToItineraryValidationChat from "../ToItineraryValidationChat";
import {IoIosArrowRoundBack} from "react-icons/io";
import {Table, Title} from "@mantine/core";
import dayjs from "dayjs";
import useAuth from "../../../../providers/UserProvider/useAuth";

const localizedFormat = require('dayjs/plugin/localizedFormat');
require('dayjs/locale/es');
dayjs.extend(localizedFormat);
dayjs.locale('es');

const ToItineraryValidationChatGroups = props => {
	const {t} = useTranslation();
	const navigation = useNavigate();
	const {getChats, newChat} = useTourOperatorItinerary();


	const [chats, setChats] = useState();
	const [loading, setLoading] = useState(false);
	const [selectedChat, setSelectedChat] = useState();
	const {itinerary} = useOutletContext();


	const loadChats = async () => {
		setLoading(true);
		const {error, chats} = await getChats({
			itinerary_id: itinerary._id
		});
		setLoading(false);
		if (error) return toastError(error, t);
		setChats(chats);
	}

	useEffect(() => {
		if (itinerary) loadChats();
	}, [itinerary]);

	const onNewChat = async () => {
		const title = prompt(t('to.itinerary.chat data.chat title ask'));
		const {error} = await newChat({
			itinerary_id: itinerary._id,
			title
		});
		if (error) return toastError(error, t);
		loadChats();
	}


	if (loading) return <Loading/>

	if (selectedChat) return <div>
		<div className={s.goBack} onClick={() => setSelectedChat(false)}>
			<div><IoIosArrowRoundBack size={20}/></div>
			<div className="ml-2">{t('to.itinerary.chat data.go back to all chat groups')}</div>
		</div>

		<ToItineraryValidationChat itinerary={itinerary} chat_id={selectedChat}/>
	</div>


	return (
		<div>
			<div className="w-full flex items-center flex-wrap">
				<Title order={3}>{t('to.itinerary.chat data.title')}</Title>
				{Array.isArray(chats) && chats.length === 0 && <div>{t('to.itinerary.chata data.no chat messages')}</div>}

				{Array.isArray(chats) && chats.length > 0 && <Table>
					<thead className="text-left">
					<tr>
						<th>{t('to.itinerary.chat data.name')}</th>
						<th>{t('to.itinerary.chat data.last message')}</th>
						<th>{t('to.itinerary.chat data.created by')}</th>
						<th>{t('to.itinerary.chat data.created at')}</th>
					</tr>
					</thead>
					<tbody>
					{chats.map(chat => <tr className="cursor-pointer hover:bg-gray-100" onClick={() => setSelectedChat(chat._id)}>
						<td>{chat.title}</td>
						<td>{dayjs(chat.updated_at).format('lll')}</td>
						<td>{chat.created_by?.name}</td>
						<td>{dayjs(chat.created_at).format('lll')}</td>
					</tr>)}
					</tbody>
				</Table>}
			</div>
			<hr className="mb-5"/>

			<div>
				<Button onClick={onNewChat}>{t('to.itinerary.chat data.create new chat')}</Button>
			</div>
		</div>
	);
};


export default ToItineraryValidationChatGroups;