import {ColumnType} from "antd/es/table";
import {getTextFilter} from "../../../../util/table/filters/TableTextFilter";
import {getDateRangeFilter} from "../../../../util/table/filters/TableDateRangeFilter";
import React from "react";
import {DateRender, MoneyRender} from "../../../Table/Renders";
import {getListFilter} from "../../../../util/table/filters/TableListFilter";
import {LinkFormat} from "../../../Formatters/Formatters";
import bases from "../../../../routes/bases";
import {TableActions} from "../../../../util/table/components/TableActions";
import {TrainersPayment} from "./types";
import {Trainer} from "../../TDTrainers/types";
import {langRender} from "../../../../helpers/i18n";

interface Props {
  trainers: Trainer[]
  products: any[]
  onEdit: (id: string) => void
  canWrite: boolean
}

export const trainersPaymentColumns = ({trainers, products, onEdit, canWrite}: Props) => {
  return [
    {
      title: '',
      dataIndex: '_id',
      key: 'action',
      width: 30,
      render: (_id: string, reservation) => <TableActions onEdit={canWrite ? () => onEdit(_id) : undefined}/>
    },
    {
      title: 'Instructor',
      dataIndex: 'trainer',
      key: 'trainer',
      sorter: true,
      render: (trainer, row) => <LinkFormat url={`/${bases.td.trainers}/update/${row.product?.trainer?._id}`} label={row?.product?.trainer?.name || ''}/>,
      ...getListFilter<TrainersPayment>({
        name: 'trainer',
        options: trainers.map((trainer: Trainer) => ({label: trainer.name, value: trainer._id}))
      })
    },
    // {
    //   title: 'Precio',
    //   dataIndex: 'total_price',
    //   key: 'total_price',
    //   sorter: true,
    //   render: a => <MoneyRender value={a}/>
    // },
    {
      title: 'Salario',
      dataIndex: 'salary',
      key: 'salary',
      sorter: true,
      render: a => <MoneyRender value={a}/>
    },
    // {
    //   title: 'Referencia',
    //   dataIndex: 'reference',
    //   key: 'reference',
    //   sorter: true,
    //   ...getTextFilter<TrainersPayment>({name: 'name'})
    // },
    {
      title: "Actividad",
      dataIndex: 'product',
      key: 'product',
      sorter: true,
      ...getListFilter({name: 'product', options: products.map(a => ({label: a?.code || a?.name?.es, value: a._id}))}),
      render: (_, row) => {
        const product = row.product
        const name = product.code || langRender(product.name)
        return <div key={product._id}>
          ({product.adults + product.children})
          <span> </span>
          <LinkFormat url={`/${bases.td.products}/${product._id}`} label={name}/>
        </div>
      }
    },
    {
      title: 'Cliente',
      dataIndex: 'client',
      key: 'client',
      sorter: true,
      render: (_, row) => <LinkFormat url={`/${bases.td.clients}/${row?.client._id}`} label={row?.client?.full_name}/>,
      ...getTextFilter<TrainersPayment>({name: 'name'})
    },
    {
      title: 'Fecha final de actividad',
      dataIndex: 'activity_final_date',
      key: 'activity_final_date',
      sorter: true,
      render: (_, reservation) => DateRender(reservation.product.date_end),
      ...getDateRangeFilter<TrainersPayment>({name: 'activity_final_date'})
    }
  ] satisfies ColumnType<TrainersPayment>[]
}