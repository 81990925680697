import React, {useEffect, useMemo} from 'react';
import s from './Filters.module.css';
import {useTranslation} from "react-i18next";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import {useForm} from "@mantine/form";
import {Accordion, Button, MultiSelect, Select, TextInput} from "@mantine/core";
import {useSearchParams} from "react-router-dom";
import {DateRangePicker} from "@mantine/dates";
import SelectLanguage from "../Inputs/SelectLanguage";

const Filters = ({elements, name, defaultFilters = {}, onFilter}) => {
	const {t} = useTranslation();
	const [filters, setFilters] = useLocalStorage(name + '_filters', defaultFilters);

	const [params, setParams] = useSearchParams();

	let initialValues = useMemo(() => {
		let r = {};

		for (let entry of params.entries()) {
			let [key, value] = entry;
			if(key.substring(0,2) === 'f_')r[key.substring(2)] = value;
		}
		for(let key in defaultFilters){
			if(defaultFilters[key])r[key] = defaultFilters[key];
		}

		elements.forEach(({type, filter, accessor}) => {
			let t = typeof filter === 'string' ? filter : type;
			r[accessor] = r[accessor] || t === 'date' ? [null, null] : (t === 'list' || t === 'list_multiple') ? [] : '';
		});
		return r;
	}, [defaultFilters, elements]);


	const form = useForm({
		initialValues
	});

	const _onFilter = data => {
		//setFilters(data);
		let r = Object.assign({}, data);
		for(let key in r){
			// we don't want to send empty array
			if(Array.isArray(r[key]) && r[key].length === 0)r[key] = null;
		}
		onFilter(r);
	}

/*
	useEffect(() => {
		onFilter(form.values);
	}, [form.values]);
*/

/*	useEffect(() => {
		if(defaultFilters){
			onFilter(defaultFilters);
			form.setValues(defaultFilters);
		}
	}, [defaultFilters]);*/


	return (
		<Accordion>
			<Accordion.Item label={t('filter')}>
				<form onSubmit={form.onSubmit((data) => _onFilter(data))}>
					{elements.map(({type, accessor, data, filter, Header}, i) => {
						let t = typeof filter === 'string' ? filter : type;
						if(t === 'string') {
							return <TextInput key={i} label={Header} {...form.getInputProps(accessor)}/>
						}else if(t === 'list' && Array.isArray(data)) {
							return <Select key={i} label={Header} data={data} {...form.getInputProps(accessor)}/>
						}else if(t === 'list_multiple'){
							return <MultiSelect key={i} label={Header} data={data} {...form.getInputProps(accessor)} />
						}else if(t === 'range_date'){
							return <DateRangePicker
								key={i}
								label={Header}
								{...form.getInputProps(accessor)}
							/>
						}else if(t === 'language'){
							return <SelectLanguage key={i} label={Header} {...form.getInputProps(accessor)}/>
						}
						else {
							return <></>
						}
					})}
					<div className="flex mt-5">
						<Button type="submit" className="mr-5">{t('filter')}</Button>
						<Button variant={"default"} onClick={() => {
							//form.reset();

							form.reset();
							form.setValues(initialValues);
							//form.setFieldValue('f_date', [null, null]);
							//form.setFieldValue('name', 'test');
							_onFilter(initialValues);
						}}>{t('reset filters')}</Button>
					</div>
				</form>
			</Accordion.Item>
		</Accordion>
	);
};


export default Filters;