import FullCalendar from "@fullcalendar/react";
import {useCallback, useEffect, useRef, useState} from "react";
import dayGridPlugin from '@fullcalendar/daygrid'
import useWindowSize from "../../../../hooks/useWindowSize/useWindowSize"; // a plugin!
import esLocale from '@fullcalendar/core/locales/es';
import s from './TDReservationsCalendar.module.css'
import {getData} from "../../../../util/req";
import {CalendarActivity, EventCalendar} from "./types";
import cn from "classnames";
import {ActivityDetails} from "./components/ActivityDetails";
import {dateToServer} from "../../../../util/util";
import dayjs from "dayjs";
import {Select} from "antd";
import {Trainer} from "../../TDTrainers/types";
import {EventDetails} from "./components/EventDetails";
import {paidFilterOptions} from "../util";

function RenderEventContent(props: any) {
  const extendedProps = props.event.extendedProps;
  if(extendedProps.activity){
    const activity = props.event.extendedProps.activity as CalendarActivity;
    const onClick = props.event.extendedProps.onClick;

    const totalPayed = activity.payments.reduce((acc, payment) => acc + payment.amount, 0);

    const isFullyPaid = totalPayed>= activity.total_price;
    const isCancelled = !!activity.cancellation;
    const isUnPaid = totalPayed === 0 && !isCancelled;

    const totalPersons = activity.product.adults + activity.product.children;
    const activityName = activity?.product?.code || activity?.product?.name?.es
    const trainerName = activity?.product?.trainer?.name

    return <div onClick={() => onClick(activity)} className={cn([
      s.reservationView,
      {[s.reservationViewPaid]: isFullyPaid && !isCancelled},
      {[s.reservationViewUnpaid]: !isFullyPaid && !isCancelled},
      {[s.reservationViewCancelled]: isCancelled},
      {[s.reservationViewUnPaid]: isUnPaid}
    ])}>
      <div className="font-bold">{totalPersons} {activityName} {trainerName}</div>
    </div>
  }else { // event
    const event = extendedProps.data as EventCalendar;
    const onClick = extendedProps.onClick;
    return <div onClick={() => onClick(event)} className={s.event} style={{backgroundColor: event.color}}>
      <div className="font-bold">{event.name}</div>
    </div>
  }
}

export function TDCalendarActivities() {
  const [calendarEvents, setCalendarEvents] = useState<any>({});
  const calendarRef = useRef<any>()
  const {height} = useWindowSize();
  const [selectedActivity, setSelectedActivity] = useState<CalendarActivity | null>(null)
  const [selectedEvent, setSelectedEvent] = useState<EventCalendar | null>(null)

  const [trainers, setTrainers] = useState<Trainer[]>([]);
  const [selectedTrainer, setSelectedTrainer] = useState<string | null>(null)
  const [reservationFilter, setReservationFilter] = useState<string | null>(null)

  const loadData = useCallback(async (from: Date, to: Date) => {
    let url = `/td/calendar/activities?activity_date=${from.toISOString()},${to.toISOString()}`
    if (selectedTrainer) {
      url += `&trainer=${selectedTrainer}`
    }
    if(reservationFilter){
      url += `&paid=${reservationFilter}`
    }

    const result = await getData(url)
    if (!result.data) {
      return false;
    }

    return result.data as (CalendarActivity | EventCalendar)[];
  }, [selectedTrainer, reservationFilter])

  const loadCalendarEvents = async (fetchInfo: any, resolve: any, reject: any) => {
    const date = {
      start: fetchInfo.start.valueOf(),
      end: fetchInfo.end.valueOf()
    }

    const key = date.start + date.end;
    if (calendarEvents[key]) {
      return resolve(calendarEvents[key]);
    }

    const activitiesAndEvents = await loadData(dateToServer(new Date(date.start)) as Date, dateToServer(new Date(date.end)) as Date);

    if (!activitiesAndEvents) {
      return reject()
    }



    setCalendarEvents((prev: any) => {
      let d = activitiesAndEvents.map(data => {
        if((data as EventCalendar).type === 'event'){
          const event = data as EventCalendar;
          return {
            extendedProps: {
              event_id: event._id,
              data: event,
              onClick: (event: EventCalendar) => setSelectedEvent(event)
            },
            start: event.date,
            end: dayjs(event.date_end).add(1, 'day').toDate() // date end is exclusive !!
          }
        }else{
          const activity = data as CalendarActivity;
          // date end is exclusive !!
          const date_end = dayjs(activity.product.date_end ?? activity.product.date).add(1, 'day').toDate();

          return {
            extendedProps: {
              event_id: Math.floor(Math.random() * 1000),
              activity,
              onClick: (activity: CalendarActivity) => setSelectedActivity(activity)
              //onClick: () => navigate(`/${routes.td.reservations}/${activity._id}`)
            },
            start: activity.product.date,
            end: date_end
          }
        }
      });

      resolve(d);

      return {
        ...prev, [key]: d
      };
    });
  }

  const loadTrainers = async () => {
    const result = await getData('/td/trainer_all')
    if(Array.isArray(result.data)){
      setTrainers(result.data.map((trainer: Trainer) => ({
        value: trainer._id,
        label: trainer.name
      })));
    }
  }

  useEffect(() => {
    loadTrainers()
  }, []);


  const eventSources = [
    {events: loadCalendarEvents}
  ];

  useEffect(() => {
    setCalendarEvents({})
    calendarRef.current!.getApi().refetchEvents()
  }, [selectedTrainer, reservationFilter]);


  return <>
    <ActivityDetails onRefresh={() => {
      setCalendarEvents({})
      calendarRef.current!.getApi().refetchEvents()
    }} activity={selectedActivity} onClose={() => setSelectedActivity(null)}/>
    <EventDetails onClose={() => setSelectedEvent(null)} event={selectedEvent}/>

    <div className="flex">
      <Select
      placeholder="Instructor"
      options={trainers}
      className="m-5"
      style={{width: 200}}
      allowClear
      value={selectedTrainer}
      onChange={v => setSelectedTrainer(v)}
    />
      <Select
        placeholder="Reservas"
        options={paidFilterOptions}
        className="m-5"
        style={{width: 200}}
        allowClear
        value={reservationFilter}
        onChange={v => setReservationFilter(v)}
      />
    </div>

    <div className="m-5" style={{height: `calc(${height}px - 30px - var(--header-height))`}}>
      <FullCalendar
        timeZone="utc"
        height="100%"
        // @ts-ignore
        ref={calendarRef}
        plugins={[dayGridPlugin]}
        initialView='dayGridMonth'
        eventSources={eventSources}
        lazyFetching={true}
        locale={esLocale}
        eventContent={RenderEventContent}
        headerToolbar={{
          end: 'prevYear,prev today next,nextYear'
        }}
      />
    </div>
  </>
}