import React, {useMemo} from 'react';
import s from './SelectCountry.module.css';
import {useTranslation} from "react-i18next";
import {MultiSelect, Select} from "@mantine/core";

const SelectCountry = ({multiple = false, ...props}) => {
	const {t} = useTranslation();
	const countries = useMemo(() =>
			["AF", "AL", "DE", "AD", "AO", "AI", "AQ", "AG", "SA", "DZ", "AR", "AM", "AW", "AU", "AT", "AZ", "BS", "BD", "BB", "BH", "BE", "BZ", "BJ", "BM", "BY", "BO", "BA", "BW", "BR", "BN", "BG", "BF", "BI", "BT", "CV", "KH", "CM", "CA", "BQ", "QA", "TD", "CZ", "CL", "CN", "CY", "VA", "CO", "KM", "CG", "KP", "KR", "CR", "CI", "HR", "CU", "CW", "DK", "DM", "EC", "EG", "SV", "AE", "ER", "SK", "SI", "ES", "US", "EE", "SZ", "ET", "PH", "FI", "FJ", "FR", "GA", "GM", "GE", "GH", "GI", "GD", "GR", "GL", "GP", "GU", "GT", "GF", "GG", "GN", "GQ", "GW", "GY", "HT", "HN", "HU", "IN", "ID", "IQ", "IR", "IE", "BV", "IM", "CX", "NF", "IS", "AX", "KY", "CC", "CK", "FO", "GS", "HM", "FK", "MP", "MH", "UM", "PN", "SB", "TC", "VG", "VI", "IL", "IT", "JM", "JP", "JE", "JO", "KZ", "KE", "KG", "KI", "KW", "LA", "LS", "LV", "LB", "LR", "LY", "LI", "LT", "LU", "MK", "MG", "MY", "MW", "MV", "ML", "MT", "MA", "MQ", "MU", "MR", "YT", "MX", "FM", "MD", "MC", "MN", "ME", "MS", "MZ", "MM", "NA", "NR", "NP", "NI", "NE", "NG", "NU", "NO", "NC", "NZ", "OM", "NL", "PK", "PW", "PA", "PG", "PY", "PE", "PF", "PL", "PT", "PR", "HK", "MO", "GB", "CF", "CD", "DO", "RE", "RW", "RO", "RU", "EH", "WS", "AS", "BL", "KN", "SM", "MF", "PM", "VC", "SH", "LC", "ST", "SN", "RS", "SC", "SL", "SG", "SX", "SY", "SO", "LK", "ZA", "SD", "SS", "SE", "CH", "SR", "SJ", "TH", "TW", "TZ", "TJ", "IO", "TF", "PS", "TL", "TG", "TK", "TO", "TT", "TN", "TM", "TR", "TV", "UA", "UG", "UY", "UZ", "VU", "VE", "VN", "WF", "YE", "DJ", "ZM", "ZW"]
				.map(key => ({
					value: key,
					label: t("countryList." + key)
				}))
		, []);

	return (
		multiple ?
			<MultiSelect
				data={countries}
				searchable={true}
				{...props}
			/>
			:
			<Select
				searchable={true}
				data={countries}
				{...props}
			/>
	);
};


export default SelectCountry;
