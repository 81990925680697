import {useNavigate} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import {TableParams, TableResult} from "../../../../util/table/types";
import {
  defaultTableParams,
  handleTableChange,
  setTotalToTableParams,
  tableParamsToSearchParams
} from "../../../../util/table/table";
import {ReservationTable, ReservationTableSum} from "../types";
import useSWR from "swr";
import {Table} from "antd";
import {reservationsColumns} from "./columns";
import {MoneyRender} from "../../../Table/Renders";
import {Button, Title} from "@mantine/core";
import bases from "../../../../routes/bases";
import {useTDReservations} from "../../../../controllers/useTDReservations/useTDReservations";
import {useTranslation} from "react-i18next";
import {TDReservationsTableMoreFilters} from "./TDReservationsTableMoreFilters";
import {Seller} from "../../TDSellers/types";
import {CancelReservation} from "../CancelReservation";
import {canTDWrite, hasPermissions} from "../../../Permissions/VisibleWithPermissions";


interface Props {
  selectedRowKeys: React.Key[]
  onSelectionChange: (selectedRowKeys: React.Key[]) => void
  refresh?: number
  products: any[]
  sellers: Seller[]
}

export function TDReservationsTable({refresh, sellers, selectedRowKeys, onSelectionChange, products}: Props){
  const navigate = useNavigate()
  const [tableParams, setTableParams] = useState<TableParams>(defaultTableParams);
  const {isLoading, data, mutate} = useSWR<TableResult<ReservationTable>>(`/api/admin/td/reservation?${tableParamsToSearchParams(tableParams)}`);
  const {data: sum} = useSWR<ReservationTableSum>(`/api/admin/td/reservation_sum?${tableParamsToSearchParams(tableParams)}`);
  const [loadingButton, setLoadingButton] = useState(false);
  const [reservationForCancel, setReservationForCancel] = useState<ReservationTable>();
  const {t} = useTranslation();

  const {newReservation, deleteReservation} = useTDReservations();

  useEffect(() => {
    setTotalToTableParams(setTableParams, data?.total)
  }, [data]);


  useEffect(() => {
    if(!refresh)return;
    mutate();
  }, [refresh]);

  const onNewReservation = async () => {
    setLoadingButton(true);
    const {error, id} = await newReservation();
    setLoadingButton(false);
    if (error) return alert(error);
    navigate(`/${bases.td.reservations}/${id}`);
  }

  const onDelete = async (id: string) => {
    if (!window.confirm('¿Está seguro de que desea eliminar la reserva?')) return false;
    await deleteReservation({id});
    await mutate();
  }

  const onCalendarCancel = async (id: string) => {
    setReservationForCancel(data?.data.find(reservation => reservation._id === id));
  }

  const canWrite = useMemo(() => canTDWrite(), [])


  return <>
    <CancelReservation reservation={reservationForCancel} onClose={() => {
      setReservationForCancel(undefined)
      mutate()
    }}/>
    <div className="p-10 mb-20">
    <div className="pb-20">
      <div className="flex items-center no-wrap float-left">
        <Title order={2} className="mr-10">{t('td.reservations.title')}</Title>
        <TDReservationsTableMoreFilters setTableParams={setTableParams} tableParams={tableParams}/>
      </div>
      {canWrite && <Button className="float-right" onClick={onNewReservation}
              loading={loadingButton}>{t('td.reservations.create new')}</Button>}
    </div>
    <Table
      columns={reservationsColumns({
        navigate, sellers, products,
        onEdit: (id: string) => navigate(`/${bases.td.reservations}/${id}`),
        onDelete: (id: string) => onDelete(id),
        onCalendarCancel,
        canWrite
      })}
      dataSource={data?.data}
      rowKey={reservation => reservation._id}
      loading={isLoading}
      pagination={tableParams.pagination}
      onChange={handleTableChange(setTableParams)}
      summary={() => {
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={1} colSpan={3}>Total</Table.Summary.Cell>
              <Table.Summary.Cell index={2} colSpan={5}><MoneyRender value={sum?.total_price}/></Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }}
    />
  </div>
    </>
    }