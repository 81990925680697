import React from 'react';
import s from './ShowWithPermissions.module.css';
import useAuth from "../../../providers/UserProvider/useAuth";

const ShowWithPermissions = ({allow = [], children}) => {
	const {getType} = useAuth();
	let search = Array.isArray(allow) ? allow : [allow];
	let type = getType();
	let isAllow = search.includes(type);

	return isAllow ? children : <></>;
};


export default ShowWithPermissions;