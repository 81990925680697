import React from 'react';
import s from './TourOperators.module.css';
import {Navigate} from "react-router-dom";
import bases from "../../../routes/bases";

const TourOperators = props => {
	return (
		<Navigate to={`/${bases.config_admin}?f_type=to`}/>
	);
};


export default TourOperators;