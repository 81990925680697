import React from 'react';
import s from './ChangeHiddenColumnsTable.module.css';
import {useTranslation} from "react-i18next";
import Checkbox from "../../Inputs/Checkbox";
import Button from "../../Inputs/Button_old";

const ChangeHiddenColumnsTable = ({columns, hiddenColumns, setHiddenColumns}) => {
  const {t} = useTranslation();

  const isAllHidden = () => columns.filter(c => !hiddenColumns.includes(c.accessor)).length === 0;

  const toggleAllColumns = () => setHiddenColumns(isAllHidden() ? [] : columns.map(column => column.accessor));
  const toggleColumn = accessor => {
    setHiddenColumns(prev => {
      let selected = [...prev];
      const i = selected.indexOf(accessor);
      if(i === -1){
        selected.push(accessor);
      }else{
        selected.splice(i, 1);
      }
      return selected;
    });
  }
  return (
    <div className="mr-10">
      <div className={s.element} onClick={() => toggleAllColumns()}>
        <div className="w-full font-bold mb-5">{t(`table.hide all`)}</div>
        <div className="w-full text-right">
          <Checkbox checked={isAllHidden()}/>
        </div>
      </div>
      {columns.map(column => <div className={s.element} onClick={() => toggleColumn(column.accessor)}>
        <div className="w-full">{column.Header}</div>
        <div className="w-full text-right">
          <Checkbox classNameContainer="self-end" checked={hiddenColumns.includes(column.accessor)}/>
        </div>
      </div>)}
    </div>
  );
};


export default ChangeHiddenColumnsTable;
