import React, {useEffect, useState} from 'react';
import s from './ToSummaryIt.module.css';
import {useTranslation} from "react-i18next";
import {useOutletContext} from "react-router-dom";
import {langRender} from "../../../helpers/i18n";
import {ItPackage, OutletItineraryContext} from "../../../types/itineraries";
import HeaderTitle from "../Components/HeaderTitle/HeaderTitle";
import Select from "../Itineraries/Inputs/Select/Select";
import {AiFillFolder} from 'react-icons/ai';
import ToSummaryTable from "./ToSummaryTable/ToSummaryTable";
import ToSummaryItHotels from "./ToSummaryItHotels/ToSummaryItHotels";
import ToSummaryItActivities from "./ToSummaryItActivities/ToSummaryItActivities";


const ToSummaryIt = () => {
  const {t} = useTranslation();
  const {itinerary, setStep} = useOutletContext<OutletItineraryContext>();
  const [selectedPackage, setSelectedPackage] = useState<ItPackage>();

  useEffect(() => {
    setStep('summary');
  }, []);

  useEffect(() => {
    if (!itinerary) return;
    if (Array.isArray(itinerary?.packages) && !selectedPackage) setSelectedPackage(itinerary.packages[0]);
  }, [itinerary]);


  if (!itinerary.packages) return <div>{t('to.itinerary.no packages created')}</div>;

  return (
    <div className="container m-auto">
      <HeaderTitle>{t('to.header.summary')}</HeaderTitle>
      <div className="flex items-center text-2xl">
        <AiFillFolder size={25}/>
        <div className="ml-5">{t('to.itinerary.package.select your package')}</div>
      </div>
      <Select
        style={{width: '800px'}}
        className="my-10"
        data={itinerary?.packages.map((p, i) => ({label: langRender(p.name), value: p._id}))}
        label={t('to.itinerary.select package')}
        value={selectedPackage?._id}
        onChange={v => setSelectedPackage(itinerary.packages!.find(p => p._id === v))}
      />

      {selectedPackage && <div className="ml-5">
        <div className="flex flex-nowrap items-center">
          <div className={s.packageNameBall}/>
          <div className="text-2xl">{langRender(selectedPackage.name)}</div>
        </div>

        <ToSummaryTable selectedPackage={selectedPackage}/>

        <div className={s.horBarWithText}>
          <div>{t('to.header.hotels')}</div>
        </div>
        <ToSummaryItHotels selectedPackage={selectedPackage}/>

        <div className={s.horBarWithText}>
          <div>{t('to.header.activities')}</div>
        </div>
        <ToSummaryItActivities/>
      </div>}


      {/*				<ToItineraryPlacesSummary
					data={elements}
					createPlaces={createPlaces}
					filterDataElement={(place, d) => place.day === d.day}
					addAddons={(title, place) => <FormPerDay
						day={place.day}
						form={form}
						isItineraryBlocked={isItineraryBlocked} title={title}
					/>}
				/>*/}
    </div>

  );
};


export default ToSummaryIt;
