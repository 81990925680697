import {Card, Form, Input, InputNumber, message, Modal} from "antd";
import {useEffect, useState} from "react";
import {SellersPayment} from "../types";
import {postData} from "../../../../../util/req";
import {formatDate} from "@fullcalendar/core";

interface Props {
  reservations: SellersPayment[] | undefined
  onClose: (payed: boolean) => void
}

export function PayToSellers({reservations, onClose}: Props) {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const initialValues = {
    payments: []
  }

  useEffect(() => {
    let payments = []

    if (reservations) {
      for (let reservation of reservations) {
        const commissionPercentage = reservation.seller.type === 'internal' ? 0.05 : 0.1

        payments.push({
          _id: reservation._id,
          total_price: reservation.total_price,
          commission: reservation.total_price * commissionPercentage
        })
      }
    }
    form.setFieldsValue({payments})
  }, [reservations]);

  const onSubmit = async () => {
    setLoading(true)
    const values = await form.validateFields()

    const payments = values.payments.map((p: any) => ({
      _id: p._id,
      commission: p.commission
    }))

    const response = await postData(`/td/reservations_pay_to_sellers`, {payments})
    setLoading(false)

    if (response.success) {
      message.open({
        type: 'success',
        content: 'Los pagos se han guardado'
      });
      onClose(true)
    }
  }


  return <Modal width={600} onOk={onSubmit} confirmLoading={loading} title="Cancelar reservas"
                open={!!reservations && reservations?.length > 0} onCancel={() => onClose(false)}>
    <Form form={form} labelCol={{span: 8}} initialValues={initialValues}>
      <Form.List name="payments">
        {(fields) => (
          <>
            {fields.map(({key, name, ...restField}) => {
              const values = form.getFieldsValue()
              const reservation = reservations!.find(reservation => reservation._id === values?.payments[name]._id)!
              let title = `${reservation.seller.name} (${reservation.reference})`
              if (reservation.payments?.length > 0 && reservation.payments[0]) {
                title += ` - ${formatDate(reservation.payments[0].date)}`
              }

              return (
                <Card className="mb-5" title={title}>
                  <Form.Item
                    {...restField}
                    noStyle
                    name={[name, '_id']}
                    rules={[{required: true}]}
                  >
                    <Input type="hidden"/>
                  </Form.Item>
                  <div className="w-full flex">
                    <Form.Item
                      className="w-full"
                      name={[name, 'total_price']}
                      label="Precio total"
                    >
                      <InputNumber style={{width: 150}} prefix="MXN" disabled />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      className="w-full"
                      label="Comision"
                      name={[name, 'commission']}
                      rules={[{required: true}]}
                    >
                      <InputNumber style={{width: 150}} prefix="MXN"/>
                    </Form.Item>
                  </div>
                </Card>
              )
            })}
          </>
        )}
      </Form.List>
    </Form>
  </Modal>
}