import React, {useState} from 'react';
import s from './ToPackageFeatures.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useOutletContext} from "react-router-dom";
import {Checkbox, Loader} from "@mantine/core";
import Title from "../../LayoutComponents/Title";
import useTourOperatorItinerary from "../../../controllers/useTourOperatorItinerary";
import {toastError} from "../../../helpers/ErrorManager";
import {langRender} from "../../../helpers/i18n";

const ToPackageFeatures = ({_package}) => {
	const {t} = useTranslation();
	const {itinerary, setItinerary, isItineraryBlocked} = useOutletContext();
	const {editPackage} = useTourOperatorItinerary();

	const [loading, setLoading] = useState([]);


	const onEditFeature = async (feature_id, checked) => {
		let features = Array.isArray(_package.features) ? _package.features.filter(_id => _id !== feature_id) : [];
		if (checked) features.push(feature_id);

		const result = await editPackage({
			itinerary_id: itinerary._id,
			package_id: _package._id,
			_package: {
				..._package,
				features
			}
		});

		if (result.error || !result._package) return toastError(result.error, t);
		setItinerary(prev => ({...prev, packages: prev.packages.map(p => p._id === result._package._id ? result._package : p)}));
	}


	const hasFeatures = Array.isArray(itinerary?.features) && itinerary.features.length > 0;

	if (!itinerary || !_package) return <Loader/>
	if (!hasFeatures) return <Title size={3} className="my-10">{t('to.itinerary.no features found')}</Title>


	return (
		<div>
			<Title size={3} className="my-10">{t('to.itinerary.features')}</Title>
			{itinerary.features.map(feature => {
				let load = loading.includes(feature._id);
				return <Checkbox
					className="mb-5"
					icon={load ? ({className}) => <Loader className={className} size={"xs"}/> : undefined}
					disabled={load || isItineraryBlocked}
					key={feature._id}
					indeterminate={load}
					label={langRender(feature.name)}
					checked={Array.isArray(_package.features) && _package.features.includes(feature._id)}
					onChange={async e => {
						let checked = e.target.checked;
						setLoading(prev => prev.includes(feature._id) ? prev : [...prev, feature._id]);
						await onEditFeature(feature._id, checked);
						setLoading(prev => prev.filter(id => id !== feature._id));
					}}
				/>})}
		</div>
	);
};


export default ToPackageFeatures;