import React, {useMemo} from 'react';
import s from './AdminPage.module.css';
import useAuth from "../../../providers/UserProvider/useAuth";
import bases from "../../../routes/bases";
import MenuProvider from "../../../providers/MenuProvider/MenuProvider";
import {Outlet, useParams} from "react-router-dom";

const AdminPage = props => {
	const {getType} = useAuth();
	let {id: admin_id} = useParams();

	let type = getType();

	let elements = useMemo(() => {
		let a = `/`
		let r = [
			{name: 'profile', path: `${bases.admin}/${admin_id}`},
			{name: 'itineraries', path: `${bases.admin}/${admin_id}/${bases.admin_itineraries}`},
			{name: 'hotels', path: `${bases.admin}/${admin_id}/${bases.admin_hotels}`},
			{name: 'activities', path: `${bases.admin}/${admin_id}/${bases.admin_activities}`},
		];
		return r;
	}, [type]);


	//if(location.pathname === '/settings')return <Navigate to={"/settings/page"}/>
	return <MenuProvider elements={elements}>
		<Outlet/>
	</MenuProvider>
};


export default AdminPage;