import moment from "moment";
import {langRender} from "../../helpers/i18n";
import languages from "../../resources/json/languageList.json";
import i18n from "i18next";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import dayjs from "dayjs";


export const RangeDateRender = (date1, date2) => {
  return `${DateRender(date1)} - ${DateRender(date2)}`;
}

export const DateRender = (value) => {
  if(!value)return '';
  let date = dayjs.tz(value)
  if(!date.isValid())return '';
  return date.format('LL');
}

export const DateTimeRender = (value) => {
  let date = moment(value);
  if(!date.isValid())return '';
  return date.format('lll');
}

export const GenderRender = (value, row, {t}) => t(`gender_col.${value}`)
export const PhoneRender = (value) => <a className="link" href={`tel:${value}`}>{value}</a>
export const EmailRender = (value) => <a className="link" href={`mailto:${value}`}>{value}</a>
export const MoneyRender = ({value}) => {
  //const currency = window.localStorage.getItem('currency') || 'EUR';
  const [currency] = useLocalStorage('currency','EUR')

  const currencyFormat = new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency
  });

  return <>{isNaN(value) ? '' : currencyFormat.format(value)}</>;
}
export const BooleanRender = (value, row, {t}) => (value === 1 || value === '1' || value === true) ? <div className="boolean_yes">{t('yes')}</div> : <div className="boolean_no">{t('no')}</div>;
//export const LanguageRender = (lang, setLang) => v => typeof v === 'string' ? v : v[lang];
export const LanguageRender = v => langRender(v);
export const FullNameRenderer = (_, row) => `${row.name} ${row.surname || ''}`;
export const TypeRenderer = (value, row, {column}) => {
  if(Array.isArray(column.data)){
    return column.data.find(a => a.value === value+'')?.label || '';
  }else{
    return value;
  }
}
export const DocumentRender = (v, row) => {
  let type = row?.document?.type?.toUpperCase();
  type = type ? `${type}: ` : '';
  let number = row?.document?.number || '';
  return `${type} ${number}`
}


export const AddIconRenderer = icon => (value) => <div className="flex flex-nowrap items-center">
  <div className="mr-2">{icon}</div>
  {value}
</div>
export const HTMLRenderer = v => <div dangerouslySetInnerHTML={{__html: v}}/>;



export const LangListRenderer = (value) => {
  if(!value)return value;
  let r = languages[i18n.language];
  r = r[0] || r;
  return r.find(l => l.alpha2 === value)?.label;
}



export const defaultTypeRenders = {
  date: DateRender,
  list: TypeRenderer
};
