import React, {useEffect} from 'react';
import s from './ErrorCallbackComponent.module.css';
import Button from "../Inputs/Button_old";
import useLocale from "../../providers/LocaleProvider/useLocale";
import {useTranslation} from "react-i18next";
import useAdminController from "../../controllers/useAdminController/useAdminController";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../providers/UserProvider/useAuth";

const ErrorCallbackComponent = ({error, componentStack, resetErrorBoundary}) => {
  const {t} = useTranslation();
  const {checkSession} = useAdminController();
  const navigate = useNavigate();
  const {logout} = useAuth();

  console.log(error);
  const msg = (error.message ?? error)?.toLowerCase();


  useEffect(() => {
    if(msg !== 'unauthorized')return;
    (async () => {
      const r = await checkSession();
      if(!r){
        navigate('/');
        logout();
      }
    })();
  }, [msg])



  return (
    <div className="p-6">
      <h1 className="py-5">
        {t('errors.an error occurred')}: {t('errors.' + (msg))}
      </h1>
      <Button className="errorCallbackButton" onClick={resetErrorBoundary}>{t('try again')}</Button>
    </div>
  );
};


export default ErrorCallbackComponent;
