import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";

export const useSelectOptions = () => {
	const {t} = useTranslation();


	const adminTypeOptions = React.useMemo(() => [
		{value: 'admin', label: t('admin data.types.admin')},
		{value: 'to', label: t('admin data.types.to')}
	], []);

	const adminSuspendedOptions = React.useMemo(() => [
		{value: '0', label: t('no')},
		{value: '1', label: t('yes')},
	], []);

	const languageOptions = React.useMemo(() =>
		["es-ES", "en-US"].map(lng => (
			{value: lng, label: t("languageList." + lng)}
		)), [t]);

	const enterpriseOptions = useMemo(() => [
		{value: 'TT', label: 'Trippy Trekkers'},
		{value: 'TD', label: 'Trippy Divers'},
	], []);
	const hotelRegimeOptions = useMemo(() => [
		{label: t('hotel regime list.all included'), value: 'all included'},
		{label: t('hotel regime list.only accommodation'), value: 'only accommodation'},
		{label: t('hotel regime list.breakfast included'), value: 'breakfast included'},
		{label: t('hotel regime list.half board'), value: 'half board'},
		{label: t('hotel regime list.full board'), value: 'full board'},
	], []);

	return {adminTypeOptions, adminSuspendedOptions, languageOptions, enterpriseOptions, hotelRegimeOptions};

}

export default useSelectOptions;