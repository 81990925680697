import {ColumnType} from "antd/es/table";
import {ReservationTable} from "../types";
import {getTextFilter} from "../../../../util/table/filters/TableTextFilter";
import {getDateRangeFilter} from "../../../../util/table/filters/TableDateRangeFilter";
import bases from "../../../../routes/bases";
import {langRender} from "../../../../helpers/i18n";
import React from "react";
import {NavigateFunction} from "react-router-dom";
import {DateRender, MoneyRender} from "../../../Table/Renders";
import {getListFilter} from "../../../../util/table/filters/TableListFilter";
import {Tag} from "antd";
import {TableActions} from "../../../../util/table/components/TableActions";
import {Seller} from "../../TDSellers/types";
import {isCancellable, paidFilterOptions} from "../util";
import {LinkFormat} from "../../../Formatters/Formatters";

interface Props {
  products: any[]
  sellers: Seller[]
  navigate: NavigateFunction
  onEdit: (id: string) => void
  onDelete: (id: string) => void
  onCalendarCancel: (id: string) => void
  canWrite: boolean
}

export const reservationsColumns = ({navigate, sellers, products, onEdit, onDelete, onCalendarCancel, canWrite}: Props) => {
  return [
    {
      title: '',
      dataIndex: '_id',
      key: 'action',
      width: 30,
      render: (_id: string, reservation) => <TableActions onView={canWrite ? undefined : () => onEdit(_id) } onEdit={canWrite ? () => onEdit(_id) : undefined} onDelete={canWrite ? () => onDelete(_id) : undefined} onCalendarCancel={canWrite && isCancellable(reservation) ? () => onCalendarCancel(_id) : undefined}/>
    },
    {
      title: 'Referencia',
      dataIndex: 'reference',
      key: 'reference',
      sorter: true,
      ...getTextFilter<ReservationTable>({name: 'reference'})
    },
    {
      title: 'Cliente',
      dataIndex: 'client',
      key: 'client',
      sorter: true,
      render: (_, row) => <LinkFormat url={`/${bases.td.clients}/${row.client?._id}`} label={row.client?.full_name}/>,
      ...getTextFilter<ReservationTable>({name: 'client'})
    },
    {
      title: 'Precio',
      dataIndex: 'total_price',
      key: 'total_price',
      sorter: true,
      render: a => <MoneyRender value={a}/>
    },
    {
      title: 'Cantidad pagada',
      dataIndex: 'paid',
      key: 'paid',
      render: (paid, row) => {
        let percentage = Math.floor(paid / row?.total_price * 100);
        percentage = isNaN(percentage) ? 0 : percentage;

        return <Tag color={percentage >= 100 ? 'success' : 'warning'}>{percentage} %</Tag>
      },
      ...getListFilter({name: 'paid', options: paidFilterOptions})
    },
    {
      title: 'Vendedor',
      dataIndex: 'seller',
      key: 'seller',
      sorter: true,
      render: (seller) => {
        if(!seller)return ''
        return <LinkFormat url={`/${bases.td.sellers}/update/${seller?._id}`} label={seller.name}/>
      },
      ...getListFilter({name: 'seller', options: sellers.map((seller: Seller) => ({label: seller.name, value: seller._id}))})
    },
    {
      title: 'Fecha de actividad',
      dataIndex: 'activity_date',
      key: 'activity_date',
      sorter: true,
      render: (_, reservation) => DateRender(reservation.products[0] ? reservation.products[0].date : undefined),
      ...getDateRangeFilter<ReservationTable>({name: 'reservation_date'})
    },
    {
      title: "Actividades",
      dataIndex: 'product',
      key: 'product',
      sorter: true,
      ...getListFilter({name: 'product', options: products.map(a => ({label: a?.code || a?.name?.es, value: a._id}))}),
      render: (_, row) => {
        const reservationProducts = row.products
        if(!Array.isArray(reservationProducts))return <></>;
        return reservationProducts.filter(p => p._id).map(product => {
          const productData = products?.find(a => a._id === product?._id);
          if(!productData)return <></>

          const name = productData.code || langRender(productData.name)

          return <div key={productData._id}>
            ({product.adults + product.children})
            <span> </span>
            <div className="inline link" onClick={() => navigate(`/${bases.td.products}/${productData._id}`)}>{name}</div>
          </div>
        })
      }
    }
  ] satisfies ColumnType<ReservationTable>[]
}