import React, {useMemo, useState} from 'react';
import s from './InputWithLabel.module.css';
import Input from "../Inputs/Input/Input";

import classNames from 'classnames';

let cx = classNames.bind(s);

const InputWithLabel = React.forwardRef(({
  label,
  Component = Input,
  classNameContainer,
  containerClassName,
  noID = false,
  forwardClassNameContainer = true,
  ...props
}, ref) => {
  classNameContainer = classNameContainer || containerClassName;
  containerClassName = containerClassName || classNameContainer;
  const id = useMemo(() => Math.floor(Math.random() * 10000), []);
  const groupStyle = cx({
    [s.group]: true,
    [classNameContainer]: true
  });

  const forwardProps = {...props};
  if(forwardClassNameContainer)forwardProps.classNameContainer = classNameContainer;

  let componentProps = {};
  if(!noID)componentProps.id = props.name + id;

  return (
    <div className={groupStyle}>
      <label className="label" htmlFor={props.name + id}>{label}</label>
      <Component ref={ref} {...componentProps} {...forwardProps} />
    </div>
  )
});


export default InputWithLabel;
