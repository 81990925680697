import React, {useEffect, useMemo} from 'react';
import s from './TOTransports.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {useFormContext} from "react-hook-form";
import bases from "../../../routes/bases";
import sn from "../TONew/TONew.module.css";
import useTourOperatorItinerary from "../../../controllers/useTourOperatorItinerary";
import ToItineraryPlacesSummary from "../ToItineraryPlacesSummary";
import ToShowSmallTransport from "./ToShowSmallTransport";
import ToSmallActionButtons from "../ToSmallActionButtons";
import {icons} from "../../../helpers/transportIcons";
import Button from "../../Inputs/Button";
import {manageError, toastError} from "../../../helpers/ErrorManager";
import {ItPlace, OutletItineraryContext} from "../../../types/itineraries";
import Transport, {TransportProps} from "../../Transport/Transport";
import HeaderTitle from "../Components/HeaderTitle/HeaderTitle";
import Bullet from "../Itineraries/Bullet/Bullet";
import ToItAddBox from "../ToItAddBox";
import Footer from "../Components/Footer/Footer";
import {ErrorType} from "../../../types/errors";

const TOTransports = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {id} = useParams();

  const {newTransport, editTransport, deleteTransport} = useTourOperatorItinerary();

  const {itinerary, setStep, setItinerary, isItineraryBlocked} = useOutletContext<OutletItineraryContext>();


  useEffect(() => {
    setStep('transport');
  }, []);


  const onNewTransport = async (place: any) => {
    let data: any = {itinerary_id: id}
    data.day = place.day;
    data.place_id = place.id;
    data.type = 'airplane';
    const {error, id: transport_id, itinerary} = await newTransport(data);
    if (error || !transport_id) return toastError(error, t);

    setItinerary(itinerary);
  }

  const onEditTransport = async (transport_id: string, data: any) => {
    try {
      const transport = await editTransport({itinerary_id: id!, transport_id, transport: data});
      if (!transport) return manageError({message: 'unknown error'}, t);
      setItinerary(prev => ({
        ...prev,
        transports: prev.transports!.map(_transport => _transport._id !== transport._id ? _transport : transport)
      }));
    }catch(error){
      manageError(error as ErrorType, t)
    }
  }

  const onDeleteTransport = async (transport_id: string) => {
    const {error, itinerary} = await deleteTransport({itinerary_id: id!, transport_id});
    if (error || !itinerary) return toastError(error, t);
    setItinerary(itinerary);
  }

  const placesTransportSummary = useMemo(() => {
    if (!Array.isArray(itinerary?.transports)) return [];

    return itinerary.transports.filter(transport => transport.day !== undefined).map(transport => {
      let props: TransportProps = {
        transport: transport,
        variant: "small"
      };
      if (!isItineraryBlocked) {
        props.onEdit = async (type: any) => await onEditTransport(transport._id, {type});
        props.onDelete = async () => await onDeleteTransport(transport._id);
      }

      return {
        day: transport.day,
        place_id: transport.place_id,
        element: <Transport {...props}/>
      };
    })
  }, [itinerary]);


  const createPlaces = (it_places: ItPlace[]) => {
    let r: any[] = [];
    let places = it_places.sort((a, b) => {
      if (a.start < b.start) return -1;
      if (a.start > b.start) return 1;
      return 0;
    });

    places.forEach((place, i) => {
      if (place.start !== place.end) {
        let p: any = {
          day: place.start,
          ...place,
          id: i !== 1 ? place.id : place.id - 1
        }
        if (!r.length) {
          p.title = t('airport') + ' - ' + place.address!.label;
        } else {
          let prev = r[r.length - 1].address.label;
          if (prev === place.address!.label) return;
          p.title = prev + ' - ' + place.address!.label;
        }
        r.push(p);
      }
    });
    let last = r[r.length - 1];
    r.push({
      ...last,
      day: last.end,
      title: last.address.label + ' - ' + t('airport')
    });
    console.log(r);
    return r;
  }

  const places = useMemo(() => {
    if (!itinerary.places) return;
    return createPlaces(itinerary.places.sort((a, b) => {
      if (a.start < b.start) return -1;
      if (a.start > b.start) return 1;
      return 0;
    }));
  }, [itinerary]);


  return (
    <div className="container m-auto">
      <HeaderTitle>{t('to.header.transport')}</HeaderTitle>

      {!places?.length && <div className="font-bold text-2xl">Primera necesitas agregar días en la pestaña de Itinerario</div>}


      {places?.map((place: any, index) => {
        let bullet = place.bullet || place.day;
        let title = place.title || place.address?.label;
        let elements = placesTransportSummary
          .filter((ph: any) => ph.place_id === place.id && (!ph.day || ph.day === place.day))
          .map(el => el.element);

        return <div key={index}>
          <div className="flex flex-nowrap items-center">
            <Bullet>{bullet}</Bullet>
            <div className="ml-5">{title}</div>
          </div>
          <div className="my-5 flex flex-wrap items-center">
            {elements}
            <ToItAddBox disabled={isItineraryBlocked} onClick={() => onNewTransport(place)}/>
          </div>
        </div>
      })}

      <Footer onSave={() => navigate(`/${bases.tour_operator_itineraries}/${id}/activities`)}/>
    </div>
  );
};


export default TOTransports;
